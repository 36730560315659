/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type UpdateCornerMutationInput = {
    readonly id: string;
    readonly x?: number | null;
    readonly y?: number | null;
    readonly visibility?: Visibility | null;
    readonly clientMutationId?: string | null;
};
export type UpdateCornerMutationVariables = {
    input: UpdateCornerMutationInput;
};
export type UpdateCornerMutationResponse = {
    readonly updateCorner: {
        readonly corner: {
            readonly id?: string;
            readonly x?: number;
            readonly y?: number;
            readonly visibility?: Visibility;
        };
    } | null;
};
export type UpdateCornerMutation = {
    readonly response: UpdateCornerMutationResponse;
    readonly variables: UpdateCornerMutationVariables;
};



/*
mutation UpdateCornerMutation(
  $input: UpdateCornerMutationInput!
) {
  updateCorner(input: $input) {
    corner {
      __typename
      ... on CuboidCorner {
        id
        x
        y
        visibility
      }
      ... on QuadCorner {
        id
        x
        y
        visibility
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCornerMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "x",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "y",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "visibility",
  "args": null,
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateCornerMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateCorner",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCornerMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "corner",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "CuboidCorner",
                "selections": (v6/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "QuadCorner",
                "selections": (v6/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateCornerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateCorner",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCornerMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "corner",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "CuboidCorner",
                "selections": (v7/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "QuadCorner",
                "selections": (v7/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateCornerMutation",
    "id": null,
    "text": "mutation UpdateCornerMutation(\n  $input: UpdateCornerMutationInput!\n) {\n  updateCorner(input: $input) {\n    corner {\n      __typename\n      ... on CuboidCorner {\n        id\n        x\n        y\n        visibility\n      }\n      ... on QuadCorner {\n        id\n        x\n        y\n        visibility\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '08541aa2537d30e02701b7ef6b977ec0';
export default node;
