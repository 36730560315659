/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type CreatePalletPocketCornerMutationInput = {
    readonly x: number;
    readonly y: number;
    readonly visibility: Visibility;
    readonly palletId: string;
    readonly isLeftPocket: boolean;
    readonly isFrontFace: boolean;
    readonly clientMutationId?: string | null;
};
export type CreatePalletPocketCornerMutationVariables = {
    input: CreatePalletPocketCornerMutationInput;
};
export type CreatePalletPocketCornerMutationResponse = {
    readonly createPalletPocketCorner: {
        readonly pallet: {
            readonly id: string;
            readonly shape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly parentShape: {
                        readonly id: string;
                        readonly faces: ReadonlyArray<{
                            readonly id: string;
                            readonly palletPocketLeft: {
                                readonly id: string;
                                readonly corners: ReadonlyArray<{
                                    readonly id: string;
                                    readonly x: number;
                                    readonly y: number;
                                    readonly visibility: Visibility;
                                }>;
                            } | null;
                            readonly palletPocketRight: {
                                readonly id: string;
                                readonly corners: ReadonlyArray<{
                                    readonly id: string;
                                    readonly x: number;
                                    readonly y: number;
                                    readonly visibility: Visibility;
                                }>;
                            } | null;
                        }>;
                    };
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            };
            readonly frontPockets: {
                readonly id: string;
                readonly left: {
                    readonly id: string;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
                readonly right: {
                    readonly id: string;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
            } | null;
            readonly sidePockets: {
                readonly id: string;
                readonly left: {
                    readonly id: string;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
                readonly right: {
                    readonly id: string;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
            } | null;
        };
    } | null;
};
export type CreatePalletPocketCornerMutation = {
    readonly response: CreatePalletPocketCornerMutationResponse;
    readonly variables: CreatePalletPocketCornerMutationVariables;
};



/*
mutation CreatePalletPocketCornerMutation(
  $input: CreatePalletPocketCornerMutationInput!
) {
  createPalletPocketCorner(input: $input) {
    pallet {
      id
      shape {
        id
        faces {
          id
          side
          parentShape {
            id
            faces {
              id
              palletPocketLeft {
                id
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
              palletPocketRight {
                id
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
            }
          }
          corners {
            id
            x
            y
            visibility
          }
        }
      }
      frontPockets {
        id
        left {
          id
          corners {
            id
            x
            y
            visibility
          }
        }
        right {
          id
          corners {
            id
            x
            y
            visibility
          }
        }
      }
      sidePockets {
        id
        left {
          id
          corners {
            id
            x
            y
            visibility
          }
        }
        right {
          id
          corners {
            id
            x
            y
            visibility
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePalletPocketCornerMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "x",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "y",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "visibility",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  (v1/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "corners",
    "storageKey": null,
    "args": null,
    "concreteType": "QuadCorner",
    "plural": true,
    "selections": (v2/*: any*/)
  }
],
v4 = [
  (v1/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "left",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v3/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "right",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v3/*: any*/)
  }
],
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createPalletPocketCorner",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePalletPocketCornerMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pallet",
        "storageKey": null,
        "args": null,
        "concreteType": "LabeledPallet",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shape",
            "storageKey": null,
            "args": null,
            "concreteType": "Cuboid",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "faces",
                "storageKey": null,
                "args": null,
                "concreteType": "CuboidFace",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "side",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "parentShape",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Cuboid",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "faces",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CuboidFace",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "palletPocketLeft",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Quad",
                            "plural": false,
                            "selections": (v3/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "palletPocketRight",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Quad",
                            "plural": false,
                            "selections": (v3/*: any*/)
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "corners",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CuboidCorner",
                    "plural": true,
                    "selections": (v2/*: any*/)
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "frontPockets",
            "storageKey": null,
            "args": null,
            "concreteType": "PalletPocket",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sidePockets",
            "storageKey": null,
            "args": null,
            "concreteType": "PalletPocket",
            "plural": false,
            "selections": (v4/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreatePalletPocketCornerMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreatePalletPocketCornerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreatePalletPocketCornerMutation",
    "id": null,
    "text": "mutation CreatePalletPocketCornerMutation(\n  $input: CreatePalletPocketCornerMutationInput!\n) {\n  createPalletPocketCorner(input: $input) {\n    pallet {\n      id\n      shape {\n        id\n        faces {\n          id\n          side\n          parentShape {\n            id\n            faces {\n              id\n              palletPocketLeft {\n                id\n                corners {\n                  id\n                  x\n                  y\n                  visibility\n                }\n              }\n              palletPocketRight {\n                id\n                corners {\n                  id\n                  x\n                  y\n                  visibility\n                }\n              }\n            }\n          }\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n      }\n      frontPockets {\n        id\n        left {\n          id\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n        right {\n          id\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n      }\n      sidePockets {\n        id\n        left {\n          id\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n        right {\n          id\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'dd4936843757fb2f26557328b81f00e9';
export default node;
