import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LabelingActivityChart from "./LabelingActivityChart";
import LabelingActivityTable from "./LabelingActivityTable";

import { LabelingActivity_viewer } from "../../__generated__/LabelingActivity_viewer.graphql";

const styles = theme =>
  createStyles({
    wrapper: {
      margin: 48
    }
  });

interface Props extends WithStyles<typeof styles> {
  viewer: LabelingActivity_viewer;
}
class LabelingActivity extends React.Component<Props> {
  render() {
    const { viewer, classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <Typography variant="h4" gutterBottom>
          Labeling Activity
        </Typography>
        <LabelingActivityTable viewer={viewer} />
        <LabelingActivityChart viewer={viewer} />
      </div>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(LabelingActivity, {
    viewer: graphql`
      fragment LabelingActivity_viewer on Viewer {
        id
        ...LabelingActivityChart_viewer
        ...LabelingActivityTable_viewer
      }
    `
  })
);
