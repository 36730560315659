import React from "react";
import PropTypes from "prop-types";

import { createStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";

import { KnownObject } from "../../__generated__/FrameViewer_viewer.graphql";

export type KnownObjectView = KnownObject | "RAW";

const localStyles = theme =>
  createStyles({
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    toggleButton: {
      margin: 0
    },
    shortcutsPopover: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: 16
    },
    shortcutRow: {
      display: "flex",
      flexDirection: "row"
    },
    bold: {
      fontWeight: "bold"
    }
  });
const styles = combineStyles(localStyles, commonStyles);

const propTypes = {
  className: PropTypes.string
};
class SensorStreamWrapperPopup extends React.Component<typeof propTypes> {
  render() {
    const { classes } = this.props;

    return (
      /*These are defined in CanvasEventHandler*/
      <div className={classes.shortcutsPopover}>
        <div className={classes.bold}>Press and hold ALT plus the below</div>
        <div className={classes.bold} style={{ paddingTop: 8 }}>
          Labeling
        </div>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>l</span>: Toggle current &nbsp;
          <span className={classes.bold}>l</span>
          abeling category between boxes and pallets.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>Delete</span>: Delete selected label.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>Click corner</span>: Toggle corner
          visibility.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>Click face</span>: Toggle front/side
          face.
        </Typography>
        <span className={classes.bold} style={{ paddingTop: 8 }}>
          Control view
        </span>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>v</span>: Toggle &nbsp;
          <span className={classes.bold}>v</span>
          iew.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>r</span>
          :&nbsp;
          <span className={classes.bold}>R</span>
          otate view.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>h</span>
          :&nbsp;
          <span className={classes.bold}>H</span>
          ide labels
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>g</span>: Show&nbsp;
          <span className={classes.bold}>G</span>
          rid
        </Typography>
        <div style={{ paddingTop: 8 }} className={classes.bold}>
          Create face for selected label
        </div>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>f</span>: Start &nbsp;
          <span className={classes.bold}>f</span>
          ront face.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>t</span>: Start &nbsp;
          <span className={classes.bold}>t</span>
          op face.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>s</span>: Start right&nbsp;
          <span className={classes.bold}>s</span>
          ide's face.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>SHIFT+s</span>: Start left&nbsp;
          <span className={classes.bold}>s</span>
          ide's face.
        </Typography>
        <div style={{ paddingTop: 8 }} className={classes.bold}>
          Navigate
        </div>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>Left</span>: Previous filtered frame.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>Right</span>: Next filtered frame.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>&#123;</span>: Previous labeled frame.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>&#125;</span>: Next labeled frame.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>Down</span>: Previous frame.
        </Typography>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>Up</span>: Next frame.
        </Typography>
        <div style={{ paddingTop: 8 }} className={classes.bold}>
          Press and hold SHIFT plus the below
        </div>
        <Typography className={classes.shortcutRow}>
          <span className={classes.bold}>Click/Double click</span>: The click
          (or double click) will ignore any nearby corners, making it easier to
          create corners in a crowded area.
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(SensorStreamWrapperPopup);
