import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { RelayProp } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Draggable from "react-draggable";
import Typography from "@material-ui/core/Typography";
import upsertQAMutation from "../../../mutations/UpsertQAInspectionMutation";

import {
  FrameQADialog_frame,
  QACategory
} from "../../../__generated__/FrameQADialog_frame.graphql";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme =>
  createStyles({
    paper: {
      position: "absolute",
      left: 50,
      bottom: 50
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      paddingLeft: 16
    },
    title: {
      fontSize: 12,
      padding: 16
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start"
    }
  });

type Props = {
  classes: any;
  relay: RelayProp;
  open: boolean;
  viewer: {
    username: string;
    id: string;
    isVetted: string;
    userId: string;
  };
  frame: FrameQADialog_frame;
  onCancel: null | (() => void);
  onProceed: null | (() => void);
};
type State = {
  mutatingCategories: Set<QACategory>;
  labelerBeingEvaluated: string | null;
};

const QA_CATEGORY_TO_LABEL: Map<QACategory, string> = new Map([
  ["ALL_SIZEABLE_PALLETS_LABELED", "All pallets labeled"],
  ["CORNER_VISIBILITY_MARKED", "Corner visibility is correctly marked"],
  ["PALLET_TYPE_CORRECT", "The pallets all have the correct pallet type"],
  ["PALLET_SIDES", "The pallet sides (e.g. short/long) are correctly labeled"],
  ["PALLETS_CORNERS_ACCURATE", "All pallet corners are accurate"],
  [
    "PALLET_POCKETS_CORNERS_ACCURATE",
    "All pallets pocket corners are accurate"
  ],
  ["PALLETS_SURFACES_CORRECT", "All pallets have the correct Pallet Surface"]
]);

class FrameQADialog extends React.Component<Props, State> {
  state = {
    mutatingCategories: new Set(),
    labelerBeingEvaluated: null
  };

  constructor(props) {
    super(props);
    const { userId, isVetted } = props.viewer;
    if (!isVetted) {
      this.state.labelerBeingEvaluated = userId;
      return;
    }
    const { labelers } = this.props.frame;
    if (!labelers.length) {
      this.state.labelerBeingEvaluated = userId;
      return;
    }
    if (labelers.length === 1) {
      this.state.labelerBeingEvaluated = labelers[0].id;
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const requestedInUrl = searchParams.get("under-review");
    if (requestedInUrl) {
      this.state.labelerBeingEvaluated = userId;
      return;
    }
    this.state.labelerBeingEvaluated = userId;
  }

  render() {
    const {
      frame,
      open,
      onCancel,
      onProceed,
      viewer,
      classes,
      relay
    } = this.props;
    if (!frame || !open) {
      return null;
    }
    const {
      labelerBeingEvaluated: requestedLabelerBeingEvaluated
    } = this.state;
    const labelerBeingEvaluated = requestedLabelerBeingEvaluated || viewer.id;

    const { qaInspections: allQaInspections, labelers } = frame;
    const qaIsTrue: (arg0: QACategory) => boolean = category => {
      const qasForEvaluatedLabeler = allQaInspections.filter(
        qa =>
          qa.labeler.id === labelerBeingEvaluated &&
          qa.inspector.id === viewer.userId
      );
      if (!qasForEvaluatedLabeler) {
        return false;
      }
      const qaForCategory = qasForEvaluatedLabeler.find(
        qa => qa.category === category
      );
      if (!qaForCategory) {
        return false;
      }
      return qaForCategory.state === "TRUE";
    };
    return (
      <Draggable>
        <Card className={classes.paper}>
          <div className={classes.header}>
            <Typography className={classes.title}>QA on:</Typography>
            {viewer.isVetted && labelers.length && (
              <Select
                value={labelerBeingEvaluated}
                onChange={event =>
                  this.setState({
                    labelerBeingEvaluated: event.target.value as string
                  })
                }
              >
                {labelers.map(labeler => (
                  <MenuItem key={labeler.id} value={labeler.id}>
                    {labeler.username}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
          <CardContent className={classes.cardContent}>
            {Array.from(QA_CATEGORY_TO_LABEL.entries()).map(
              (categoryAndLabel, i) => {
                const category = categoryAndLabel[0];
                const label = categoryAndLabel[1];
                const isTrue = qaIsTrue(category);
                const { mutatingCategories } = this.state;

                return (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={isTrue}
                        disabled={mutatingCategories.has(category)}
                        onChange={() => {
                          mutatingCategories.add(category);
                          this.setState({
                            mutatingCategories
                          });
                          upsertQAMutation(
                            relay.environment,
                            {
                              category,
                              state: isTrue ? "FALSE" : "TRUE",
                              labelerId: labelerBeingEvaluated,
                              frameId: frame.id
                            },
                            () => {
                              mutatingCategories.delete(category);
                              this.setState({
                                mutatingCategories
                              });
                            }
                          );
                        }}
                        color="primary"
                      />
                    }
                    label={
                      <Typography color="textSecondary" variant="body1">
                        {label}
                      </Typography>
                    }
                  />
                );
              }
            )}
          </CardContent>
          <CardActions>
            {onCancel && (
              <Button onClick={() => onCancel && onCancel()} color="primary">
                Remain
              </Button>
            )}
            {onProceed && (
              <Button onClick={() => onProceed()} color="primary">
                Proceed
              </Button>
            )}
          </CardActions>
        </Card>
      </Draggable>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(FrameQADialog, {
    frame: graphql`
      fragment FrameQADialog_frame on Frame {
        id
        labelers {
          id
          username
        }
        qaInspections {
          inspector {
            id
            username
          }
          labeler {
            id
            username
          }
          category
          state
        }
      }
    `
  })
);
