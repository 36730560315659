import React from "react";
import { graphql, createRefetchContainer, RelayRefetchProp } from "react-relay";

import { Disposable } from "react-relay";
import {
  createStyles,
  WithStyles,
  withStyles,
  WithTheme
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LoadingScreen from "../common/LoadingScreen";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { LabelingActivityTable_viewer } from "../../__generated__/LabelingActivityTable_viewer.graphql";
import {
  KnownObject,
  CameraPosition
} from "../../__generated__/LabelingActivityTableRefetchQuery.graphql";
import { KNOWN_OBJECT_ENABLED } from "../../utils/Enums";
import DebouncedSelect from "../common/DebouncedSelect";

const styles = theme =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    inputRoot: {
      color: "inherit",
      padding: 7
    },
    inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 120,
        "&:focus": {
          width: 200
        }
      }
    },
    dropDownMenuItem: {
      textTransform: "capitalize"
    },
    selectFormControl: {
      margin: theme.spacing.unit,
      minWidth: 180,
      maxWidth: 300,
      textTransform: "capitalize"
    },
    wrapper: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      margin: 8,
      padding: 0
    },
    chartWrapper: {
      margin: 0,
      padding: 0,
      width: "100%",
      overflow: "visible"
    },
    toolbarRoot: {
      flexWrap: "wrap"
    }
  });

interface Props extends WithStyles<typeof styles>, WithTheme {
  viewer: LabelingActivityTable_viewer;
  relay: RelayRefetchProp;
}
type State = {
  pendingRefetch: Disposable | null;
  knownObjects: Array<KnownObject> | null;
  cameraPositions: Array<CameraPosition> | null;
  runName: string | null;
};

const ALL_CAMERA_POSITIONS: ReadonlyMap<CameraPosition, string> = new Map([
  ["PELVIS", "Pelvis"],
  ["GRIPPER", "Gripper"],
  ["HAND_HELD", "Hand-held"]
]);

class LabelingActivityTable extends React.Component<Props, State> {
  state = {
    pendingRefetch: null,
    knownObjects: null,
    cameraPositions: null,
    runName: null
  };
  render() {
    const { viewer, classes } = this.props;
    const { pendingRefetch, knownObjects, cameraPositions } = this.state;
    const { labelCounts } = viewer;
    const trainingCounts = labelCounts.find(c => c.datasetType === "TRAINING");
    const validationCounts = labelCounts.find(
      c => c.datasetType === "VALIDATION"
    );
    const testCounts = labelCounts.find(c => c.datasetType === "TEST");
    const overallCounts = labelCounts.find(c => !c.datasetType);
    if (!trainingCounts || !validationCounts || !testCounts || !overallCounts) {
      console.warn("Could not find all label counts.");
      return null;
    }
    return (
      <div className={classes.wrapper}>
        <AppBar color="default" position="static">
          <Toolbar classes={{ root: classes.toolbarRoot }}>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Label Counts
            </Typography>
            <FormControl className={classes.selectFormControl}>
              <InputLabel htmlFor="select-known-objects">Objects</InputLabel>

              <DebouncedSelect
                value={knownObjects || []}
                itemMap={KNOWN_OBJECT_ENABLED}
                onChangeDebounced={e => {
                  this.setState(
                    {
                      knownObjects: !e.target.value.length
                        ? null
                        : e.target.value
                    },
                    () => this._refetch()
                  );
                }}
              />
            </FormControl>
            <FormControl className={classes.selectFormControl}>
              <InputLabel htmlFor="select-camera-positions">
                Camera positions
              </InputLabel>

              <DebouncedSelect
                value={cameraPositions || []}
                itemMap={ALL_CAMERA_POSITIONS}
                onChangeDebounced={e => {
                  this.setState(
                    {
                      cameraPositions: !e.target.value.length
                        ? null
                        : e.target.value
                    },
                    () => this._refetch()
                  );
                }}
              />
            </FormControl>
            <Input
              placeholder="Run name..."
              onChange={e =>
                this.setState(
                  {
                    runName: e.target.value ? e.target.value : null
                  },
                  () => this._refetch()
                )
              }
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
            />
          </Toolbar>
        </AppBar>
        {pendingRefetch && <LoadingScreen />}
        {!pendingRefetch && (
          <React.Fragment>
            <div className={classes.chartWrapper}>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Dataset</TableCell>
                      <TableCell>Labels</TableCell>
                      <TableCell>Labeled Frames</TableCell>
                      <TableCell>Labeled Files</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>All</TableCell>
                      <TableCell>{overallCounts.numLabels}</TableCell>
                      <TableCell>{overallCounts.numLabeledFrames}</TableCell>
                      <TableCell>{overallCounts.numSensorStreams}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Training</TableCell>
                      <TableCell>{trainingCounts.numLabels}</TableCell>
                      <TableCell>{trainingCounts.numLabeledFrames}</TableCell>
                      <TableCell>{trainingCounts.numSensorStreams}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Validation</TableCell>
                      <TableCell>{validationCounts.numLabels}</TableCell>
                      <TableCell>{validationCounts.numLabeledFrames}</TableCell>
                      <TableCell>{validationCounts.numSensorStreams}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Test</TableCell>
                      <TableCell>{testCounts.numLabels}</TableCell>
                      <TableCell>{testCounts.numLabeledFrames}</TableCell>
                      <TableCell>{testCounts.numSensorStreams}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  _refetch = () => {
    const { pendingRefetch, knownObjects, runName, cameraPositions } =
      this.state;
    if (pendingRefetch) {
      pendingRefetch.dispose();
    }
    const refetchVariables = fragmentVariables => {
      return {
        knownObjects,
        runName,
        cameraPositions
      };
    };
    this.setState({
      pendingRefetch: this.props.relay.refetch(
        refetchVariables,
        null, // Use the refetchVariables as renderVariables
        () => this.setState({ pendingRefetch: null }),
        { force: true } // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
      )
    });
  };
}

export default withStyles(styles, { withTheme: true })(
  createRefetchContainer(
    LabelingActivityTable,
    {
      viewer: graphql`
        fragment LabelingActivityTable_viewer on Viewer
        @argumentDefinitions(
          knownObjects: { type: "[KnownObject!]", defaultValue: null }
          cameraPositions: { type: "[CameraPosition!]", defaultValue: null }
          lightingConditions: {
            type: "[LightingConditions!]"
            defaultValue: null
          }
          runName: { type: "String", defaultValue: null }
        ) {
          labelCounts(
            knownObjects: $knownObjects
            cameraPositions: $cameraPositions
            runName: $runName
          ) {
            datasetType
            numLabels
            numLabeledFrames
            numSensorStreams
          }
        }
      `
    },
    graphql`
      query LabelingActivityTableRefetchQuery(
        $knownObjects: [KnownObject!]
        $cameraPositions: [CameraPosition!]
        $runName: String
      ) {
        viewer {
          labelCounts(
            knownObjects: $knownObjects
            cameraPositions: $cameraPositions
            runName: $runName
          ) {
            datasetType
            numLabels
            numLabeledFrames
            numSensorStreams
          }
        }
      }
    `
  )
);
