/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type IssuesBar_frame = {
    readonly id: string;
    readonly indexInSensorStream: number;
    readonly issues: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"LabelingIssueSideBarListItem_issue">;
    }>;
    readonly sensorStream: {
        readonly issues: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"LabelingIssueSideBarListItem_issue">;
        }>;
    };
    readonly " $fragmentRefs": FragmentRefs<"CreateIssueDialog_frame">;
    readonly " $refType": "IssuesBar_frame";
};
export type IssuesBar_frame$data = IssuesBar_frame;
export type IssuesBar_frame$key = {
    readonly " $data"?: IssuesBar_frame$data;
    readonly " $fragmentRefs": FragmentRefs<"IssuesBar_frame">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "issues",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelingIssue",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "FragmentSpread",
      "name": "LabelingIssueSideBarListItem_issue",
      "args": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "IssuesBar_frame",
  "type": "Frame",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "indexInSensorStream",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sensorStream",
      "storageKey": null,
      "args": null,
      "concreteType": "SensorStream",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CreateIssueDialog_frame",
      "args": null
    }
  ]
};
})();
(node as any).hash = 'bfaec75afb789e0cedc63fcc89f36c2c';
export default node;
