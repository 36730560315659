import React from "react";


import { createStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import { LabelMutators } from "../LabelMutators";
import { LabelSelector } from "../SelectionState";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem
} from "@material-ui/core";

import { combineStyles, commonStyles } from "../../../../utils/CommonStyles";
import { Corner } from "../LabelMutators";

const localStyles = theme => createStyles({});
const styles = combineStyles(localStyles, commonStyles);

type Props = {
  classes: any;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  corner: Corner;
  idx: any;
  faceId: any;
};

type State = {
  expanded: boolean | null;
};

class LabelFaceCorner extends React.Component<Props, State> {
  state = {
    expanded: null
  };

  render() {
    const { faceId, labelSelector, classes, labelMutator, corner, idx } =
      this.props;
    if (!corner || !corner.id) return null;

    return (
      <ListItem
        divider
        dense
        key={corner.id}
        onMouseOver={() => labelSelector.hoverCorner(corner)}
        classes={{ root: classes.listItemRoot }}
      >
        <Typography color="textSecondary" variant="body1">
          Corner {(idx + 1).toString()}
        </Typography>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={corner.visibility === "VISIBLE"}
                onChange={() =>
                  labelMutator.corner.update({
                    id: corner.id,
                    x: corner.x,
                    y: corner.y,
                    // TODO(malcolm): Determine if corners are off-frame and
                    // toggle them to OFF_FRAME instead of OCCLUDED
                    visibility:
                      corner.visibility === "VISIBLE" ? "OCCLUDED" : "VISIBLE"
                  })
                }
                color="primary"
              />
            }
            label={
              <Typography color="textSecondary" variant="body1">
                Visible?
              </Typography>
            }
          />
          <IconButton
            color="default"
            onClick={() => labelMutator.face.removeCorner(faceId, corner.id)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </ListItem>
    );
  }
}
export default withStyles(styles)(LabelFaceCorner);
