/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SensorStreamFrameSliderIconRefetchQueryVariables = {
    frameID: string;
    skipImage?: boolean | null;
};
export type SensorStreamFrameSliderIconRefetchQueryResponse = {
    readonly frame: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"SensorStreamFrameSliderIcon_frame">;
    } | null;
};
export type SensorStreamFrameSliderIconRefetchQuery = {
    readonly response: SensorStreamFrameSliderIconRefetchQueryResponse;
    readonly variables: SensorStreamFrameSliderIconRefetchQueryVariables;
};



/*
query SensorStreamFrameSliderIconRefetchQuery(
  $frameID: ID!
  $skipImage: Boolean
) {
  frame: frameById(id: $frameID) {
    id
    ...SensorStreamFrameSliderIcon_frame_411g4K
  }
}

fragment SensorStreamFrameSliderIcon_frame_411g4K on Frame {
  id
  indexInSensorStream
  labeledPalletsCount
  timestamp
  labelers {
    id
    username
  }
  imageMetadata {
    id
    signedThumbnailUrl(skip: $skipImage)
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "frameID",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipImage",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "frameID"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SensorStreamFrameSliderIconRefetchQuery",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "frame",
        "name": "frameById",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Frame",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "SensorStreamFrameSliderIcon_frame",
            "args": [
              {
                "kind": "Variable",
                "name": "skipImage",
                "variableName": "skipImage"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SensorStreamFrameSliderIconRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "frame",
        "name": "frameById",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Frame",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "indexInSensorStream",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "labeledPalletsCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timestamp",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labelers",
            "storageKey": null,
            "args": null,
            "concreteType": "LabelerUser",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "imageMetadata",
            "storageKey": null,
            "args": null,
            "concreteType": "ImageMetadata",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "signedThumbnailUrl",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "skip",
                    "variableName": "skipImage"
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SensorStreamFrameSliderIconRefetchQuery",
    "id": null,
    "text": "query SensorStreamFrameSliderIconRefetchQuery(\n  $frameID: ID!\n  $skipImage: Boolean\n) {\n  frame: frameById(id: $frameID) {\n    id\n    ...SensorStreamFrameSliderIcon_frame_411g4K\n  }\n}\n\nfragment SensorStreamFrameSliderIcon_frame_411g4K on Frame {\n  id\n  indexInSensorStream\n  labeledPalletsCount\n  timestamp\n  labelers {\n    id\n    username\n  }\n  imageMetadata {\n    id\n    signedThumbnailUrl(skip: $skipImage)\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '04a4e71d373f982fe1c5b73a49cc93aa';
export default node;
