import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { DeleteFaceMutationInput } from "../__generated__/DeleteFaceMutation.graphql";

const mutation = graphql`
  mutation DeleteFaceMutation($input: DeleteFaceMutationInput!) {
    deleteFace(input: $input) {
      cuboid {
        id
        faces {
          id
        }
      }
      frame {
        labeledBoxes {
          id
        }
        labeledPallets {
          id
          frontPockets {
            id
            left {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
            right {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
          }
          sidePockets {
            id
            left {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
            right {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
          }
        }
      }
    }
  }
`;

function deleteFace(
  environment: Environment,
  input: DeleteFaceMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let face = store.get(input.id);
      if (!face) {
        return;
      }
      let existingShape = face.getLinkedRecord("parentShape");
      let existingFaces = existingShape.getLinkedRecords("faces");
      if (!existingFaces) {
        existingFaces = [];
      }
      let newFaces = existingFaces.filter(f => f && f.getDataID() !== input.id);
      existingShape.setLinkedRecords(newFaces, "faces");
    }
  });
}

export default deleteFace;
