import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { RelayProp } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { QA_CATEGORIES } from "../../../../utils/Enums";

import { labelFramePath } from "../../../../utils/Paths";
import FoxLink from "../../../links/FoxLink";
import updateLabelingIssue from "../../../../mutations/UpdateLabelingIssueMutation";

import {
  LabelingIssueSideBarListItem_issue,
  LabelingIssueStatus
} from "../../../../__generated__/LabelingIssueSideBarListItem_issue.graphql";

const styles = theme =>
  createStyles({
    root: {
      width: "100%"
    },
    itemText: {
      maxWidth: "70%"
    }
  });

interface Props extends WithStyles<typeof styles> {
  viewer: any;
  relay: RelayProp;
  viewingFrameId: string;
  issue: LabelingIssueSideBarListItem_issue;
}
class LabelingIssueSideBarListItem extends React.Component<Props> {
  _updateIssueStatus(rawStatus: string) {
    const status = (rawStatus as any) as LabelingIssueStatus;
    const { relay, issue } = this.props;
    updateLabelingIssue(relay.environment, { issueId: issue.id, status });
  }

  render() {
    const { viewer, classes, issue, viewingFrameId } = this.props;
    const { boxes, pallets, reporter, frame, sensorStream } = issue;
    const boxesText = !!boxes.length
      ? `box(es) ${boxes.map(b => b.number).join(", ")}`
      : "";
    const palletsText = !!pallets.length
      ? `pallet(s) ${pallets.map(p => p.number).join(", ")}`
      : "";
    const showLabelsText = !!boxesText.length || !!palletsText.length;
    const labelsText = !showLabelsText
      ? ""
      : ` for ${boxesText} ${palletsText}`;
    const multiFramesLabel = !frame ? " multiple frames" : "";
    const label = `${QA_CATEGORIES.get(issue.category) ||
      ""}${labelsText} for${multiFramesLabel}`;
    const link = (
      <FoxLink
        underline={"always"}
        to={labelFramePath(
          sensorStream.run.runName,
          sensorStream.sensorName,
          frame ? frame.indexInSensorStream : 0,
          { showIssues: "1" }
        )}
      >
        {frame && ` in frame ${frame.indexInSensorStream}`}
      </FoxLink>
    );
    const primary = (
      <p>
        {" "}
        {label} {link}{" "}
      </p>
    );
    const secondary = `Reported by ${
      viewer.username === reporter.username ? "you" : reporter.username || ""
    }. ${issue.description}`;
    return (
      <ListItem
        divider
        dense
        selected={issue.frame && issue.frame.id === viewingFrameId}
        key={issue.id}
        classes={{ container: classes.root }}
      >
        <ListItemText
          className={classes.itemText}
          primary={primary}
          secondary={secondary}
        />
        <ListItemSecondaryAction>
          <Select
            value={issue.status}
            onChange={e => this._updateIssueStatus(e.target.value as string)}
          >
            <MenuItem value={"NEEDS_LABELER_ATTENTION"}>
              <ListItemText primary="Needs work" />
            </MenuItem>
            <MenuItem value={"NEEDS_QA_ATTENTION"}>
              <ListItemText primary="Needs approval" />
            </MenuItem>
            <MenuItem value={"RESOLVED"}>
              <ListItemText primary="Resolved" />
            </MenuItem>
          </Select>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(LabelingIssueSideBarListItem, {
    issue: graphql`
      fragment LabelingIssueSideBarListItem_issue on LabelingIssue {
        id
        description
        category
        status
        reporter {
          username
        }
        pallets {
          number
        }
        boxes {
          number
        }
        sensorStream {
          sensorName
          run {
            runName
            source
          }
        }
        frame {
          id
          indexInSensorStream
        }
      }
    `
  })
);
