import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { DeleteLabeledPalletMutationInput } from "../__generated__/DeleteLabeledPalletMutation.graphql";

const mutation = graphql`
  mutation DeleteLabeledPalletMutation(
    $input: DeleteLabeledPalletMutationInput!
  ) {
    deleteLabeledPallet(input: $input) {
      frame {
        id
        labeledPallets {
          id
        }
        labeledBoxes {
          pallet {
            id
          }
        }
      }
    }
  }
`;

function deleteLabeledPallet(
  environment: Environment,
  input: DeleteLabeledPalletMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let label = store.get(input.id);
      if (!label) {
        return;
      }
      let existingFrame = label.getLinkedRecord("frame");
      let existingLabels = existingFrame.getLinkedRecords("labeledPallets");
      if (!existingLabels) {
        existingLabels = [];
      }
      let newLabels = existingLabels.filter(
        l => l && l.getDataID() !== input.id
      );
      existingFrame.setLinkedRecords(newLabels, "labeledPallets");
      const palletBoxes = label.getLinkedRecords("boxes") || [];
      palletBoxes.forEach(box => box && box.setValue(null, "pallet"));
    }
  });
}

export default deleteLabeledPallet;
