import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { UpdateLabeledItemMutationInput } from "../__generated__/UpdateLabeledItemMutation.graphql";

const mutation = graphql`
  mutation UpdateLabeledItemMutation($input: UpdateLabeledItemMutationInput!) {
    updateLabeledItem(input: $input) {
      labeledItem {
        id
        number
        objectType
        frame {
          id
          labeledPallets {
            id
            obstacles {
              id
              number
            }
          }
        }
        thwartedPallets {
          id
        }
        shape {
          id
          faces {
            id
            side
            corners {
              id
              x
              y
              visibility
            }
          }
        }
      }
    }
  }
`;

function updateLabeledItem(
  environment: Environment,
  input: UpdateLabeledItemMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let existingItem = store.get(input.id);
      if (!existingItem) {
        return;
      }
      if (input.knownObjectType) {
        existingItem.setValue(input.knownObjectType, "objectType");
      }
      if (input.thwartedPallets) {
        const existingThwartedPallets =
          existingItem.getLinkedRecords("thwartedPallets") || [];
        const newThwartedPalletIds = new Set(input.thwartedPallets.palletIds);
        const unthwartedPallets = existingThwartedPallets.filter(
          p => !newThwartedPalletIds.has(p.getDataID())
        );

        // Remove the item as an obstacle for the pallets that are no longer
        // thwarted
        for (const p of unthwartedPallets) {
          p.setLinkedRecords(
            (p.getLinkedRecords("obstacles") || []).filter(
              o => o.getDataID() !== input.id
            ),
            "obstacles"
          );
        }
        existingThwartedPallets.forEach(p =>
          newThwartedPalletIds.delete(p.getDataID())
        );
        for (const newPalletId of newThwartedPalletIds) {
          const newPallet = store.get(newPalletId);
          const obstacles = newPallet.getLinkedRecords("obstacles") || [];
          newPallet.setLinkedRecords([...obstacles, existingItem], "obstacles");
        }
        existingItem.setLinkedRecords(
          input.thwartedPallets.palletIds.map(id => store.get(id)),
          "thwartedPallets"
        );
      }
    }
  });
}

export default updateLabeledItem;
