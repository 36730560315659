/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type LabelFaceListItem_face = {
    readonly id: string;
    readonly side: FaceSide;
    readonly corners: ReadonlyArray<{
        readonly id: string;
        readonly x: number;
        readonly y: number;
        readonly visibility: Visibility;
    }>;
    readonly " $refType": "LabelFaceListItem_face";
};
export type LabelFaceListItem_face$data = LabelFaceListItem_face;
export type LabelFaceListItem_face$key = {
    readonly " $data"?: LabelFaceListItem_face$data;
    readonly " $fragmentRefs": FragmentRefs<"LabelFaceListItem_face">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "LabelFaceListItem_face",
  "type": "CuboidFace",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "side",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "corners",
      "storageKey": null,
      "args": null,
      "concreteType": "CuboidCorner",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "x",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "y",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "visibility",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = '89e3f9fe15c0aedffc421e9cf7f2548a';
export default node;
