import { Vector2, Vector3, Quaternion, Matrix3 } from "three";
import { intersect } from "mathjs";
import { Label } from "./LabelMutators";

export interface Viewer {
  username: string;
  isSupervisor: boolean;
  isQualityController: boolean;
}

interface CameraModel {
  height: number;
  width: number;
  cx: number;
  cy: number;
  fx: number;
  fy: number;
}

/**
 * Generate a quad about a given pose.
 * @param {number} distance  - The distance to the pose.
 * @param {Quaternion} orientation  - The pose's orientation.
 * @param {Vector3} position  - The pose's position.
 * @param {CameraModel} cameraModel - The frame's camera model.
 * @param {number} widthContext - The amount of image to include around the pose in the x-direction.
 * @param {number} heightContext - The amount of image to include around the pose in the y-direction.
 * @return {Array<Vector2>} A 4-element array of Vector2 wound CW from top right which can be used to e.g. crop an image around the given pose.
 */
export function poseToPalletQuad(
  distance: number,
  orientation: Quaternion,
  position: Vector3,
  cameraModel: CameraModel,
  widthContext: number = 1.8,
  heightContext: number = 2
): Array<Vector2> {
  const kLoadedPalletHeight = 2.5;
  const kMaxPalletWidth = 2.5;
  const kPalletWidth = 1.22;
  const totalWidth = kMaxPalletWidth * widthContext;
  const widthOffset = (totalWidth - kPalletWidth) / 2;
  const heightOffset = (heightContext - 1.0) * 0.5 * kLoadedPalletHeight;

  // Select a load depth as the more distant of 1m from the camera and half a
  // box from the front of the pallet.
  const kMinLoadDistance = 1;
  let loadDepth = 0;
  if (distance + loadDepth < kMinLoadDistance) {
    loadDepth = kMinLoadDistance - distance;
  }

  // Corners of the back face of the pallet load, including context. This is
  // what we crop the image to.
  const kLoadBackCorners: Array<Vector3> = [
    new Vector3(
      loadDepth,
      kPalletWidth + widthOffset,
      kLoadedPalletHeight + heightOffset
    ),
    new Vector3(loadDepth, kPalletWidth + widthOffset, -heightOffset),
    new Vector3(loadDepth, -widthOffset, -heightOffset),
    new Vector3(loadDepth, -widthOffset, kLoadedPalletHeight + heightOffset)
  ];

  return kLoadBackCorners.map((cornerPallet: Vector3) => {
    const cornerImage = cornerPallet.applyQuaternion(orientation).add(position);
    return new Vector2(
      cameraModel.cx + (cornerImage.x * cameraModel.fx) / cornerImage.z,
      cameraModel.cy + (cornerImage.y * cameraModel.fy) / cornerImage.z
    );
  });
}

/**
 * Compute the center of a 1-4 sided polygon.
 * @param {Array<Vector2>} corners An array of 1-4 corners wound CW.
 * @return {Vector2} The center of the given corners.
 */
export function computeCenter(corners: ReadonlyArray<Vector2>): Vector2 {
  if (!corners.length) {
    console.warn("Cannot compute center of empty list of corners");
    return new Vector2();
  }
  if (corners.length < 4) {
    return corners
      .reduce((accumulator: Vector2, currentValue: Vector2) =>
        accumulator.add(currentValue)
      )
      .divideScalar(corners.length);
  }
  // Determine the center of the quad.
  // See: http://jwilson.coe.uga.edu/EMT668/EMT668.Folders.F97/Patterson/EMT%20669/centroid%20of%20quad/Centroid.html
  const faceTris: Array<Array<Vector2>> = [0, 2, 1, 3].map(startIndex => [
    corners[startIndex],
    corners[(startIndex + 1) % 4],
    corners[(startIndex + 2) % 4]
  ]);
  let faceCentroids = faceTris.map(tri =>
    tri[0].add(tri[1]).add(tri[2]).divideScalar(3)
  );
  const xAndY = intersect(...faceCentroids.map(c => [c.x, c.y]));
  // intersect returns null if it cannot find an intersection. In this case,
  // return the average of the given corners.
  if (!xAndY) {
    return corners
      .reduce((accumulator: Vector2, currentValue: Vector2) =>
        accumulator.add(currentValue)
      )
      .divideScalar(corners.length);
  }
  return new Vector2(xAndY[0], xAndY[1]);
}

// Applies the given 3x3 transformation matrix bTA to the given vector.
export function transformVector2(vector: Vector2, aTB: Matrix3): Vector2 {
  return transformPoint(vector.x, vector.y, aTB);
}

// Applies the given 3x3 transformation matrix bTA to xA and yA.
export function transformPoint(xA: number, yA: number, bTA: Matrix3): Vector2 {
  const vA = new Vector3(xA, yA, 1);
  const vB = vA.applyMatrix3(bTA);
  return new Vector2(vB.x / vB.z, vB.y / vB.z);
}

// Returns the minimum distance between consecutive corners.
export function minEdgeLength(corners: ReadonlyArray<Vector2>): number {
  let minDistance = Number.MAX_SAFE_INTEGER;
  corners.forEach((corner: Vector2, idx) => {
    const lastCorner =
      idx === 0 ? corners[corners.length - 1] : corners[idx - 1];
    minDistance = Math.min(minDistance, corner.distanceTo(lastCorner));
  });
  return minDistance;
}

export function labelContainingCornerId(labels, cornerId): Label {
  return labels.find(
    label =>
      label?.shape?.faces.some(f => f.corners.some(c => c.id === cornerId)) ||
      label?.load?.faces.some(f => f.corners.some(c => c.id === cornerId))
  );
}

export function allFacesForLabel(label, includePockets?: boolean) {
  const faces = Array.from(label.shape.faces);
  if (label?.load?.faces) {
    for (const face of label.load.faces) {
      if (face.id) {
        faces.push(face);
      }
    }
  }
  if (includePockets && label.frontPockets) {
    label.frontPockets.left && faces.push(label.frontPockets.left);
    label.frontPockets.right && faces.push(label.frontPockets.right);
  }
  if (includePockets && label.sidePockets) {
    label.sidePockets.left && faces.push(label.sidePockets.left);
    label.sidePockets.right && faces.push(label.sidePockets.right);
  }
  return faces;
}
