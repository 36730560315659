import { isNil, isUndefined, isEmpty } from "lodash";
import { BucketDuration } from "../../__generated__/LabelingReportRefetchQuery.graphql";
import { Label } from "../components/common/canvas/LabelMutators";

import {
  KNOWN_OBJECT_BMW_BINS,
  KNOWN_OBJECT_BLOCK_SKIDS,
  KNOWN_OBJECT_PALLETS,
  KNOWN_OBJECT_ITEMS,
  KNOWN_OBJECT_BINS
} from "./Enums";
const ALL_TIME_DURATION = "all-time";

export const getDurationBefore = (duration, startTime) => {
  let returnTime = startTime;
  switch (duration) {
    case ALL_TIME_DURATION:
      returnTime = null;
      break;
    case "WEEK" as BucketDuration:
      returnTime = new Date(
        new Date(startTime.setDate(new Date(startTime).getDate() - 7)).setHours(
          0,
          0,
          0
        )
      );
      break;
    case "MONTH" as BucketDuration:
      returnTime = new Date(
        new Date(
          startTime.setMonth(new Date(startTime).getMonth() - 1)
        ).setHours(0, 0, 0)
      );
      break;
    case "DAY" as BucketDuration:
      returnTime = new Date(
        new Date(
          new Date(startTime).setDate(new Date(startTime).getDate() - 1)
        ).setHours(0, 0, 0)
      );
      break;
    case "YEAR" as BucketDuration:
      returnTime = new Date(
        new Date(
          new Date(startTime).setFullYear(new Date(startTime).getFullYear() - 1)
        ).setHours(0, 0, 0)
      );
      break;
    default:
      returnTime = startTime;
      break;
  }
  return returnTime;
};

export const getDurationAfter = (duration, startTime) => {
  let returnTime = startTime;
  switch (duration) {
    case ALL_TIME_DURATION:
      returnTime = null;
      break;
    case "WEEK" as BucketDuration:
      returnTime = new Date(
        startTime.setDate(new Date(startTime).getDate() + 7)
      );
      break;
    case "MONTH" as BucketDuration:
      returnTime = new Date(
        startTime.setMonth(new Date(startTime).getMonth() + 1)
      );
      break;
    case "DAY" as BucketDuration:
      returnTime = new Date(
        new Date(startTime).setDate(new Date(startTime).getDate() + 1)
      );
      break;
    case "YEAR" as BucketDuration:
      returnTime = new Date(
        new Date(startTime).setFullYear(new Date(startTime).getFullYear() + 1)
      );
      break;
    default:
      returnTime = startTime;
      break;
  }
  return returnTime;
};

export const isValid = (object: any): boolean => {
  return !isNil(object) && !isUndefined(object) && !isEmpty(object);
};

export const combineMaps = (map1, map2) => {
  const combinedMap = map1;
  map2.forEach(function (value, key) {
    combinedMap.set(key, value);
  });
  return combinedMap;
};

export const isPallet = (object: Label): boolean => {
  return (
    KNOWN_OBJECT_PALLETS.get(object.objectType) !== undefined ||
    KNOWN_OBJECT_BLOCK_SKIDS.get(object.objectType) !== undefined
  );
};

export const isBin = (object: Label): boolean => {
  return (
    KNOWN_OBJECT_BMW_BINS.get(object.objectType) !== undefined ||
    KNOWN_OBJECT_BINS.get(object.objectType) !== undefined
  );
};

export const isItem = (object: Label): boolean => {
  return KNOWN_OBJECT_ITEMS.get(object.objectType) !== undefined;
};
