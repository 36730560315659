/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type BucketDuration = "DAY" | "HOUR" | "MINUTE" | "MONTH" | "WEEK" | "YEAR" | "%future added value";
export type LabelingReportTableRefetchQueryVariables = {
    startTime?: unknown | null;
    duration?: BucketDuration | null;
};
export type LabelingReportTableRefetchQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly labelerOverviews: ReadonlyArray<{
            readonly labeler: {
                readonly username: string | null;
            } | null;
            readonly numPallets: number;
            readonly numItems: number;
            readonly numLoads: number | null;
            readonly numBins: number | null;
            readonly numLabels: number;
            readonly numIssues: number;
            readonly numOpenIssues: number;
            readonly numPockets: number;
        }>;
    } | null;
};
export type LabelingReportTableRefetchQuery = {
    readonly response: LabelingReportTableRefetchQueryResponse;
    readonly variables: LabelingReportTableRefetchQueryVariables;
};



/*
query LabelingReportTableRefetchQuery(
  $startTime: DateTime
  $duration: BucketDuration
) {
  viewer {
    id
    labelerOverviews(startTime: $startTime, duration: $duration) {
      labeler {
        username
        id
      }
      numPallets
      numItems
      numLoads
      numBins
      numLabels
      numIssues
      numOpenIssues
      numPockets
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "startTime",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "duration",
    "type": "BucketDuration",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "duration",
    "variableName": "duration"
  },
  {
    "kind": "Variable",
    "name": "startTime",
    "variableName": "startTime"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numPallets",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numItems",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numLoads",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numBins",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numLabels",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numIssues",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numOpenIssues",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numPockets",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LabelingReportTableRefetchQuery",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labelerOverviews",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "LabelerOverview",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "labeler",
                "storageKey": null,
                "args": null,
                "concreteType": "LabelerUser",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LabelingReportTableRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labelerOverviews",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "LabelerOverview",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "labeler",
                "storageKey": null,
                "args": null,
                "concreteType": "LabelerUser",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v1/*: any*/)
                ]
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "LabelingReportTableRefetchQuery",
    "id": null,
    "text": "query LabelingReportTableRefetchQuery(\n  $startTime: DateTime\n  $duration: BucketDuration\n) {\n  viewer {\n    id\n    labelerOverviews(startTime: $startTime, duration: $duration) {\n      labeler {\n        username\n        id\n      }\n      numPallets\n      numItems\n      numLoads\n      numBins\n      numLabels\n      numIssues\n      numOpenIssues\n      numPockets\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '32c43ccf9662b0e7eab478ec7d3849e0';
export default node;
