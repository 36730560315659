import React from "react";
import { graphql, createFragmentContainer } from "react-relay";

import classNames from "classnames";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ReportIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TimelineIcon from "@material-ui/icons/Timeline";
import AuthIcon from "@material-ui/icons/Lock";
import CreateAccountIcon from "@material-ui/icons/AccountBoxRounded";
import IssuesIcon from "@material-ui/icons/Feedback";

import { clearJwt } from "../../utils/Auth";
import {
  logInPath,
  signUpPath,
  dashboardPath,
  activityPath,
  reportsPath,
  issuesPath
} from "../../utils/Paths";
import FoxLink from "../links/FoxLink";
import { TopBar_viewer } from "../../__generated__/TopBar_viewer.graphql";

const styles = theme =>
  createStyles({
    toolbar: {
      width: "100%",
      paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    appBar: {
      height: 40,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36
    },
    menuButtonHidden: {
      display: "none"
    },
    title: {
      flexGrow: 1
    },
    showTopBarButton: {
      position: "absolute",
      top: 12,
      right: 24,
      zIndex: 100
    }
  });

interface Props extends WithStyles<typeof styles> {
  viewer: TopBar_viewer;
}
interface State {
  open: boolean;
  hidden: boolean;
}
class TopBar extends React.Component<Props, State> {
  state = {
    open: false,
    hidden: false
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes, viewer } = this.props;
    const { hidden, open } = this.state;
    const isLoggedIn = viewer && !viewer.isAnonymous;
    return (
      <React.Fragment>
        {hidden && (
          <Button
            variant="outlined"
            className={classes.showTopBarButton}
            onClick={() => this.setState({ hidden: false })}
          >
            Show Topbar
          </Button>
        )}
        {!hidden && (
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar disableGutters={!open} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                FoxBots
              </Typography>
              <Button
                onClick={() => this.setState({ hidden: true })}
                variant="outlined"
                size="small"
              >
                Hide
              </Button>
            </Toolbar>
          </AppBar>
        )}
        <Drawer open={open} onClose={() => this.handleDrawerClose()}>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {isLoggedIn && (
            <List>
              <div>
                <ListItem
                  button
                  component={FoxLink}
                  to={dashboardPath()}
                  underline="never"
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </div>
            </List>
          )}
          {isLoggedIn && (
            <List>
              <div>
                <ListItem
                  button
                  component={FoxLink}
                  to={activityPath()}
                  underline="never"
                >
                  <ListItemIcon>
                    <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Activity" />
                </ListItem>
              </div>
            </List>
          )}
          {isLoggedIn && (
            <List>
              <div>
                <ListItem
                  button
                  component={FoxLink}
                  to={issuesPath()}
                  underline="never"
                >
                  <ListItemIcon>
                    <IssuesIcon />
                  </ListItemIcon>
                  <ListItemText primary="Issues" />
                </ListItem>
              </div>
            </List>
          )}
          {isLoggedIn && (
            <List>
              <div>
                <ListItem
                  button
                  component={FoxLink}
                  to={reportsPath()}
                  underline="never"
                >
                  <ListItemIcon>
                    <ReportIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItem>
              </div>
            </List>
          )}
          <Divider />
          <List>
            {!isLoggedIn && (
              <div>
                <ListItem
                  button
                  component={FoxLink}
                  to={logInPath()}
                  underline="never"
                >
                  <ListItemIcon>
                    <AuthIcon />
                  </ListItemIcon>
                  <ListItemText primary="Log In" />
                </ListItem>
                <ListItem
                  button
                  component={FoxLink}
                  to={signUpPath()}
                  underline="never"
                >
                  <ListItemIcon>
                    <CreateAccountIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign Up" />
                </ListItem>
              </div>
            )}
            {isLoggedIn && (
              <div>
                <ListItem
                  button
                  onClick={() => {
                    clearJwt();
                    window.location = logInPath();
                  }}
                >
                  <ListItemIcon>
                    <AuthIcon />
                  </ListItemIcon>
                  <ListItemText primary="Log Out" />
                </ListItem>
              </div>
            )}
          </List>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(TopBar, {
    viewer: graphql`
      fragment TopBar_viewer on Viewer {
        id
        isAnonymous
      }
    `
  })
);
