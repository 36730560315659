import React from "react";
import { graphql, createFragmentContainer, RelayProp } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";

import { combineStyles, commonStyles } from "../../utils/CommonStyles";
import { sensorStreamPath } from "../../utils/Paths";
import FoxLink from "../links/FoxLink";
import updateSensorStream from "../../mutations/UpdateSensorStreamMutation";
import { SensorStreamListItem_sensorStream } from "../../__generated__/SensorStreamListItem_sensorStream.graphql";
import { Viewer } from "../common/canvas/Utils";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { isBin, isPallet } from "../../utils/CommonFunctions";
import SensorStreamListItemDropdown from "./SensorStreamListItemDropdown";

const localStyles = () =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      flex: 1,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    textUnLabeled: {},
    textLabeledByViewer: {
      fontWeight: "bolder",
      fontStyle: "italic"
    },
    textLabeledBySomeoneElse: {
      fontStyle: "italic"
    },
    titleText: {
      fontSize: "14px !important"
    },
    buttonText: {
      fontSize: "10px !important"
    },
    secondaryText: {
      whiteSpace: "pre",
      maxWidth: 100,
      display: "flex",
      flexDirection: "column"
    },
    secondaryContainer: {
      display: "flex",
      flexDirection: "column",
      width: 100
    },
    flexItem: {
      minHeight: 40,
      minWidth: 80
    },
    flexItemTitle: {
      minHeight: 40,
      minWidth: 250
    },
    adjustWidth: {
      marginRight: -100
    }
  });

const styles = combineStyles(localStyles, commonStyles);

export const getFiltersFromRunName = (fullName: string) => {
  if (!/^\d/.test(fullName)) {
    // incorrect input
    return {};
  }
  const words = fullName.split("_");
  if (words.length !== 6) {
    // improper input. Update if run names change on gcloud
    return {};
  }
  const [date, time, robotName] = words;
  const dateString = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(
    6,
    2
  )}T${time.substr(0, 2)}:${time.substr(2, 2)}:${time.substr(4, 2)}`;
  const dateTime = moment(dateString).format("MM/DD/YYYY, h:mm:ss a");
  return { date: dateTime, robotName: robotName };
};
interface Props extends WithStyles<typeof styles> {
  viewer: Viewer;
  relay: RelayProp;
  sensorStream: SensorStreamListItem_sensorStream;
  refresh: any;
}
class SensorStreamListItem extends React.Component<Props> {
  state = {
    localTextStyle: ""
  };

  componentDidMount() {
    this.setState({
      localTextStyle: this._sensorStreamTextStyle(this.props.sensorStream)
    });
  }

  _getCountsMessages = () => {
    const { labelSummary } = this.props.sensorStream;
    const pallets = labelSummary.labeledPalletsAll.filter(object =>
      // @ts-ignore
      isPallet(object)
    );
    const bins = labelSummary.labeledPalletsAll.filter(object => {
      // @ts-ignore
      return isBin(object);
    });
    const palletCountText = `\n${pallets.length} pallet${
      pallets.length !== 1 ? "s" : ""
    }`;
    const binCountText = `\n${bins.length} bin${bins.length !== 1 ? "s" : ""}`;
    return [palletCountText, binCountText];
  };
  _getTitleMessage = (name: string, directions: string, numFrames: number) => {
    const classname = this.state.localTextStyle;
    const html = [];
    html.push(
      <div className={classname} key={`run-item-title-${name}`}>
        Robot: {name.toUpperCase()}
      </div>
    );
    html.push(
      <div key={`run-item-frames-${name}`}>{numFrames} frames loaded</div>
    );
    html.push(<div key={`run-item-description-${name}`}>{directions}</div>);

    return html;
  };

  _getObjectsLabeledMessage = (): string => {
    const {
      sensorStream: {
        labelSummary: {
          labeledPocketCount,
          labeledItemsAll,
          labeledPalletsAll: labeledPalletsBinsAll
        }
      }
    } = this.props;
    const totalLabels =
      labeledItemsAll.length +
      labeledPalletsBinsAll.length +
      labeledPocketCount;
    const message = "\n" + totalLabels + ` total labels`;

    return message;
  };

  _sensorStreamTextStyle = (sensorStream): string => {
    const { classes } = this.props;
    const { labeledFrameCount, viewerLabeledFrameCount } =
      sensorStream.labelSummary;
    if (viewerLabeledFrameCount) {
      return `${classes.titleText} ${classes.textLabeledByViewer}`;
    } else if (labeledFrameCount) {
      return `${classes.titleText} ${classes.textLabeledBySomeoneElse}`;
    }
    return `${classes.titleText} ${classes.textUnLabeled}`;
  };

  _renderPrioritizeButton = () => {
    const { classes, sensorStream } = this.props;
    return (
      <ListItemSecondaryAction className={classes.secondaryContainer}>
        <Button
          className={classes.buttonText}
          onClick={() =>
            this._toggleSensorStreamPriority({
              id: sensorStream.id,
              labelingDirections: sensorStream.labelingDirections,
              labelingPriority: sensorStream.labelingPriority,
              lightingConditions: sensorStream.lightingConditions,
              allowExport: sensorStream.allowExport
            })
          }
          aria-label="Prioritize"
        >
          {sensorStream.labelingPriority === "HIGH"
            ? "DePrioritize"
            : "Prioritize"}
        </Button>
      </ListItemSecondaryAction>
    );
  };
  render() {
    const { viewer, classes, sensorStream } = this.props;
    const isSupervisor = viewer.isSupervisor;
    const { localTextStyle } = this.state;
    const { labelSummary, labelingDirections, issues } =
      this.props.sensorStream;

    const { updateTime, createTime } = sensorStream.run;
    if (!sensorStream.numFrames) {
      return null;
    }
    const { date, robotName } = getFiltersFromRunName(sensorStream.run.runName);
    const resolvedIssues = issues.filter(issue => {
      return issue.status === "RESOLVED";
    });
    const issuesText = `\n${issues.length} issue${
      issues.length !== 1 ? "s" : ""
    }, ${resolvedIssues.length} resolved`;
    const objectsText = this._getObjectsLabeledMessage();
    const listItemClass = `${classes.flexItem}`;
    const nameTitleText = this._getTitleMessage(
      robotName,
      labelingDirections,
      sensorStream.numFrames
    );
    const lastTextItemClass = isSupervisor
      ? `${classes.flexItemTitle}`
      : `${classes.flexItemTitle} ${classes.adjustWidth}`;
    const [palletCountText, binCountText] = this._getCountsMessages();

    return (
      <>
        <ListItem
          button
          disableRipple={true}
          key={sensorStream.id}
          component={FoxLink}
          className={classes.root}
          classes={{
            root:
              sensorStream.labelingPriority === "HIGH"
                ? classes.listItemHighPriority
                : classes.listItemNormalPriority,
            container: classes.root
          }}
          to={sensorStreamPath(
            sensorStream.run.runName,
            sensorStream.sensorName,
            sensorStream.frameNumbers[0]
          )}
        >
          {/* contents */}
          <ListItemText
            className={`${classes.flexItemTitle}`}
            classes={{
              primary: localTextStyle,
              secondary: classes.secondaryText
            }}
            primaryTypographyProps={{
              component: "div"
            }}
            secondaryTypographyProps={{
              component: "div"
            }}
            primary={sensorStream.run.runName}
            secondary={nameTitleText}
          />
          <ListItemText
            className={listItemClass}
            classes={{
              primary: localTextStyle,
              secondary: classes.secondaryText
            }}
            secondary={palletCountText}
          />
          <ListItemText
            className={listItemClass}
            classes={{
              primary: localTextStyle,
              secondary: classes.secondaryText
            }}
            secondary={binCountText}
          />
          <ListItemText
            className={listItemClass}
            classes={{
              primary: localTextStyle,
              secondary: classes.secondaryText
            }}
            secondary={`\n${labelSummary.labeledItemsAll.length} item${
              labelSummary.labeledItemCount !== 1 ? "s" : ""
            }`}
          />
          <ListItemText
            className={listItemClass}
            classes={{
              primary: localTextStyle,
              secondary: classes.secondaryText
            }}
            secondary={`\n${labelSummary.labeledPocketCount} pocket${
              labelSummary.labeledPocketCount !== 1 ? "s" : ""
            }`}
          />
          <ListItemText
            className={listItemClass}
            classes={{
              primary: localTextStyle,
              secondary: classes.secondaryText
            }}
            secondary={issuesText}
          />
          <Tooltip title={"Includes all four-cornered Labels"}>
            <ListItemText
              className={listItemClass}
              classes={{
                primary: localTextStyle,
                secondary: classes.secondaryText
              }}
              secondary={objectsText}
            />
          </Tooltip>
          <ListItemText
            className={lastTextItemClass}
            classes={{
              primary: localTextStyle,
              secondary: classes.secondaryText
            }}
            primary={`Run Date: ${date}`}
            secondary={`File created: ${moment(createTime).format(
              "MM/DD/YYYY, h:mm:ss a"
            )}\nFile updated: ${moment(updateTime).format(
              "MM/DD/YYYY, h:mm:ss a"
            )}`}
          />
          {isSupervisor && this._renderPrioritizeButton()}
        </ListItem>
        <ListItem
          button
          divider
          dense
          disableRipple={true}
          key={`${sensorStream.id}-sub`}
          classes={{
            root:
              sensorStream.labelingPriority === "HIGH"
                ? classes.listItemHighPriority
                : classes.listItemNormalPriority,
            container: classes.root
          }}
        >
          <SensorStreamListItemDropdown
            sensorStream={sensorStream}
            isSupervisor={isSupervisor}
            onClickSensorStreamExport={o =>
              this._toggleAllowSensorStreamExport(o)
            }
          />
        </ListItem>
      </>
    );
  }

  _toggleSensorStreamPriority(sensorStream) {
    updateSensorStream(this.props.relay.environment, {
      ...sensorStream,
      labelingPriority:
        sensorStream.labelingPriority === "UNSPECIFIED"
          ? "HIGH"
          : "UNSPECIFIED",
      allowExport: sensorStream.allowExport
    });
    this.props.refresh();
  }

  _toggleAllowSensorStreamExport(sensorStream) {
    updateSensorStream(this.props.relay.environment, {
      ...sensorStream,
      allowExport: !sensorStream.allowExport
    });
    this.props.refresh();
  }
}

export default withStyles(styles)(
  createFragmentContainer(SensorStreamListItem, {
    sensorStream: graphql`
      fragment SensorStreamListItem_sensorStream on SensorStream {
        id
        labelingDirections
        lightingConditions
        sensorName
        numFrames
        frameNumbers
        labelingPriority
        allowExport
        issues {
          ...LabelingIssueListItem_issue
          status
        }
        run {
          runName
          source
          createTime
          updateTime
        }
        labelSummary {
          labeledFrameCount
          viewerLabeledFrameCount
          labeledPocketCount
          labeledItemCount
          labeledPalletsAll {
            id
            objectType
            surfaceType
            author {
              id
              username
              shortName
            }
            frame {
              indexInSensorStream
            }
          }
          labeledItemsAll {
            id
            objectType
            author {
              id
              username
              shortName
            }
            frame {
              indexInSensorStream
            }
          }
        }
      }
    `
  })
);
