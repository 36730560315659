/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ListHighPrioritySensorStreams_viewer = {
    readonly id: string;
    readonly username: string | null;
    readonly isAnonymous: boolean | null;
    readonly isSupervisor: boolean | null;
    readonly highPrioritySensorStreams: {
        readonly pageInfo: {
            readonly endCursor: string | null;
            readonly hasNextPage: boolean;
        };
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"SensorStreamListItem_sensorStream">;
            } | null;
        } | null>;
    } | null;
    readonly " $refType": "ListHighPrioritySensorStreams_viewer";
};
export type ListHighPrioritySensorStreams_viewer$data = ListHighPrioritySensorStreams_viewer;
export type ListHighPrioritySensorStreams_viewer$key = {
    readonly " $data"?: ListHighPrioritySensorStreams_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ListHighPrioritySensorStreams_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ListHighPrioritySensorStreams_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": "countHighPrioritySensorStreams",
        "cursor": null,
        "direction": "forward",
        "path": [
          "highPrioritySensorStreams"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "countHighPrioritySensorStreams",
      "type": "Int",
      "defaultValue": 15
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAnonymous",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSupervisor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "highPrioritySensorStreams",
      "name": "__ListHighPrioritySensorStreams_highPrioritySensorStreams_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "SensorStreamConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SensorStreamEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SensorStream",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "SensorStreamListItem_sensorStream",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'c2b2e845f2d58b4428d5e6dc6bd773f5';
export default node;
