/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DeleteLabeledBoxMutationInput = {
    readonly id: string;
    readonly clientMutationId?: string | null;
};
export type DeleteLabeledBoxMutationVariables = {
    input: DeleteLabeledBoxMutationInput;
};
export type DeleteLabeledBoxMutationResponse = {
    readonly deleteLabeledBox: {
        readonly frame: {
            readonly id: string;
            readonly labeledBoxes: ReadonlyArray<{
                readonly id: string;
                readonly pallet: {
                    readonly id: string;
                    readonly boxes: ReadonlyArray<{
                        readonly id: string;
                    }>;
                } | null;
            }>;
        };
    } | null;
};
export type DeleteLabeledBoxMutation = {
    readonly response: DeleteLabeledBoxMutationResponse;
    readonly variables: DeleteLabeledBoxMutationVariables;
};



/*
mutation DeleteLabeledBoxMutation(
  $input: DeleteLabeledBoxMutationInput!
) {
  deleteLabeledBox(input: $input) {
    frame {
      id
      labeledBoxes {
        id
        pallet {
          id
          boxes {
            id
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteLabeledBoxMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteLabeledBox",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteLabeledBoxMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "frame",
        "storageKey": null,
        "args": null,
        "concreteType": "Frame",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labeledBoxes",
            "storageKey": null,
            "args": null,
            "concreteType": "LabeledBox",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pallet",
                "storageKey": null,
                "args": null,
                "concreteType": "LabeledPallet",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "boxes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabeledBox",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteLabeledBoxMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteLabeledBoxMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteLabeledBoxMutation",
    "id": null,
    "text": "mutation DeleteLabeledBoxMutation(\n  $input: DeleteLabeledBoxMutationInput!\n) {\n  deleteLabeledBox(input: $input) {\n    frame {\n      id\n      labeledBoxes {\n        id\n        pallet {\n          id\n          boxes {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '512d9ccca1c7ae84e7b6bc54f78d7a63';
export default node;
