/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QACategory = "ALL_SIZEABLE_PALLETS_LABELED" | "BOXES_CORNERS_ACCURATE" | "BOXES_OFF_PALLET_LABELED" | "BOXES_ON_PALLET_LABELED" | "BOX_TOPS_LABELED" | "CORNER_VISIBILITY_MARKED" | "PALLETS_CORNERS_ACCURATE" | "PALLETS_SURFACES_CORRECT" | "PALLET_POCKETS_CORNERS_ACCURATE" | "PALLET_SIDES" | "PALLET_TYPE_CORRECT" | "TARGETED_PALLET_LABELED" | "%future added value";
export type QAState = "FALSE" | "TRUE" | "%future added value";
export type UpsertQAInspectionMutationInput = {
    readonly labelerId: string;
    readonly frameId: string;
    readonly category: QACategory;
    readonly state: QAState;
    readonly clientMutationId?: string | null;
};
export type UpsertQAInspectionMutationVariables = {
    input: UpsertQAInspectionMutationInput;
};
export type UpsertQAInspectionMutationResponse = {
    readonly upsertQaInspection: {
        readonly qaInspection: {
            readonly frame: {
                readonly " $fragmentRefs": FragmentRefs<"FrameQADialog_frame">;
            };
            readonly category: QACategory;
            readonly labeler: {
                readonly id: string;
                readonly username: string | null;
            };
        } | null;
    } | null;
};
export type UpsertQAInspectionMutation = {
    readonly response: UpsertQAInspectionMutationResponse;
    readonly variables: UpsertQAInspectionMutationVariables;
};



/*
mutation UpsertQAInspectionMutation(
  $input: UpsertQAInspectionMutationInput!
) {
  upsertQaInspection(input: $input) {
    qaInspection {
      frame {
        ...FrameQADialog_frame
        id
      }
      category
      labeler {
        id
        username
      }
    }
  }
}

fragment FrameQADialog_frame on Frame {
  id
  labelers {
    id
    username
  }
  qaInspections {
    inspector {
      id
      username
    }
    labeler {
      id
      username
    }
    category
    state
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpsertQAInspectionMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "category",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "username",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labeler",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelerUser",
  "plural": false,
  "selections": (v4/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpsertQAInspectionMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "upsertQaInspection",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertQAInspectionMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "qaInspection",
            "storageKey": null,
            "args": null,
            "concreteType": "QAInspection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "frame",
                "storageKey": null,
                "args": null,
                "concreteType": "Frame",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "FrameQADialog_frame",
                    "args": null
                  }
                ]
              },
              (v2/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpsertQAInspectionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "upsertQaInspection",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertQAInspectionMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "qaInspection",
            "storageKey": null,
            "args": null,
            "concreteType": "QAInspection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "frame",
                "storageKey": null,
                "args": null,
                "concreteType": "Frame",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "labelers",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabelerUser",
                    "plural": true,
                    "selections": (v4/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "qaInspections",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "QAInspection",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "inspector",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LabelerUser",
                        "plural": false,
                        "selections": (v4/*: any*/)
                      },
                      (v5/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              (v2/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpsertQAInspectionMutation",
    "id": null,
    "text": "mutation UpsertQAInspectionMutation(\n  $input: UpsertQAInspectionMutationInput!\n) {\n  upsertQaInspection(input: $input) {\n    qaInspection {\n      frame {\n        ...FrameQADialog_frame\n        id\n      }\n      category\n      labeler {\n        id\n        username\n      }\n    }\n  }\n}\n\nfragment FrameQADialog_frame on Frame {\n  id\n  labelers {\n    id\n    username\n  }\n  qaInspections {\n    inspector {\n      id\n      username\n    }\n    labeler {\n      id\n      username\n    }\n    category\n    state\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '1a70410a4657f0635170884a66cd9a52';
export default node;
