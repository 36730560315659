/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_LabelingIssues_QueryVariables = {};
export type routes_LabelingIssues_QueryResponse = {
    readonly viewer: {
        readonly isAnonymous: boolean | null;
        readonly isApprovedForAccess: boolean | null;
        readonly " $fragmentRefs": FragmentRefs<"LabelingIssues_viewer">;
    } | null;
};
export type routes_LabelingIssues_Query = {
    readonly response: routes_LabelingIssues_QueryResponse;
    readonly variables: routes_LabelingIssues_QueryVariables;
};



/*
query routes_LabelingIssues_Query {
  viewer {
    isAnonymous
    isApprovedForAccess
    ...LabelingIssues_viewer
    id
  }
}

fragment LabelingIssueListItem_issue on LabelingIssue {
  id
  description
  category
  status
  reporter {
    username
    id
  }
  labeler {
    username
    id
  }
  pallets {
    number
    id
  }
  boxes {
    number
    id
  }
  sensorStream {
    sensorName
    run {
      runName
      id
    }
    id
  }
  frame {
    indexInSensorStream
    id
  }
}

fragment LabelingIssues_viewer on Viewer {
  id
  ...ListIssuesForViewer_viewer
  ...ListIssuesByViewer_viewer
}

fragment ListIssuesByViewer_viewer on Viewer {
  id
  issuesByViewer: issues(first: 15, onlyByViewer: true, statuses: [NEEDS_LABELER_ATTENTION, NEEDS_QA_ATTENTION]) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        ...LabelingIssueListItem_issue
        description
        frame {
          id
        }
        __typename
      }
      cursor
    }
  }
}

fragment ListIssuesForViewer_viewer on Viewer {
  id
  issuesForViewer: issues(first: 15, onlyForViewer: true, statuses: [NEEDS_LABELER_ATTENTION, NEEDS_QA_ATTENTION]) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ...LabelingIssueListItem_issue
        description
        frame {
          id
        }
        __typename
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAnonymous",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isApprovedForAccess",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 15
},
v4 = {
  "kind": "Literal",
  "name": "statuses",
  "value": [
    "NEEDS_LABELER_ATTENTION",
    "NEEDS_QA_ATTENTION"
  ]
},
v5 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "onlyForViewer",
    "value": true
  },
  (v4/*: any*/)
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasNextPage",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "endCursor",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "username",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
],
v9 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "number",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
],
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "edges",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelingIssueEdge",
  "plural": true,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "LabelingIssue",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "category",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "reporter",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": false,
          "selections": (v8/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeler",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": false,
          "selections": (v8/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pallets",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledPallet",
          "plural": true,
          "selections": (v9/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "boxes",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledBox",
          "plural": true,
          "selections": (v9/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "sensorStream",
          "storageKey": null,
          "args": null,
          "concreteType": "SensorStream",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "sensorName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "run",
              "storageKey": null,
              "args": null,
              "concreteType": "Run",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "runName",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/)
              ]
            },
            (v2/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "frame",
          "storageKey": null,
          "args": null,
          "concreteType": "Frame",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "indexInSensorStream",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/)
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "__typename",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cursor",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "onlyByViewer",
    "value": true
  },
  (v4/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routes_LabelingIssues_Query",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "LabelingIssues_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes_LabelingIssues_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "issuesForViewer",
            "name": "issues",
            "storageKey": "issues(first:15,onlyForViewer:true,statuses:[\"NEEDS_LABELER_ATTENTION\",\"NEEDS_QA_ATTENTION\"])",
            "args": (v5/*: any*/),
            "concreteType": "LabelingIssueConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": "issuesForViewer",
            "name": "issues",
            "args": (v5/*: any*/),
            "handle": "connection",
            "key": "ListIssuesForViewer_issuesForViewer",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": "issuesByViewer",
            "name": "issues",
            "storageKey": "issues(first:15,onlyByViewer:true,statuses:[\"NEEDS_LABELER_ATTENTION\",\"NEEDS_QA_ATTENTION\"])",
            "args": (v11/*: any*/),
            "concreteType": "LabelingIssueConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": "issuesByViewer",
            "name": "issues",
            "args": (v11/*: any*/),
            "handle": "connection",
            "key": "ListIssuesByViewer_issuesByViewer",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routes_LabelingIssues_Query",
    "id": null,
    "text": "query routes_LabelingIssues_Query {\n  viewer {\n    isAnonymous\n    isApprovedForAccess\n    ...LabelingIssues_viewer\n    id\n  }\n}\n\nfragment LabelingIssueListItem_issue on LabelingIssue {\n  id\n  description\n  category\n  status\n  reporter {\n    username\n    id\n  }\n  labeler {\n    username\n    id\n  }\n  pallets {\n    number\n    id\n  }\n  boxes {\n    number\n    id\n  }\n  sensorStream {\n    sensorName\n    run {\n      runName\n      id\n    }\n    id\n  }\n  frame {\n    indexInSensorStream\n    id\n  }\n}\n\nfragment LabelingIssues_viewer on Viewer {\n  id\n  ...ListIssuesForViewer_viewer\n  ...ListIssuesByViewer_viewer\n}\n\nfragment ListIssuesByViewer_viewer on Viewer {\n  id\n  issuesByViewer: issues(first: 15, onlyByViewer: true, statuses: [NEEDS_LABELER_ATTENTION, NEEDS_QA_ATTENTION]) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...LabelingIssueListItem_issue\n        description\n        frame {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment ListIssuesForViewer_viewer on Viewer {\n  id\n  issuesForViewer: issues(first: 15, onlyForViewer: true, statuses: [NEEDS_LABELER_ATTENTION, NEEDS_QA_ATTENTION]) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        ...LabelingIssueListItem_issue\n        description\n        frame {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '67c8fac7e5b73226c18871613780edda';
export default node;
