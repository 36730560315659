/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type ItemDetails_item = {
    readonly id: string;
    readonly shape: {
        readonly faces: ReadonlyArray<{
            readonly id: string;
            readonly side: FaceSide;
            readonly corners: ReadonlyArray<{
                readonly id: string;
                readonly x: number;
                readonly y: number;
                readonly visibility: Visibility;
            }>;
        }>;
    };
    readonly number: number;
    readonly objectType: KnownObject;
    readonly thwartedPallets: ReadonlyArray<{
        readonly id: string;
        readonly number: number;
    }>;
    readonly frame: {
        readonly labeledPallets: ReadonlyArray<{
            readonly id: string;
            readonly number: number;
        }>;
    };
    readonly " $refType": "ItemDetails_item";
};
export type ItemDetails_item$data = ItemDetails_item;
export type ItemDetails_item$key = {
    readonly " $data"?: ItemDetails_item$data;
    readonly " $fragmentRefs": FragmentRefs<"ItemDetails_item">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "ItemDetails_item",
  "type": "LabeledItem",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "shape",
      "storageKey": null,
      "args": null,
      "concreteType": "Cuboid",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "faces",
          "storageKey": null,
          "args": null,
          "concreteType": "CuboidFace",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "side",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "corners",
              "storageKey": null,
              "args": null,
              "concreteType": "CuboidCorner",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "x",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "y",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "visibility",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "objectType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "thwartedPallets",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledPallet",
      "plural": true,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "frame",
      "storageKey": null,
      "args": null,
      "concreteType": "Frame",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeledPallets",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledPallet",
          "plural": true,
          "selections": (v2/*: any*/)
        }
      ]
    }
  ]
};
})();
(node as any).hash = '2b964301b2cadf312a05eb7b10fc8e7b';
export default node;
