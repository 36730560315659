/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DatasetType = "TEST" | "TRAINING" | "VALIDATION" | "%future added value";
export type LabelingActivityTable_viewer = {
    readonly labelCounts: ReadonlyArray<{
        readonly datasetType: DatasetType | null;
        readonly numLabels: number | null;
        readonly numLabeledFrames: number | null;
        readonly numSensorStreams: number | null;
    }>;
    readonly " $refType": "LabelingActivityTable_viewer";
};
export type LabelingActivityTable_viewer$data = LabelingActivityTable_viewer;
export type LabelingActivityTable_viewer$key = {
    readonly " $data"?: LabelingActivityTable_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"LabelingActivityTable_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "LabelingActivityTable_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "knownObjects",
      "type": "[KnownObject!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "cameraPositions",
      "type": "[CameraPosition!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "lightingConditions",
      "type": "[LightingConditions!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "runName",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labelCounts",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "cameraPositions",
          "variableName": "cameraPositions"
        },
        {
          "kind": "Variable",
          "name": "knownObjects",
          "variableName": "knownObjects"
        },
        {
          "kind": "Variable",
          "name": "runName",
          "variableName": "runName"
        }
      ],
      "concreteType": "LabelCounts",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "datasetType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numLabels",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numLabeledFrames",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numSensorStreams",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '7a0ec4ee13811e082e0ea77a8e56350f';
export default node;
