/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type LabelFaceList_label = {
    readonly number: number;
    readonly objectType: KnownObject;
    readonly shape: {
        readonly faces: ReadonlyArray<{
            readonly corners: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly side: FaceSide;
            readonly " $fragmentRefs": FragmentRefs<"LabelFaceListItem_face">;
        }>;
    };
    readonly id?: string;
    readonly load?: {
        readonly id: string;
        readonly faces: ReadonlyArray<{
            readonly corners: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly id: string;
            readonly side: FaceSide;
            readonly " $fragmentRefs": FragmentRefs<"LabelFaceListItem_face">;
        }>;
    } | null;
    readonly frontPockets?: {
        readonly id: string;
        readonly left: {
            readonly id: string;
        } | null;
        readonly right: {
            readonly id: string;
        } | null;
    } | null;
    readonly sidePockets?: {
        readonly id: string;
        readonly left: {
            readonly id: string;
        } | null;
        readonly right: {
            readonly id: string;
        } | null;
    } | null;
    readonly " $refType": "LabelFaceList_label";
};
export type LabelFaceList_label$data = LabelFaceList_label;
export type LabelFaceList_label$key = {
    readonly " $data"?: LabelFaceList_label$data;
    readonly " $fragmentRefs": FragmentRefs<"LabelFaceList_label">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "corners",
  "storageKey": null,
  "args": null,
  "concreteType": "CuboidCorner",
  "plural": true,
  "selections": (v1/*: any*/)
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "side",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "FragmentSpread",
  "name": "LabelFaceListItem_face",
  "args": null
},
v5 = [
  (v0/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "left",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v1/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "right",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v1/*: any*/)
  }
];
return {
  "kind": "Fragment",
  "name": "LabelFaceList_label",
  "type": "FrameLabel",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "objectType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "shape",
      "storageKey": null,
      "args": null,
      "concreteType": "Cuboid",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "faces",
          "storageKey": null,
          "args": null,
          "concreteType": "CuboidFace",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "LabeledBox",
      "selections": (v1/*: any*/)
    },
    {
      "kind": "InlineFragment",
      "type": "LabeledPallet",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "load",
          "storageKey": null,
          "args": null,
          "concreteType": "Cuboid",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "faces",
              "storageKey": null,
              "args": null,
              "concreteType": "CuboidFace",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v0/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "frontPockets",
          "storageKey": null,
          "args": null,
          "concreteType": "PalletPocket",
          "plural": false,
          "selections": (v5/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "sidePockets",
          "storageKey": null,
          "args": null,
          "concreteType": "PalletPocket",
          "plural": false,
          "selections": (v5/*: any*/)
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'ee1ca80ec395b3bff96acd2d60bb1797';
export default node;
