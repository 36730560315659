/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DeleteLabeledPalletMutationInput = {
    readonly id: string;
    readonly clientMutationId?: string | null;
};
export type DeleteLabeledPalletMutationVariables = {
    input: DeleteLabeledPalletMutationInput;
};
export type DeleteLabeledPalletMutationResponse = {
    readonly deleteLabeledPallet: {
        readonly frame: {
            readonly id: string;
            readonly labeledPallets: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly labeledBoxes: ReadonlyArray<{
                readonly pallet: {
                    readonly id: string;
                } | null;
            }>;
        };
    } | null;
};
export type DeleteLabeledPalletMutation = {
    readonly response: DeleteLabeledPalletMutationResponse;
    readonly variables: DeleteLabeledPalletMutationVariables;
};



/*
mutation DeleteLabeledPalletMutation(
  $input: DeleteLabeledPalletMutationInput!
) {
  deleteLabeledPallet(input: $input) {
    frame {
      id
      labeledPallets {
        id
      }
      labeledBoxes {
        pallet {
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteLabeledPalletMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labeledPallets",
  "storageKey": null,
  "args": null,
  "concreteType": "LabeledPallet",
  "plural": true,
  "selections": (v3/*: any*/)
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pallet",
  "storageKey": null,
  "args": null,
  "concreteType": "LabeledPallet",
  "plural": false,
  "selections": (v3/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteLabeledPalletMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteLabeledPallet",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteLabeledPalletMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "frame",
            "storageKey": null,
            "args": null,
            "concreteType": "Frame",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "labeledBoxes",
                "storageKey": null,
                "args": null,
                "concreteType": "LabeledBox",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteLabeledPalletMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteLabeledPallet",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteLabeledPalletMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "frame",
            "storageKey": null,
            "args": null,
            "concreteType": "Frame",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "labeledBoxes",
                "storageKey": null,
                "args": null,
                "concreteType": "LabeledBox",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteLabeledPalletMutation",
    "id": null,
    "text": "mutation DeleteLabeledPalletMutation(\n  $input: DeleteLabeledPalletMutationInput!\n) {\n  deleteLabeledPallet(input: $input) {\n    frame {\n      id\n      labeledPallets {\n        id\n      }\n      labeledBoxes {\n        pallet {\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3168ce74851b386075b63d256d17d43d';
export default node;
