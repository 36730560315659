/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type LabelingPriority = "HIGH" | "LOW" | "UNSPECIFIED" | "%future added value";
export type RunSource = "BD_FIELD_TRIP" | "BD_TRIAL_RUN" | "FOX_FIELD_TRIP" | "FOX_OFFICE" | "UNKNOWN" | "%future added value";
export type ListSensorStreams_viewer = {
    readonly username: string | null;
    readonly isAnonymous: boolean | null;
    readonly isSupervisor: boolean | null;
    readonly allSensorStreams: {
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly numFrames: number;
                readonly labelingPriority: LabelingPriority;
                readonly createTime: unknown;
                readonly updateTime: unknown;
                readonly run: {
                    readonly id: string;
                    readonly runName: string;
                    readonly source: RunSource;
                    readonly createTime: unknown;
                    readonly updateTime: unknown;
                    readonly knownObjects: ReadonlyArray<KnownObject>;
                };
                readonly " $fragmentRefs": FragmentRefs<"SensorStreamListItem_sensorStream">;
            } | null;
        } | null>;
    } | null;
    readonly " $refType": "ListSensorStreams_viewer";
};
export type ListSensorStreams_viewer$data = ListSensorStreams_viewer;
export type ListSensorStreams_viewer$key = {
    readonly " $data"?: ListSensorStreams_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ListSensorStreams_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createTime",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updateTime",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ListSensorStreams_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": "countAllSensorStreams",
        "cursor": null,
        "direction": "forward",
        "path": [
          "allSensorStreams"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "countAllSensorStreams",
      "type": "Int",
      "defaultValue": 20
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAnonymous",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSupervisor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "allSensorStreams",
      "name": "__ListSensorStreams_allSensorStreams_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "SensorStreamConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SensorStreamEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SensorStream",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "numFrames",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "labelingPriority",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "run",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Run",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "runName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "source",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "knownObjects",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "SensorStreamListItem_sensorStream",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'a4c32bfcae605917ecc809adb75e7305';
export default node;
