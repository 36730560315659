import React from "react";
import { graphql, createFragmentContainer } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { isEmpty } from "lodash";

import { LabelSelector } from "../SelectionState";
import { FaceSide, LabelMutators } from "../LabelMutators";
import { LABEL_SIDE_NAMES, PALLET_FACE_TYPES } from "../../../../utils/Enums";

import LabelFaceListItem from "./LabelFaceListItem";
import { LabelFaceList_label } from "../../../../__generated__/LabelFaceList_label.graphql";
import { Viewer } from "../Utils";

const styles = theme =>
  createStyles({
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "baseline"
    },
    leftOrRightRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: 38
    },
    labelDetailsText: {
      textTransform: "capitalize"
    },
    list: {
      paddingTop: 0,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 0,
      zIndex: 100,
      background: "inherit"
    },
    button: {
      margin: theme.spacing.unit,
      padding: 0,
      "& span": {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between"
    }
  });

type Props = {
  classes: any;
  viewer: Viewer;
  label: LabelFaceList_label;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
};
const CreateSideListItem = props => {
  const { faceName, label, onClick, classes, isLoad } = props;

  const isDisabled = isLoad
    ? label.load.faces[0].corners.length !== 4
    : label.shape.faces[0].corners.length !== 4;
  return (
    <ListItem dense className={classes.leftOrRightRow}>
      <ListItemText
        secondary={`Create ${PALLET_FACE_TYPES.get(faceName as FaceSide)}`}
      />
      <div className={classes.row}>
        <Button
          onClick={() => onClick(label.id, faceName, true, isLoad)}
          size={"small"}
          variant="outlined"
          className={classes.button}
          disabled={isDisabled}
        >
          Left
        </Button>
        <Button
          onClick={() => onClick(label.id, faceName, false, isLoad)}
          size={"small"}
          variant="outlined"
          className={classes.button}
          disabled={isDisabled}
        >
          Right
        </Button>
      </div>
    </ListItem>
  );
};
class LabelFaceList extends React.Component<Props> {
  render() {
    const { classes, viewer, label, labelSelector, labelMutator } = this.props;
    const front = label.shape.faces.find(f => f.side === "FRONT");
    const top = label.shape.faces.find(f => f.side === "TOP");
    const side = label.shape.faces.find(f => f.side === "SIDE");

    const { objectType } = label;
    const palletSideNames = LABEL_SIDE_NAMES.get(objectType);
    const frontName = palletSideNames?.front ?? "Front";
    const sideName = palletSideNames?.side ?? "Side";

    const frontListItem = front ? (
      <LabelFaceListItem
        face={front}
        faceName={frontName}
        viewer={viewer}
        labelSelector={labelSelector}
        labelMutator={labelMutator}
        label={label}
        key={front.id}
        palletPockets={label?.frontPockets}
      />
    ) : side ? (
      <CreateSideListItem
        faceName={"FRONT"}
        label={label}
        onClick={labelMutator.face.start}
        classes={classes}
        isLoad={false}
      />
    ) : null;

    const sideListItem = side ? (
      <LabelFaceListItem
        face={side}
        faceName={sideName}
        viewer={viewer}
        labelSelector={labelSelector}
        labelMutator={labelMutator}
        label={label}
        key={side.id}
        palletPockets={label?.sidePockets}
      />
    ) : front || top ? (
      <CreateSideListItem
        faceName={"SIDE"}
        label={label}
        onClick={labelMutator.face.start}
        classes={classes}
        isLoad={false}
      />
    ) : null;

    const loadArray = label.load?.faces ?? [];
    let loadFace = undefined;
    let loadSideFace = undefined;

    for (let load of loadArray) {
      if (load.side !== undefined) {
        switch (load.side) {
          case "FRONT":
            loadFace = load;
            break;
          case "SIDE":
            loadSideFace = load;
            break;
          default:
            console.warn("Load face was set to incorrect side");
            break;
        }
      }
    }

    const loadListItem = loadFace ? (
      <div>
        <LabelFaceListItem
          face={loadFace}
          faceName={"load"}
          viewer={viewer}
          labelSelector={labelSelector}
          labelMutator={labelMutator}
          label={label}
          key={loadFace.id}
          isLoadLabel={true}
          palletPockets={null}
        />
      </div>
    ) : loadSideFace ? (
      <CreateSideListItem
        faceName={"FRONT"}
        label={label}
        onClick={labelMutator.face.start}
        classes={classes}
        isLoad={true}
      />
    ) : isEmpty(loadFace) || loadFace.id === undefined ? (
      <ListItem dense className={classes.leftOrRightRow}>
        <ListItemText
          secondary={"Create Load"}
          className={classes.labelDetailsText}
        />
        <div className={classes.row}>
          <Button
            size={"small"}
            onClick={() =>
              labelMutator.label.load.create({ palletId: label.id })
            }
            variant="outlined"
            className={classes.button}
          >
            Create Load
          </Button>
        </div>
      </ListItem>
    ) : null;

    const loadListSideItem = loadSideFace ? (
      <div>
        <LabelFaceListItem
          face={loadSideFace}
          faceName={"load side"}
          viewer={viewer}
          labelSelector={labelSelector}
          labelMutator={labelMutator}
          label={label}
          key={loadSideFace.id}
          isLoadLabel={true}
          palletPockets={null}
        />
      </div>
    ) : isEmpty(loadSideFace) || loadSideFace.id === undefined ? (
      <CreateSideListItem
        faceName={"SIDE"}
        label={label}
        onClick={labelMutator.face.start}
        classes={classes}
        isLoad={true}
      />
    ) : null;
    return (
      <List dense key={label.id} className={classes.list}>
        {frontListItem}
        <Divider />
        {sideListItem}
        <Divider />
        {loadListItem}
        {(loadFace || loadSideFace) && <Divider />}
        {(loadFace || loadSideFace) && loadListSideItem}
      </List>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(LabelFaceList, {
    label: graphql`
      fragment LabelFaceList_label on FrameLabel {
        ... on LabeledBox {
          id
        }
        ... on LabeledPallet {
          id
          load {
            id
            faces {
              corners {
                id
              }
              id
              side
              ...LabelFaceListItem_face
            }
          }
          frontPockets {
            id
            left {
              id
            }
            right {
              id
            }
          }
          sidePockets {
            id
            left {
              id
            }
            right {
              id
            }
          }
        }
        number
        objectType
        shape {
          faces {
            corners {
              id
            }
            side
            ...LabelFaceListItem_face
          }
        }
      }
    `
  })
);
