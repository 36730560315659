import React from "react";
import { graphql, createRefetchContainer, RelayRefetchProp } from "react-relay";
import { Disposable } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LoadingScreen from "../common/LoadingScreen";
import Button from "@material-ui/core/Button";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";

import SensorStreamListItem from "./SensorStreamListItem";

import { ListHighPrioritySensorStreams_viewer } from "../../__generated__/ListHighPrioritySensorStreams_viewer.graphql";
import DebouncedTextField from "../common/DebouncedTextField";

const localStyles = theme =>
  createStyles({
    toolbarRoot: {
      flexWrap: "wrap"
    },
    list: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start"
    }
  });
const styles = combineStyles(localStyles, commonStyles);

interface Props extends WithStyles<typeof styles> {
  relay: RelayRefetchProp;
  viewer: ListHighPrioritySensorStreams_viewer;
  reload: any;
  isLoadingMore: boolean;
}
type State = {
  runNameQuery: string | null;
  pendingRefetch: Disposable | null;
  isLoadingMore: boolean;
  isListOpen: boolean;
};

class ListHighPrioritySensorStreams extends React.Component<Props, State> {
  state = {
    runNameQuery: null,
    pendingRefetch: null,
    isLoadingMore: false,
    isListOpen: true
  };
  componentDidUpdate() {
    if (this.props.isLoadingMore) {
      this._refetch();
    }
  }
  render() {
    const { viewer, classes, reload } = this.props;
    const { pendingRefetch, isLoadingMore, isListOpen, runNameQuery } =
      this.state;
    const accordionClass = isListOpen
      ? classes.accordionOpen
      : classes.accordionClosed;
    const { endCursor } = this.props.viewer.highPrioritySensorStreams.pageInfo;

    return (
      <div className={classes.wrapper}>
        <AppBar color="default" position="static" className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarRoot }}>
            <Button
              className={classes.expandIcon}
              onClick={this._toggleAccordion}
            >
              {isListOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
            <Typography className={classes.title} color="inherit" noWrap>
              High priority logs
            </Typography>
            <div className={classes.grow} />
            <div className={classes.search}>
              <DebouncedTextField
                key={"debounce-search-viewer"}
                value={runNameQuery || ""}
                label="Log name..."
                onChangeDebounced={e =>
                  this._updateRunNameQuery(e.target.value)
                }
              />
            </div>
          </Toolbar>
        </AppBar>
        <div className={accordionClass}>
          <List className={classes.list}>
            {viewer.highPrioritySensorStreams.edges.length === 0 && (
              <Typography>
                There are no high priority logs. Please pick a recent run below.
              </Typography>
            )}
            {/*If loading more, show loading icon on bottom*/}
            {pendingRefetch && !isLoadingMore && <LoadingScreen />}
            {viewer.highPrioritySensorStreams &&
              viewer.highPrioritySensorStreams.edges.map(
                ({ node: sensorStream }) => (
                  <SensorStreamListItem
                    key={sensorStream.id}
                    viewer={viewer}
                    sensorStream={sensorStream}
                    refresh={reload}
                  />
                )
              )}
            {isLoadingMore && <LoadingScreen />}
          </List>
          <Button
            fullWidth
            disabled={
              pendingRefetch !== null ||
              !viewer.highPrioritySensorStreams.pageInfo.hasNextPage
            }
            onClick={() => this._refetch(endCursor)}
          >
            Load More
          </Button>
        </div>
      </div>
    );
  }

  _updateRunNameQuery(query: string) {
    const runNameQuery = query === "" ? null : query;
    if (runNameQuery === this.state.runNameQuery) {
      return;
    }

    this.setState({ runNameQuery }, () => this._refetch());
  }

  _toggleAccordion = () => {
    this.setState(prevState => ({
      isListOpen: !prevState.isListOpen
    }));
  };
  _refetch = (after: string | null = null) => {
    const { runNameQuery, pendingRefetch } = this.state;
    if (pendingRefetch) {
      pendingRefetch.dispose();
    }
    const refetchVariables = fragmentVariables => {
      const countHighPrioritySensorStreams =
        fragmentVariables.countHighPrioritySensorStreams;
      return {
        runNameQuery,
        countHighPrioritySensorStreams,
        labelingPriorities: ["HIGH"],
        after
      };
    };
    this.setState({
      isLoadingMore: !!after,
      pendingRefetch: this.props.relay.refetch(
        refetchVariables,
        null, // Use the refetchVariables as renderVariables
        () => this.setState({ isLoadingMore: false, pendingRefetch: null }),
        { force: true } // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
      )
    });
  };
}

export default withStyles(styles, { withTheme: true })(
  createRefetchContainer(
    ListHighPrioritySensorStreams,
    {
      viewer: graphql`
        fragment ListHighPrioritySensorStreams_viewer on Viewer
        @argumentDefinitions(
          countHighPrioritySensorStreams: { type: "Int", defaultValue: 15 }
        ) {
          id
          username
          isAnonymous
          isSupervisor
          highPrioritySensorStreams: sensorStreams(
            first: $countHighPrioritySensorStreams
            runNameQuery: null
            labelingPriorities: [HIGH]
            after: null
          )
            @connection(
              key: "ListHighPrioritySensorStreams_highPrioritySensorStreams"
              filters: []
            ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                ...SensorStreamListItem_sensorStream
              }
            }
          }
        }
      `
    },
    graphql`
      query ListHighPrioritySensorStreamsRefetchQuery(
        $labelingPriorities: [LabelingPriority!]
        $runNameQuery: String
        $countHighPrioritySensorStreams: Int!
        $after: String
      ) {
        viewer {
          id
          username
          isAnonymous
          isSupervisor
          highPrioritySensorStreams: sensorStreams(
            labelingPriorities: $labelingPriorities
            first: $countHighPrioritySensorStreams
            runNameQuery: $runNameQuery
            after: $after
          )
            @connection(
              key: "ListHighPrioritySensorStreams_highPrioritySensorStreams"
              filters: []
            ) {
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id
                ...SensorStreamListItem_sensorStream
              }
            }
          }
        }
      }
    `
  )
);
