/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type PalletSurface = "FLOOR" | "FORKLIFT" | "PALLET_EMPTY" | "PALLET_LOADED" | "RACK" | "TRUCK" | "UNKNOWN" | "%future added value";
export type TargetState = "NA" | "NOT_TARGETED" | "TARGETED" | "UNKNOWN" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type FrameViewer_viewer = {
    readonly id: string;
    readonly isAnonymous: boolean | null;
    readonly isSupervisor: boolean | null;
    readonly isVetted: boolean | null;
    readonly isQualityController: boolean | null;
    readonly userId: string | null;
    readonly username: string | null;
    readonly frame: {
        readonly id: string;
        readonly indexInSensorStream: number;
        readonly timestamp: string;
        readonly cameraModel: {
            readonly height: number;
            readonly width: number;
            readonly fx: number | null;
            readonly fy: number | null;
            readonly cx: number | null;
            readonly cy: number | null;
        };
        readonly labeledBoxes: ReadonlyArray<{
            readonly id: string;
            readonly number: number;
            readonly frame: {
                readonly id: string;
            };
            readonly author: {
                readonly id: string;
                readonly username: string | null;
            };
            readonly pallet: {
                readonly number: number;
            } | null;
            readonly shape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            };
        }>;
        readonly labeledItems: ReadonlyArray<{
            readonly id: string;
            readonly number: number;
            readonly frame: {
                readonly id: string;
            };
            readonly author: {
                readonly id: string;
                readonly username: string | null;
            };
            readonly thwartedPallets: ReadonlyArray<{
                readonly number: number;
            }>;
            readonly shape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            };
        }>;
        readonly labeledPallets: ReadonlyArray<{
            readonly id: string;
            readonly number: number;
            readonly surfaceType: PalletSurface;
            readonly frame: {
                readonly id: string;
            };
            readonly author: {
                readonly id: string;
                readonly username: string | null;
            };
            readonly sidePockets: {
                readonly left: {
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
                readonly right: {
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
            } | null;
            readonly frontPockets: {
                readonly left: {
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
                readonly right: {
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
            } | null;
            readonly shape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            };
            readonly load: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            } | null;
        }>;
        readonly knownObjectPoses: ReadonlyArray<{
            readonly id: string;
            readonly targetState: TargetState;
            readonly knownObject: KnownObject;
            readonly orientation: {
                readonly w: number;
                readonly x: number;
                readonly y: number;
                readonly z: number;
            };
            readonly position: {
                readonly x: number;
                readonly y: number;
                readonly z: number;
            };
            readonly distance: number;
        }>;
        readonly imageMetadata: {
            readonly height: number;
            readonly width: number;
            readonly signedUrl: string;
        };
        readonly " $fragmentRefs": FragmentRefs<"CanvasRenderer_frame" | "SideBar_frame" | "IssuesBar_frame" | "FrameQADialog_frame">;
    } | null;
    readonly " $refType": "FrameViewer_viewer";
};
export type FrameViewer_viewer$data = FrameViewer_viewer;
export type FrameViewer_viewer$key = {
    readonly " $data"?: FrameViewer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"FrameViewer_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "height",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "width",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "frame",
  "storageKey": null,
  "args": null,
  "concreteType": "Frame",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelerUser",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ]
},
v7 = [
  (v4/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "x",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "y",
  "args": null,
  "storageKey": null
},
v10 = [
  (v0/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "visibility",
    "args": null,
    "storageKey": null
  }
],
v11 = [
  (v0/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "faces",
    "storageKey": null,
    "args": null,
    "concreteType": "CuboidFace",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "side",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "corners",
        "storageKey": null,
        "args": null,
        "concreteType": "CuboidCorner",
        "plural": true,
        "selections": (v10/*: any*/)
      }
    ]
  }
],
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "shape",
  "storageKey": null,
  "args": null,
  "concreteType": "Cuboid",
  "plural": false,
  "selections": (v11/*: any*/)
},
v13 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "corners",
    "storageKey": null,
    "args": null,
    "concreteType": "QuadCorner",
    "plural": true,
    "selections": (v10/*: any*/)
  }
],
v14 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "left",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v13/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "right",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v13/*: any*/)
  }
],
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "z",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "FrameViewer_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "runName",
      "type": "String!"
    },
    {
      "kind": "RootArgument",
      "name": "sensorName",
      "type": "String!"
    },
    {
      "kind": "RootArgument",
      "name": "indexInSensorStream",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "timestamp",
      "type": "String"
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAnonymous",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSupervisor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isVetted",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isQualityController",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userId",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": "frame",
      "name": "frameBySensor",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "indexInSensorStream",
          "variableName": "indexInSensorStream"
        },
        {
          "kind": "Variable",
          "name": "runName",
          "variableName": "runName"
        },
        {
          "kind": "Variable",
          "name": "sensorName",
          "variableName": "sensorName"
        },
        {
          "kind": "Variable",
          "name": "timestamp",
          "variableName": "timestamp"
        }
      ],
      "concreteType": "Frame",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "indexInSensorStream",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "timestamp",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "cameraModel",
          "storageKey": null,
          "args": null,
          "concreteType": "CameraModel",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "fx",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "fy",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cx",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cy",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeledBoxes",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledBox",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "pallet",
              "storageKey": null,
              "args": null,
              "concreteType": "LabeledPallet",
              "plural": false,
              "selections": (v7/*: any*/)
            },
            (v12/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeledItems",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledItem",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "thwartedPallets",
              "storageKey": null,
              "args": null,
              "concreteType": "LabeledPallet",
              "plural": true,
              "selections": (v7/*: any*/)
            },
            (v12/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeledPallets",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledPallet",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "surfaceType",
              "args": null,
              "storageKey": null
            },
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "sidePockets",
              "storageKey": null,
              "args": null,
              "concreteType": "PalletPocket",
              "plural": false,
              "selections": (v14/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "frontPockets",
              "storageKey": null,
              "args": null,
              "concreteType": "PalletPocket",
              "plural": false,
              "selections": (v14/*: any*/)
            },
            (v12/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "load",
              "storageKey": null,
              "args": null,
              "concreteType": "Cuboid",
              "plural": false,
              "selections": (v11/*: any*/)
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "knownObjectPoses",
          "storageKey": null,
          "args": null,
          "concreteType": "KnownObjectPose",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "targetState",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "knownObject",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "orientation",
              "storageKey": null,
              "args": null,
              "concreteType": "Quaternion",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "w",
                  "args": null,
                  "storageKey": null
                },
                (v8/*: any*/),
                (v9/*: any*/),
                (v15/*: any*/)
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "position",
              "storageKey": null,
              "args": null,
              "concreteType": "Vector3",
              "plural": false,
              "selections": [
                (v8/*: any*/),
                (v9/*: any*/),
                (v15/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "distance",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "imageMetadata",
          "storageKey": null,
          "args": null,
          "concreteType": "ImageMetadata",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "signedUrl",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "CanvasRenderer_frame",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "SideBar_frame",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "IssuesBar_frame",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "FrameQADialog_frame",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'dff62f89521e0efc6741607fa8187786';
export default node;
