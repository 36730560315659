import React from "react";
import { graphql, createRefetchContainer, RelayRefetchProp } from "react-relay";
import { Disposable } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LoadingScreen from "../common/LoadingScreen";
import Button from "@material-ui/core/Button";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";

import SensorStreamListItem from "./SensorStreamListItem";

import { ListViewerLabeledSensorStreams_viewer } from "../../__generated__/ListViewerLabeledSensorStreams_viewer.graphql";
import DebouncedTextField from "../common/DebouncedTextField";

const localStyles = theme =>
  createStyles({
    list: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start"
    }
  });
const styles = combineStyles(localStyles, commonStyles);

interface Props extends WithStyles<typeof styles> {
  viewer: ListViewerLabeledSensorStreams_viewer;
  relay: RelayRefetchProp;
  reload: any;
}
type State = {
  runNameQuery: string | null;
  pendingRefetch: Disposable | null;
  isLoadingMore: boolean;
  isListOpen: boolean;
};

class ListViewerLabeledSensorStreams extends React.Component<Props, State> {
  state = {
    runNameQuery: null,
    pendingRefetch: null,
    isLoadingMore: false,
    isListOpen: true
  };

  render() {
    const { viewer, classes, reload } = this.props;
    const { pendingRefetch, isLoadingMore, runNameQuery, isListOpen } =
      this.state;
    const { endCursor } = this.props.viewer.viewerLabeledSensorStreams.pageInfo;

    const accordionClass = isListOpen
      ? classes.accordionOpen
      : classes.accordionClosed;

    return (
      <div className={classes.wrapper}>
        <AppBar color="default" position="static" className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarRoot }}>
            <Button
              className={classes.expandIcon}
              onClick={this._toggleAccordion}
            >
              {isListOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
            <Typography className={classes.title} color="inherit" noWrap>
              Your recent labels
            </Typography>
            <div className={classes.grow} />
            <div className={classes.search}>
              <DebouncedTextField
                key={"debounce-search-viewer"}
                value={runNameQuery || ""}
                label="Log name..."
                onChangeDebounced={e =>
                  this._updateRunNameQuery(e.target.value)
                }
              />
            </div>
          </Toolbar>
        </AppBar>
        <div className={accordionClass}>
          <List className={classes.list}>
            {viewer.viewerLabeledSensorStreams.edges.length === 0 && (
              <Typography>
                {runNameQuery
                  ? "No labels matched your query"
                  : "You haven't labeled anything"}
              </Typography>
            )}
            {/*If loading more, show loading icon on bottom*/}
            {pendingRefetch && !isLoadingMore && <LoadingScreen />}
            {viewer.viewerLabeledSensorStreams &&
              viewer.viewerLabeledSensorStreams.edges.map(
                ({ node: sensorStream }) => (
                  <SensorStreamListItem
                    key={sensorStream.id}
                    viewer={viewer}
                    sensorStream={sensorStream}
                    refresh={reload}
                  />
                )
              )}
            {isLoadingMore && <LoadingScreen />}
          </List>
          <Button
            fullWidth
            disabled={
              pendingRefetch !== null ||
              !viewer.viewerLabeledSensorStreams.pageInfo.hasNextPage
            }
            onClick={() => this._refetch(endCursor)}
          >
            Load More
          </Button>
        </div>
      </div>
    );
  }

  _updateRunNameQuery(query: string) {
    const runNameQuery = query === "" ? null : query;
    if (runNameQuery === this.state.runNameQuery) {
      return;
    }

    this.setState({ runNameQuery }, () => this._refetch());
  }

  _toggleAccordion = () => {
    this.setState(prevState => ({
      isListOpen: !prevState.isListOpen
    }));
  };

  _refetch = (after: string | null = null) => {
    const { runNameQuery, pendingRefetch } = this.state;
    if (pendingRefetch) {
      pendingRefetch.dispose();
    }
    const refetchVariables = fragmentVariables => {
      const countViewerLabeledSensorStreams =
        fragmentVariables.countViewerLabeledSensorStreams;
      return {
        runNameQuery,
        countViewerLabeledSensorStreams,
        onlyShowViewer: true,
        after
      };
    };
    this.setState({
      isLoadingMore: !!after,
      pendingRefetch: this.props.relay.refetch(
        refetchVariables,
        null, // Use the refetchVariables as renderVariables
        () => this.setState({ isLoadingMore: false, pendingRefetch: null }),
        { force: true } // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
      )
    });
  };
}

export default withStyles(styles, { withTheme: true })(
  createRefetchContainer(
    ListViewerLabeledSensorStreams,
    {
      viewer: graphql`
        fragment ListViewerLabeledSensorStreams_viewer on Viewer
        @argumentDefinitions(
          countViewerLabeledSensorStreams: { type: "Int", defaultValue: 15 }
        ) {
          username
          isAnonymous
          isSupervisor
          viewerLabeledSensorStreams: sensorStreams(
            first: $countViewerLabeledSensorStreams
            runNameQuery: null
            onlyShowViewer: true
          )
            @connection(
              key: "ListViewerLabeledSensorStreams_viewerLabeledSensorStreams"
              filters: []
            ) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                ...SensorStreamListItem_sensorStream
                numFrames
                labelingPriority
                createTime
                updateTime
                run {
                  runName
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query ListViewerLabeledSensorStreamsRefetchQuery(
        $onlyShowViewer: Boolean
        $runNameQuery: String
        $countViewerLabeledSensorStreams: Int!
        $after: String
      ) {
        viewer {
          username
          isAnonymous
          isSupervisor
          viewerLabeledSensorStreams: sensorStreams(
            onlyShowViewer: $onlyShowViewer
            first: $countViewerLabeledSensorStreams
            after: $after
            runNameQuery: $runNameQuery
          )
            @connection(
              key: "ListViewerLabeledSensorStreams_viewerLabeledSensorStreams"
              filters: []
            ) {
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id
                ...SensorStreamListItem_sensorStream
                numFrames
                labelingPriority
                createTime
                updateTime
                run {
                  runName
                }
              }
            }
          }
        }
      }
    `
  )
);
