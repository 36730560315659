import React from "react";

import {
  KNOWN_OBJECT_ITEMS,
  LABEL_SIDE_NAMES,
  PALLET_SURFACE_TYPES
} from "../../../../utils/Enums";

import FormHelperText from "@material-ui/core/FormHelperText";
import { KNOWN_OBJECT_PALLETS_BINS } from "../../../../utils/Enums";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { TopLevelLabelActions } from "../../../label_frame/SensorStreamWrapper";

import { combineStyles, commonStyles } from "../../../../utils/CommonStyles";

const localStyles = theme =>
  createStyles({
    faceLists: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start"
    },
    selectWrapper: {
      background: "lightgray",
      display: "flex",
      flexDirection: "column",
      margin: 0,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: 4
    },
    tabs: {
      border: "1px solid rgba(0, 0, 0, 0.23)"
    },
    openButton: {
      position: "absolute",
      right: 32,
      paddingTop: 16
    },
    drawerPaper: {
      position: "relative",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      marginBottom: "65px"
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 8,
      flexDirection: "row",
      position: "relative"
    },
    drawer: {
      height: "fit-content",
      marginBottom: 360
    },
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    pickers: {
      background: "lightgray",
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "nowrap",
      margin: 0,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: 4
    },
    formControlSize: {
      margin: theme.spacing.unit,
      minWidth: 120,
      maxWidth: 300
    }
  });
const styles = combineStyles(localStyles, commonStyles);

type Props = {
  classes: any;
  topLevelLabelActions: TopLevelLabelActions;
};
class LabelCreationDetails extends React.Component<Props> {
  render() {
    const { classes, topLevelLabelActions } = this.props;

    const {
      creationKnownObject,
      creationFaceSide,
      creationPalletSurface,
      setCreationKnownObject,
      setCreationFaceSide,
      setCreationPalletSurface,
      creationLabelCategory
    } = topLevelLabelActions;
    const palletSideNames = LABEL_SIDE_NAMES.get(creationKnownObject);

    return creationLabelCategory === "PALLET" ? (
      <div className={classes.selectWrapper}>
        <FormHelperText>New pallet options</FormHelperText>
        <div className={classes.row}>
          <Select
            value={creationFaceSide}
            onChange={e => setCreationFaceSide(e.target.value)}
          >
            <MenuItem value="FRONT">
              {palletSideNames ? palletSideNames.front : "Front"}
            </MenuItem>
            <MenuItem value="TOP">Top</MenuItem>
            <MenuItem value="SIDE">
              {palletSideNames ? palletSideNames.side : "Side"}
            </MenuItem>
          </Select>
          <Select
            value={creationKnownObject}
            onChange={e => setCreationKnownObject(e.target.value)}
          >
            {Array.from(KNOWN_OBJECT_PALLETS_BINS.entries()).map(entry => (
              <MenuItem key={entry[0]} value={entry[0]}>
                {entry[1]}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={creationPalletSurface}
            onChange={e => setCreationPalletSurface(e.target.value)}
          >
            {Array.from(PALLET_SURFACE_TYPES.entries()).map(entry => (
              <MenuItem key={entry[0]} value={entry[0]}>
                {entry[1]}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    ) : (
      <div className={classes.selectWrapper}>
        <FormHelperText>New item type</FormHelperText>
        <div className={classes.row}>
          <Select
            value={creationKnownObject}
            onChange={e => setCreationKnownObject(e.target.value)}
          >
            {Array.from(KNOWN_OBJECT_ITEMS.entries()).map(entry => (
              <MenuItem key={entry[0]} value={entry[0]}>
                {entry[1]}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LabelCreationDetails);
