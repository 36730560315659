import { commitMutation, graphql, commitLocalUpdate } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";
import { isNil } from "lodash";

import { UpdateCornerMutationInput } from "../__generated__/UpdateCornerMutation.graphql";

const mutation = graphql`
  mutation UpdateCornerMutation($input: UpdateCornerMutationInput!) {
    updateCorner(input: $input) {
      corner {
        ... on CuboidCorner {
          id
          x
          y
          visibility
        }
        ... on QuadCorner {
          id
          x
          y
          visibility
        }
      }
    }
  }
`;

function updateCorner(
  environment: Environment,
  input: UpdateCornerMutationInput,
  commit: boolean = true,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  if (!commit) {
    // changes screen, not DB
    commitLocalUpdate(environment, store => {
      input.visibility &&
        store.get(input.id).setValue(input.visibility, "visibility");
      !isNil(input.x) && store.get(input.id).setValue(input.x, "x");
      !isNil(input.y) && store.get(input.id).setValue(input.y, "y");
    });
    return;
  }
  // updater is handled by relay
  // optimisticUpdater updates screen on api delay
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      input.visibility &&
        store.get(input.id).setValue(input.visibility, "visibility");
      !isNil(input.x) && store.get(input.id).setValue(input.x, "x");
      !isNil(input.y) && store.get(input.id).setValue(input.y, "y");
    }
  });
}

export default updateCorner;
