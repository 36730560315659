/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelingActivity_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"LabelingActivityChart_viewer" | "LabelingActivityTable_viewer">;
    readonly " $refType": "LabelingActivity_viewer";
};
export type LabelingActivity_viewer$data = LabelingActivity_viewer;
export type LabelingActivity_viewer$key = {
    readonly " $data"?: LabelingActivity_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"LabelingActivity_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "LabelingActivity_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "LabelingActivityChart_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "LabelingActivityTable_viewer",
      "args": null
    }
  ]
};
(node as any).hash = '773eee8a01c189941cbcb9b4381ad41f';
export default node;
