/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type LabelingPriority = "HIGH" | "LOW" | "UNSPECIFIED" | "%future added value";
export type LightingConditions = "BACKLIT" | "DARK" | "ILLUMINATED_BY_ROBOT" | "OVERHEAD_FRIENDLY" | "OVERHEAD_NORMAL" | "VERY_DARK" | "%future added value";
export type UpdateSensorStreamMutationInput = {
    readonly id: string;
    readonly labelingPriority?: LabelingPriority | null;
    readonly lightingConditions?: LightingConditions | null;
    readonly labelingDirections?: string | null;
    readonly allowExport: boolean;
    readonly clientMutationId?: string | null;
};
export type UpdateSensorStreamMutationVariables = {
    input: UpdateSensorStreamMutationInput;
};
export type UpdateSensorStreamMutationResponse = {
    readonly updateSensorStream: {
        readonly sensorStream: {
            readonly id: string;
            readonly labelingDirections: string;
            readonly labelingPriority: LabelingPriority;
            readonly lightingConditions: LightingConditions;
            readonly allowExport: boolean;
        };
    } | null;
};
export type UpdateSensorStreamMutation = {
    readonly response: UpdateSensorStreamMutationResponse;
    readonly variables: UpdateSensorStreamMutationVariables;
};



/*
mutation UpdateSensorStreamMutation(
  $input: UpdateSensorStreamMutationInput!
) {
  updateSensorStream(input: $input) {
    sensorStream {
      id
      labelingDirections
      labelingPriority
      lightingConditions
      allowExport
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSensorStreamMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateSensorStream",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSensorStreamMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sensorStream",
        "storageKey": null,
        "args": null,
        "concreteType": "SensorStream",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "labelingDirections",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "labelingPriority",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lightingConditions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "allowExport",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSensorStreamMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSensorStreamMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateSensorStreamMutation",
    "id": null,
    "text": "mutation UpdateSensorStreamMutation(\n  $input: UpdateSensorStreamMutationInput!\n) {\n  updateSensorStream(input: $input) {\n    sensorStream {\n      id\n      labelingDirections\n      labelingPriority\n      lightingConditions\n      allowExport\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c8d56d261bb8dc98641bce1b35fe8d49';
export default node;
