import React from "react";
import { graphql, createRefetchContainer, RelayRefetchProp } from "react-relay";
import { Disposable } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import ButtonGroup from "@material-ui/core/ButtonGroup";
import { BucketDuration } from "../../__generated__/LabelingReportTableRefetchQuery.graphql";
import { cloneDeep, isNull } from "lodash";
import LoadingScreen from "../common/LoadingScreen";
import { LabelingReportTable_viewer } from "../../__generated__/LabelingReportTable_viewer.graphql";
import {
  AppBar,
  Checkbox,
  FormControlLabel,
  Toolbar,
} from "@material-ui/core";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";

// TODO(emily) automate this
const validReportTypes = new Map<string, boolean>([
  ["numPallets", true],
  ["numItems", true],
  ["numLoads", true],
  ["numLabels", true],
  ["numIssues", true],
  ["numBins", true],
  ["numPockets", true],
  ["numOpenIssues", false]
]);
// reportTypes MUST? be in same order validReportTypes and reportTypeLabels
const reportTypeLabels = new Map<string, string>([
  ["numPallets", "# Pallets"],
  ["numItems", "# Items"],
  ["numLabels", "# Labels"],
  ["numLoads", "# Loads"],
  ["numIssues", "# Issues"],
  ["numBins", "# Bins"],
  ["numPockets", "# Pockets"],
  ["numOpenIssues", "# Open Issues"]
]);

const localStyles = theme =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing.unit * 2
    },
    infoBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    spaced: {
      marginLeft: 48
    }
  });
const styles = combineStyles(localStyles, commonStyles);

interface Props extends WithStyles<typeof styles> {
  viewer: LabelingReportTable_viewer;
  relay: RelayRefetchProp;
  startTime: Date | null;
  duration: BucketDuration;
}
type State = {
  pendingRefetch: Disposable | null;
  reportTypes: Map<string, boolean>;
};

class LabelingReportTable extends React.Component<Props, State> {
  state = {
    pendingRefetch: null,
    startTime: null,
    reportTypes: cloneDeep(validReportTypes)
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.startTime !== this.props.startTime ||
      prevProps.duration !== this.props.duration
    ) {
      this._refetch();
    }
  }
  _toggleColumn = (column: string) => {
    const oldMap = cloneDeep(this.state.reportTypes);
    oldMap.set(column, !oldMap.get(column));
    this.setState({ reportTypes: cloneDeep(oldMap) });
  };

  renderTableBanner() {
    const { classes } = this.props;
    const { reportTypes } = this.state;
    const html = [];
    for (const [val, bool] of reportTypes.entries()) {
      const formHtml = (
        <FormControlLabel
          key={`control-${val}`}
          control={
            <Checkbox onClick={() => this._toggleColumn(val)} checked={bool} />
          }
          label={reportTypeLabels.get(val)}
        />
      );
      html.push(formHtml);
    }
    return (
      <AppBar color="default" position="static" className={classes.appBar}>
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <div className={classes.flexGroup}>{html}</div>
        </Toolbar>
      </AppBar>
    );
  }

  renderTableHead() {
    const { reportTypes } = this.state;

    const html = [];

    for (const [val, bool] of reportTypes.entries()) {
      // if bool is very important
      if (bool) {
        const htmlTableCell = (
          <TableCell align="right" key={`header-${val}`}>
            {reportTypeLabels.get(val)}
          </TableCell>
        );
        html.push(htmlTableCell);
      }
    }
    return (
      <TableHead>
        <TableRow>
          <TableCell>Labeler</TableCell>
          {html}
        </TableRow>
      </TableHead>
    );
  }

  renderTableRow(overview, i) {
    const { reportTypes } = this.state;

    const rowHtml = [];
    for (const [val, bool] of reportTypes.entries()) {
      if (bool) {
        const cellHtml = (
          <TableCell align="right" key={`${val}-${i}`}>
            {overview[val]}
          </TableCell>
        );
        rowHtml.push(cellHtml);
      }
    }
    return (
      <TableRow key={`row-${i}`}>
        <TableCell component="th" scope="row">
          {overview.labeler ? overview.labeler.username : "Everyone"}
        </TableCell>
        {rowHtml}
      </TableRow>
    );
  }

  renderTableBody() {
    const { viewer } = this.props;
    const { labelerOverviews } = viewer;
    return (
      <TableBody>
        {labelerOverviews.map((overview, i) =>
          this.renderTableRow(overview, i)
        )}
      </TableBody>
    );
  }

  render() {
    const { pendingRefetch } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.marginWrapper}>
        {this.renderTableBanner()}
        {pendingRefetch ? (
          <LoadingScreen />
        ) : (
          <Paper className={classes.verticalScroll}>
            <Table>
              {this.renderTableHead()}
              {this.renderTableBody()}
            </Table>
          </Paper>
        )}
      </div>
    );
  }

  _refetch = () => {
    const { startTime, duration } = this.props;
    const { pendingRefetch } = this.state;
    if (!isNull(pendingRefetch)) {
      pendingRefetch.dispose();
    }
    const refetchVariables = fragmentVariables => {
      return {
        duration,
        startTime
      };
    };

    this.setState({
      pendingRefetch: this.props.relay.refetch(
        refetchVariables,
        null, // Use the refetchVariables as renderVariables
        () => this.setState({ pendingRefetch: null }),
        { force: true }
      )
    });
  };
}

export default withStyles(styles)(
  createRefetchContainer(
    LabelingReportTable,
    {
      viewer: graphql`
        fragment LabelingReportTable_viewer on Viewer
        @argumentDefinitions(
          startTime: { type: DateTime, defaultValue: null }
          duration: { type: BucketDuration, defaultValue: WEEK }
        ) {
          id
          labelerOverviews(startTime: $startTime, duration: $duration) {
            labeler {
              username
            }
            numPallets
            numItems
            numLoads
            numBins
            numLabels
            numIssues
            numOpenIssues
            numPockets
          }
        }
      `
    },
    graphql`
      query LabelingReportTableRefetchQuery(
        $startTime: DateTime
        $duration: BucketDuration
      ) {
        viewer {
          id
          labelerOverviews(startTime: $startTime, duration: $duration) {
            labeler {
              username
            }
            numPallets
            numItems
            numLoads
            numBins
            numLabels
            numIssues
            numOpenIssues
            numPockets
          }
        }
      }
    `
  )
);
