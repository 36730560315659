import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { createStyles } from "@material-ui/core";

const styleSheet = theme =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "90vh",
      minHeight: 100,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }
  });

type Props = {
  classes: any;
};
class AccountPending extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography color="primary" variant="h1">
          Account Pending
        </Typography>
        <Typography color="primary" variant="h4">
          Your account is pending approval
        </Typography>
      </div>
    );
  }
}

export default withStyles(styleSheet)(AccountPending);
