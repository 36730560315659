import React, { Component } from "react";
import { MenuItem, Select } from "@material-ui/core";

type Props = {
  onChangeDebounced: (changeEvent: Event) => void;
  itemMap: ReadonlyMap<any, string>;
  value: any;
  selectProps?: any;
  label?: string;
  className?: string;
};
type State = {
  debounceTimeoutId: number | null;
  pendingValue: any | null;
};

class DebouncedSelect extends Component<Props, State> {
  state = {
    debounceTimeoutId: null,
    pendingValue: null
  };

  _debouncedOnChange = (e: any) => {
    const { onChangeDebounced } = this.props;
    const { debounceTimeoutId } = this.state;
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }
    e.persist();
    this.setState({
      pendingValue: e.target.value,
      debounceTimeoutId: setTimeout(() => {
        onChangeDebounced(e);
        this.setState({ debounceTimeoutId: null, pendingValue: null });
      }, 500)
    });
  };

  render() {
    const { onChangeDebounced, itemMap, value, ...selectProps } = this.props;
    const { pendingValue } = this.state;
    const valueToUse = pendingValue !== null ? pendingValue : value;
    return (
      <Select
        multiple
        value={valueToUse}
        renderValue={(selected: any[]) =>
          selected.length === itemMap.size
            ? "All"
            : selected.length > 1
            ? "Multiple"
            : selected.length === 1
            ? itemMap.get(selected.toString())
            : ""
        }
        onChange={e => this._debouncedOnChange(e)}
        {...selectProps}
      >
        {Array.from(itemMap.entries()).map(entry => (
          <MenuItem key={entry[0]} value={entry[0]}>
            {entry[1]}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

export default DebouncedSelect;
