import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { createStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";

const styleSheet = theme =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "90vh",
      minHeight: 100,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }
  });

interface Props extends WithStyles<typeof styleSheet> {}
class LoadingScreen extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography color="primary" variant="h1">
          404
        </Typography>
        <Typography color="primary" variant="h4">
          Page Not Found
        </Typography>
      </div>
    );
  }
}

export default withStyles(styleSheet)(LoadingScreen);
