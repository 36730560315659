/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelingIssueStatus = "NEEDS_LABELER_ATTENTION" | "NEEDS_QA_ATTENTION" | "RESOLVED" | "%future added value";
export type QACategory = "ALL_SIZEABLE_PALLETS_LABELED" | "BOXES_CORNERS_ACCURATE" | "BOXES_OFF_PALLET_LABELED" | "BOXES_ON_PALLET_LABELED" | "BOX_TOPS_LABELED" | "CORNER_VISIBILITY_MARKED" | "PALLETS_CORNERS_ACCURATE" | "PALLETS_SURFACES_CORRECT" | "PALLET_POCKETS_CORNERS_ACCURATE" | "PALLET_SIDES" | "PALLET_TYPE_CORRECT" | "TARGETED_PALLET_LABELED" | "%future added value";
export type LabelingIssueListItem_issue = {
    readonly id: string;
    readonly description: string;
    readonly category: QACategory;
    readonly status: LabelingIssueStatus;
    readonly reporter: {
        readonly username: string | null;
    };
    readonly labeler: {
        readonly username: string | null;
    };
    readonly pallets: ReadonlyArray<{
        readonly number: number;
    }>;
    readonly boxes: ReadonlyArray<{
        readonly number: number;
    }>;
    readonly sensorStream: {
        readonly sensorName: string;
        readonly run: {
            readonly runName: string;
        };
    };
    readonly frame: {
        readonly indexInSensorStream: number;
    } | null;
    readonly " $refType": "LabelingIssueListItem_issue";
};
export type LabelingIssueListItem_issue$data = LabelingIssueListItem_issue;
export type LabelingIssueListItem_issue$key = {
    readonly " $data"?: LabelingIssueListItem_issue$data;
    readonly " $fragmentRefs": FragmentRefs<"LabelingIssueListItem_issue">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "username",
    "args": null,
    "storageKey": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "number",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "LabelingIssueListItem_issue",
  "type": "LabelingIssue",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "category",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "reporter",
      "storageKey": null,
      "args": null,
      "concreteType": "LabelerUser",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeler",
      "storageKey": null,
      "args": null,
      "concreteType": "LabelerUser",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pallets",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledPallet",
      "plural": true,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "boxes",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledBox",
      "plural": true,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sensorStream",
      "storageKey": null,
      "args": null,
      "concreteType": "SensorStream",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sensorName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "run",
          "storageKey": null,
          "args": null,
          "concreteType": "Run",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "runName",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "frame",
      "storageKey": null,
      "args": null,
      "concreteType": "Frame",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "indexInSensorStream",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = '92aac064e89f32beaed5c60af65933de';
export default node;
