import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";

type Props = {
  onChangeDebounced: (arg0: any) => void;
  value: any;
  label?: string;
};
type State = {
  debounceTimeoutId: number | null;
  pendingValue: any | null;
};

class DebouncedTextField extends Component<Props, State> {
  state = {
    debounceTimeoutId: null,
    pendingValue: null
  };

  _debouncedOnChange = (e: any) => {
    const { onChangeDebounced } = this.props;
    const { debounceTimeoutId } = this.state;
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }
    e.persist();
    this.setState({
      pendingValue: e.target.value,
      debounceTimeoutId: setTimeout(() => {
        onChangeDebounced(e);
        this.setState({ debounceTimeoutId: null, pendingValue: null });
      }, 500)
    });
  };

  render() {
    const { onChangeDebounced, value, ...props } = this.props;
    const { pendingValue } = this.state;
    const valueToUse = pendingValue !== null ? pendingValue : value;
    return (
      <TextField
        onChange={e => this._debouncedOnChange(e)}
        value={valueToUse}
        {...props}
      />
    );
  }
}

export default DebouncedTextField;
