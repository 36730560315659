/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelingIssues_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"ListIssuesForViewer_viewer" | "ListIssuesByViewer_viewer">;
    readonly " $refType": "LabelingIssues_viewer";
};
export type LabelingIssues_viewer$data = LabelingIssues_viewer;
export type LabelingIssues_viewer$key = {
    readonly " $data"?: LabelingIssues_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"LabelingIssues_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "LabelingIssues_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ListIssuesForViewer_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ListIssuesByViewer_viewer",
      "args": null
    }
  ]
};
(node as any).hash = 'eb746b5a86134b27271808a2f3d7d5c1';
export default node;
