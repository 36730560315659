import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { RelayProp } from "react-relay";
import { PayloadError } from "relay-runtime";

import { createStyles, withStyles } from "@material-ui/core/styles";

import { Matrix3, Vector3, Vector2, Quaternion } from "three";
import CanvasRenderer from "./CanvasRenderer";
import { LabelSelector } from "./SelectionState";
import { TopLevelLabelActions } from "../../label_frame/SensorStreamWrapper";
import { LabelCategory } from "./SelectionState";
import { Corner, LabelFace, Label, LabelMutators } from "./LabelMutators";
import { allFacesForLabel, poseToPalletQuad, transformPoint } from "./Utils";
import SideBar from "./sidebar/SideBar";
import IssuesBar from "./issues_bar/IssuesBar";
import FrameQADialog from "./FrameQADialog";

import {
  FrameViewer_viewer,
  FaceSide
} from "../../../__generated__/FrameViewer_viewer.graphql";
import createLabeledBox from "../../../mutations/CreateLabeledBoxMutation";
import updateLabeledBox from "../../../mutations/UpdateLabeledBoxMutation";
import deleteLabeledBox from "../../../mutations/DeleteLabeledBoxMutation";
import createLabeledPallet from "../../../mutations/CreateLabeledPalletMutation";
import updateLabeledPallet from "../../../mutations/UpdateLabeledPalletMutation";
import deleteLabeledPallet from "../../../mutations/DeleteLabeledPalletMutation";
import createCorner from "../../../mutations/CreateCornerMutation";
import updateCorner from "../../../mutations/UpdateCornerMutation";
import createFace from "../../../mutations/CreateFaceMutation";
import deleteFace from "../../../mutations/DeleteFaceMutation";
import updateFace from "../../../mutations/UpdateFaceMutation";
import createPalletPocketCorner from "../../../mutations/CreatePalletPocketCornerMutation";
import deletePalletPocket from "../../../mutations/DeletePalletPocketMutation";

import { DeletePalletPocketMutationInput } from "../../../__generated__/DeletePalletPocketMutation.graphql";
import {
  CreatePalletPocketCornerMutationInput,
  CreatePalletPocketCornerMutationResponse
} from "../../../__generated__/CreatePalletPocketCornerMutation.graphql";
import { CreateCornerMutationResponse } from "../../../__generated__/CreateCornerMutation.graphql";
import {
  UpdateCornerMutationResponse,
  UpdateCornerMutationInput
} from "../../../__generated__/UpdateCornerMutation.graphql";
import { CreateFaceMutationResponse } from "../../../__generated__/CreateFaceMutation.graphql";
import {
  UpdateFaceMutationResponse,
  UpdateFaceMutationInput
} from "../../../__generated__/UpdateFaceMutation.graphql";
import { CreateLabeledBoxMutationResponse } from "../../../__generated__/CreateLabeledBoxMutation.graphql";
import { CreateLabeledPalletMutationResponse } from "../../../__generated__/CreateLabeledPalletMutation.graphql";
import {
  UpdateLabeledBoxMutationInput,
  UpdateLabeledBoxMutationResponse
} from "../../../__generated__/UpdateLabeledBoxMutation.graphql";
import {
  UpdateLabeledPalletMutationInput,
  UpdateLabeledPalletMutationResponse
} from "../../../__generated__/UpdateLabeledPalletMutation.graphql";
import createLabeledItem from "../../../mutations/CreateLabeledItemMutation";
import { CreateLabeledItemMutationResponse } from "../../../__generated__/CreateLabeledItemMutation.graphql";
import {
  UpdateLabeledItemMutationInput,
  UpdateLabeledItemMutationResponse
} from "../../../__generated__/UpdateLabeledItemMutation.graphql";
import updateLabeledItem from "../../../mutations/UpdateLabeledItemMutation";
import deleteLabeledItem from "../../../mutations/DeleteLabeledItemMutation";
import createLoad from "../../../mutations/CreateLoadMutation";
import {
  CreateLoadMutationInput,
  CreateLoadMutationResponse
} from "../../../__generated__/CreateLoadMutation.graphql";

const styles = theme =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "row",
      height: "100vh",
      width: "100%",
      justifyContent: "space-between"
    }
  });

type Props = {
  classes: any;
  viewer: FrameViewer_viewer;
  relay: RelayProp;
  openCV: Object;
  canvasContext: CanvasRenderingContext2D;
  topLevelLabelActions: TopLevelLabelActions;
};
type State = {
  image: HTMLImageElement | null;
  originalImageData: ImageData | null;
  imageData: ImageData | null;
  originalImage: HTMLImageElement | null;
  imageTFacade: Matrix3;
  knownObjectPoseId: string | null;

  selectedLabelIds: Set<string>;
  selectedFaceIds: Set<string>;
  selectedCornerIds: Set<string>;
  hoveredFaceIds: Set<string>;
  hoveredCornerIds: Set<string>;

  currentCreation: {
    labelId: string | null;
    faceId: string | null;
    palletPocketDetails?: {
      isFrontFace: boolean;
      isLeftPocket: boolean;
    };
    isLoad: boolean;
  } | null;
  notificationText: string | null;

  dirtyCorner: UpdateCornerMutationInput | null;

  autoPalletValue: string | null;
  labelerUsernames: Set<string>;

  showQAHook: boolean;
};

class FrameViewer extends React.Component<Props, State> {
  state = {
    image: null,
    originalImageData: null,
    imageData: null,
    originalImage: null,
    imageTFacade: new Matrix3().identity(),
    knownObjectPoseId: null,

    selectedLabelIds: new Set(),
    selectedFaceIds: new Set(),
    selectedCornerIds: new Set(),
    hoveredFaceIds: new Set(),
    hoveredCornerIds: new Set(),

    currentCreation: null,

    notificationText: null,

    rotationRadians: 0,
    dirtyCorner: null,

    autoPalletValue: null,
    labelerUsernames: new Set(),

    showQAHook: false
  };

  _labelSelector: LabelSelector;
  _labelMutators: LabelMutators;
  _removeTransitionHook: () => void;
  _removeQAHook: () => void;
  _resolveQAHook: null | ((arg0: boolean) => void);

  _qaHook = location => {
    if (this.props.topLevelLabelActions.showQAPolicy !== "OnNavigation") {
      return;
    }
    this.setState({ showQAHook: true });

    return new Promise(resolve => {
      this._resolveQAHook = confirmedQA => {
        if (!confirmedQA) {
          // Broadcast that the navigation was canceled.
          const { topLevelLabelActions, viewer } = this.props;
          const { frame } = viewer;
          if (frame) {
            const { id, indexInSensorStream, timestamp } = frame;
            topLevelLabelActions.setLoadedFrameDetails({
              id,
              indexInSensorStream,
              timestamp
            });
          }
        }
        this.setState({ showQAHook: false });
        this._resolveQAHook = null;
        resolve(confirmedQA);
      };
    });
  };

  constructor(props, context) {
    super(props, context);
    this._removeQAHook = props.router.addTransitionHook(this._qaHook);

    const frameViewer = this;
    this._labelSelector = {
      selectFace: (face, preserveExisting: boolean | null): void => {
        let newSelection = preserveExisting
          ? frameViewer.state.selectedFaceIds
          : new Set();
        if (!preserveExisting) {
          newSelection.clear();
        }
        if (face) {
          newSelection.add(face.id);
        }
        frameViewer.setState({ selectedFaceIds: newSelection });
      },
      selectCorner: (corner, preserveExisting: boolean | null): void => {
        let newSelection = preserveExisting
          ? frameViewer.state.selectedCornerIds
          : new Set();
        if (corner) {
          newSelection.add(corner.id);
        }
        frameViewer.setState({
          selectedCornerIds: newSelection
        });
      },
      hoverLabel: (
        unpopulatedLabel,
        preserveExisting: boolean | null
      ): void => {
        const labelAndCategory = frameViewer._findLabel(unpopulatedLabel.id);
        if (!labelAndCategory) {
          return;
        }
        // Hover a label by hovering all of the label's faces.
        let newHovered = preserveExisting
          ? frameViewer.state.hoveredFaceIds
          : new Set();
        const faces = allFacesForLabel(labelAndCategory.label, false);

        faces.forEach(f => newHovered.add(f.id));
        frameViewer.setState({ hoveredFaceIds: newHovered });
      },
      hoverFace: (face, preserveExisting: boolean | null): void => {
        if (!preserveExisting) {
          frameViewer.state.hoveredFaceIds.clear();
        }
        let newHovered = preserveExisting
          ? frameViewer.state.hoveredFaceIds
          : new Set();
        frameViewer.setState({ hoveredFaceIds: newHovered.add(face.id) });
      },
      hoverCorner: (corner, preserveExisting: boolean | null): void => {
        if (!preserveExisting) {
          frameViewer.state.hoveredFaceIds.clear();
        }
        let newHovered = preserveExisting
          ? frameViewer.state.hoveredCornerIds
          : new Set();
        frameViewer.setState({
          hoveredCornerIds: newHovered.add(corner.id)
        });
      },
      clearHovers: (): void => {
        const { hoveredFaceIds, hoveredCornerIds } = frameViewer.state;
        if (!hoveredFaceIds.size && !hoveredCornerIds.size) {
          // Skip no-op state change.
          return;
        }
        frameViewer.setState({
          hoveredFaceIds: new Set(),
          hoveredCornerIds: new Set()
        });
      },
      clearSelections: (): void => {
        const { selectedFaceIds, selectedCornerIds } = frameViewer.state;
        selectedFaceIds.clear();
        selectedCornerIds.clear();
        frameViewer.setState({ selectedFaceIds, selectedCornerIds });
      },

      isFaceSelected: (face): boolean =>
        frameViewer.state.selectedFaceIds.has(face.id),
      isFaceHovered: (face): boolean =>
        frameViewer.state.hoveredFaceIds.has(face.id),
      isCornerSelected: (corner): boolean =>
        frameViewer.state.selectedCornerIds.has(corner.id),
      isCornerHovered: (corner): boolean =>
        frameViewer.state.hoveredCornerIds.has(corner.id),
      isLabelSelected: (label): boolean => {
        if (frameViewer.state.selectedLabelIds.has(label.id)) {
          return true;
        }
        const fullLabel = this._findLabel(label.id);
        if (!fullLabel) {
          return false;
        }
        const faces = fullLabel.label.shape.faces;
        if (fullLabel?.label?.load?.faces) {
          faces.concat(fullLabel.label.load.faces);
        }
        return faces.some(f => this.state.selectedFaceIds.has(f.id));
      },
      selectedFaceIds: () => [...frameViewer.state.selectedFaceIds],
      selectedCornerIds: () => [...frameViewer.state.selectedCornerIds],
      selectedLabelIds: () =>
        Array.from(
          new Set(
            [...frameViewer.state.selectedFaceIds]
              .map(id => frameViewer._findLabelByFace(id))
              .filter(l => !!l)
              .map(l => l.id)
          )
        ),
      isAnyLabelBeingCreated: () => frameViewer.state.currentCreation !== null,
      isFaceBeingCreated: face =>
        !!frameViewer.state.currentCreation &&
        face.id === frameViewer.state.currentCreation.faceId,
      isLabelBeingCreated: label =>
        !!frameViewer.state.currentCreation &&
        label.id === frameViewer.state.currentCreation.labelId,
      currentCreationLabelId: () =>
        frameViewer.state.currentCreation
          ? frameViewer.state.currentCreation.labelId
          : null,
      currentCreationFaceId: () =>
        !frameViewer.state.currentCreation
          ? null
          : frameViewer.state.currentCreation.faceId,
      currentCreationPocketDetails: () => {
        const { currentCreation } = frameViewer.state;
        return currentCreation ? currentCreation.palletPocketDetails : null;
      },
      startCreatingPalletPocket: (faceId, isLeftPocket) => {
        const label = frameViewer._findLabelByFace(faceId);
        if (!label) {
          return;
        }
        const face = frameViewer._findFace(faceId);
        if (!face) {
          return;
        }
        frameViewer.setState({
          currentCreation: {
            labelId: label.id,
            faceSide: face.side,
            faceId: faceId,
            palletPocketDetails: {
              isFrontFace: face.side === "FRONT",
              isLeftPocket
            }
          }
        });
      }
    };
  }

  componentWillUnmount() {
    this._removeQAHook();
  }

  _resetFacade() {
    const { viewer, openCV, topLevelLabelActions, canvasContext } = this.props;
    const { frame } = viewer;
    const { originalImage, knownObjectPoseId } = this.state;
    if (!canvasContext || !frame) {
      return;
    }

    let src = openCV.imread(originalImage);
    const imageWidth = frame.imageMetadata.width || src.cols;
    const imageHeight = frame.imageMetadata.height || src.rows;

    // Clear the old image.
    canvasContext.save();
    canvasContext.setTransform(1, 0, 0, 1, 0, 0);
    canvasContext.clearRect(
      0,
      0,
      canvasContext.canvas.width,
      canvasContext.canvas.height
    );
    canvasContext.restore();

    let knownObjectPose = null;
    if (knownObjectPoseId !== null) {
      knownObjectPose = frame.knownObjectPoses.find(
        p => p.id === knownObjectPoseId
      );
    } else {
      knownObjectPose = frame.knownObjectPoses.find(
        p => p.knownObject === topLevelLabelActions.preferredKnownObjectView
      );
    }

    // Determine the bounds of the new facade.
    const imageQuad = [
      0,
      0,
      0,
      imageHeight - 1,
      imageWidth - 1,
      imageHeight - 1,
      imageWidth - 1,
      0
    ];
    // Default destQuad to the image dimensions. Use a quad derived from the
    // facade if the facade exists.
    let destQuad = imageQuad;
    if (knownObjectPose) {
      const { distance, orientation, position } = knownObjectPose;
      const autoQuad = poseToPalletQuad(
        distance,
        new Quaternion(
          orientation.x,
          orientation.y,
          orientation.z,
          orientation.w
        ),
        new Vector3(position.x, position.y, position.z),
        frame.cameraModel
      );
      destQuad = [
        autoQuad[0].x,
        autoQuad[0].y,
        autoQuad[1].x,
        autoQuad[1].y,
        autoQuad[2].x,
        autoQuad[2].y,
        autoQuad[3].x,
        autoQuad[3].y
      ];
    }

    // Get and apply the perspective transform for the respective quads.
    let dst = new openCV.Mat();
    let dsize = new openCV.Size(imageWidth, imageHeight);
    let srcTri = openCV.matFromArray(4, 1, openCV.CV_32FC2, imageQuad);
    let dstTri = openCV.matFromArray(4, 1, openCV.CV_32FC2, destQuad);
    let M = openCV.getPerspectiveTransform(dstTri, srcTri);
    openCV.warpPerspective(
      src,
      dst,
      M,
      dsize,
      openCV.INTER_AREA,
      openCV.BORDER_CONSTANT,
      new openCV.Scalar()
    );
    let imageTFacade = new Matrix3();
    imageTFacade.set(...M.data64F);
    this.setState({ imageTFacade: imageTFacade });

    // Unpack the warped image so the canvas can use it.
    const img = new openCV.Mat();
    const depth = dst.type() % 8;
    const scale =
      depth <= openCV.CV_8S ? 1 : depth <= openCV.CV_32S ? 1 / 256 : 255;
    const shift = depth === openCV.CV_8S || depth === openCV.CV_16S ? 128 : 0;
    dst.convertTo(img, openCV.CV_8U, scale, shift);
    switch (img.type()) {
      case openCV.CV_8UC1:
        openCV.openCV(img, img, openCV.COLOR_GRAY2RGBA);
        break;
      case openCV.CV_8UC3:
        openCV.openCV(img, img, openCV.COLOR_RGB2RGBA);
        break;
      case openCV.CV_8UC4:
        break;
      default:
        throw new Error(
          "Bad number of channels (Source image must have 1, 3 or 4 channels)"
        );
    }
    const imageData = new ImageData(
      new Uint8ClampedArray(img.data),
      img.cols,
      img.rows
    );

    this.setState(
      {
        imageData,
        originalImageData: new ImageData(
          Uint8ClampedArray.from(imageData.data),
          imageData.width,
          imageData.height
        )
      },
      () =>
        this._applyBrightness().then(() => {
          src.delete();
          dst.delete();
          img.delete();
          M.delete();
          srcTri.delete();
          dstTri.delete();
        })
    );
  }

  _resetImage(imageData: ImageData): Promise<void> {
    const { canvasContext } = this.props;
    // Clear the old image.
    canvasContext.save();
    canvasContext.setTransform(1, 0, 0, 1, 0, 0);
    canvasContext.clearRect(
      0,
      0,
      canvasContext.canvas.width,
      canvasContext.canvas.height
    );
    canvasContext.restore();

    // Assign the imageData to the canvas just to get a data url. Hide the
    // canvas to avoid an image jitter between putImageData and drawImage in
    // CanvasRenderer.
    canvasContext.canvas.style.visibility = "hidden";
    canvasContext.putImageData(imageData, 0, 0);
    let newImage = document.createElement("img");
    newImage.src = canvasContext.canvas.toDataURL("image/png");
    return new Promise((resolve, reject) => {
      newImage.onload = () => {
        if (this.state.originalImageData === null) {
          this.setState({
            originalImageData: new ImageData(
              Uint8ClampedArray.from(imageData.data),
              imageData.width,
              imageData.height
            )
          });
        }
        this.setState(
          { image: newImage, imageData },
          () => (canvasContext.canvas.style.visibility = "visible")
        );
        resolve();
      };
    });
  }

  componentDidMount() {
    const { topLevelLabelActions, viewer } = this.props;
    const { setLoadedFrameDetails, preferredKnownObjectView } =
      topLevelLabelActions;
    const { frame } = viewer;
    if (!frame) {
      return;
    }
    const { id, indexInSensorStream, timestamp } = frame;
    const loadedFrameDetails = { indexInSensorStream, id, timestamp };
    setLoadedFrameDetails(loadedFrameDetails);

    const palletLabelerUsernames = new Set(
      frame.labeledPallets
        .map(label => label.author.username)
        .concat(frame.labeledBoxes.map(label => label.author.username))
        .concat([viewer.username])
    );
    const itemLabelerUsernames = new Set(
      frame.labeledItems.map(label => label.author.username)
    );
    const labelerUsernames = new Set([
      ...palletLabelerUsernames,
      ...itemLabelerUsernames
    ]);

    let poseId: string | null = null;
    if (preferredKnownObjectView !== "RAW" && !!frame.knownObjectPoses) {
      const pose =
        frame?.knownObjectPoses.length && preferredKnownObjectView === null
          ? frame.knownObjectPoses[0]
          : frame.knownObjectPoses.find(
              p => p.knownObject === preferredKnownObjectView
            );
      if (pose?.position?.y > 0) {
        poseId = pose.id;
      }
    }
    this.setState({
      labelerUsernames,
      knownObjectPoseId: poseId
    });
    const { imageMetadata } = frame;
    if (!imageMetadata) {
      console.log("Frame has no image metadata");
      return;
    }
    let originalImage =
      imageMetadata.width > 0 && imageMetadata.height > 0
        ? new Image(imageMetadata.width, imageMetadata.height)
        : new Image(); // Compute size from loaded image
    originalImage.crossOrigin = "*";
    originalImage.src = imageMetadata.signedUrl;
    originalImage.onload = () => {
      this.setState({ originalImage });
      this._resetFacade();
    };
  }

  _applyBrightness(): Promise<void> {
    const { canvasBrightness } = this.props.topLevelLabelActions;
    const brightnessDiff = 255 * ((canvasBrightness - 100) / 100);
    const { originalImageData, imageData } = this.state;
    if (!originalImageData || !imageData) {
      return new Promise((resolve, reject) => reject("No ImageData"));
    }
    imageData.data.forEach(
      (value, idx) =>
        (imageData.data[idx] = originalImageData.data[idx] + brightnessDiff)
    );
    return this._resetImage(imageData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { canvasBrightness } = this.props.topLevelLabelActions;
    if (prevProps.topLevelLabelActions.canvasBrightness === canvasBrightness) {
      return;
    }
    this._applyBrightness();
  }

  render() {
    const { classes, viewer, openCV, canvasContext, topLevelLabelActions } =
      this.props;
    const { frame } = viewer;
    if (!frame) {
      return null;
    }
    const {
      imageTFacade,
      image,
      imageData,
      autoPalletValue,
      knownObjectPoseId,
      labelerUsernames,
      showQAHook
    } = this.state;
    const labelSelector = this._labelSelector;
    const showQA = showQAHook || topLevelLabelActions.showQAPolicy === "Always";

    const mutator: LabelMutators = {
      corner: {
        create: (x: number, y: number, visibility, faceId): Promise<Corner> =>
          this._createCorner(x, y, visibility, faceId),
        update: (corner: UpdateCornerMutationInput) =>
          this._updateCorner(corner, true),
        updateLocal: (corner: UpdateCornerMutationInput) =>
          this._updateCorner(corner, false),
        commitLocalUpdate: (cornerId: string): Promise<Corner> =>
          this._commitLocalCornerUpdate(cornerId)
      },
      face: {
        start: (
          labelId,
          faceSide,
          leftOfExisting: boolean | null = null,
          isLoad: boolean
        ): Promise<LabelFace> =>
          this._startFace(labelId, faceSide, leftOfExisting, isLoad),
        resume: (labelId: string, faceId: string) =>
          this._resumeFace(labelId, faceId),
        removeCorner: (faceId: string, cornerId: string): Promise<LabelFace> =>
          this._removeFaceCorner(faceId, cornerId),
        addCorner: (faceId: string, cornerId: string): Promise<LabelFace> =>
          this._addFaceCorner(faceId, cornerId),
        update: (input: UpdateFaceMutationInput): Promise<LabelFace> =>
          this._updateFace(input),
        delete: (faceId: string) => this._deleteFace(faceId)
      },
      label: {
        box: {
          create: (label, frameId): Promise<Label> =>
            this._createLabeledBox(label, frameId),
          update: (input: UpdateLabeledBoxMutationInput): Promise<Label> =>
            this._updateLabeledBox(input),
          delete: (labelId: string) => this._deleteLabeledBox(labelId)
        },
        item: {
          create: (label, frameId): Promise<Label> =>
            this._createLabeledItem(label, frameId),
          update: (input: UpdateLabeledBoxMutationInput): Promise<Label> =>
            this._updateLabeledItem(input),
          delete: (labelId: string) => this._deleteLabeledItem(labelId)
        },
        pallet: {
          create: (label, frameId): Promise<Label> =>
            this._createLabeledPallet(label, frameId),
          update: (input: UpdateLabeledPalletMutationInput): Promise<Label> =>
            this._updateLabeledPallet(input),
          delete: (labelId: string) => this._deleteLabeledPallet(labelId),
          createPocketCorner: (
            input: CreatePalletPocketCornerMutationInput
          ): Promise<Label> => this._createPalletPocketCorner(input),
          deletePocket: (faceId, isLeftPocket) =>
            this._deletePalletPocket({ faceId, isLeftPocket })
        },
        load: {
          create: (input: CreateLoadMutationInput): Promise<Label> =>
            this._createLoad(input),
          addSide: (input: any): Promise<LabelFace> => this._addLoadSide(input)
        },
        any: {
          delete: (labelId: string) => this._deleteLabel(labelId)
        }
      }
    };

    const setKnownObjectPose = (knownObjectPoseId: string | null) => {
      const { frame } = this.props.viewer;
      if (!frame) {
        return;
      }
      let preferredKnownObjectView = "RAW";
      if (knownObjectPoseId !== null) {
        const pose = frame.knownObjectPoses.find(
          p => p.id === knownObjectPoseId
        );
        if (pose) {
          preferredKnownObjectView = pose.knownObject;
        }
      }

      this.props.topLevelLabelActions.setPreferredKnownObjectView(
        preferredKnownObjectView
      );
      this.setState({ knownObjectPoseId }, () => this._resetFacade());
    };
    const hookResolver = this._resolveQAHook ? this._resolveQAHook : _ => {};

    return (
      <React.Fragment>
        <div className={classes.main}>
          {canvasContext && image && frame && imageData && openCV && (
            <CanvasRenderer
              topLevelLabelActions={topLevelLabelActions}
              frame={frame}
              knownObjectPoseId={knownObjectPoseId}
              setKnownObjectPoseId={setKnownObjectPose}
              canvasContext={canvasContext}
              image={image}
              imageData={imageData}
              openCV={openCV}
              imageTFacade={imageTFacade}
              viewer={viewer}
              labelSelector={labelSelector}
              labelMutator={mutator}
              labelerUsernames={labelerUsernames}
            />
          )}
          {frame && (
            <IssuesBar
              labelMutator={mutator}
              labelSelector={labelSelector}
              topLevelLabelActions={topLevelLabelActions}
              frame={frame}
              labelerUsernames={labelerUsernames}
              viewer={viewer}
            />
          )}
          {frame && (
            <SideBar
              labelMutator={mutator}
              labelSelector={labelSelector}
              topLevelLabelActions={topLevelLabelActions}
              autoPalletValue={autoPalletValue}
              setAutoPalletValue={autoPalletValue =>
                this.setState({ autoPalletValue })
              }
              knownObjectPoseId={knownObjectPoseId}
              setKnownObjectPoseId={setKnownObjectPose}
              labelerUsernames={labelerUsernames}
              setLabelerUsernames={labelerUsernames =>
                this.setState({
                  labelerUsernames: new Set(labelerUsernames)
                })
              }
              frame={frame}
              viewer={viewer}
            />
          )}
        </div>
        <FrameQADialog
          open={showQA}
          frame={frame}
          viewer={viewer}
          onCancel={showQAHook ? () => hookResolver(false) : null}
          onProceed={showQAHook ? () => hookResolver(true) : null}
        />
      </React.Fragment>
    );
  }

  _findLabel(
    labelId: string
  ): { label: Label; category: LabelCategory } | null {
    const { frame } = this.props.viewer;
    if (!frame) {
      return null;
    }
    let labelAndCategory = null;
    const categoryToLabels: Map<LabelCategory, ReadonlyArray<Label>> = new Map([
      ["BOX", frame.labeledBoxes],
      ["PALLET", frame.labeledPallets],
      ["ITEM", frame.labeledItems]
    ]);
    categoryToLabels.forEach(
      (labels: ReadonlyArray<Label>, category: LabelCategory) => {
        const label = labels.find(l => l.id === labelId);
        if (!label) {
          return;
        }
        labelAndCategory = { label, category };
      }
    );
    return labelAndCategory;
  }

  _findLabelByFace(faceId: string): Label | null {
    const { frame } = this.props.viewer;
    if (!frame) {
      return null;
    }
    const loads = [];
    for (const pallet of frame.labeledPallets) {
      pallet.load && loads.push(pallet.load);
    }
    const labels: Label[] = []
      .concat(frame.labeledBoxes)
      .concat(frame.labeledPallets)
      .concat(frame.labeledItems)
      .filter(l => l?.shape?.faces?.find(f => f.id === faceId))
      .concat(loads.filter(l => l?.faces?.find(f => f.id === faceId)));
    if (!labels) {
      return null;
    }

    return labels.find(l => !!l);
  }

  _findFace(faceId: string): LabelFace | null {
    const { frame } = this.props.viewer;
    if (!frame) {
      return null;
    }
    const labels = []
      .concat(frame.labeledBoxes)
      .concat(frame.labeledPallets)
      .concat(frame.labeledItems);

    const shapeFaces = labels.map(l =>
      l.shape.faces.find(f => f.id === faceId)
    );
    const loadFaces = labels.map(
      l => l.load && l.load?.faces?.find(f => f.id === faceId)
    );

    return shapeFaces.concat(loadFaces).find(f => !!f);
  }

  async _createCorner(x, y, visibility, faceId: string): Promise<Corner> {
    // Create corner.
    // Preemptively complete the creation if this mutation will add a 4th corner
    // TODO(malcolm): re-start the creation if this mutation fails.
    const face = this._findFace(faceId);
    let isCornersComplete = false;
    if (!face) {
      return Promise.reject();
    }
    if (face?.corners.length === 3) {
      this.setState({ currentCreation: null });
      isCornersComplete = true;
    }
    const label = this._findLabelByFace(faceId);
    // need to handle response for createCorner and updateFace
    // pallet has multiple cuboids
    // cuboid has one face

    const input_corner: Corner = {
      x,
      y,
      visibility,
      id: "client:final"
    };
    let cornersToCommit: Corner[] = [];
    // note: hard coded to assume all local updates use client in temp id
    if (isCornersComplete) {
      const newCorners = face.corners.filter(c => c.id.includes("client"));
      cornersToCommit = cornersToCommit.concat(newCorners);
    }
    cornersToCommit.push(input_corner);

    for (const corner of cornersToCommit) {
      await new Promise((resolve, reject) =>
        createCorner(
          this.props.relay.environment,
          {
            faceId,
            x: corner.x,
            y: corner.y,
            visibility: corner.visibility
          },
          isCornersComplete,
          face,
          label.id,
          (res: CreateCornerMutationResponse | null, errors) => {
            if (res?.createCorner?.face) {
              if (res.createCorner.face.corners.length === 4) {
                this.setState({ currentCreation: null });
                res.createCorner &&
                  this._labelSelector.selectFace(res.createCorner.face);
              }
              return resolve(res);
            }
            reject(errors);
          }
        )
      );
    }
    return input_corner;
  }

  _deletePalletPocket(input: DeletePalletPocketMutationInput): void {
    // TODO(malcolm): re-start the creation if this mutation fails.
    const face = this._findFace(input.faceId);
    if (!face) {
      console.warn("Cannot find face with id: ", input.faceId);
      return;
    }
    this.setState({ currentCreation: null });
    deletePalletPocket(this.props.viewer, this.props.relay.environment, input);
  }

  async _createPalletPocketCorner(
    input: CreatePalletPocketCornerMutationInput
  ): Promise<Label> {
    // Create corner.
    // Preemptively complete the creation if this mutation will add a 4th corner
    // TODO(malcolm): re-start the creation if this mutation fails.
    const pallet = this._findLabel(input.palletId);
    if (!pallet) {
      return Promise.reject();
    }

    const face = input.isFrontFace
      ? pallet.label.shape.faces.find(f => f.side === "FRONT")
      : pallet.label.shape.faces.find(f => f.side === "SIDE");

    const label = this._findLabelByFace(face.id);

    const pockets = input.isFrontFace ? label.frontPockets : label.sidePockets;

    const pocket = input.isLeftPocket ? pockets?.left : pockets?.right;

    const isCornersComplete = pocket?.corners.length === 3;

    const cornersToCommit = [];
    if (isCornersComplete) {
      pocket.corners.forEach(val => cornersToCommit.push(val));
    }
    cornersToCommit.push(input);

    for (const corner of cornersToCommit) {
      const cornerInput = {
        ...input,
        x: corner.x,
        y: corner.y,
        visibility: corner.visibility
      };

      await new Promise((resolve, reject) =>
        createPalletPocketCorner(
          this.props.viewer,
          this.props.relay.environment,
          cornerInput,
          isCornersComplete,
          (res: CreatePalletPocketCornerMutationResponse | null, errors) => {
            if (res?.createPalletPocketCorner?.pallet) {
              const { pallet } = res.createPalletPocketCorner;
              const pockets = input.isFrontFace
                ? pallet.frontPockets
                : pallet.sidePockets;

              if (pockets == null) {
                reject(new Error("Pallet response has no pockets"));
              }
              const pocket = input.isLeftPocket
                ? pockets?.left
                : pockets?.right;
              if (!pocket) {
                reject(
                  new Error("Pallet response does not have expected pocket")
                );
              }
              if (pocket.corners.length === 4) {
                this.setState({ currentCreation: null });
              }
              resolve(pallet);
            }
            reject(errors);
          }
        )
      );
    }
    return pallet.label;
  }

  /**
   * Updates a corner to the local store and optionally to the server.
   * @param {Corner} corner The updated corner value.
   * @param {boolean} commit If false, the update will only be local, and will
   * not be pushed to the GraphQL API. Local changes can later be commited with
   * _commitLocalCornerUpdate.
   * @returns {Promise<Corner>} A promise of the updated corner.
   * @private
   */
  _updateCorner(
    corner: UpdateCornerMutationInput,
    commit: boolean = true
  ): Promise<Corner> {
    if (!commit) {
      this.setState({ dirtyCorner: corner });
    }
    return new Promise((resolve, reject) => {
      updateCorner(
        this.props.relay.environment,
        corner,
        commit,
        (
          response: UpdateCornerMutationResponse | null,
          errors: Array<PayloadError> | null
        ) => {
          this.setState({
            dirtyCorner: null
          });
          if (errors) {
            this.setState({
              notificationText:
                "Failed to update corner: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!response || errors || !response.updateCorner) {
            reject();
            return;
          }
          resolve(response.updateCorner.corner);
        },
        () => reject()
      );
    });
  }

  _commitLocalCornerUpdate(dirtyCornerId): Promise<Corner> {
    // wants to hit DB
    // only does client side if rolling back changes
    // rolls back changes if doesnt hit DB

    const { dirtyCorner } = this.state;

    if (!dirtyCorner || dirtyCorner.id !== dirtyCornerId) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    const rollbackLocalChanges = () =>
      updateCorner(
        this.props.relay.environment,
        {
          id: dirtyCorner.id,
          x: dirtyCorner.x,
          y: dirtyCorner.y,
          visibility: dirtyCorner.visibility
        },
        false
      );

    return new Promise((resolve, reject) => {
      // onError is called when the actual request fails (e.g. bad gateway)
      const onError = (e: Error) => {
        // Present the raw error to the user to help with debugging. We'll want
        // to change this behavior if we product-ize the labeler.
        const debugInfo = e.stack ? e.stack : e.message;
        this.setState({
          dirtyCorner: null,
          notificationText: "Failed to update corner. Debug info: " + debugInfo
        });
        rollbackLocalChanges();
        reject();
      };
      const onCompleted = (
        response: UpdateCornerMutationResponse | null,
        errors: Array<PayloadError> | null
      ) => {
        this.setState({
          dirtyCorner: null
        });
        if (errors) {
          this.setState({
            notificationText:
              "Failed to update corner: \n" +
              errors.map(e => e.message).join("\n")
          });
          rollbackLocalChanges();
        }
        if (!response || errors || !response.updateCorner) {
          reject();
          return;
        }
        resolve(response.updateCorner.corner);
      };
      updateCorner(
        this.props.relay.environment,
        {
          id: dirtyCorner.id,
          x: dirtyCorner.x,
          y: dirtyCorner.y,
          visibility: dirtyCorner.visibility
        },
        true,
        onCompleted,
        onError
      );
    });
  }

  _createLabeledItem(label, frameId): Promise<Label> {
    const { relay, topLevelLabelActions, viewer } = this.props;
    const face = label.shape.faces.length === 1 ? label.shape.faces[0] : null;
    if (!face) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    this.setState({ currentCreation: { labelId: null, faceId: null } });

    return new Promise((resolve, reject) => {
      createLabeledItem(
        viewer,
        relay.environment,
        {
          frameId,
          knownObjectType: topLevelLabelActions.creationKnownObject,
          faces: label.shape.faces.map(f => {
            return {
              newCorners: f.corners,
              existingCorners: [],
              side: f.side
            };
          })
        },
        (res: CreateLabeledItemMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to update corner: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.createLabeledItem) {
            reject();
            return;
          }
          const label = res.createLabeledItem.labeledItem;
          const labelId = label.id;
          const face =
            label.shape.faces.length === 1 ? label.shape.faces[0] : null;
          const faceId = face ? face.id : null;
          this.setState({ currentCreation: { labelId, faceId } });
          face && this._labelSelector.selectFace(face);
          resolve(label);
        }
      );
    });
  }

  _updateLabeledItem(input: UpdateLabeledItemMutationInput): Promise<Label> {
    const { relay } = this.props;

    return new Promise((resolve, reject) => {
      updateLabeledItem(
        relay.environment,
        input,
        (res: UpdateLabeledItemMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to update box: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.updateLabeledItem) {
            reject();
            return;
          }
          const label = res.updateLabeledItem.labeledItem;
          resolve({ frontPockets: {}, sidePockets: {}, ...label });
        }
      );
    });
  }

  _createLabeledBox(label, frameId): Promise<Label> {
    const { relay, topLevelLabelActions, viewer } = this.props;
    const { autoPalletValue } = this.state;
    const face = label.shape.faces.length === 1 ? label.shape.faces[0] : null;
    if (!face) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    this.setState({ currentCreation: { labelId: null, faceId: null } });

    return new Promise((resolve, reject) => {
      createLabeledBox(
        viewer,
        relay.environment,
        {
          frameId,
          palletId: autoPalletValue,
          knownObjectType: topLevelLabelActions.creationKnownObject,
          faces: label.shape.faces.map(f => {
            return {
              newCorners: f.corners,
              existingCorners: [],
              side: f.side
            };
          })
        },
        (res: CreateLabeledBoxMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to update corner: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.createLabeledBox) {
            reject();
            return;
          }
          const label = res.createLabeledBox.labeledBox;
          const labelId = label.id;
          const face =
            label.shape.faces.length === 1 ? label.shape.faces[0] : null;
          const faceId = face ? face.id : null;
          this.setState({ currentCreation: { labelId, faceId } });
          face && this._labelSelector.selectFace(face);
          resolve(label);
        }
      );
    });
  }

  _updateLabeledBox(input: UpdateLabeledBoxMutationInput): Promise<Label> {
    const { relay } = this.props;

    return new Promise((resolve, reject) => {
      updateLabeledBox(
        relay.environment,
        input,
        (res: UpdateLabeledBoxMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to update box: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.updateLabeledBox) {
            reject();
            return;
          }
          const label = res.updateLabeledBox.labeledBox;
          resolve({ frontPockets: {}, sidePockets: {}, ...label });
        }
      );
    });
  }

  _createLabeledPallet(label, frameId): Promise<Label> {
    const { relay, topLevelLabelActions, viewer } = this.props;
    const face = label.shape.faces.length === 1 ? label.shape.faces[0] : null;
    if (!face) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    this.setState({ currentCreation: { labelId: null, faceId: null } });

    return new Promise((resolve, reject) => {
      createLabeledPallet(
        viewer,
        relay.environment,
        {
          frameId,
          knownObjectType: topLevelLabelActions.creationKnownObject,
          surfaceType: topLevelLabelActions.creationPalletSurface,
          faces: label.shape.faces.map(f => {
            return {
              newCorners: f.corners,
              existingCorners: [],
              side: f.side
            };
          })
        },
        (res: CreateLabeledPalletMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to create pallet: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.createLabeledPallet) {
            reject();
            return;
          }
          const label = res.createLabeledPallet.labeledPallet;
          const face =
            label.shape.faces.length === 1 ? label.shape.faces[0] : null;
          const faceId = face ? face.id : null;
          this.setState({
            currentCreation: { labelId: label.id, faceId },
            autoPalletValue: label.id
          });
          face && this._labelSelector.selectFace(face);
          resolve(label);
        }
      );
    });
  }

  _updateLabeledPallet(
    input: UpdateLabeledPalletMutationInput
  ): Promise<Label> {
    const { relay } = this.props;

    return new Promise((resolve, reject) => {
      updateLabeledPallet(
        relay.environment,
        input,
        (res: UpdateLabeledPalletMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to update pallet: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.updateLabeledPallet) {
            reject();
            return;
          }
          const label = res.updateLabeledPallet.labeledPallet;
          resolve(label);
        }
      );
    });
  }

  _createLoad(input: CreateLoadMutationInput): Promise<Label> {
    // should create face for load
    // no corners yet
    const { palletId } = input;
    const { relay, viewer } = this.props;
    const pallet = this._findLabel(palletId);

    if (!pallet) {
      return Promise.reject();
    }
    this.setState({ currentCreation: { labelId: null, faceId: null } });
    return new Promise((resolve, reject) => {
      createLoad(
        viewer,
        relay.environment,
        input,
        (res: CreateLoadMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to create load: \n" +
                errors.map(e => e.message).join("\n")
            });
          }

          if (!res || !res.createLoad) {
            reject();
            return;
          }
          // TODO(emily)
          const label = res.createLoad.pallet;
          const labelId = label.id;
          this.setState({
            currentCreation: { labelId, faceId: label.load.faces[0].id }
          });
          resolve(label);
        }
      );
    });
  }

  _addLoadSide(input): Promise<LabelFace> {
    // should create second face for load
    // with no corners
    const { id: palletId } = input;
    const { relay } = this.props;

    const pallet = this._findLabel(palletId);

    if (!pallet) {
      return Promise.reject();
    }
    this.setState({ currentCreation: { labelId: palletId, faceId: null } });

    return new Promise((resolve, reject) => {
      updateLoadSide(
        relay.environment,
        { palletId },
        (res: any | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to create load side: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.updateLoadFace) {
            reject();
            return;
          }
          // TODO(emily)
          const face = res.updateLoadFace.face;
          const faceId = face.id;
          this.setState({
            currentCreation: { faceId: faceId }
          });
          resolve(face);
        }
      );
    });
  }

  _updateFace(input: UpdateFaceMutationInput): Promise<LabelFace> {
    const { relay } = this.props;
    const label = this._findLabelByFace(input.id);
    if (!label) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    return new Promise((resolve, reject) => {
      updateFace(
        relay.environment,
        input,
        label.id,
        (res: UpdateFaceMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to update face: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.updateFace) {
            reject();
            return;
          }
          const face = res.updateFace.face;
          resolve(face);
        }
      );
    });
  }

  _resumeFace(faceId: string, labelId: string) {
    const { viewer } = this.props;
    const { frame } = viewer;
    if (!frame) {
      return;
    }
    let label = this._findLabel(labelId);
    if (!label) {
      return;
    }
    const face = this._findFace(faceId);
    if (!face) {
      return;
    }

    this.setState({ currentCreation: { labelId, faceId } });
  }

  _removeFaceCorner(faceId: string, cornerId: string): Promise<LabelFace> {
    const { relay } = this.props;
    const face = this._findFace(faceId);
    if (!face) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    const label = this._findLabelByFace(faceId);
    if (!label) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    this.setState({ currentCreation: { faceId, labelId: label.id } });
    const updatedCornerIds = face.corners
      .filter(c => c.id !== cornerId)
      .map(c => c.id);

    return new Promise((resolve, reject) =>
      updateFace(
        relay.environment,
        {
          id: faceId,
          corners: updatedCornerIds
        },
        label.id,
        (res: UpdateFaceMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to remove corner: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.updateFace) {
            reject();
            if (face.corners.length === 4) {
              this.setState({ currentCreation: null });
            }
            return;
          }
          resolve(res.updateFace.face);
        }
      )
    );
  }

  _deleteFace(faceId: string) {
    const { environment } = this.props.relay;
    deleteFace(environment, { id: faceId });
  }

  _deleteLabel(labelId: string) {
    const labelAndCategory = this._findLabel(labelId);
    if (!labelAndCategory) {
      console.warn("Deletion failed. Could not find label with id:", labelId);
      return;
    }
    switch (labelAndCategory.category) {
      case "BOX":
        return this._deleteLabeledBox(labelId);
      case "PALLET":
        return this._deleteLabeledPallet(labelId);
      default:
        console.warn(
          "Deletion failed. Unrecognized label category:",
          labelAndCategory.category
        );
    }
  }

  _deleteLabeledItem(labelId: string) {
    const { environment } = this.props.relay;
    // If user was creating the box they want to delete, clear the creation
    // state.
    const { currentCreation } = this.state;
    if (currentCreation && labelId === currentCreation.labelId) {
      this.setState({ currentCreation: null });
    }
    deleteLabeledItem(environment, { id: labelId });
  }

  _deleteLabeledBox(labelId: string) {
    const { environment } = this.props.relay;
    // If user was creating the box they want to delete, clear the creation
    // state.
    const { currentCreation } = this.state;
    if (currentCreation && labelId === currentCreation.labelId) {
      this.setState({ currentCreation: null });
    }
    deleteLabeledBox(environment, { id: labelId });
  }

  _deleteLabeledPallet(labelId: string) {
    const { environment } = this.props.relay;
    // If user was creating the box they want to delete, clear the creation
    // state.
    const { currentCreation, autoPalletValue } = this.state;
    if (currentCreation && labelId === currentCreation.labelId) {
      this.setState({ currentCreation: null });
    }
    if (autoPalletValue && labelId === autoPalletValue) {
      this.setState({ autoPalletValue: null });
    }
    deleteLabeledPallet(environment, { id: labelId });
  }

  _addFaceCorner(faceId: string, cornerId: string): Promise<LabelFace> {
    const { relay } = this.props;
    const face = this._findFace(faceId);
    if (!face) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    const label = this._findLabelByFace(faceId);
    if (!label) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    if (face.corners.some(c => c.id === cornerId)) {
      // The corner is already in the face.
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    const corners = face.corners.map(c => c.id);
    corners.push(cornerId);

    if (corners.length === 4) {
      this.setState({ currentCreation: null });
    } else {
      this.setState({ currentCreation: { faceId, labelId: label.id } });
    }
    return new Promise((resolve, reject) =>
      updateFace(
        relay.environment,
        {
          id: faceId,
          corners: corners
        },
        label.id,
        (res: UpdateFaceMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to add corner: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.updateFace) {
            reject();
            if (face.corners.length < 4) {
              this.setState({
                currentCreation: { faceId, labelId: label.id }
              });
            }
            return;
          }
          resolve(res.updateFace.face);
        }
      )
    );
  }

  _startFace(
    labelId: string,
    faceSide: FaceSide,
    leftOfExisting: boolean | null,
    isLoad: boolean | null
  ): Promise<LabelFace> {
    const { viewer } = this.props;
    const { frame } = viewer;
    if (!frame) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    let label = frame.labeledBoxes.find(b => b.id === labelId);
    if (!label) {
      label = frame.labeledPallets.find(p => p.id === labelId);
    }
    if (!label) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    const faceExists = isLoad
      ? label?.load?.faces.find(f => f.side === faceSide)
      : label.shape.faces.find(f => f.side === faceSide);
    // try change:
    if (faceExists) {
      console.warn(
        "Face already exists with side, label id:",
        faceSide,
        labelId
      );
      return new Promise((resolve, reject) => {
        reject(
          `Face already exists with side, label id: ${faceSide}, ${labelId}`
        );
      });
    }
    this.setState({
      currentCreation: {
        labelId,
        faceId: null
      }
    });
    const { imageTFacade } = this.state;
    if (!imageTFacade) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    this._labelSelector.clearSelections();

    // Find the corners that are expected to overlap with the new face and add
    // them to the face.
    const existingSideToOverlappingIndexes = {
      FRONT: {
        TOP: [1, 2],
        SIDE: leftOfExisting ? [0, 1] : [2, 3]
      },
      TOP: {
        FRONT: [0, 3],
        SIDE: [0, 3]
      },
      SIDE: {
        FRONT: leftOfExisting ? [0, 1] : [2, 3],
        TOP: leftOfExisting ? [0, 1] : [2, 3]
      },
      "%future added value": {}
    }[faceSide];

    let overlappingCornerIds = new Set();
    const faces = isLoad ? label.load.faces : label.shape.faces;

    for (const existingFace of faces) {
      let facadeCorners = existingFace.corners.map(c => {
        return {
          id: c.id,
          location: this._imageToFacade(c.x, c.y)
        };
      });
      // Use the top left corner (as seen in the facade) as a reference point
      // for finding overlapping corners.
      const topLeft = facadeCorners
        .concat()
        .sort((a, b) => {
          if (a.location.y === b.location.y) {
            return a.location.x - b.location.x;
          }
          // Top of the image is Y=0. Y increases towards the bottom of the
          // image.
          return a.location.y - b.location.y;
        })
        .slice(0, 2)
        .sort((a, b) => a.location.x - b.location.x)[0];

      const topLeftIndex = existingFace.corners.indexOf(
        existingFace.corners.find(c => c.id === topLeft.id)
      );

      const overlappingIndexes =
        existingSideToOverlappingIndexes[existingFace.side];

      overlappingIndexes
        .map(idx => existingFace.corners[(topLeftIndex + idx) % 4].id)
        .forEach(cornerId => overlappingCornerIds.add(cornerId));
    }

    return new Promise((resolve, reject) => {
      createFace(
        this.props.relay.environment,
        {
          face: {
            side: faceSide,
            existingCorners: [...overlappingCornerIds],
            newCorners: []
          },
          labelId,
          isLoad
        },
        (res: CreateFaceMutationResponse | null, errors) => {
          if (errors) {
            this.setState({
              notificationText:
                "Failed to create face: \n" +
                errors.map(e => e.message).join("\n")
            });
          }
          if (!res || !res.createFace) {
            reject(errors);
            return;
          }
          const face = res.createFace.face;
          this.setState({ currentCreation: { labelId, faceId: face.id } });
          this._labelSelector.selectFace(face);
          resolve(face);
        }
      );
    });
  }

  _imageToFacade = (xA: number, yA: number): Vector2 => {
    const { imageTFacade } = this.state;
    const { topLevelLabelActions } = this.props;
    return transformPoint(xA, yA, imageTFacade).rotateAround(
      new Vector2(0, 0),
      topLevelLabelActions.rotationRadians
    );
  };
}

export default withStyles(styles)(
  createFragmentContainer(FrameViewer, {
    viewer: graphql`
      fragment FrameViewer_viewer on Viewer {
        id
        isAnonymous
        isSupervisor
        isVetted
        isQualityController
        userId
        username
        frame: frameBySensor(
          runName: $runName
          sensorName: $sensorName
          indexInSensorStream: $indexInSensorStream
          timestamp: $timestamp
        ) {
          ...CanvasRenderer_frame
          ...SideBar_frame
          ...IssuesBar_frame
          ...FrameQADialog_frame
          id
          indexInSensorStream
          timestamp
          cameraModel {
            height
            width
            fx
            fy
            cx
            cy
          }
          labeledBoxes {
            id
            number
            frame {
              id
            }
            author {
              id
              username
            }
            pallet {
              number
            }
            shape {
              id
              faces {
                id
                side
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
            }
          }
          labeledItems {
            id
            number
            frame {
              id
            }
            author {
              id
              username
            }
            thwartedPallets {
              number
            }
            shape {
              id
              faces {
                id
                side
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
            }
          }
          labeledPallets {
            id
            number
            surfaceType
            frame {
              id
            }
            author {
              id
              username
            }
            sidePockets {
              left {
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
              right {
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
            }
            frontPockets {
              left {
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
              right {
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
            }
            shape {
              id
              faces {
                id
                side
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
            }
            load {
              id
              faces {
                id
                side
                corners {
                  id
                  x
                  y
                  visibility
                }
              }
            }
          }
          knownObjectPoses {
            id
            targetState
            knownObject
            orientation {
              w
              x
              y
              z
            }
            position {
              x
              y
              z
            }
            distance
          }
          imageMetadata {
            height
            width
            signedUrl
          }
        }
      }
    `
  })
);
