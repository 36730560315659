/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type UpdateFaceMutationInput = {
    readonly id: string;
    readonly side?: FaceSide | null;
    readonly corners?: ReadonlyArray<string> | null;
    readonly clientMutationId?: string | null;
};
export type UpdateFaceMutationVariables = {
    input: UpdateFaceMutationInput;
};
export type UpdateFaceMutationResponse = {
    readonly updateFace: {
        readonly face: {
            readonly id: string;
            readonly side: FaceSide;
            readonly corners: ReadonlyArray<{
                readonly id: string;
                readonly x: number;
                readonly y: number;
                readonly visibility: Visibility;
            }>;
        };
        readonly label: {
            readonly id?: string;
            readonly sidePockets?: {
                readonly id: string;
                readonly left: {
                    readonly id: string;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
                readonly right: {
                    readonly id: string;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
            } | null;
            readonly frontPockets?: {
                readonly id: string;
                readonly left: {
                    readonly id: string;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
                readonly right: {
                    readonly id: string;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
            } | null;
            readonly load?: {
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            } | null;
        };
    } | null;
};
export type UpdateFaceMutation = {
    readonly response: UpdateFaceMutationResponse;
    readonly variables: UpdateFaceMutationVariables;
};



/*
mutation UpdateFaceMutation(
  $input: UpdateFaceMutationInput!
) {
  updateFace(input: $input) {
    face {
      id
      side
      corners {
        id
        x
        y
        visibility
      }
    }
    label {
      __typename
      ... on LabeledBox {
        id
      }
      ... on LabeledPallet {
        id
        sidePockets {
          id
          left {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
          right {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
        }
        frontPockets {
          id
          left {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
          right {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
        }
        load {
          faces {
            id
            side
            corners {
              id
              x
              y
              visibility
            }
          }
          id
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFaceMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "x",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "y",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "visibility",
    "args": null,
    "storageKey": null
  }
],
v4 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "side",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "corners",
    "storageKey": null,
    "args": null,
    "concreteType": "CuboidCorner",
    "plural": true,
    "selections": (v3/*: any*/)
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "face",
  "storageKey": null,
  "args": null,
  "concreteType": "CuboidFace",
  "plural": false,
  "selections": (v4/*: any*/)
},
v6 = [
  (v2/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "corners",
    "storageKey": null,
    "args": null,
    "concreteType": "QuadCorner",
    "plural": true,
    "selections": (v3/*: any*/)
  }
],
v7 = [
  (v2/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "left",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v6/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "right",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v6/*: any*/)
  }
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "sidePockets",
  "storageKey": null,
  "args": null,
  "concreteType": "PalletPocket",
  "plural": false,
  "selections": (v7/*: any*/)
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "frontPockets",
  "storageKey": null,
  "args": null,
  "concreteType": "PalletPocket",
  "plural": false,
  "selections": (v7/*: any*/)
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "faces",
  "storageKey": null,
  "args": null,
  "concreteType": "CuboidFace",
  "plural": true,
  "selections": (v4/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateFaceMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFace",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFaceMutationPayload",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "label",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "LabeledBox",
                "selections": [
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "InlineFragment",
                "type": "LabeledPallet",
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "load",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Cuboid",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateFaceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFace",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFaceMutationPayload",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "label",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "LabeledPallet",
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "load",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Cuboid",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateFaceMutation",
    "id": null,
    "text": "mutation UpdateFaceMutation(\n  $input: UpdateFaceMutationInput!\n) {\n  updateFace(input: $input) {\n    face {\n      id\n      side\n      corners {\n        id\n        x\n        y\n        visibility\n      }\n    }\n    label {\n      __typename\n      ... on LabeledBox {\n        id\n      }\n      ... on LabeledPallet {\n        id\n        sidePockets {\n          id\n          left {\n            id\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n          right {\n            id\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n        }\n        frontPockets {\n          id\n          left {\n            id\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n          right {\n            id\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n        }\n        load {\n          faces {\n            id\n            side\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n          id\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '41cf6143d414de56424ac919ed74b887';
export default node;
