import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import {
  DeletePalletPocketMutationInput,
  DeletePalletPocketMutationResponse
} from "../__generated__/DeletePalletPocketMutation.graphql";

import { FrameViewer_viewer } from "../__generated__/FrameViewer_viewer.graphql";

const mutation = graphql`
  mutation DeletePalletPocketMutation(
    $input: DeletePalletPocketMutationInput!
  ) {
    deletePalletPocket(input: $input) {
      parent {
        id
        left {
          id
        }
        right {
          id
        }
      }
      face {
        id
        palletPocketLeft {
          id
        }
        palletPocketRight {
          id
        }
      }
    }
  }
`;

let tempID = 0;
function deletePalletPocket(
  viewer: FrameViewer_viewer,
  environment: Environment,
  input: DeletePalletPocketMutationInput,
  onCompleted?: (
    response: DeletePalletPocketMutationResponse | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  let mutationId: string = (tempID++).toString();
  const fullInput: DeletePalletPocketMutationInput = {
    clientMutationId: mutationId,
    ...input
  };
  commitMutation(environment, {
    mutation,
    variables: {
      input: fullInput
    },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      const face = store.get(input.faceId);
      if (!face) {
        return;
      }
      const pocketName = input.isLeftPocket
        ? "palletPocketsLeft"
        : "palletPocketsRight";
      face.setValue(undefined, pocketName);
    }
  });
}

export default deletePalletPocket;
