import React from "react";
import PropTypes from "prop-types";

import { graphql, createFragmentContainer } from "react-relay";

import environment from "../../createRelayEnvironment";
import signUp from "../../mutations/SignUpMutation";
import { setJwt } from "../../utils/Auth";
import { dashboardPath } from "../../utils/Paths";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired
};
type State = {
  email: string;
  username: string;
  password: string;
  userAlreadyExists: boolean;
  passwordInvalid: boolean;
  emailInvalid: boolean;
  requestPending: boolean;
};
class SignUp extends React.Component<typeof propTypes, State> {
  state = {
    email: "",
    username: "",
    password: "",
    userAlreadyExists: false,
    passwordInvalid: false,
    emailInvalid: false,
    requestPending: false
  };
  _signUp() {
    const { requestPending, email, username, password } = this.state;
    if (requestPending || !email || !username || !password) {
      return;
    }
    this.setState({
      requestPending: true,
      userAlreadyExists: false,
      passwordInvalid: false,
      emailInvalid: false
    });
    signUp(environment, { email, username, password })
      .then(response => {
        this.setState({ requestPending: false });
        if (response.signUp && response.signUp.token) {
          setJwt(response.signUp.token);
          const searchParams = new URLSearchParams(window.location.search);
          window.location = searchParams.get("next") || dashboardPath();
        }
      })
      .catch(errors => {
        this.setState({
          requestPending: false,
          passwordInvalid: !!errors.find(
            e =>
              e.code === "bad-request" &&
              e.message.toLowerCase().includes("username")
          ),
          emailInvalid: !!errors.find(
            e =>
              e.code === "bad-request" &&
              e.message.toLowerCase().includes("email")
          ),
          userAlreadyExists: !!errors.find(e => e.code === "forbidden")
        });
      });
  }

  render() {
    const { classes } = this.props;
    const {
      requestPending,
      userAlreadyExists,
      emailInvalid,
      passwordInvalid
    } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography variant="h5">Sign Up</Typography>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel error={emailInvalid} htmlFor="email">
                  {emailInvalid ? "Invalid email address" : "Email"}
                </InputLabel>
                <Input
                  onChange={e => this.setState({ email: e.target.value })}
                  onKeyDown={e => {
                    e.key === "Enter" && this._signUp();
                  }}
                  id="email"
                  name="email"
                  error={emailInvalid}
                  autoComplete="email"
                  autoFocus
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel error={userAlreadyExists} htmlFor="username">
                  {userAlreadyExists ? "Username is taken" : "Username"}
                </InputLabel>
                <Input
                  onChange={e =>
                    this.setState({
                      username: e.target.value
                    })
                  }
                  onKeyDown={e => {
                    e.key === "Enter" && this._signUp();
                  }}
                  id="username"
                  name="username"
                  error={userAlreadyExists}
                  autoComplete="username"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel error={passwordInvalid} htmlFor="password">
                  {passwordInvalid ? "Password too weak" : "Password"}
                </InputLabel>
                <Input
                  onChange={e =>
                    this.setState({
                      password: e.target.value
                    })
                  }
                  onKeyDown={e => {
                    e.key === "Enter" && this._signUp();
                  }}
                  name="password"
                  type="password"
                  id="password"
                  error={passwordInvalid}
                  autoComplete="current-password"
                />
              </FormControl>
              <Button
                onClick={() => this._signUp()}
                fullWidth
                disabled={requestPending}
                variant="raised"
                color="primary"
                className={classes.submit}
              >
                Sign up
              </Button>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(SignUp, {
    viewer: graphql`
      fragment SignUp_viewer on Viewer {
        id
        username
      }
    `
  })
);
