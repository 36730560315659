function frameNotLabelableKey(frameId: string) {
  return "notLabelable/" + frameId;
}

export function isFrameLabelable(frameId: string) {
  return !localStorage.getItem(frameNotLabelableKey(frameId));
}

export function toggleIsFrameLabelable(frameId: string) {
  if (isFrameLabelable(frameId)) {
    localStorage.setItem(frameNotLabelableKey(frameId), "true");
  } else {
    localStorage.removeItem(frameNotLabelableKey(frameId));
  }
}
