import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { UpdateFaceMutationInput } from "../__generated__/UpdateFaceMutation.graphql";

const mutation = graphql`
  mutation UpdateFaceMutation($input: UpdateFaceMutationInput!) {
    updateFace(input: $input) {
      face {
        id
        side
        corners {
          id
          x
          y
          visibility
        }
      }
      label {
        ... on LabeledBox {
          id
        }
        ... on LabeledPallet {
          id
          sidePockets {
            id
            left {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
            right {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
          }
          frontPockets {
            id
            left {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
            right {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
          }
          load {
            faces {
              id
              side
              corners {
                id
                x
                y
                visibility
              }
            }
          }
        }
      }
    }
  }
`;

function updateFace(
  environment: Environment,
  input: UpdateFaceMutationInput,
  labelId: string,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      onCompleted(
        {
          updateFace: {
            face: {
              id: input.id,
              side: input.side,
              corners: input.corners
            },
            label: store.get(labelId)
          }
        },
        []
      );
    }
  });
}

export default updateFace;
