import React from "react";
import { graphql, createRefetchContainer, RelayRefetchProp } from "react-relay";
import { Disposable } from "react-relay";
import {
  createStyles,
  WithStyles,
  withStyles,
  WithTheme
} from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LoadingScreen from "../common/LoadingScreen";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SensorStreamListItem from "./SensorStreamListItem";
import DebouncedTextField from "../common/DebouncedTextField";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";
import { ListSensorStreams_viewer } from "../../__generated__/ListSensorStreams_viewer.graphql";
import {
  CameraPosition,
  RunSource,
  LightingConditions,
  KnownObject,
  PalletSurface
} from "../../__generated__/ListSensorStreamsRefetchQuery.graphql";
import { isEmpty } from "lodash";

import {
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@material-ui/core";
import moment from "moment";
import { isValid } from "../../utils/CommonFunctions";
import {
  KNOWN_OBJECT_ENABLED,
  PALLET_SURFACE_TYPES,
  PALLET_FACE_TYPES
} from "../../utils/Enums";
import { FaceSide } from "../common/canvas/LabelMutators";
import DebouncedSelect from "../common/DebouncedSelect";

const localStyles = theme =>
  createStyles({
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    formControlSize: {
      textTransform: "capitalize"
    },
    cameraPositionMenuItem: {
      textTransform: "capitalize"
    },
    searchDate: {
      display: "flex",
      flexDirection: "column",
      height: 45,
      marginLeft: 8
    },
    searchDateLabel: {
      transform: "translate(0, 1.5px) scale(0.75)"
    },
    dateBox: {
      marginTop: 2
    },
    clearIcon: {
      padding: 0
    },
    dropDown: {
      marginLeft: 0,
      width: "auto",
      minWidth: 100,
      maxWidth: 150
    },
    dropDownMini: {
      marginLeft: 0,
      width: "auto",
      minWidth: 50,
      maxWidth: 130
    },
    selectWrapper: { marginLeft: 8 }
  });
const styles = combineStyles(localStyles, commonStyles);

const mlOptions = ["Show All", "Unmarked Only", "Marked Only"];

interface Props extends WithStyles<typeof styles>, WithTheme {
  viewer: ListSensorStreams_viewer;
  relay: RelayRefetchProp;
  reload: any;
}
type State = {
  runNameQuery: string | null;
  runDateStartQuery: Date | null;
  runDateEndQuery: Date | null;
  labelerQuery: string | null;
  knownObjects: Array<KnownObject> | null;
  sideVisible: Array<FaceSide> | null;
  surfaceType: Array<PalletSurface> | null;
  cameraPositions: Array<CameraPosition> | null;
  runSources: Array<RunSource> | null;
  lightingConditions: Array<LightingConditions> | null;
  minimumFrames: number | null;
  pendingRefetch: Disposable | null;
  isLoadingMore: boolean;
  isListOpen: boolean;
  filteredSensorStreams: any | Array<any> | null;
  selectedMLOption: string;
};

class ListSensorStreams extends React.Component<Props, State> {
  state = {
    runNameQuery: null,
    runDateStartQuery: null,
    runDateEndQuery: null,
    labelerQuery: null,
    knownObjects: null,
    sideVisible: null,
    surfaceType: null,
    cameraPositions: null,
    runSources: null,
    lightingConditions: null,
    minimumFrames: null,
    pendingRefetch: null,
    isLoadingMore: false,
    refetchCursor: null,
    isListOpen: true,
    filteredSensorStreams: null,
    selectedMLOption: mlOptions[0]
  };

  componentDidMount() {
    // Decided to filter by numFrames on frontend side
    const { viewer } = this.props;

    const streamArray = [];

    viewer.allSensorStreams.edges.forEach(({ node: sensorStream }) => {
      if (sensorStream.numFrames + 1 > this.state.minimumFrames) {
        streamArray.push(sensorStream);
      }
    });

    this.setState({ filteredSensorStreams: streamArray });
  }

  componentDidUpdate(prevProps, prevState) {
    // Decided to filter by numFrames on frontend side
    if (
      prevProps.viewer.allSensorStreams !==
        this.props.viewer.allSensorStreams ||
      prevState.minimumFrames !== this.state.minimumFrames
    ) {
      const { viewer } = this.props;
      const streamArray = [];

      viewer.allSensorStreams.edges.forEach(({ node: sensorStream }) => {
        if (sensorStream.numFrames + 1 > this.state.minimumFrames) {
          streamArray.push(sensorStream);
        }
      });

      this.setState({ filteredSensorStreams: streamArray });
    }
  }

  render() {
    const { viewer, classes, reload, theme } = this.props;
    let {
      isLoadingMore,
      pendingRefetch,
      minimumFrames,
      runNameQuery,
      runDateStartQuery,
      runDateEndQuery,
      labelerQuery,
      isListOpen,
      filteredSensorStreams,
      knownObjects,
      sideVisible,
      surfaceType,
      selectedMLOption
    } = this.state;
    const accordionClass = isListOpen
      ? classes.accordionOpen
      : classes.accordionClosed;
    const { endCursor } = this.props.viewer.allSensorStreams.pageInfo;
    const mlTooltip =
      selectedMLOption === mlOptions[1]
        ? "Runs Unmarked for ML Loop"
        : selectedMLOption === mlOptions[2]
        ? "Runs Sent to ML Loop"
        : "";
    return (
      <div className={classes.wrapper}>
        <AppBar color="default" position="static" className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarRoot }}>
            <Button
              className={classes.expandIcon}
              onClick={this._toggleAccordion}
            >
              {isListOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
            <Typography className={classes.title} color="inherit" noWrap>
              All logs
            </Typography>
            <div className={classes.grow} />
            {viewer.isSupervisor && (
              <div className={classes.search}>
                <FormHelperText>ML Filter</FormHelperText>

                <Tooltip title={mlTooltip}>
                  {/* ML Flag Filter */}
                  <Select
                    className={classes.dropDown}
                    value={selectedMLOption}
                    onChange={e => this._updateMLOption(e.target.value)}
                  >
                    {mlOptions.map((entry, index) => (
                      <MenuItem
                        key={`${entry}-${index}`}
                        value={entry}
                        className={classes.dropDownMenuItem}
                        style={{
                          fontWeight:
                            mlOptions.indexOf(entry[0]) === -1
                              ? theme.typography.fontWeightRegular
                              : theme.typography.fontWeightMedium
                        }}
                      >
                        {entry}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </div>
            )}

            {/* Object Filters */}
            <div className={classes.selectWrapper}>
              <FormHelperText>Object Type</FormHelperText>
              <div className={classes.row}>
                {/* Long / Short dropdown */}
                <DebouncedSelect
                  className={classes.dropDownMini}
                  value={sideVisible || []}
                  itemMap={PALLET_FACE_TYPES}
                  onChangeDebounced={e =>
                    this._updateVisibleSideQuery(e.target.value)
                  }
                />
                {/* Known Objects dropdown */}
                <DebouncedSelect
                  className={classes.dropDown}
                  value={knownObjects || []}
                  itemMap={KNOWN_OBJECT_ENABLED}
                  onChangeDebounced={e =>
                    this._updateKnownObjectsQuery(e.target.value)
                  }
                />
                {/* Surface Type dropdown */}
                <DebouncedSelect
                  className={classes.dropDownMini}
                  value={surfaceType || []}
                  itemMap={PALLET_SURFACE_TYPES}
                  onChangeDebounced={e =>
                    this._updateSurfaceTypeQuery(e.target.value)
                  }
                />
              </div>
            </div>

            {/* Frame Count Filter */}
            <div className={classes.search}>
              <DebouncedTextField
                value={minimumFrames || ""}
                label="Min Frame Count"
                onChangeDebounced={e =>
                  this._updateMinimumFrames(e.target.value)
                }
              />
            </div>
            {/* Date Filter */}
            {/* Run's From Filter */}
            <div className={classes.searchDate}>
              <InputLabel
                className={classes.searchDateLabel}
                htmlFor="labeling-report-input"
              >
                Run's From:
              </InputLabel>
              <div className={classes.flexGroup}>
                <TextField
                  className={classes.dateBox}
                  onChange={e => this._updateRunDateStartQuery(e.target.value)}
                  type={"date"}
                  value={
                    isValid(runDateStartQuery) &&
                    moment(runDateStartQuery).format("YYYY-MM-DD")
                  }
                />
                <Tooltip title="Clear Date">
                  <IconButton
                    aria-label="delete"
                    className={classes.clearIcon}
                    // @ts-ignore
                    onClick={e => this._updateRunDateStartQuery()}
                  >
                    <Clear fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className={classes.searchDate}>
              <InputLabel
                className={classes.searchDateLabel}
                htmlFor="labeling-report-input"
              >
                Run's To:
              </InputLabel>
              <div className={classes.flexGroup}>
                <TextField
                  className={classes.dateBox}
                  onChange={e => this._updateRunDateEndQuery(e.target.value)}
                  type={"date"}
                  value={
                    isValid(runDateEndQuery) &&
                    moment(runDateEndQuery).format("YYYY-MM-DD")
                  }
                />
                <Tooltip title="Clear Date">
                  <IconButton
                    aria-label="delete"
                    className={classes.clearIcon}
                    // @ts-ignore
                    onClick={e => this._updateRunDateEndQuery()}
                  >
                    <Clear fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {/* Run Name Filter */}
            <div className={classes.search}>
              <DebouncedTextField
                value={runNameQuery || ""}
                label="Run name..."
                onChangeDebounced={e =>
                  this._updateRunNameQuery(e.target.value)
                }
                classes={{
                  root: classes.inputRoot
                }}
              />
            </div>
            {/* Labeler Filter */}
            <div className={classes.search}>
              <DebouncedTextField
                label="Labeler username..."
                value={labelerQuery || ""}
                onChangeDebounced={e =>
                  this._updateUsernameQuery(e.target.value)
                }
                classes={{
                  root: classes.inputRoot
                }}
              />
            </div>
          </Toolbar>
        </AppBar>

        <div className={accordionClass}>
          <List key={"key"}>
            {/*If loading more, show loading icon on bottom*/}
            {pendingRefetch && !isLoadingMore && <LoadingScreen />}

            {isValid(filteredSensorStreams) &&
              filteredSensorStreams.map(sensorStream => {
                return (
                  <SensorStreamListItem
                    key={sensorStream.id}
                    viewer={viewer}
                    sensorStream={sensorStream}
                    refresh={reload}
                  />
                );
              })}
            {isLoadingMore && <LoadingScreen />}
          </List>
          <Button
            fullWidth
            disabled={
              pendingRefetch !== null ||
              !viewer.allSensorStreams.pageInfo.hasNextPage
            }
            onClick={() => this._refetch(endCursor)}
          >
            Load More
          </Button>
        </div>
      </div>
    );
  }

  _updateMLOption(option: string) {
    this.setState(
      {
        selectedMLOption: option
      },
      () => this._refetch()
    );
  }

  _updateMinimumFrames(minimum: any) {
    // no refetch because handle in front end
    this.setState({ minimumFrames: parseInt(minimum, 10) | 0 });
  }

  _updateVisibleSideQuery(query: FaceSide[]) {
    this.setState({ sideVisible: query }, () => this._refetch());
  }

  _updateKnownObjectsQuery(query: KnownObject[]) {
    this.setState({ knownObjects: query }, () => this._refetch());
  }

  _updateSurfaceTypeQuery(query: PalletSurface[]) {
    this.setState({ surfaceType: query }, () => this._refetch());
  }

  _updateRunDateStartQuery(query: any) {
    this.setState({ runDateStartQuery: query }, () => this._refetch());
  }

  _updateRunDateEndQuery(query: any) {
    this.setState({ runDateEndQuery: query }, () => this._refetch());
  }

  _updateRunNameQuery(query: string) {
    const runNameQuery = query === "" ? null : query;
    if (runNameQuery === this.state.runNameQuery) {
      return;
    }
    this.setState({ runNameQuery }, () => this._refetch());
  }

  _updateUsernameQuery(query: string) {
    const labelerQuery = query === "" ? null : query;
    if (labelerQuery === this.state.labelerQuery) {
      return;
    }

    this.setState({ labelerQuery }, () => this._refetch());
  }

  _toggleAccordion = () => {
    this.setState(prevState => ({
      isListOpen: !prevState.isListOpen
    }));
  };

  _refetch = (after: string | null = null) => {
    const {
      runNameQuery,
      runDateStartQuery,
      runDateEndQuery,
      labelerQuery,
      knownObjects,
      surfaceType,
      sideVisible,
      cameraPositions,
      runSources,
      pendingRefetch,
      lightingConditions,
      selectedMLOption
    } = this.state;
    const showStuckOnly = selectedMLOption === mlOptions[1];
    const showSentOnly = selectedMLOption === mlOptions[2];
    if (pendingRefetch) {
      pendingRefetch.dispose();
    }
    const refetchVariables = fragmentVariables => {
      const countAllSensorStreams = fragmentVariables.countAllSensorStreams;
      return {
        runNameQuery,
        runDateStartQuery: isValid(runDateStartQuery)
          ? new Date(runDateStartQuery)
          : null,
        runDateEndQuery: isValid(runDateEndQuery) ? new Date(runDateEndQuery) : null,
        labelerQuery,
        knownObjects: isEmpty(knownObjects) ? null : knownObjects,
        cameraPositions,
        runSources,
        lightingConditions,
        objectSide: isEmpty(sideVisible) ? null : sideVisible,
        objectSurface: isEmpty(surfaceType) ? null : surfaceType,
        countAllSensorStreams,
        allowExport: showStuckOnly ? false : showSentOnly ? true : null,
        after
      };
    };
    this.setState({
      isLoadingMore: !!after,
      pendingRefetch: this.props.relay.refetch(
        refetchVariables,
        null, // Use the refetchVariables as renderVariables
        () => this.setState({ pendingRefetch: null, isLoadingMore: false }),
        { force: true } // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
      )
    });
  };
}

export default withStyles(styles, { withTheme: true })(
  createRefetchContainer(
    ListSensorStreams,
    {
      viewer: graphql`
        fragment ListSensorStreams_viewer on Viewer
        @argumentDefinitions(
          countAllSensorStreams: { type: "Int", defaultValue: 20 }
        ) {
          username
          isAnonymous
          isSupervisor
          allSensorStreams: sensorStreams(
            first: $countAllSensorStreams
            runNameQuery: null
            runDateStartQuery: null
            runDateEndQuery: null
            labelerQuery: null
            knownObjects: null
            objectSide: null
            objectSurface: null
            allowExport: null
            cameraPositions: null
            lightingConditions: null
            runSources: null
          )
            @connection(
              key: "ListSensorStreams_allSensorStreams"
              filters: []
            ) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                ...SensorStreamListItem_sensorStream
                numFrames
                labelingPriority
                createTime
                updateTime
                run {
                  id
                  runName
                  source
                  createTime
                  updateTime
                  knownObjects
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query ListSensorStreamsRefetchQuery(
        $runNameQuery: String
        $runDateStartQuery: DateTime
        $runDateEndQuery: DateTime
        $labelerQuery: String
        $allowExport: Boolean
        $knownObjects: [KnownObject!]
        $objectSide: [FaceSide!]
        $objectSurface: [PalletSurface!]
        $countAllSensorStreams: Int!
        $after: String
      ) {
        viewer {
          username
          isAnonymous
          isSupervisor
          allSensorStreams: sensorStreams(
            first: $countAllSensorStreams
            after: $after
            runNameQuery: $runNameQuery
            runDateStartQuery: $runDateStartQuery
            runDateEndQuery: $runDateEndQuery
            labelerQuery: $labelerQuery
            knownObjects: $knownObjects
            objectSide: $objectSide
            objectSurface: $objectSurface
            allowExport: $allowExport
          )
            @connection(
              key: "ListSensorStreams_allSensorStreams"
              filters: []
            ) {
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id
                ...SensorStreamListItem_sensorStream
                numFrames
                labelingPriority
                createTime
                updateTime
                run {
                  id
                  runName
                  source
                  createTime
                  updateTime
                  knownObjects
                }
              }
            }
          }
        }
      }
    `
  )
);
