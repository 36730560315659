/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_SensorStreamWrapper_QueryVariables = {
    runName: string;
    sensorName: string;
};
export type routes_SensorStreamWrapper_QueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly isAnonymous: boolean | null;
        readonly isApprovedForAccess: boolean | null;
        readonly " $fragmentRefs": FragmentRefs<"SensorStreamWrapper_viewer">;
    } | null;
};
export type routes_SensorStreamWrapper_Query = {
    readonly response: routes_SensorStreamWrapper_QueryResponse;
    readonly variables: routes_SensorStreamWrapper_QueryVariables;
};



/*
query routes_SensorStreamWrapper_Query(
  $runName: String!
  $sensorName: String!
) {
  viewer {
    id
    isAnonymous
    isApprovedForAccess
    ...SensorStreamWrapper_viewer
  }
}

fragment SensorStreamFrameSliderIcon_frame on Frame {
  id
  indexInSensorStream
  labeledPalletsCount
  timestamp
  labelers {
    id
    username
  }
  imageMetadata {
    id
    signedThumbnailUrl(skip: true)
  }
}

fragment SensorStreamFrameSlider_sensorStream on SensorStream {
  id
  numFrames
}

fragment SensorStreamWrapper_viewer on Viewer {
  id
  username
  isSupervisor
  sensorStream: sensorStreamByName(runName: $runName, sensorName: $sensorName) {
    ...SensorStreamFrameSlider_sensorStream
    id
    labelingDirections
    lightingConditions
    labelingPriority
    sensorName
    numFrames
    allowExport
    run {
      runName
      id
    }
    frameNumbers
    filteredFrames {
      ...SensorStreamFrameSliderIcon_frame
      id
      mlReady
      labeledPallets {
        id
        objectType
      }
      labeledItems {
        id
        objectType
      }
      indexInSensorStream
      timestamp
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "runName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sensorName",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAnonymous",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isApprovedForAccess",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "objectType",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routes_SensorStreamWrapper_Query",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "SensorStreamWrapper_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes_SensorStreamWrapper_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isSupervisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "sensorStream",
            "name": "sensorStreamByName",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "runName",
                "variableName": "runName"
              },
              {
                "kind": "Variable",
                "name": "sensorName",
                "variableName": "sensorName"
              }
            ],
            "concreteType": "SensorStream",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numFrames",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "labelingDirections",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lightingConditions",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "labelingPriority",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sensorName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "allowExport",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "run",
                "storageKey": null,
                "args": null,
                "concreteType": "Run",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "runName",
                    "args": null,
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "frameNumbers",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "filteredFrames",
                "storageKey": null,
                "args": null,
                "concreteType": "Frame",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "indexInSensorStream",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "labeledPalletsCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "timestamp",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "labelers",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabelerUser",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "imageMetadata",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ImageMetadata",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "signedThumbnailUrl",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "skip",
                            "value": true
                          }
                        ],
                        "storageKey": "signedThumbnailUrl(skip:true)"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "mlReady",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "labeledPallets",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabeledPallet",
                    "plural": true,
                    "selections": (v5/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "labeledItems",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabeledItem",
                    "plural": true,
                    "selections": (v5/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routes_SensorStreamWrapper_Query",
    "id": null,
    "text": "query routes_SensorStreamWrapper_Query(\n  $runName: String!\n  $sensorName: String!\n) {\n  viewer {\n    id\n    isAnonymous\n    isApprovedForAccess\n    ...SensorStreamWrapper_viewer\n  }\n}\n\nfragment SensorStreamFrameSliderIcon_frame on Frame {\n  id\n  indexInSensorStream\n  labeledPalletsCount\n  timestamp\n  labelers {\n    id\n    username\n  }\n  imageMetadata {\n    id\n    signedThumbnailUrl(skip: true)\n  }\n}\n\nfragment SensorStreamFrameSlider_sensorStream on SensorStream {\n  id\n  numFrames\n}\n\nfragment SensorStreamWrapper_viewer on Viewer {\n  id\n  username\n  isSupervisor\n  sensorStream: sensorStreamByName(runName: $runName, sensorName: $sensorName) {\n    ...SensorStreamFrameSlider_sensorStream\n    id\n    labelingDirections\n    lightingConditions\n    labelingPriority\n    sensorName\n    numFrames\n    allowExport\n    run {\n      runName\n      id\n    }\n    frameNumbers\n    filteredFrames {\n      ...SensorStreamFrameSliderIcon_frame\n      id\n      mlReady\n      labeledPallets {\n        id\n        objectType\n      }\n      labeledItems {\n        id\n        objectType\n      }\n      indexInSensorStream\n      timestamp\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '328c03a17c7ba9d4c4a9cefe24cb281d';
export default node;
