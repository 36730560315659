/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type BulkSwitchFacesMutationInput = {
    readonly sensorStreamId?: string | null;
    readonly frameId?: string | null;
    readonly clientMutationId?: string | null;
};
export type BulkSwitchFacesMutationVariables = {
    input: BulkSwitchFacesMutationInput;
};
export type BulkSwitchFacesMutationResponse = {
    readonly bulkSwitchFaces: {
        readonly pallets: ReadonlyArray<{
            readonly id: string;
            readonly shape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                }>;
            };
        }>;
    } | null;
};
export type BulkSwitchFacesMutation = {
    readonly response: BulkSwitchFacesMutationResponse;
    readonly variables: BulkSwitchFacesMutationVariables;
};



/*
mutation BulkSwitchFacesMutation(
  $input: BulkSwitchFacesMutationInput!
) {
  bulkSwitchFaces(input: $input) {
    pallets {
      id
      shape {
        id
        faces {
          id
          side
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkSwitchFacesMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "bulkSwitchFaces",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkSwitchFacesMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pallets",
        "storageKey": null,
        "args": null,
        "concreteType": "LabeledPallet",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shape",
            "storageKey": null,
            "args": null,
            "concreteType": "Cuboid",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "faces",
                "storageKey": null,
                "args": null,
                "concreteType": "CuboidFace",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "side",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BulkSwitchFacesMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BulkSwitchFacesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BulkSwitchFacesMutation",
    "id": null,
    "text": "mutation BulkSwitchFacesMutation(\n  $input: BulkSwitchFacesMutationInput!\n) {\n  bulkSwitchFaces(input: $input) {\n    pallets {\n      id\n      shape {\n        id\n        faces {\n          id\n          side\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '118f0d50335ffabc8d24fa86e5f93058';
export default node;
