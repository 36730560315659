import React from "react";
import { graphql, createFragmentContainer } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";

import PalletDetails from "./PalletDetails";
import { LabelSelector } from "../SelectionState";
import { LabelMutators } from "../LabelMutators";
import { TopLevelLabelActions } from "../../../label_frame/SensorStreamWrapper";

import { PalletSideBar_frame } from "../../../../__generated__/PalletSideBar_frame.graphql";
import { Viewer } from "../Utils";

const styles = () =>
  createStyles({
    faceLists: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start"
    },
    selectWrapper: {
      background: "lightgray",
      display: "flex",
      flexDirection: "column",
      margin: 0,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: 4
    },
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    tabs: {
      border: "1px solid rgba(0, 0, 0, 0.23)"
    }
  });

type Props = {
  classes: any;
  frame: PalletSideBar_frame;
  viewer: Viewer;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  labelerUsernames: Set<string>;
  topLevelLabelActions: TopLevelLabelActions;
};

class PalletSideBar extends React.Component<Props> {
  render() {
    const {
      classes,
      frame,
      labelMutator,
      labelSelector,
      viewer,
      labelerUsernames
    } = this.props;
    const { labeledPallets } = frame;

    return (
      <React.Fragment>
        {labeledPallets.length > 0 && (
          <div className={classes.faceLists}>
            {labeledPallets
              .filter(label => labelerUsernames.has(label.author.username))
              .concat()
              .sort((a, b) => a.number - b.number)
              .map(pallet => (
                <PalletDetails
                  key={pallet.id}
                  viewer={viewer}
                  pallet={pallet}
                  labelSelector={labelSelector}
                  labelMutator={labelMutator}
                />
              ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(PalletSideBar, {
    frame: graphql`
      fragment PalletSideBar_frame on Frame {
        id
        indexInSensorStream
        sensorStream {
          labelers {
            id
            username
            shortName
          }
        }
        labeledPallets {
          ...PalletDetails_pallet
          id
          number
          author {
            id
            username
          }
        }
      }
    `
  })
);
