import { commitMutation, graphql } from "react-relay";
import { Environment } from "relay-runtime";

import {
  SignUpMutationInput,
  SignUpMutationResponse
} from "../__generated__/SignUpMutation.graphql";

const mutation = graphql`
  mutation SignUpMutation($input: SignUpMutationInput!) {
    signUp(input: $input) {
      username
      token
    }
  }
`;

function signUp(
  environment: Environment,
  input: SignUpMutationInput
): Promise<SignUpMutationResponse> {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      configs: [],
      onCompleted: (data: SignUpMutationResponse | null, errors) => {
        if (!data || !data.signUp) {
          reject(errors);
          return;
        }
        resolve(data);
      }
    });
  });
}

export default signUp;
