import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { UpsertQAInspectionMutationInput } from "../__generated__/UpsertQAInspectionMutation.graphql";

const mutation = graphql`
  mutation UpsertQAInspectionMutation(
    $input: UpsertQAInspectionMutationInput!
  ) {
    upsertQaInspection(input: $input) {
      qaInspection {
        frame {
          ...FrameQADialog_frame
        }
        category
        labeler {
          id
          username
        }
      }
    }
  }
`;

function upsertQaInspection(
  environment: Environment,
  input: UpsertQAInspectionMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError
  });
}

export default upsertQaInspection;
