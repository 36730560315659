/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelingPriority = "HIGH" | "LOW" | "UNSPECIFIED" | "%future added value";
export type ListHighPrioritySensorStreamsRefetchQueryVariables = {
    labelingPriorities?: ReadonlyArray<LabelingPriority> | null;
    runNameQuery?: string | null;
    countHighPrioritySensorStreams: number;
    after?: string | null;
};
export type ListHighPrioritySensorStreamsRefetchQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly username: string | null;
        readonly isAnonymous: boolean | null;
        readonly isSupervisor: boolean | null;
        readonly highPrioritySensorStreams: {
            readonly pageInfo: {
                readonly hasNextPage: boolean;
            };
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly " $fragmentRefs": FragmentRefs<"SensorStreamListItem_sensorStream">;
                } | null;
            } | null>;
        } | null;
    } | null;
};
export type ListHighPrioritySensorStreamsRefetchQuery = {
    readonly response: ListHighPrioritySensorStreamsRefetchQueryResponse;
    readonly variables: ListHighPrioritySensorStreamsRefetchQueryVariables;
};



/*
query ListHighPrioritySensorStreamsRefetchQuery(
  $labelingPriorities: [LabelingPriority!]
  $runNameQuery: String
  $countHighPrioritySensorStreams: Int!
  $after: String
) {
  viewer {
    id
    username
    isAnonymous
    isSupervisor
    highPrioritySensorStreams: sensorStreams(labelingPriorities: $labelingPriorities, first: $countHighPrioritySensorStreams, runNameQuery: $runNameQuery, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...SensorStreamListItem_sensorStream
          __typename
        }
        cursor
      }
    }
  }
}

fragment LabelingIssueListItem_issue on LabelingIssue {
  id
  description
  category
  status
  reporter {
    username
    id
  }
  labeler {
    username
    id
  }
  pallets {
    number
    id
  }
  boxes {
    number
    id
  }
  sensorStream {
    sensorName
    run {
      runName
      id
    }
    id
  }
  frame {
    indexInSensorStream
    id
  }
}

fragment SensorStreamListItem_sensorStream on SensorStream {
  id
  labelingDirections
  lightingConditions
  sensorName
  numFrames
  frameNumbers
  labelingPriority
  allowExport
  issues {
    ...LabelingIssueListItem_issue
    status
    id
  }
  run {
    runName
    source
    createTime
    updateTime
    id
  }
  labelSummary {
    labeledFrameCount
    viewerLabeledFrameCount
    labeledPocketCount
    labeledItemCount
    labeledPalletsAll {
      id
      objectType
      surfaceType
      author {
        id
        username
        shortName
      }
      frame {
        indexInSensorStream
        id
      }
    }
    labeledItemsAll {
      id
      objectType
      author {
        id
        username
        shortName
      }
      frame {
        indexInSensorStream
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "labelingPriorities",
    "type": "[LabelingPriority!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "runNameQuery",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "countHighPrioritySensorStreams",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAnonymous",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSupervisor",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "countHighPrioritySensorStreams"
  },
  {
    "kind": "Variable",
    "name": "labelingPriorities",
    "variableName": "labelingPriorities"
  },
  {
    "kind": "Variable",
    "name": "runNameQuery",
    "variableName": "runNameQuery"
  }
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sensorName",
  "args": null,
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v1/*: any*/)
],
v11 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "number",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/)
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "runName",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "frame",
  "storageKey": null,
  "args": null,
  "concreteType": "Frame",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "indexInSensorStream",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/)
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "objectType",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelerUser",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shortName",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ListHighPrioritySensorStreamsRefetchQuery",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "highPrioritySensorStreams",
            "name": "__ListHighPrioritySensorStreams_highPrioritySensorStreams_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "SensorStreamConnection",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SensorStreamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SensorStream",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "SensorStreamListItem_sensorStream",
                        "args": null
                      }
                    ]
                  },
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ListHighPrioritySensorStreamsRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "highPrioritySensorStreams",
            "name": "sensorStreams",
            "storageKey": null,
            "args": (v8/*: any*/),
            "concreteType": "SensorStreamConnection",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SensorStreamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SensorStream",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "labelingDirections",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lightingConditions",
                        "args": null,
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numFrames",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "frameNumbers",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "labelingPriority",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "allowExport",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "issues",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LabelingIssue",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "description",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "category",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "reporter",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LabelerUser",
                            "plural": false,
                            "selections": (v10/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "labeler",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LabelerUser",
                            "plural": false,
                            "selections": (v10/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pallets",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LabeledPallet",
                            "plural": true,
                            "selections": (v11/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "boxes",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LabeledBox",
                            "plural": true,
                            "selections": (v11/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "sensorStream",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "SensorStream",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "run",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Run",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v1/*: any*/)
                                ]
                              },
                              (v1/*: any*/)
                            ]
                          },
                          (v13/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "run",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Run",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "source",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createTime",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "updateTime",
                            "args": null,
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "labelSummary",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SensorStreamLabelSummary",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "labeledFrameCount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "viewerLabeledFrameCount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "labeledPocketCount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "labeledItemCount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "labeledPalletsAll",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LabeledPallet",
                            "plural": true,
                            "selections": [
                              (v1/*: any*/),
                              (v14/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "surfaceType",
                                "args": null,
                                "storageKey": null
                              },
                              (v15/*: any*/),
                              (v13/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "labeledItemsAll",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LabeledItem",
                            "plural": true,
                            "selections": [
                              (v1/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v13/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v6/*: any*/)
                    ]
                  },
                  (v7/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": "highPrioritySensorStreams",
            "name": "sensorStreams",
            "args": (v8/*: any*/),
            "handle": "connection",
            "key": "ListHighPrioritySensorStreams_highPrioritySensorStreams",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ListHighPrioritySensorStreamsRefetchQuery",
    "id": null,
    "text": "query ListHighPrioritySensorStreamsRefetchQuery(\n  $labelingPriorities: [LabelingPriority!]\n  $runNameQuery: String\n  $countHighPrioritySensorStreams: Int!\n  $after: String\n) {\n  viewer {\n    id\n    username\n    isAnonymous\n    isSupervisor\n    highPrioritySensorStreams: sensorStreams(labelingPriorities: $labelingPriorities, first: $countHighPrioritySensorStreams, runNameQuery: $runNameQuery, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...SensorStreamListItem_sensorStream\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n\nfragment LabelingIssueListItem_issue on LabelingIssue {\n  id\n  description\n  category\n  status\n  reporter {\n    username\n    id\n  }\n  labeler {\n    username\n    id\n  }\n  pallets {\n    number\n    id\n  }\n  boxes {\n    number\n    id\n  }\n  sensorStream {\n    sensorName\n    run {\n      runName\n      id\n    }\n    id\n  }\n  frame {\n    indexInSensorStream\n    id\n  }\n}\n\nfragment SensorStreamListItem_sensorStream on SensorStream {\n  id\n  labelingDirections\n  lightingConditions\n  sensorName\n  numFrames\n  frameNumbers\n  labelingPriority\n  allowExport\n  issues {\n    ...LabelingIssueListItem_issue\n    status\n    id\n  }\n  run {\n    runName\n    source\n    createTime\n    updateTime\n    id\n  }\n  labelSummary {\n    labeledFrameCount\n    viewerLabeledFrameCount\n    labeledPocketCount\n    labeledItemCount\n    labeledPalletsAll {\n      id\n      objectType\n      surfaceType\n      author {\n        id\n        username\n        shortName\n      }\n      frame {\n        indexInSensorStream\n        id\n      }\n    }\n    labeledItemsAll {\n      id\n      objectType\n      author {\n        id\n        username\n        shortName\n      }\n      frame {\n        indexInSensorStream\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": "countHighPrioritySensorStreams",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "viewer",
            "highPrioritySensorStreams"
          ]
        }
      ]
    }
  }
};
})();
(node as any).hash = '893d60efdd4886113925d7658810e628';
export default node;
