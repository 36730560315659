import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { CreateFaceMutationInput } from "../__generated__/CreateFaceMutation.graphql";

const mutation = graphql`
  mutation CreateFaceMutation($input: CreateFaceMutationInput!) {
    createFace(input: $input) {
      face {
        id
        side
        parentShape {
          id
          faces {
            id
          }
        }
        corners {
          id
          x
          y
          visibility
        }
      }
      label {
        ... on LabeledBox {
          id
        }
        ... on LabeledPallet {
          id
        }
      }
    }
  }
`;

let tempID = 0;
function createFace(
  environment: Environment,
  input: CreateFaceMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  const cleanCorners = input.face.newCorners.map(e => {
    return {
      x: e.x,
      y: e.y,
      visibility: e.visibility
    };
  });
  const cleanInput = {
    face: { ...input.face, newCorners: cleanCorners },
    labelId: input.labelId,
    isLoad: input.isLoad
  };

  commitMutation(environment, {
    mutation,
    variables: { input: cleanInput },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let label = store.get(input.labelId);
      if (!label) {
        return;
      }
      let existingShape = label.getLinkedRecord("shape");
      let existingFaces = existingShape.getLinkedRecords("faces");
      if (!existingFaces) {
        existingFaces = [];
      }
      let mutationId: string = (tempID++).toString();
      const faceId = "client:createFace:" + mutationId;
      let face = store.create(faceId, "CuboidFace");
      const existingCorners = cleanInput.face.existingCorners.map(id =>
        store.get(id)
      );
      const allCorners = [...existingCorners];
      let newCornerNumber = 0;
      for (const newCornerInput of cleanInput.face.newCorners) {
        const cornerId = "client:createCorner:" + newCornerNumber++;

        let corner = store.get(cornerId);
        if (!corner) {
          corner = store.create(cornerId, "CuboidCorner");
          corner.setValue(cornerId, "id");
          corner.setValue(newCornerInput.x, "x");
          corner.setValue(newCornerInput.y, "y");
          corner.setValue(newCornerInput.visibility, "visibility");
        }

        allCorners.push(corner);
      }
      face.setLinkedRecords(allCorners, "corners");
      face.setLinkedRecord(existingShape, "parentShape");
      existingShape.setLinkedRecords([face, ...existingFaces], "faces");
    }
  });
}

export default createFace;
