import React from "react";
import { graphql, createRefetchContainer, RelayRefetchProp } from "react-relay";

import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Label from "@material-ui/icons/TurnedIn";
import LabelOff from "@material-ui/icons/TurnedInNot";
import NoSim from "@material-ui/icons/NoSim";
import NoSimOutlined from "@material-ui/icons/NoSimOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";

import memoizeOne from "memoize-one";

import { isFrameLabelable } from "../common/FrameLabelable";
import { SensorStreamFrameSliderIcon_frame } from "../../__generated__/SensorStreamFrameSliderIcon_frame.graphql";
import CardHeader from "@material-ui/core/CardHeader";
import { SensorStreamWrapper_viewer } from "../../__generated__/SensorStreamWrapper_viewer.graphql";

const styles = theme =>
  createStyles({
    previousLabel: {
      opacity: 0.5
    },
    currentLabel: {
      opacity: 1
    },
    futureLabel: {
      opacity: 0.2
    },
    popover: {
      pointerEvents: "none"
    },
    paper: {
      padding: theme.spacing.unit
    },
    card: {
      height: 320,
      width: 320
    },
    media: {
      height: 320,
      width: 320
    },
    spinner: {
      left: 140,
      top: 140,
      position: "relative"
    }
  });

interface Props extends WithStyles<typeof styles> {
  relay: RelayRefetchProp;
  viewer: SensorStreamWrapper_viewer;
  loadFrame: Function;
  frame: SensorStreamFrameSliderIcon_frame;
  loadedSensorStreamIndex: number;
  loadedFrameTimestamp: string;
}

type State = {
  popoverAnchorEl: HTMLElement | null;
};

const frameClass = memoizeOne(
  (loadedTimestamp, loadedIndex, timestamp, index, classes) => {
    if (loadedIndex === null || loadedTimestamp == null) {
      return classes.futureLabel;
    }
    if (loadedIndex === index) {
      return classes.currentLabel;
    }
    return parseInt(timestamp, 10) > loadedTimestamp
      ? classes.futureLabel
      : classes.previousLabel;
  }
);

class SensorStreamFrameSliderIcon extends React.Component<Props, State> {
  state = {
    popoverAnchorEl: null
  };

  handlePopoverOpen = event => {
    this.setState({ popoverAnchorEl: event.currentTarget });
    if (this.props.frame.imageMetadata.signedThumbnailUrl) {
      return;
    }
    this.props.relay.refetch({
      frameID: this.props.frame.id,
      skipImage: false
    });
  };

  handlePopoverClose = () => {
    this.setState({ popoverAnchorEl: null });
  };

  render() {
    const {
      frame,
      loadFrame,
      classes,
      loadedSensorStreamIndex,
      loadedFrameTimestamp,
      viewer
    } = this.props;
    const { popoverAnchorEl } = this.state;
    const open = Boolean(popoverAnchorEl);
    const { indexInSensorStream, timestamp, labelers, imageMetadata } = frame;
    const { sensorStream, username } = viewer;
    const { sensorName, frameNumbers } = sensorStream;
    const className = frameClass(
      loadedFrameTimestamp,
      loadedSensorStreamIndex,
      timestamp,
      indexInSensorStream,
      classes
    );
    const isLabeled = frame.labeledPalletsCount > 0;
    let LabelClass = Label;
    const frameId = frame.id;
    if (isFrameLabelable(frameId)) {
      LabelClass = isLabeled ? Label : LabelOff;
    } else {
      LabelClass = isLabeled ? NoSim : NoSimOutlined;
    }

    const labeledByViewer = !!labelers.find(l => l.username === username);
    const labeledByOthers = !!labelers.find(l => l.username !== username);
    const iconColor =
      labeledByOthers && !labeledByViewer ? "inherit" : "primary";
    const cardMedia =
      open && !!imageMetadata && !!imageMetadata.signedThumbnailUrl ? (
        <CardMedia
          className={classes.media}
          image={imageMetadata.signedThumbnailUrl}
          title={`Frame ${indexInSensorStream}`}
        />
      ) : (
        <CircularProgress className={classes.spinner} />
      );
    const cardTitle = `Frame ${frameNumbers.indexOf(indexInSensorStream)}`;
    const cardText = `${sensorName}/frames/${indexInSensorStream} : ${timestamp}`;
    return (
      <div>
        <LabelClass
          id={`frame-${frame.id}-bookmark`}
          aria-owns={
            open ? `mouse-over-popover-${indexInSensorStream}` : undefined
          }
          color={iconColor}
          className={className}
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
          onClick={() => loadFrame(indexInSensorStream)}
        />
        <Popover
          id={`mouse-over-popover-${indexInSensorStream}`}
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          open={open}
          anchorEl={popoverAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Card className={classes.card}>
            <CardHeader title={cardTitle} subheader={cardText} />
            <CardActionArea>{cardMedia}</CardActionArea>
          </Card>
        </Popover>
      </div>
    );
  }
}

export default withStyles(styles)(
  createRefetchContainer(
    SensorStreamFrameSliderIcon,
    {
      frame: graphql`
        fragment SensorStreamFrameSliderIcon_frame on Frame
        @argumentDefinitions(
          skipImage: { type: "Boolean", defaultValue: true } # Optional argument
        ) {
          id
          indexInSensorStream
          labeledPalletsCount
          timestamp
          labelers {
            id
            username
          }
          imageMetadata {
            id
            # signedThumbnailUrl needs to be included in the first query for the
            # refetch query to update this component's state in relay. It would
            # take too much time to generate a signed url for each icon eagerly,
            # so the url is skipped on first load and fetched on hover.
            signedThumbnailUrl(skip: $skipImage)
          }
        }
      `
    },
    graphql`
      query SensorStreamFrameSliderIconRefetchQuery(
        $frameID: ID!
        $skipImage: Boolean
      ) {
        frame: frameById(id: $frameID) {
          id
          ...SensorStreamFrameSliderIcon_frame @arguments(skipImage: $skipImage)
        }
      }
    `
  )
);
