import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { createStyles } from "@material-ui/core";

const styleSheet = theme =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "100%",
      minHeight: 100,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      // Use a high zIndex to e.g. push the typography in front on the canvas when
      // loading a new frame. CircularProgress seems to automatically have a high
      // zIndex, but it doesn't hurt to assign a high value to its wrapper here.
      zIndex: 500,
      color: "orange"
    }
  });

type Props = {
  classes: any;
  fullScreen?: boolean;
};
class LoadingScreen extends Component<Props> {
  render() {
    const { classes, fullScreen } = this.props;
    return (
      <div
        className={classes.container}
        style={fullScreen ? { height: "90vh" } : {}}
      >
        <Typography color="inherit" variant="h5">
          Loading...
        </Typography>
        <CircularProgress color="inherit" />
      </div>
    );
  }
}

export default withStyles(styleSheet)(LoadingScreen);
