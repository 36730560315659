/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type CreateFaceMutationInput = {
    readonly face: NewFaceInput;
    readonly labelId: string;
    readonly isLoad: boolean;
    readonly clientMutationId?: string | null;
};
export type NewFaceInput = {
    readonly side: FaceSide;
    readonly newCorners: ReadonlyArray<NewCornerInput>;
    readonly existingCorners: ReadonlyArray<string>;
};
export type NewCornerInput = {
    readonly x: number;
    readonly y: number;
    readonly visibility: Visibility;
};
export type CreateFaceMutationVariables = {
    input: CreateFaceMutationInput;
};
export type CreateFaceMutationResponse = {
    readonly createFace: {
        readonly face: {
            readonly id: string;
            readonly side: FaceSide;
            readonly parentShape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                }>;
            };
            readonly corners: ReadonlyArray<{
                readonly id: string;
                readonly x: number;
                readonly y: number;
                readonly visibility: Visibility;
            }>;
        };
        readonly label: {
            readonly id?: string;
        };
    } | null;
};
export type CreateFaceMutation = {
    readonly response: CreateFaceMutationResponse;
    readonly variables: CreateFaceMutationVariables;
};



/*
mutation CreateFaceMutation(
  $input: CreateFaceMutationInput!
) {
  createFace(input: $input) {
    face {
      id
      side
      parentShape {
        id
        faces {
          id
        }
      }
      corners {
        id
        x
        y
        visibility
      }
    }
    label {
      __typename
      ... on LabeledBox {
        id
      }
      ... on LabeledPallet {
        id
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateFaceMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "face",
  "storageKey": null,
  "args": null,
  "concreteType": "CuboidFace",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "side",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parentShape",
      "storageKey": null,
      "args": null,
      "concreteType": "Cuboid",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "faces",
          "storageKey": null,
          "args": null,
          "concreteType": "CuboidFace",
          "plural": true,
          "selections": (v3/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "corners",
      "storageKey": null,
      "args": null,
      "concreteType": "CuboidCorner",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "x",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "y",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "visibility",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateFaceMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createFace",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFaceMutationPayload",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "label",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "LabeledBox",
                "selections": (v3/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "LabeledPallet",
                "selections": (v3/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateFaceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createFace",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFaceMutationPayload",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "label",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateFaceMutation",
    "id": null,
    "text": "mutation CreateFaceMutation(\n  $input: CreateFaceMutationInput!\n) {\n  createFace(input: $input) {\n    face {\n      id\n      side\n      parentShape {\n        id\n        faces {\n          id\n        }\n      }\n      corners {\n        id\n        x\n        y\n        visibility\n      }\n    }\n    label {\n      __typename\n      ... on LabeledBox {\n        id\n      }\n      ... on LabeledPallet {\n        id\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b780fad91581b4e77e8c150e802600a6';
export default node;
