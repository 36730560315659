/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SignUpMutationInput = {
    readonly username: string;
    readonly email: string;
    readonly password: string;
    readonly clientMutationId?: string | null;
};
export type SignUpMutationVariables = {
    input: SignUpMutationInput;
};
export type SignUpMutationResponse = {
    readonly signUp: {
        readonly username: string | null;
        readonly token: string | null;
    } | null;
};
export type SignUpMutation = {
    readonly response: SignUpMutationResponse;
    readonly variables: SignUpMutationVariables;
};



/*
mutation SignUpMutation(
  $input: SignUpMutationInput!
) {
  signUp(input: $input) {
    username
    token
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SignUpMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "signUp",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignUpMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SignUpMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SignUpMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SignUpMutation",
    "id": null,
    "text": "mutation SignUpMutation(\n  $input: SignUpMutationInput!\n) {\n  signUp(input: $input) {\n    username\n    token\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '9e69cad228dea6f5603af1d0f2da7cde';
export default node;
