/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ItemSideBar_frame = {
    readonly id: string;
    readonly labeledItems: ReadonlyArray<{
        readonly id: string;
        readonly number: number;
        readonly author: {
            readonly id: string;
            readonly username: string | null;
        };
        readonly " $fragmentRefs": FragmentRefs<"ItemDetails_item">;
    }>;
    readonly " $refType": "ItemSideBar_frame";
};
export type ItemSideBar_frame$data = ItemSideBar_frame;
export type ItemSideBar_frame$key = {
    readonly " $data"?: ItemSideBar_frame$data;
    readonly " $fragmentRefs": FragmentRefs<"ItemSideBar_frame">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ItemSideBar_frame",
  "type": "Frame",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeledItems",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledItem",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "number",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "author",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "username",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "ItemDetails_item",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'f962cf14eadea4e78e323873d7b94a60';
export default node;
