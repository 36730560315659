/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PalletSideBar_frame = {
    readonly id: string;
    readonly indexInSensorStream: number;
    readonly sensorStream: {
        readonly labelers: ReadonlyArray<{
            readonly id: string;
            readonly username: string | null;
            readonly shortName: string | null;
        }>;
    };
    readonly labeledPallets: ReadonlyArray<{
        readonly id: string;
        readonly number: number;
        readonly author: {
            readonly id: string;
            readonly username: string | null;
        };
        readonly " $fragmentRefs": FragmentRefs<"PalletDetails_pallet">;
    }>;
    readonly " $refType": "PalletSideBar_frame";
};
export type PalletSideBar_frame$data = PalletSideBar_frame;
export type PalletSideBar_frame$key = {
    readonly " $data"?: PalletSideBar_frame$data;
    readonly " $fragmentRefs": FragmentRefs<"PalletSideBar_frame">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PalletSideBar_frame",
  "type": "Frame",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "indexInSensorStream",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sensorStream",
      "storageKey": null,
      "args": null,
      "concreteType": "SensorStream",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labelers",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "shortName",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeledPallets",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledPallet",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "number",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "author",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "PalletDetails_pallet",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'ad515710c59a34946135e0b49ba1d318';
export default node;
