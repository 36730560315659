/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QACategory = "ALL_SIZEABLE_PALLETS_LABELED" | "BOXES_CORNERS_ACCURATE" | "BOXES_OFF_PALLET_LABELED" | "BOXES_ON_PALLET_LABELED" | "BOX_TOPS_LABELED" | "CORNER_VISIBILITY_MARKED" | "PALLETS_CORNERS_ACCURATE" | "PALLETS_SURFACES_CORRECT" | "PALLET_POCKETS_CORNERS_ACCURATE" | "PALLET_SIDES" | "PALLET_TYPE_CORRECT" | "TARGETED_PALLET_LABELED" | "%future added value";
export type QAState = "FALSE" | "TRUE" | "%future added value";
export type FrameQADialog_frame = {
    readonly id: string;
    readonly labelers: ReadonlyArray<{
        readonly id: string;
        readonly username: string | null;
    }>;
    readonly qaInspections: ReadonlyArray<{
        readonly inspector: {
            readonly id: string;
            readonly username: string | null;
        };
        readonly labeler: {
            readonly id: string;
            readonly username: string | null;
        };
        readonly category: QACategory;
        readonly state: QAState;
    }>;
    readonly " $refType": "FrameQADialog_frame";
};
export type FrameQADialog_frame$data = FrameQADialog_frame;
export type FrameQADialog_frame$key = {
    readonly " $data"?: FrameQADialog_frame$data;
    readonly " $fragmentRefs": FragmentRefs<"FrameQADialog_frame">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "username",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "FrameQADialog_frame",
  "type": "Frame",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labelers",
      "storageKey": null,
      "args": null,
      "concreteType": "LabelerUser",
      "plural": true,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "qaInspections",
      "storageKey": null,
      "args": null,
      "concreteType": "QAInspection",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "inspector",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeler",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "category",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'c3d3795ea2ccd5826d756bfef358ccd5';
export default node;
