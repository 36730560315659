/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelingIssueStatus = "NEEDS_LABELER_ATTENTION" | "NEEDS_QA_ATTENTION" | "RESOLVED" | "%future added value";
export type QACategory = "ALL_SIZEABLE_PALLETS_LABELED" | "BOXES_CORNERS_ACCURATE" | "BOXES_OFF_PALLET_LABELED" | "BOXES_ON_PALLET_LABELED" | "BOX_TOPS_LABELED" | "CORNER_VISIBILITY_MARKED" | "PALLETS_CORNERS_ACCURATE" | "PALLETS_SURFACES_CORRECT" | "PALLET_POCKETS_CORNERS_ACCURATE" | "PALLET_SIDES" | "PALLET_TYPE_CORRECT" | "TARGETED_PALLET_LABELED" | "%future added value";
export type CreateLabelingIssueMutationInput = {
    readonly labelerId: string;
    readonly boxes: ReadonlyArray<string | null>;
    readonly pallets: ReadonlyArray<string | null>;
    readonly description: string;
    readonly status: LabelingIssueStatus;
    readonly category: QACategory;
    readonly frameId?: string | null;
    readonly sensorStreamId?: string | null;
    readonly clientMutationId?: string | null;
};
export type CreateLabelingIssueMutationVariables = {
    input: CreateLabelingIssueMutationInput;
};
export type CreateLabelingIssueMutationResponse = {
    readonly createLabelingIssue: {
        readonly issue: {
            readonly id: string;
            readonly labeler: {
                readonly id: string;
            };
            readonly reporter: {
                readonly id: string;
            };
            readonly boxes: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly pallets: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly description: string;
            readonly status: LabelingIssueStatus;
            readonly category: QACategory;
            readonly frame: {
                readonly id: string;
            } | null;
            readonly " $fragmentRefs": FragmentRefs<"LabelingIssueListItem_issue" | "LabelingIssueSideBarListItem_issue">;
        } | null;
        readonly frame: {
            readonly id: string;
            readonly issues: ReadonlyArray<{
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"LabelingIssueListItem_issue" | "LabelingIssueSideBarListItem_issue">;
            }>;
        } | null;
        readonly sensorStream: {
            readonly id: string;
            readonly issues: ReadonlyArray<{
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"LabelingIssueListItem_issue" | "LabelingIssueSideBarListItem_issue">;
            }>;
        };
    } | null;
};
export type CreateLabelingIssueMutation = {
    readonly response: CreateLabelingIssueMutationResponse;
    readonly variables: CreateLabelingIssueMutationVariables;
};



/*
mutation CreateLabelingIssueMutation(
  $input: CreateLabelingIssueMutationInput!
) {
  createLabelingIssue(input: $input) {
    issue {
      id
      ...LabelingIssueListItem_issue
      ...LabelingIssueSideBarListItem_issue
      labeler {
        id
      }
      reporter {
        id
      }
      boxes {
        id
      }
      pallets {
        id
      }
      description
      status
      category
      frame {
        id
      }
    }
    frame {
      id
      issues {
        id
        ...LabelingIssueListItem_issue
        ...LabelingIssueSideBarListItem_issue
      }
    }
    sensorStream {
      id
      issues {
        id
        ...LabelingIssueListItem_issue
        ...LabelingIssueSideBarListItem_issue
      }
    }
  }
}

fragment LabelingIssueListItem_issue on LabelingIssue {
  id
  description
  category
  status
  reporter {
    username
    id
  }
  labeler {
    username
    id
  }
  pallets {
    number
    id
  }
  boxes {
    number
    id
  }
  sensorStream {
    sensorName
    run {
      runName
      id
    }
    id
  }
  frame {
    indexInSensorStream
    id
  }
}

fragment LabelingIssueSideBarListItem_issue on LabelingIssue {
  id
  description
  category
  status
  reporter {
    username
    id
  }
  pallets {
    number
    id
  }
  boxes {
    number
    id
  }
  sensorStream {
    sensorName
    run {
      runName
      source
      id
    }
    id
  }
  frame {
    id
    indexInSensorStream
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateLabelingIssueMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "category",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "FragmentSpread",
  "name": "LabelingIssueListItem_issue",
  "args": null
},
v8 = {
  "kind": "FragmentSpread",
  "name": "LabelingIssueSideBarListItem_issue",
  "args": null
},
v9 = [
  (v2/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "issues",
    "storageKey": null,
    "args": null,
    "concreteType": "LabelingIssue",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ]
  }
],
v10 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "username",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
],
v11 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "number",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
],
v12 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/),
  (v5/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reporter",
    "storageKey": null,
    "args": null,
    "concreteType": "LabelerUser",
    "plural": false,
    "selections": (v10/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "labeler",
    "storageKey": null,
    "args": null,
    "concreteType": "LabelerUser",
    "plural": false,
    "selections": (v10/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pallets",
    "storageKey": null,
    "args": null,
    "concreteType": "LabeledPallet",
    "plural": true,
    "selections": (v11/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "boxes",
    "storageKey": null,
    "args": null,
    "concreteType": "LabeledBox",
    "plural": true,
    "selections": (v11/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sensorStream",
    "storageKey": null,
    "args": null,
    "concreteType": "SensorStream",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sensorName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "run",
        "storageKey": null,
        "args": null,
        "concreteType": "Run",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "runName",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "source",
            "args": null,
            "storageKey": null
          }
        ]
      },
      (v2/*: any*/)
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "frame",
    "storageKey": null,
    "args": null,
    "concreteType": "Frame",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "indexInSensorStream",
        "args": null,
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  }
],
v13 = [
  (v2/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "issues",
    "storageKey": null,
    "args": null,
    "concreteType": "LabelingIssue",
    "plural": true,
    "selections": (v12/*: any*/)
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateLabelingIssueMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLabelingIssue",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLabelingIssueMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "issue",
            "storageKey": null,
            "args": null,
            "concreteType": "LabelingIssue",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "labeler",
                "storageKey": null,
                "args": null,
                "concreteType": "LabelerUser",
                "plural": false,
                "selections": (v3/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "reporter",
                "storageKey": null,
                "args": null,
                "concreteType": "LabelerUser",
                "plural": false,
                "selections": (v3/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "boxes",
                "storageKey": null,
                "args": null,
                "concreteType": "LabeledBox",
                "plural": true,
                "selections": (v3/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pallets",
                "storageKey": null,
                "args": null,
                "concreteType": "LabeledPallet",
                "plural": true,
                "selections": (v3/*: any*/)
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "frame",
                "storageKey": null,
                "args": null,
                "concreteType": "Frame",
                "plural": false,
                "selections": (v3/*: any*/)
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "frame",
            "storageKey": null,
            "args": null,
            "concreteType": "Frame",
            "plural": false,
            "selections": (v9/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sensorStream",
            "storageKey": null,
            "args": null,
            "concreteType": "SensorStream",
            "plural": false,
            "selections": (v9/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateLabelingIssueMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLabelingIssue",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLabelingIssueMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "issue",
            "storageKey": null,
            "args": null,
            "concreteType": "LabelingIssue",
            "plural": false,
            "selections": (v12/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "frame",
            "storageKey": null,
            "args": null,
            "concreteType": "Frame",
            "plural": false,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sensorStream",
            "storageKey": null,
            "args": null,
            "concreteType": "SensorStream",
            "plural": false,
            "selections": (v13/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateLabelingIssueMutation",
    "id": null,
    "text": "mutation CreateLabelingIssueMutation(\n  $input: CreateLabelingIssueMutationInput!\n) {\n  createLabelingIssue(input: $input) {\n    issue {\n      id\n      ...LabelingIssueListItem_issue\n      ...LabelingIssueSideBarListItem_issue\n      labeler {\n        id\n      }\n      reporter {\n        id\n      }\n      boxes {\n        id\n      }\n      pallets {\n        id\n      }\n      description\n      status\n      category\n      frame {\n        id\n      }\n    }\n    frame {\n      id\n      issues {\n        id\n        ...LabelingIssueListItem_issue\n        ...LabelingIssueSideBarListItem_issue\n      }\n    }\n    sensorStream {\n      id\n      issues {\n        id\n        ...LabelingIssueListItem_issue\n        ...LabelingIssueSideBarListItem_issue\n      }\n    }\n  }\n}\n\nfragment LabelingIssueListItem_issue on LabelingIssue {\n  id\n  description\n  category\n  status\n  reporter {\n    username\n    id\n  }\n  labeler {\n    username\n    id\n  }\n  pallets {\n    number\n    id\n  }\n  boxes {\n    number\n    id\n  }\n  sensorStream {\n    sensorName\n    run {\n      runName\n      id\n    }\n    id\n  }\n  frame {\n    indexInSensorStream\n    id\n  }\n}\n\nfragment LabelingIssueSideBarListItem_issue on LabelingIssue {\n  id\n  description\n  category\n  status\n  reporter {\n    username\n    id\n  }\n  pallets {\n    number\n    id\n  }\n  boxes {\n    number\n    id\n  }\n  sensorStream {\n    sensorName\n    run {\n      runName\n      source\n      id\n    }\n    id\n  }\n  frame {\n    id\n    indexInSensorStream\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '85b842a6227926c7c7fed0b1cfe654b8';
export default node;
