import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { DeleteLabeledItemMutationInput } from "../__generated__/DeleteLabeledItemMutation.graphql";

const mutation = graphql`
  mutation DeleteLabeledItemMutation($input: DeleteLabeledItemMutationInput!) {
    deleteLabeledItem(input: $input) {
      frame {
        id
        labeledItems {
          id
        }
      }
    }
  }
`;

function deleteLabeledItem(
  environment: Environment,
  input: DeleteLabeledItemMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let label = store.get(input.id);
      if (!label) {
        return;
      }
      let existingFrame = label.getLinkedRecord("frame");
      let existingLabels = existingFrame.getLinkedRecords("labeledItems");
      if (!existingLabels) {
        existingLabels = [];
      }
      let newLabels = existingLabels.filter(
        l => l && l.getDataID() !== input.id
      );
      existingFrame.setLinkedRecords(newLabels, "labeledItems");
    }
  });
}

export default deleteLabeledItem;
