import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { RelayProp } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Draggable from "react-draggable";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";

import { QA_CATEGORIES } from "../../../utils/Enums";
import { LabelSelector } from "./SelectionState";
import {
  CreateIssueDialog_frame,
  QACategory
} from "../../../__generated__/CreateIssueDialog_frame.graphql";
import createIssue from "../../../mutations/CreateLabelingIssueMutation";
import { Viewer } from "./Utils";

const styles = theme =>
  createStyles({
    paper: {
      position: "absolute",
      left: 600,
      bottom: 300,
      width: 480
    },
    title: {
      fontSize: 12,
      padding: 16
    },
    labelerAndCategory: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around"
    },
    labels: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-around",
      height: 240
    },
    labelGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexGrow: 1,
      height: "100%",
      overflowY: "scroll",
      padding: 8,
      margin: 8,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4
    },
    label: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: 64
    },
    descriptionWrapper: {
      margin: 16
    },
    description: {
      width: "100%"
    },
    actions: {
      justifyContent: "space-evenly",
      padding: 16,
      paddingTop: 0
    }
  });

type Props = {
  classes: any;
  relay: RelayProp;
  viewer: Viewer;
  frame: CreateIssueDialog_frame;
  closer: () => void;
  readonly displayedLabelerUsernames: Set<string>;
  labelSelector: LabelSelector;
};
type State = {
  palletIds: Set<string>;
  boxIds: Set<string>;
  labelerId: string | null;
  qaCategory: QACategory;
  description: string;
  setFrameId: boolean;
};

class CreateIssueDialog extends React.Component<Props, State> {
  state = {
    palletIds: new Set(),
    boxIds: new Set(),
    labelerId: null,
    qaCategory: "PALLET_SIDES" as QACategory,
    description: "",
    setFrameId: true
  };

  _submit() {
    const { frame, relay, closer } = this.props;
    const { id: frameId } = frame;
    const { environment } = relay;
    const {
      labelerId,
      palletIds,
      boxIds,
      description,
      qaCategory: category,
      setFrameId
    } = this.state;
    const status = "NEEDS_LABELER_ATTENTION";
    if (!labelerId) {
      return;
    }
    const boxes = Array.from(boxIds);
    const pallets = Array.from(palletIds);
    const maybeFrameId = setFrameId ? frameId : undefined;
    createIssue(
      environment,
      {
        labelerId,
        boxes,
        pallets,
        description,
        status,
        category,
        sensorStreamId: frame.sensorStream.id,
        frameId: maybeFrameId
      },
      () => closer()
    );
  }

  componentDidMount() {
    const { labelSelector, frame } = this.props;
    const palletIds = new Set();
    const boxIds = new Set();
    frame.labeledBoxes.forEach(
      box => labelSelector.isLabelSelected(box) && boxIds.add(box.id)
    );
    frame.labeledPallets.forEach(
      pallet =>
        labelSelector.isLabelSelected(pallet) && palletIds.add(pallet.id)
    );
    this.setState({ boxIds, palletIds });
  }

  render() {
    const {
      frame,
      viewer,
      displayedLabelerUsernames,
      classes,
      closer,
      labelSelector
    } = this.props;
    if (!frame) {
      return null;
    }
    let { labelerId, qaCategory, boxIds, palletIds, setFrameId } = this.state;
    const {
      labelers: allLabelers,
      labeledBoxes: allBoxes,
      labeledPallets: allPallets
    } = frame;
    // If there is no username already set, pick a username from the list of
    // displayed usernames.
    const displayedLabelerIds = allLabelers
      .filter(labeler => displayedLabelerUsernames.has(labeler.username || ""))
      .map(l => l.id);
    if (!labelerId && displayedLabelerIds.length) {
      labelerId = displayedLabelerIds[0];
      this.setState({ labelerId });
    }
    // If there is still no username and the frame has labels, pick a username
    // from one of the frame's labelers.
    if (!labelerId && allLabelers.length) {
      labelerId = allLabelers[0].id;
      this.setState({ labelerId });
    }
    return (
      <Draggable enableUserSelectHack={false}>
        <Card className={classes.paper}>
          <CardHeader className="handle" title={"Submit Issue"} />
          <CardContent>
            <div className={classes.labelerAndCategory}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-labelers-checkbox">
                  Labeler
                </InputLabel>
                <Select
                  value={labelerId || ""}
                  onChange={e =>
                    this.setState({
                      labelerId: e.target.value as string
                    })
                  }
                  input={<Input id="select-labelers-checkbox" />}
                >
                  {allLabelers.map(labeler => (
                    <MenuItem key={labeler.id} value={labeler.id}>
                      <ListItemText
                        primary={
                          labeler.username === viewer.username
                            ? "You"
                            : labeler.shortName
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-category-checkbox">
                  Category
                </InputLabel>
                <Select
                  value={qaCategory}
                  onChange={e =>
                    this.setState({
                      qaCategory: e.target.value as QACategory
                    })
                  }
                  input={<Input id="select-category-checkbox" />}
                >
                  {Array.from(QA_CATEGORIES.entries()).map(entry => (
                    <MenuItem key={entry[0]} value={entry[0]}>
                      <ListItemText primary={entry[1]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.labels}>
              <div className={classes.labelGroup}>
                <Typography variant="subtitle1">Pallets</Typography>
                {allPallets.map(pallet => (
                  <FormControlLabel
                    key={pallet.id}
                    onMouseEnter={() => labelSelector.hoverLabel(pallet)}
                    onMouseLeave={() => labelSelector.clearHovers()}
                    control={
                      <Checkbox
                        checked={palletIds.has(pallet.id)}
                        onChange={() => this._togglePallet(pallet.id)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography color="textSecondary" variant="body1">
                        {pallet.number}
                      </Typography>
                    }
                  />
                ))}
              </div>
              <div className={classes.labelGroup}>
                <Typography variant="subtitle1">Boxes</Typography>
                {allBoxes.map(box => (
                  <FormControlLabel
                    key={box.id}
                    onMouseEnter={() => labelSelector.hoverLabel(box)}
                    onMouseLeave={() => labelSelector.clearHovers()}
                    control={
                      <Checkbox
                        checked={boxIds.has(box.id)}
                        onChange={() => this._toggleBox(box.id)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography color="textSecondary" variant="body1">
                        {box.number}
                      </Typography>
                    }
                  />
                ))}
              </div>
            </div>
            <div className={classes.descriptionWrapper}>
              <TextField
                classes={{ root: classes.description }}
                label="Description"
                type="text"
                variant="outlined"
                multiline
                onChange={e => this._updateDescription(e.target.value)}
              />
            </div>
            <div className={classes.descriptionWrapper}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!setFrameId}
                    onChange={() =>
                      this.setState({
                        setFrameId: !setFrameId
                      })
                    }
                    color="primary"
                  />
                }
                label={
                  <Typography color="textSecondary" variant="body1">
                    For multiple frames in this run
                  </Typography>
                }
              />
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              variant="outlined"
              onClick={() => closer()}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={() => this._submit()}
              color="primary"
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </Draggable>
    );
  }

  _toggleBox(id: string) {
    const { boxIds: existingBoxIds } = this.state;
    if (existingBoxIds.has(id)) {
      const boxIds = new Set(existingBoxIds.values());
      boxIds.delete(id);
      this.setState({ boxIds });
      return;
    }
    const boxIds = existingBoxIds.add(id);
    this.setState({ boxIds });
  }

  _togglePallet(id: string) {
    const { palletIds: existingPalletIds } = this.state;
    if (existingPalletIds.has(id)) {
      const palletIds = new Set(existingPalletIds.values());
      palletIds.delete(id);
      this.setState({ palletIds });
      return;
    }
    const palletIds = existingPalletIds.add(id);
    this.setState({ palletIds });
  }

  _updateDescription(description: string) {
    this.setState({ description });
  }
}

export default withStyles(styles)(
  createFragmentContainer(CreateIssueDialog, {
    frame: graphql`
      fragment CreateIssueDialog_frame on Frame {
        id
        sensorStream {
          id
        }
        labelers {
          id
          username
          shortName
        }
        labeledBoxes {
          id
          number
        }
        labeledPallets {
          id
          number
        }
        qaInspections {
          category
        }
      }
    `
  })
);
