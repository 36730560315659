import React from "react";
import { Matrix3, Vector2 } from "three";

import { LabelCategory, LabelSelector } from "./SelectionState";
import { LabelMutators } from "./LabelMutators";
import { TopLevelLabelActions } from "../../label_frame/SensorStreamWrapper";
import { Corner, Label, LabelFace, Visibility } from "./LabelMutators";
import {
  computeCenter,
  transformVector2,
  transformPoint,
  minEdgeLength,
  labelContainingCornerId,
  allFacesForLabel
} from "./Utils";

type Props = {
  viewer: Object;
  frameId: string;

  canvasContext: CanvasRenderingContext2D;
  imageWidth: number;
  imageHeight: number;
  imageData: ImageData;
  drawCanvas: Function;
  imageTFacade: Matrix3;
  defaultCursor: string;

  topLevelLabelActions: TopLevelLabelActions;
  goToNextPose: () => void;

  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  labels: Array<Label>;

  setNotificationText: (arg0: string | null) => void;
  toggleShowLabels: () => void;
};
type State = {
  dragStart: Vector2 | null;
  isMouseDown: boolean;
  dirtyTranslationX: number;
  dirtyTranslationY: number;
  dirtyCornerSource: Corner | null;
  notificationText: string | null;
  waitingForMutation: boolean;
  lastMouseDownTime: Date | null;
  // ChromeOS does not keep track of the ALT key during mouse events, so it's
  // necessary to track it manually.
  altKey: boolean;
};

function arrayToSVGMatrix(array: Array<number>): SVGMatrix {
  const svgMatrix = (
    document.createElementNS("http://www.w3.org/2000/svg", "svg") as any
  ).createSVGMatrix();
  svgMatrix.a = array[0];
  svgMatrix.b = array[1];
  svgMatrix.c = array[2];
  svgMatrix.d = array[3];
  svgMatrix.e = array[4];
  svgMatrix.f = array[5];
  return svgMatrix;
}

class CanvasEventHandler extends React.Component<Props, State> {
  state = {
    dragStart: null,
    isMouseDown: false,
    dirtyTranslationX: 0,
    dirtyTranslationY: 0,
    dirtyCornerSource: null,
    notificationText: null,
    waitingForMutation: false,
    lastMouseDownTime: null,
    altKey: false
  };

  _handleWheel = (event: WheelEvent) => {
    event.preventDefault();
    this.onWheel(event);
  };

  _handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    this.onMouseMove(event);
  };

  _handleKeyUp = (event: KeyboardEvent) => {
    const { altKey } = event;
    this.setState({ altKey });
  };

  _handleKeyDown = (event: KeyboardEvent) => {
    const { altKey } = event;
    this.setState({ altKey });
    if (!altKey) {
      return;
    }
    event.preventDefault();
    const {
      labelMutator,
      labelSelector,
      goToNextPose,
      toggleShowLabels,
      topLevelLabelActions
    } = this.props;
    const selectedLabelIds = labelSelector.selectedLabelIds();
    switch (event.code) {
      case "KeyR":
        // Rotate view.
        const rads = -Math.PI / 2;
        this.rotate(rads);
        break;
      case "KeyF":
        // Start top face for selected label.
        if (selectedLabelIds.length !== 1) {
          return;
        }

        labelMutator.face
          .start(selectedLabelIds[0], "FRONT")
          .catch(() => console.log("Could not start front"));
        break;
      case "KeyT":
        // Start top face for selected label.
        if (selectedLabelIds.length !== 1) {
          return;
        }

        labelMutator.face
          .start(selectedLabelIds[0], "TOP")
          .catch(() => console.log("Could not start top"));
        break;
      case "KeyS":
        // Start left or right face for selected label.
        if (selectedLabelIds.length !== 1) {
          return;
        } // shiftKey seems to always be false on Ubuntu 18.04.

        const leftOfExisting = event.ctrlKey;
        labelMutator.face
          .start(selectedLabelIds[0], "SIDE", leftOfExisting)
          .catch(() => console.log("Could not start side"));
        break;
      case "KeyV":
        goToNextPose();
        break;
      case "KeyI": {
        const faceIds = labelSelector.selectedFaceIds();
        if (faceIds.length !== 1) {
          throw new Error(
            "Can only start pocket corner when one face is selected"
          );
        }
        labelSelector.startCreatingPalletPocket(faceIds[0], true);
        break;
      }
      case "KeyO": {
        const faceIds = labelSelector.selectedFaceIds();
        if (faceIds.length !== 1) {
          throw new Error(
            "Can only start pocket corner when one face is selected"
          );
        }
        labelSelector.startCreatingPalletPocket(faceIds[0], false);
        break;
      }
      case "KeyP":
        break;
      case "Esc":
        this.stopDrag();
        break;
      case "KeyL":
        const nextLabelCategory =
          topLevelLabelActions.creationLabelCategory === "BOX"
            ? "PALLET"
            : "BOX";
        topLevelLabelActions.setCreationLabelCategory(nextLabelCategory);
        break;
      case "KeyH":
        toggleShowLabels();
        break;
      case "KeyG":
        topLevelLabelActions.setShowCanvasGrid(
          !topLevelLabelActions.showCanvasGrid
        );
        break;
      case "ArrowLeft":
        topLevelLabelActions.requestPrevFilteredFrame();
        break;
      case "ArrowRight":
        topLevelLabelActions.requestNextFilteredFrame();
        break;
      case "BracketLeft":
        topLevelLabelActions.requestPrevLabeledFrame();
        break;
      case "BracketRight":
        topLevelLabelActions.requestNextLabeledFrame();
        break;
      case "ArrowUp":
        topLevelLabelActions.requestNextFrame();
        break;
      case "ArrowDown":
        topLevelLabelActions.requestPrevFrame();
        break;
      case "Delete":
        labelSelector
          .selectedLabelIds()
          .forEach(labelId => labelMutator.label.any.delete(labelId));
        break;
      default:
        break;
    }
    return false;
  };

  _handleMouseUp = (event: MouseEvent) => {
    event.preventDefault();
    const { altKey } = this.state;
    // Infer that the OS is Chrome if the manually tracked alt key does not
    // match event.altKey, which ChromeOS never sets to true.
    const isChromeOS = altKey && !event.altKey;
    // ChromeOS simulates sends right click events when left clicking with the
    // alt key down.
    const rightClickSimulated = isChromeOS && altKey;
    const rightClick = event.button === 2 && !rightClickSimulated;
    if (rightClick) {
      this.onRightClick(event);
    } else {
      this.onMouseUp(event);
    }
  };

  _clearMouseDown = () => {
    this.setState({ isMouseDown: false });
  };

  _handleDoubleClick = (event: MouseEvent) => {
    event.preventDefault();
    this.onDoubleClick(event);
  };

  _handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    return false;
  };

  _handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    this.onMouseDown(event);
  };

  componentDidMount() {
    this.props.canvasContext.canvas.addEventListener(
      "wheel",
      this._handleWheel
    );
    this.props.canvasContext.canvas.addEventListener(
      "mousemove",
      this._handleMouseMove
    );
    this.props.canvasContext.canvas.addEventListener(
      "mouseup",
      this._handleMouseUp
    );
    this.props.canvasContext.canvas.addEventListener(
      "dblclick",
      this._handleDoubleClick
    );
    this.props.canvasContext.canvas.addEventListener(
      "contextmenu",
      (event: MouseEvent) => this._handleContextMenu(event),
      false
    );
    // Add mouseup listener to document so that mouse-ups will not be missed if
    // the canvas interaction is interrupted.
    document.addEventListener("mouseup", this._clearMouseDown);
    this.props.canvasContext.canvas.addEventListener(
      "mousedown",
      this._handleMouseDown
    );

    // Listen to key-presses on the canvas. Listening on the canvas may miss
    // some key-presses that would be caught when listening on the document, but
    // listening on the document leads to key-presses being caught when they are
    // unexpected, which is probably the worser of the evils.
    document.addEventListener("keydown", this._handleKeyDown);
    document.addEventListener("keyup", this._handleKeyUp);
  }

  componentWillUnmount() {
    this.props.canvasContext.canvas.removeEventListener(
      "wheel",
      this._handleWheel
    );
    this.props.canvasContext.canvas.removeEventListener(
      "mousemove",
      this._handleMouseMove
    );
    this.props.canvasContext.canvas.removeEventListener(
      "mouseup",
      this._handleMouseUp
    );
    this.props.canvasContext.canvas.removeEventListener(
      "mousedown",
      this._handleMouseDown
    );
    this.props.canvasContext.canvas.removeEventListener(
      "dblclick",
      this._handleDoubleClick
    );
    this.props.canvasContext.canvas.removeEventListener(
      "contextmenu",
      this._handleContextMenu
    );
    document.removeEventListener("mouseup", this._clearMouseDown);
    document.removeEventListener("keydown", this._handleKeyDown);
    document.removeEventListener("keyup", this._handleKeyUp);
  }

  render() {
    return null;
  }

  transformPointToCanvas(x: number, y: number): Vector2 {
    const point = document
      .createElementNS("http://www.w3.org/2000/svg", "svg")
      .createSVGPoint();
    point.x = x;
    point.y = y;
    const { canvasContext } = this.props;
    const transformed: SVGPoint = point.matrixTransform(
      arrayToSVGMatrix(canvasContext.currentTransform).inverse()
    );
    return new Vector2(transformed.x, transformed.y);
  }

  translate(dx: number, dy: number) {
    this.props.canvasContext.translate(dx, dy);
    const { dirtyTranslationX, dirtyTranslationY } = this.state;
    this.setState({
      dirtyTranslationX: dirtyTranslationX + dx,
      dirtyTranslationY: dirtyTranslationY + dy
    });
  }

  zoom(levels: number, x: number, y: number) {
    let point: Vector2 = this.transformPointToCanvas(x, y);
    this.translate(point.x, point.y);

    const { canvasContext, topLevelLabelActions } = this.props;
    const { setCanvasScale, canvasScale } = topLevelLabelActions;
    let factor = Math.pow(1.1, levels);
    canvasContext.scale(factor, factor);
    setCanvasScale(canvasScale * factor);
    this.translate(-point.x, -point.y);
    this.props.drawCanvas();
  }

  startDrag(event: MouseEvent) {
    this.setState({
      dragStart: this.mousePosition(event)
    });
  }

  onMouseDown(event: MouseEvent) {
    this.setState({ isMouseDown: true, lastMouseDownTime: new Date() });
    const cornerUnderMouse = this.cornerUnderMouse(event);
    const { labelSelector, drawCanvas, canvasContext, labels } = this.props;
    if (!cornerUnderMouse || event.shiftKey) {
      this.startDrag(event);
      return;
    }

    const labelWithCorner = labelContainingCornerId(
      labels,
      cornerUnderMouse.id
    );

    if (
      !labelSelector.isAnyLabelBeingCreated() ||
      (labelWithCorner &&
        labelSelector.currentCreationLabelId() === labelWithCorner.id)
    ) {
      // If the alt key is down, the the user is doing something other than
      // grabbing a corner, e.g. toggling corner visibility.
      if (!this.state.altKey) {
        canvasContext.canvas.style.cursor = "grabbing";
      }
      labelSelector.selectCorner(cornerUnderMouse);
      this.setState({ dirtyCornerSource: cornerUnderMouse });
      drawCanvas();
    }
  }

  onMouseMove(event: MouseEvent) {
    const { labelSelector, canvasContext, drawCanvas, labelMutator } =
      this.props;
    const { isMouseDown, dirtyCornerSource, altKey } = this.state;

    // If the alt key is down, the move is incidental and the user is doing
    // something other than dragging a mouse, e.g. toggling corner visibility.
    if (altKey) {
      return;
    }

    // Check if a corner is being moved.
    if (
      isMouseDown &&
      dirtyCornerSource &&
      !dirtyCornerSource.id.startsWith("client")
    ) {
      const mousePosition = this.mouseLocationRawImageSpace(event);
      const defaultVisibility: Visibility =
        dirtyCornerSource.visibility === "OCCLUDED" ? "OCCLUDED" : "VISIBLE";
      labelMutator.corner.updateLocal({
        id: dirtyCornerSource.id,
        x: mousePosition.x,
        y: mousePosition.y,
        visibility: this._inferVisibility(mousePosition, defaultVisibility)
      });
      return;
    }

    // If the user is not dragging a corner but the mouse is down, they are
    // panning.
    if (isMouseDown) {
      this.drag(event);
      return;
    }

    const cursorIsWaiting = canvasContext.canvas.style.cursor === "wait";
    // Check if a corner is being hovered over
    const cornerUnderMouse = this.cornerUnderMouse(
      event,
      labelSelector.currentCreationLabelId()
    );
    // Update the cursor based on selection options, unless the cursor is "wait"
    if (cornerUnderMouse && !cursorIsWaiting) {
      labelSelector.hoverCorner(cornerUnderMouse);

      // If no label is being created, indicate that the user can drag the
      // corner.
      if (!event.shiftKey && !labelSelector.isAnyLabelBeingCreated()) {
        canvasContext.canvas.style.cursor = "grab";
        drawCanvas();
        return;
      }
      const labelWithCorner = labelContainingCornerId(
        this.props.labels,
        cornerUnderMouse.id
      );

      if (!labelWithCorner) {
        // The label with the hovered corner could not be found.
        return;
      }
      if (
        !labelWithCorner.id ||
        !labelSelector.isLabelBeingCreated(labelWithCorner)
      ) {
        // The hovered corner is not part of the label being created. Ignore it.
        return;
      }
      // try change:

      const facesWithCorner = labelWithCorner.shape.faces.filter(f =>
        f.corners.some(c => c.id === cornerUnderMouse.id)
      );

      if (
        facesWithCorner.length === 1 &&
        labelSelector.isFaceBeingCreated(facesWithCorner[0])
      ) {
        // The corner is only part of the face being created. Users can move it.
        canvasContext.canvas.style.cursor = "grab";
        drawCanvas();
        return;
      }
      // The corner is part of multiple faces, or is not part of the face being
      // created. Either way, the user is not free to move the corner. Clicking
      // the corner will toggle whether or not the corner belongs to the face
      // being created.
      canvasContext.canvas.style.cursor = "cell";
      drawCanvas();
      return;
    }

    // Check if a corner is being hovered over
    const faceUnderMouse = this.faceUnderMouse(event);
    if (!labelSelector.isAnyLabelBeingCreated() && faceUnderMouse) {
      labelSelector.hoverFace(faceUnderMouse);
      // Show "pointer" so the user knows the face is selectable, unless the
      // cursor is showing a "wait" icon.
      if (!cursorIsWaiting && !event.shiftKey) {
        canvasContext.canvas.style.cursor = "pointer";
      }
      drawCanvas();
      return;
    }

    labelSelector.clearHovers();
    // Clear non-"wait" cursors.
    if (!cursorIsWaiting) {
      canvasContext.canvas.style.cursor =
        event.shiftKey || labelSelector.isAnyLabelBeingCreated()
          ? "crosshair"
          : this.props.defaultCursor;
    }
  }

  onDoubleClick(event: MouseEvent) {
    const { labelSelector, labelMutator, topLevelLabelActions, drawCanvas } =
      this.props;

    // Beware: DoubleClick might fire if the user clicks to create a last corner
    // and clicks again soon after, then drags the corner for a while before
    // releasing the clicker. This wouldn't feel like a double-click because the
    // MouseUps are so far apart. Checking that the last MouseDown event is more
    // than 500 milliseconds ago guards against surprises here.
    if (
      this.state.lastMouseDownTime &&
      new Date().valueOf() - this.state.lastMouseDownTime > 500
    ) {
      // This double-click is stale. Ignore it.
      return;
    }

    if (labelSelector.isAnyLabelBeingCreated()) {
      return;
    }
    this.setState({ waitingForMutation: true });
    const { frameId } = this.props;
    const location = this.mouseLocationRawImageSpace(event);
    const labelCategory = topLevelLabelActions.creationLabelCategory;
    const faceSide = topLevelLabelActions.creationFaceSide;
    const { x, y } = location;
    // Create label, face, and corner.
    const categoryToCreator = category => {
      switch (category) {
        case LabelCategory.BOX:
          return labelMutator.label.box.create;
        case LabelCategory.PALLET:
          return labelMutator.label.pallet.create;
        case LabelCategory.ITEM:
          return labelMutator.label.item.create;
        default:
          return labelMutator.label.pallet.create;
      }
    };
    const mutator = categoryToCreator(labelCategory);
    mutator(
      {
        shape: {
          faces: [
            {
              side: faceSide,
              corners: [
                {
                  x,
                  y,
                  visibility: this._inferVisibility(location, "VISIBLE")
                }
              ]
            }
          ]
        }
      },
      frameId
    )
      .then(() => this._markMutationComplete())
      .catch(() => this._markMutationComplete());
    drawCanvas();
  }

  _markMutationComplete = (mutatedCorner?: Corner) => {
    this.setState({ waitingForMutation: false });
    const { canvasContext, drawCanvas } = this.props;
    const { dirtyCornerSource } = this.state;
    if (dirtyCornerSource && mutatedCorner) {
      // If dirtyCornerSource is truthy, that means the user is dragging the
      // corner they just created. Replace the client-generated temp ID with
      // the server-generated ID in the mutatedCorner.
      const newDirtyCornerSource = {
        ...dirtyCornerSource,
        id: mutatedCorner.id
      };
      this.setState({ dirtyCornerSource: newDirtyCornerSource });
    }
    if (canvasContext.canvas.style.cursor === "wait") {
      canvasContext.canvas.style.cursor = this.props.defaultCursor;
    }
    drawCanvas();
  };

  onMouseUp(event: MouseEvent) {
    this.setState({ isMouseDown: false });
    const { labelSelector, labelMutator, canvasContext, setNotificationText } =
      this.props;
    const { dirtyCornerSource, waitingForMutation } = this.state;
    const { dirtyTranslationX, dirtyTranslationY, altKey } = this.state;
    const dragDistance = Math.sqrt(
      dirtyTranslationX ** 2 + dirtyTranslationY ** 2
    );
    this.setState({ dirtyCornerSource: null });
    if (event.shiftKey) {
      return;
    }
    this.stopDrag();
    if (dirtyCornerSource) {
      // The user clicked a corner. This may be from dragging a corner, or it
      // may be a side-effect of clicking a corner to add / remove the corner
      // from a face. Use the distance the corner was dragged to distinguish
      // between the two.
      if (altKey) {
        labelMutator.corner.update({
          id: dirtyCornerSource.id,
          visibility:
            dirtyCornerSource.visibility === "VISIBLE" ? "OCCLUDED" : "VISIBLE"
        });
        return;
      }
      const cornerDragDistance = this.mouseLocationRawImageSpace(
        event
      ).distanceTo(new Vector2(dirtyCornerSource.x, dirtyCornerSource.y));
      // Determine if this is a click or a corner drag. Be more tolerant of
      // clicks when creating a label because the user may to be toggling a
      // corner in / out of the face being created.
      const dragSensitivity = labelSelector.isAnyLabelBeingCreated()
        ? 1.75
        : 0.5;
      if (cornerDragDistance > dragSensitivity) {
        if (waitingForMutation) {
          canvasContext.canvas.style.cursor = "wait";
          setNotificationText(
            "We're a little backed up. Please try that again in a moment."
          );
          return;
        }
        canvasContext.canvas.style.cursor = "grab";
        this.setState({ waitingForMutation: true });
        labelMutator.corner
          .commitLocalUpdate(dirtyCornerSource.id)
          .then(() => {
            this._markMutationComplete();
            labelSelector.selectCorner(null);
          })
          .catch(() => this._markMutationComplete());
        return;
      }
    }

    if (dragDistance > 2) {
      return;
    }

    const faceUnderMouse = this.faceUnderMouse(event);
    if (faceUnderMouse && altKey && faceUnderMouse.side !== "TOP") {
      const newSide = faceUnderMouse.side === "FRONT" ? "SIDE" : "FRONT";
      labelMutator.face.update({
        id: faceUnderMouse.id,
        side: newSide
      });
      return;
    }
    if (labelSelector.isAnyLabelBeingCreated()) {
      // We need to create at least a corner, and possibly a new face and/or
      // label if they do not already exist for this corner.
      const currentCreationFaceId = labelSelector.currentCreationFaceId();
      if (!currentCreationFaceId) {
        // A corner cannot be added if there is no face being created.
        return;
      }

      const cornerUnderMouse = this.cornerUnderMouse(event);
      const visible: Visibility = "VISIBLE";
      if (!cornerUnderMouse) {
        this._createCornerFromMouse(event, visible);
        return;
      }

      // Check if the user is trying to add an existing corner from a different
      // face in the label, or trying to remove a corner from the current face.
      const labelWithCorner = this.props.labels.find(l =>
        l.shape.faces.some(f =>
          f.corners.some(c => c.id === cornerUnderMouse.id)
        )
      );
      if (!labelWithCorner) {
        // The label with the hovered corner could not be found.
        this._createCornerFromMouse(event, visible);
        return;
      }
      if (!labelSelector.isLabelBeingCreated(labelWithCorner)) {
        // The hovered corner is not part of the label being created. Create a
        // new corner.
        this._createCornerFromMouse(event, visible);
        return;
      }
      const facesWithCorner = labelWithCorner.shape.faces.filter(f =>
        f.corners.some(c => c.id === cornerUnderMouse.id)
      );

      if (
        facesWithCorner.length === 1 &&
        facesWithCorner[0].id === labelSelector.currentCreationFaceId()
      ) {
        // Don't add or remove the corner if
        return;
      }
      if (!facesWithCorner) {
        // Something is amok with the creation state.
        return;
      }
      if (facesWithCorner.some(f => f.id === currentCreationFaceId)) {
        return labelMutator.face.removeCorner(
          currentCreationFaceId,
          cornerUnderMouse.id
        );
      } else {
        labelSelector.selectCorner(null);
        return labelMutator.face.addCorner(
          currentCreationFaceId,
          cornerUnderMouse.id
        );
      }
    }

    if (faceUnderMouse) {
      labelSelector.selectFace(faceUnderMouse);
      return;
    }

    labelSelector.clearSelections();
  }

  onRightClick(event: MouseEvent) {
    if (!this.props.labelSelector.isAnyLabelBeingCreated()) {
      return;
    }
    this._createCornerFromMouse(event, "OCCLUDED");
  }

  drag(event: MouseEvent) {
    const { dragStart } = this.state;
    if (!dragStart) {
      return;
    }
    let position = this.mousePosition(event);
    let dx = position.x - dragStart.x;
    let dy = position.y - dragStart.y;
    this.translate(dx, dy);
    this.props.drawCanvas();
  }

  stopDrag() {
    this.setState({
      dragStart: null,
      dirtyTranslationX: 0,
      dirtyTranslationY: 0
    });
  }

  zoomIn(x: number, y: number) {
    this.zoom(1, x, y);
  }

  zoomOut(x: number, y: number) {
    this.zoom(-1, x, y);
  }

  onWheel(event: WheelEvent) {
    if (event.deltaY > 0) {
      this.zoomOut(event.offsetX, event.offsetY);
    } else {
      this.zoomIn(event.offsetX, event.offsetY);
    }
    event.preventDefault();
  }

  rotate(radians: number, doIncrement: boolean = true) {
    const { imageHeight, imageWidth, topLevelLabelActions } = this.props;
    const originOffsetX = imageWidth / 2;
    const originOffsetY = imageHeight / 2;

    this.translate(originOffsetX, originOffsetY);

    // CanvasRenderingContext2D.rotate expects angles in radians, while
    // SVGMatrix.rotate expects degrees, of course.
    const { canvasContext } = this.props;
    canvasContext.rotate(radians);

    this.translate(-originOffsetX, -originOffsetY);
    doIncrement &&
      topLevelLabelActions.setRotationRadians(
        radians + topLevelLabelActions.rotationRadians
      );
  }

  faceUnderMouse(event: MouseEvent): LabelFace | null {
    const mouseLocation = this.mouseLocationRawImageSpace(event);
    const location = transformVector2(mouseLocation, this.props.imageTFacade);
    let minDistance = Number.MAX_SAFE_INTEGER;
    let closestFace = null;
    let labels = this.props.labels;
    for (let label of labels) {
      // try change:

      const { shape } = label;
      if (!shape) {
        continue;
      }
      for (let face of shape.faces) {
        if (!face) {
          continue;
        }
        const faceMinEdgeLength = minEdgeLength(
          face.corners.map(c => new Vector2(c.x, c.y))
        );
        const faceHoverRadius = Math.sqrt(faceMinEdgeLength / 1.5);
        const center: Vector2 = computeCenter(
          face.corners
            .map(c =>
              transformVector2(new Vector2(c.x, c.y), this.props.imageTFacade)
            )
            .map(c => new Vector2(c.x, c.y))
        );
        const distance = center.distanceTo(location);
        if (distance <= minDistance && distance <= faceHoverRadius) {
          closestFace = face;
          minDistance = distance;
        }
      }
    }
    return closestFace;
  }

  cornerUnderMouse(event: MouseEvent, onlyLabelId?: string): Corner | null {
    return this.cornerUnderLocation(
      this.mouseLocationRawImageSpace(event),
      onlyLabelId
    );
  }

  cornerUnderLocation(
    locationRawImageSpace: Vector2,
    onlyLabelId?: string
  ): Corner | null {
    const { labels, imageTFacade, topLevelLabelActions } = this.props;
    let facadeTImage = new Matrix3();
    facadeTImage.getInverse(imageTFacade);
    // Normalize tolerance with the sqrt of zoomControl.scale because this value
    // drives how the corner's radius changes.
    let minDistance = 5 / Math.sqrt(topLevelLabelActions.canvasScale);
    let hoveredCorner: Corner | null = null;

    for (const label of labels) {
      if (onlyLabelId && label.id !== onlyLabelId) {
        continue;
      }
      const faces = allFacesForLabel(label, true);
      for (const corner of faces.map(f => f.corners).flat()) {
        const distance = Math.sqrt(
          (corner.x - locationRawImageSpace.x) ** 2 +
            (corner.y - locationRawImageSpace.y) ** 2
        );
        if (distance <= minDistance) {
          hoveredCorner = corner;
          minDistance = distance;
        }
      }
    }
    return hoveredCorner;
  }

  mouseLocationRawImageSpace(event: MouseEvent): Vector2 {
    const positionInImage = this.mousePosition(event);
    let facadeTImage = new Matrix3();
    facadeTImage.getInverse(this.props.imageTFacade);
    return transformPoint(positionInImage.x, positionInImage.y, facadeTImage);
  }

  mousePosition(event: MouseEvent): Vector2 {
    return this.transformPointToCanvas(event.offsetX, event.offsetY);
  }

  _createCornerFromMouse(
    event: MouseEvent,
    defaultVisibility: Visibility = "VISIBLE"
  ): void {
    const { labelSelector } = this.props;

    if (!labelSelector.isAnyLabelBeingCreated()) {
      return;
    }
    const faceId = labelSelector.currentCreationFaceId();
    const location = this.mouseLocationRawImageSpace(event);
    const { x, y } = location;
    if (!faceId) {
      return;
    }
    const palletId = labelSelector.currentCreationLabelId();
    if (!palletId) {
      return;
    }
    const pocketDetails = labelSelector.currentCreationPocketDetails();
    this.setState({ waitingForMutation: true });
    const visibility = this._inferVisibility(location, defaultVisibility);
    if (pocketDetails) {
      this.props.labelMutator.label.pallet
        .createPocketCorner({
          x,
          y,
          visibility,
          palletId,
          ...pocketDetails
        })
        .then(response => {
          this._markMutationComplete();
        })
        .catch(() => this._markMutationComplete());
    } else {
      this.props.labelMutator.corner
        .create(x, y, visibility, faceId)
        .then(response => {
          this._markMutationComplete(response);
        })
        .catch(() => this._markMutationComplete());
    }
  }

  _inferVisibility(
    positionInImage: Vector2,
    defaultVisibility: Visibility
  ): Visibility {
    const positionOutOfBounds = (
      position: Vector2,
      boundX: number,
      boundY: number
    ) => {
      const x = position.x;
      const y = position.y;
      return x < 0 || y < 0 || x > boundX || y > boundY;
    };

    const { imageWidth, imageHeight, imageTFacade, imageData } = this.props;
    let offFrame = positionOutOfBounds(
      positionInImage,
      imageWidth,
      imageHeight
    );
    offFrame =
      offFrame ||
      positionOutOfBounds(
        transformVector2(positionInImage, imageTFacade),
        imageData.width,
        imageData.height
      );
    return offFrame ? "OFF_FRAME" : defaultVisibility;
  }
}

export default CanvasEventHandler;
