// @flow

import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, createFragmentContainer } from "react-relay";

import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import amber from "@material-ui/core/colors/amber";
import red from "@material-ui/core/colors/red";

import TopBar from "./components/topbar/TopBar";

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: blue,
    warning: amber,
    error: red
  },
  typography: {
    fontSize: 12,
    useNextVariants: true
  }
});

const propTypes = {
  viewer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired
};
const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    padding: 0,
    height: "100vh",
    overflow: "auto"
  },
  appBarSpacer: {
    height: 40
  }
});

class App extends Component<typeof propTypes> {
  render() {
    const { children, viewer, classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <CssBaseline />
          <div data-framework="relay" style={{ display: "flex" }}>
            <TopBar viewer={viewer} />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              {children}
            </main>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(App, {
    viewer: graphql`
      fragment App_viewer on Viewer {
        ...TopBar_viewer
      }
    `
  })
);
