import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import {
  CreateLoadMutationInput,
  CreateLoadMutationResponse
} from "../__generated__/CreateLoadMutation.graphql";

import { FrameViewer_viewer } from "../__generated__/FrameViewer_viewer.graphql";

const mutation = graphql`
  mutation CreateLoadMutation($input: CreateLoadMutationInput!) {
    createLoad(input: $input) {
      pallet {
        id
        load {
          id
          faces {
            id
            side
            parentShape {
              id
              faces {
                id
              }
            }
            corners {
              id
              x
              y
              visibility
            }
          }
        }
      }
    }
  }
`;

let tempID = 0;
function createLoad(
  viewer: FrameViewer_viewer,
  environment: Environment,
  input: CreateLoadMutationInput,
  onCompleted?: (
    response: CreateLoadMutationResponse | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  let mutationId: string = (tempID++).toString();
  const fullInput: CreateLoadMutationInput = {
    clientMutationId: mutationId,
    ...input
  };
  commitMutation(environment, {
    mutation,
    variables: {
      input: fullInput
    },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      // label is existingPallet
      let pallet = store.get(input.palletId);
      if (!pallet) {
        return;
      }
      let existingLoad = pallet.getLinkedRecord("load");

      //   only allow one Face per now
      if (!existingLoad) {
        existingLoad = store.create(
          `client::createLoad:${mutationId}`,
          "Cuboid"
        );
        pallet.setLinkedRecord(existingLoad, "load");
      }
      let existingLoadFaces = existingLoad.getLinkedRecords("faces");
      if (!existingLoadFaces) {
        existingLoadFaces = [];
      }

      const faceId = "client:createLoadFace:" + mutationId;
      let face = store.create(faceId, "CuboidFace");

      //   solo face is parent shape by default
      face.setLinkedRecord(existingLoad, "parentShape");
      // load only has 1 face for now
      existingLoad.setLinkedRecords([face], "faces");
    }
  });
}

export default createLoad;
