/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_LogIn_QueryVariables = {};
export type routes_LogIn_QueryResponse = {
    readonly viewer: {
        readonly isAnonymous: boolean | null;
        readonly " $fragmentRefs": FragmentRefs<"LogIn_viewer">;
    } | null;
};
export type routes_LogIn_Query = {
    readonly response: routes_LogIn_QueryResponse;
    readonly variables: routes_LogIn_QueryVariables;
};



/*
query routes_LogIn_Query {
  viewer {
    isAnonymous
    ...LogIn_viewer
    id
  }
}

fragment LogIn_viewer on Viewer {
  id
  username
  isAnonymous
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAnonymous",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routes_LogIn_Query",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "LogIn_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes_LogIn_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routes_LogIn_Query",
    "id": null,
    "text": "query routes_LogIn_Query {\n  viewer {\n    isAnonymous\n    ...LogIn_viewer\n    id\n  }\n}\n\nfragment LogIn_viewer on Viewer {\n  id\n  username\n  isAnonymous\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '88b7d0ec2092969e1d657607a7596f77';
export default node;
