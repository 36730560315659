/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SensorStreamFrameSliderIcon_frame = {
    readonly id: string;
    readonly indexInSensorStream: number;
    readonly labeledPalletsCount: number;
    readonly timestamp: string;
    readonly labelers: ReadonlyArray<{
        readonly id: string;
        readonly username: string | null;
    }>;
    readonly imageMetadata: {
        readonly id: string;
        readonly signedThumbnailUrl: string | null;
    };
    readonly " $refType": "SensorStreamFrameSliderIcon_frame";
};
export type SensorStreamFrameSliderIcon_frame$data = SensorStreamFrameSliderIcon_frame;
export type SensorStreamFrameSliderIcon_frame$key = {
    readonly " $data"?: SensorStreamFrameSliderIcon_frame$data;
    readonly " $fragmentRefs": FragmentRefs<"SensorStreamFrameSliderIcon_frame">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SensorStreamFrameSliderIcon_frame",
  "type": "Frame",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "skipImage",
      "type": "Boolean",
      "defaultValue": true
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "indexInSensorStream",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "labeledPalletsCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "timestamp",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labelers",
      "storageKey": null,
      "args": null,
      "concreteType": "LabelerUser",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "imageMetadata",
      "storageKey": null,
      "args": null,
      "concreteType": "ImageMetadata",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "signedThumbnailUrl",
          "args": [
            {
              "kind": "Variable",
              "name": "skip",
              "variableName": "skipImage"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'bebd0b6f01c8d8f32f6a10674c097860';
export default node;
