import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { RelayProp } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { QA_CATEGORIES } from "../../utils/Enums";

import { labelFramePath } from "../../utils/Paths";
import FoxLink from "../links/FoxLink";
import updateLabelingIssue from "../../mutations/UpdateLabelingIssueMutation";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";

import {
  LabelingIssueListItem_issue,
  LabelingIssueStatus
} from "../../__generated__/LabelingIssueListItem_issue.graphql";
import { Viewer } from "../common/canvas/Utils";

const localStyles = theme =>
  createStyles({
    root: {
      width: "100%"
    },
    listItemNormalPriority: {
      width: "100%"
    },
    textUnLabeled: {},
    textLabeledByViewer: {
      fontWeight: "bolder",
      fontStyle: "italic"
    },
    textLabeledBySomeoneElse: {
      fontStyle: "italic"
    },
    secondaryText: {
      whiteSpace: "pre"
    }
  });
const styles = combineStyles(localStyles, commonStyles);

interface Props extends WithStyles<typeof styles> {
  viewer: Viewer;
  relay: RelayProp;
  issue: LabelingIssueListItem_issue;
}
class LabelingIssueListItem extends React.Component<Props> {
  _updateIssueStatus(rawStatus: string) {
    const status = rawStatus as any as LabelingIssueStatus;
    const { relay, issue } = this.props;
    updateLabelingIssue(relay.environment, { issueId: issue.id, status });
  }

  render() {
    const { viewer, classes, issue } = this.props;
    const { boxes, pallets, reporter, frame, sensorStream, labeler } = issue;
    const boxesText = !!boxes.length
      ? `box(es) ${boxes.map(b => b.number).join(", ")}`
      : "";
    const palletsText = !!pallets.length
      ? `pallet(s) ${pallets.map(p => p.number).join(", ")}`
      : "";
    const showLabelsText = !!boxesText.length || !!palletsText.length;
    const labelsText = !showLabelsText
      ? ""
      : ` for ${boxesText} ${palletsText}`;
    const multiFramesLabel = !frame ? " multiple frames in" : "";
    const label = `${
      QA_CATEGORIES.get(issue.category) || ""
    }${labelsText} for${multiFramesLabel}`;
    const link = (
      <FoxLink
        underline={"always"}
        to={labelFramePath(
          sensorStream.run.runName,
          sensorStream.sensorName,
          frame ? frame.indexInSensorStream : 0,
          { showIssues: "1" }
        )}
      >
        {frame &&
          ` frame ${frame.indexInSensorStream} in ${sensorStream.run.runName}`}
        {!frame && ` ${sensorStream.run.runName}`}
      </FoxLink>
    );
    const primary = (
      <p>
        {" "}
        {label} {link}{" "}
      </p>
    );
    const secondary = `Reported by ${
      viewer.username === reporter.username ? "you" : reporter.username || ""
    } on ${labeler.username}. ${issue.description}`;
    return (
      <ListItem
        divider
        dense
        key={issue.id}
        classes={{ container: classes.root }}
      >
        <ListItemText primary={primary} secondary={secondary} />
        <ListItemSecondaryAction>
          <Select
            value={issue.status}
            onChange={e => this._updateIssueStatus(e.target.value as string)}
          >
            <MenuItem value={"NEEDS_LABELER_ATTENTION"}>
              <ListItemText primary="Needs work" />
            </MenuItem>
            <MenuItem value={"NEEDS_QA_ATTENTION"}>
              <ListItemText primary="Needs approval" />
            </MenuItem>
            <MenuItem value={"RESOLVED"}>
              <ListItemText primary="Resolved" />
            </MenuItem>
          </Select>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(LabelingIssueListItem, {
    issue: graphql`
      fragment LabelingIssueListItem_issue on LabelingIssue {
        id
        description
        category
        status
        reporter {
          username
        }
        labeler {
          username
        }
        pallets {
          number
        }
        boxes {
          number
        }
        sensorStream {
          sensorName
          run {
            runName
          }
        }
        frame {
          indexInSensorStream
        }
      }
    `
  })
);
