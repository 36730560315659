/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type PalletSurface = "FLOOR" | "FORKLIFT" | "PALLET_EMPTY" | "PALLET_LOADED" | "RACK" | "TRUCK" | "UNKNOWN" | "%future added value";
export type UpdateLabeledPalletMutationInput = {
    readonly id: string;
    readonly knownObjectType?: KnownObject | null;
    readonly surfaceType?: PalletSurface | null;
    readonly clientMutationId?: string | null;
};
export type UpdateLabeledPalletMutationVariables = {
    input: UpdateLabeledPalletMutationInput;
};
export type UpdateLabeledPalletMutationResponse = {
    readonly updateLabeledPallet: {
        readonly labeledPallet: {
            readonly id: string;
            readonly objectType: KnownObject;
            readonly surfaceType: PalletSurface;
        };
    } | null;
};
export type UpdateLabeledPalletMutation = {
    readonly response: UpdateLabeledPalletMutationResponse;
    readonly variables: UpdateLabeledPalletMutationVariables;
};



/*
mutation UpdateLabeledPalletMutation(
  $input: UpdateLabeledPalletMutationInput!
) {
  updateLabeledPallet(input: $input) {
    labeledPallet {
      id
      objectType
      surfaceType
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateLabeledPalletMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateLabeledPallet",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLabeledPalletMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "labeledPallet",
        "storageKey": null,
        "args": null,
        "concreteType": "LabeledPallet",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "objectType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "surfaceType",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateLabeledPalletMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateLabeledPalletMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateLabeledPalletMutation",
    "id": null,
    "text": "mutation UpdateLabeledPalletMutation(\n  $input: UpdateLabeledPalletMutationInput!\n) {\n  updateLabeledPallet(input: $input) {\n    labeledPallet {\n      id\n      objectType\n      surfaceType\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b6a28f70e3165a51bb06c398367d6ffd';
export default node;
