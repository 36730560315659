/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type LabelingIssueStatus = "NEEDS_LABELER_ATTENTION" | "NEEDS_QA_ATTENTION" | "RESOLVED" | "%future added value";
export type UpdateLabelingIssueMutationInput = {
    readonly issueId: string;
    readonly status: LabelingIssueStatus;
    readonly clientMutationId?: string | null;
};
export type UpdateLabelingIssueMutationVariables = {
    input: UpdateLabelingIssueMutationInput;
};
export type UpdateLabelingIssueMutationResponse = {
    readonly updateLabelingIssue: {
        readonly issue: {
            readonly id: string;
            readonly status: LabelingIssueStatus;
        } | null;
    } | null;
};
export type UpdateLabelingIssueMutation = {
    readonly response: UpdateLabelingIssueMutationResponse;
    readonly variables: UpdateLabelingIssueMutationVariables;
};



/*
mutation UpdateLabelingIssueMutation(
  $input: UpdateLabelingIssueMutationInput!
) {
  updateLabelingIssue(input: $input) {
    issue {
      id
      status
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateLabelingIssueMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateLabelingIssue",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLabelingIssueMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "issue",
        "storageKey": null,
        "args": null,
        "concreteType": "LabelingIssue",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateLabelingIssueMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateLabelingIssueMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateLabelingIssueMutation",
    "id": null,
    "text": "mutation UpdateLabelingIssueMutation(\n  $input: UpdateLabelingIssueMutationInput!\n) {\n  updateLabelingIssue(input: $input) {\n    issue {\n      id\n      status\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '18572c5e5ac8caeec4b2bc96250376c9';
export default node;
