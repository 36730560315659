/**
 * Relay's generated enum flow types are not useful for iteration. Here we
 * create iterable types for enum values.
 */

import { KnownObject } from "../__generated__/UpdateLabeledItemMutation.graphql";
import { PalletSurface } from "../__generated__/PalletDetails_pallet.graphql";
import { QACategory } from "../__generated__/CreateIssueDialog_frame.graphql";
import { FaceSide } from "../components/common/canvas/LabelMutators";

// NAMING CONVENTIONS
// if single bin    BIN_BMW   BLOCK_SKID_DIVERT BLOCK_SKID_BMW
// if multiple bins BWM_BINS  DIVERT_BINS

export const KNOWN_OBJECT_BOXES: Map<KnownObject, string> = new Map([
  ["BOX_M2", "Plain"],
  ["BOX_PLAIN_WIDE", "Plain (wide)"],
  ["BOX_ZALANDO", "Zalando"],
  ["BOX_NEWARK", "Newark"],
  ["BOX_JETPUFFED", "Jetpuffed"],
  ["BOX_LOWES_LARGE", "Lowes (large)"],
  ["BOX_LOWES_SMALL", "Lowes (small)"],
  ["BOX_BABY_OIL_WITH_ALOE", "Baby oil with aloe"],
  ["BOX_PROXY_13_13_8", "Proxy for baby oil with aloe"],
  ["BOX_BABY_OIL_WITHOUT_ALOE", "Baby oil without aloe"],
  ["BOX_PROXY_12_12_8", "Proxy for baby oil without aloe"],
  ["BOX_CONTACT_LENSES", "Contact lenses"],
  ["BOX_PROXY_10_12_7", "Proxy for contact lenses"],
  ["BOX_UNSPECIFIED", "Unknown box"]
]);
// PALLET_EURO is ommitted because we don't have any data sources with EPALs.
export const KNOWN_OBJECT_PALLETS: Map<KnownObject, string> = new Map([
  ["PALLET_GMA", "GMA stringer"],
  ["STRINGER_CANADIAN", "Canadian stringer"],
  ["PALLET_GMA_BLOCK", "GMA block"],
  ["PALLET_CP1", "Pallet CP1"],
  ["PALLET_HALF", "Half Pallet"],
  ["PALLET_CUSTOM_BD", "Custom pallet"],
  ["PALLET_UNSPECIFIED", "Unknown"]
]);
export const KNOWN_OBJECT_ITEMS: Map<KnownObject, string> = new Map([
  ["ITEM_AIRBAG", "Air bag"],
  ["ITEM_STRIPED_AIRBAG", "Striped air bag"],
  ["ITEM_LOAD_RESTRAINT", "Load restraint"],
  ["ITEM_WEATHER_GUARD_TOP", "Weather guard - Top"],
  ["ITEM_WEATHER_GUARD_LEFT", "Weather guard - Left"],
  ["ITEM_WEATHER_GUARD_RIGHT", "Weather guard - Right"],
  ["ITEM_HUMAN", "Human"],
  ["ITEM_FORKLIFT", "Forklift"]
]);

export const KNOWN_OBJECT_BMW_BINS: Map<KnownObject, string> = new Map([
  ["BIN_BMW_M1", "BMW bin M1"],
  ["BIN_BMW_M2", "BMW bin M2"],
  ["BIN_BMW_M3", "BMW bin M3"],
  ["BIN_BMW_M4", "BMW bin M4"],
  ["BIN_BMW_M5", "BMW bin M5"],
  ["BIN_BMW_M6", "BMW bin M6"],
  ["BIN_BMW_M7", "BMW bin M7"],
  ["BIN_BMW_M8", "BMW bin M8"],
  ["BIN_BMW_P1", "BMW bin P1"],
  ["BIN_BMW_P2", "BMW bin P2"]
]);

export const KNOWN_OBJECT_BINS = new Map([
  ["BIN_IBC", "IBC bin"]
]);

export const KNOWN_OBJECT_BLOCK_SKIDS: Map<KnownObject, string> = new Map([
  ["BLOCK_SKID_DIVERT", "Divert Skid"],
  ["BLOCK_SKID_DIVERT_SMALL", 'Divert Skid 36"x29"'],
  ["BLOCK_SKID_BMW", "BMW Skid"]
]);

// relevant fields from above
// boxes pallets items bins
export const KNOWN_OBJECT_ENABLED: Map<KnownObject, string> = new Map([
  ["BOX_M2", "Plain"],
  ["BOX_NEWARK", "Newark"],
  ["BOX_JETPUFFED", "Jetpuffed"],
  ["BOX_LOWES_LARGE", "Lowes (large)"],
  ["BOX_LOWES_SMALL", "Lowes (small)"],
  ["PALLET_GMA", "GMA stringer"],
  ["STRINGER_CANADIAN", "Canadian Stringer"],
  ["PALLET_GMA_BLOCK", "GMA block"],
  ["ITEM_HUMAN", "Human"],
  ["ITEM_FORKLIFT", "Forklift"],
  ["ITEM_AIRBAG", "Air bag"],
  ["ITEM_STRIPED_AIRBAG", "Striped air bag"],
  ["ITEM_LOAD_RESTRAINT", "Load restraint"],
  ["ITEM_WEATHER_GUARD_TOP", "Weather guard - Top"],
  ["ITEM_WEATHER_GUARD_LEFT", "Weather guard - Left"],
  ["ITEM_WEATHER_GUARD_RIGHT", "Weather guard - Right"],
  ["PALLET_CP1", "Pallet CP1"],
  ["BIN_BMW_M1", "BMW bin M1"],
  ["BIN_BMW_M2", "BMW bin M2"],
  ["BIN_BMW_M3", "BMW bin M3"],
  ["BIN_BMW_M4", "BMW bin M4"],
  ["BIN_BMW_M5", "BMW bin M5"],
  ["BIN_BMW_M6", "BMW bin M6"],
  ["BIN_BMW_M7", "BMW bin M7"],
  ["BIN_BMW_M8", "BMW bin M8"],
  ["BIN_BMW_P1", "BMW bin P1"],
  ["BIN_BMW_P2", "BMW bin P2"],
  ["BLOCK_SKID_DIVERT", "Divert Skid"],
  ["BLOCK_SKID_DIVERT_SMALL", 'Divert Skid 36"x29"'],
  ["BLOCK_SKID_BMW", "BMW Skid"],
  ["PALLET_HALF", "Half Pallet"],
  ["BIN_IBC", "IBC bin"],
  ["PALLET_CUSTOM_BD", "Custom pallet"],
  ["PALLET_UNSPECIFIED", "Unknown"]
]);

// relevant OBJECT from above that are picked up like pallets
export const KNOWN_OBJECT_PALLETS_BINS: Map<KnownObject, string> = new Map([
  ["PALLET_GMA", "GMA stringer"],
  ["PALLET_GMA_BLOCK", "GMA block"],
  ["STRINGER_CANADIAN", "Canadian Stringer"],
  ["PALLET_CP1", "Pallet CP1"],
  ["BIN_BMW_M1", "BMW bin M1"],
  ["BIN_BMW_M2", "BMW bin M2"],
  ["BIN_BMW_M3", "BMW bin M3"],
  ["BIN_BMW_M4", "BMW bin M4"],
  ["BIN_BMW_M5", "BMW bin M5"],
  ["BIN_BMW_M6", "BMW bin M6"],
  ["BIN_BMW_M7", "BMW bin M7"],
  ["BIN_BMW_M8", "BMW bin M8"],
  ["BIN_BMW_P1", "BMW bin P1"],
  ["BIN_BMW_P2", "BMW bin P2"],
  ["BLOCK_SKID_DIVERT", "Divert Skid"],
  ["BLOCK_SKID_DIVERT_SMALL", 'Divert Skid 36"x29"'],
  ["BLOCK_SKID_BMW", "BMW Skid"],
  ["PALLET_HALF", "Half Pallet"],
  ["BIN_IBC", "IBC bin"],
  ["PALLET_CUSTOM_BD", "Custom pallet"],
  ["PALLET_UNSPECIFIED", "Unknown"]
]);

export const PALLET_SURFACE_TYPES: Map<PalletSurface, string> = new Map([
  ["UNKNOWN", "Unknown"],
  ["FLOOR", "Floor"],
  ["PALLET_EMPTY", "Empty Pallet"],
  ["PALLET_LOADED", "Loaded Pallet"],
  ["RACK", "Rack"],
  ["FORKLIFT", "Forklift"],
  ["TRUCK", "Truck"]
]);

export const PALLET_FACE_TYPES: Map<FaceSide, string> = new Map([
  ["FRONT", "Long"],
  ["TOP", "Top"],
  ["SIDE", "Short"]
]);

interface LabelSideNames {
  front: string;
  side: string;
}

export const LABEL_SIDE_NAMES: Map<KnownObject, LabelSideNames> = new Map([
  ["PALLET_GMA", { front: "Long (Closed)", side: "Short (Open)" }],
  ["PALLET_GMA_BLOCK", { front: "Long", side: "Short" }],
  ["PALLET_CUSTOM_BD", { front: "Long (Closed)", side: "Short (Open)" }],
  ["PALLET_UNSPECIFIED", { front: "Long", side: "Short" }],
  ["BIN_BMW_M1", { front: "Long", side: "Short" }],
  ["BIN_BMW_M2", { front: "Long", side: "Short" }],
  ["BIN_BMW_M3", { front: "Long", side: "Short" }],
  ["BIN_BMW_M4", { front: "Long", side: "Short" }],
  ["BIN_BMW_M5", { front: "Long", side: "Short" }],
  ["BIN_BMW_M6", { front: "Long", side: "Short" }],
  ["BIN_BMW_M7", { front: "Long", side: "Short" }],
  ["BIN_BMW_M8", { front: "Long", side: "Short" }],
  ["BIN_BMW_P1", { front: "Long", side: "Short" }],
  ["BIN_BMW_P2", { front: "Long", side: "Short" }],
  ["BLOCK_SKID_DIVERT", { front: "Long", side: "Short" }],
  ["BLOCK_SKID_DIVERT_SMALL", { front: "Long", side: "Short" }],
  ["BLOCK_SKID_BMW", { front: "Long", side: "Short" }],
  ["PALLET_HALF", { front: "Long", side: "Short" }],
  ["PALLET_CP1", { front: "Long", side: "Short" }]
]);

export const QA_CATEGORIES: Map<QACategory, string> = new Map([
  ["PALLETS_CORNERS_ACCURATE", "Pallet corners inaccurate"],
  ["PALLET_SIDES", "Pallet short/long incorrect"],
  ["PALLET_TYPE_CORRECT", "Pallet type incorrect"],
  ["ALL_SIZEABLE_PALLETS_LABELED", "Not all pallets labeled"],
  ["BOXES_ON_PALLET_LABELED", "Unlabeled boxes on pallet"],
  ["BOXES_CORNERS_ACCURATE", "Box corners inaccurate"],
  ["CORNER_VISIBILITY_MARKED", "Corner visibility incorrect"]
]);

export interface PalletBinItem {
  id: string;
  objectType: string;
  frame: any;
}
