export function logInPath(next?: string): string {
  const suffix = next ? "?next=" + next : "";
  return "/labeler/log-in" + suffix;
}

export function signUpPath(): string {
  return "/labeler/sign-up";
}

export function dashboardPath(): string {
  return "/labeler/dashboard";
}

export function activityPath(): string {
  return "/labeler/activity";
}

export function issuesPath(): string {
  return "/labeler/issues";
}

export function reportsPath(): string {
  return "/labeler/reports";
}

export function notFound(): string {
  return "/labeler/not-found";
}

export function sensorStreamPath(
  runName: string,
  sensorName: string,
  sensorStreamIndex: number
) {
  return `/labeler/label-tool/runs/${runName}/${sensorName}/frames/${sensorStreamIndex}`;
}

export function labelFramePath(
  runName: string,
  cameraPosition: string,
  indexInSensorStream: number,
  params?: {
    showIssues?: "0" | "1";
  }
): string {
  let suffix = "";
  if (params) {
    const paramBuilder = new URLSearchParams();
    Object.keys(params).forEach(function (key) {
      params && paramBuilder.set(key, params[key] || "");
    });
    suffix = "?" + paramBuilder.toString();
  }
  return (
    "/labeler/label-tool/runs/" +
    runName +
    "/" +
    cameraPosition +
    "/frames/" +
    indexInSensorStream +
    suffix
  );
}
