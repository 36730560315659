import React from "react";
import PropTypes from "prop-types";

import { graphql, createFragmentContainer } from "react-relay";
import environment, { API_URL } from "../../createRelayEnvironment";
import logIn from "../../mutations/LogInMutation";
import { setJwt } from "../../utils/Auth";
import { dashboardPath } from "../../utils/Paths";
import { LogInMutationResponse } from "../../__generated__/LogInMutation.graphql";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";

const localStyles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  reset: {
    marginTop: theme.spacing.unit
  }
});
const styles = combineStyles(localStyles, commonStyles);

type Props = {
  classes: PropTypes.object.isRequired;
  viewer: PropTypes.object.isRequired;
};
type State = {
  password: string;
  username: string;
  userDoesNotExist: boolean;
  passwordWrong: boolean;
  requestPending: boolean;
};
class LogIn extends React.Component<Props, State> {
  state = {
    password: "",
    username: "",
    userDoesNotExist: false,
    passwordWrong: false,
    requestPending: false
  };
  _logIn() {
    const { username, password, requestPending } = this.state;
    if (requestPending || !username || !password) {
      return;
    }
    this.setState({
      requestPending: true,
      userDoesNotExist: false,
      passwordWrong: false
    });
    logIn(environment, { username, password })
      .then(
        (response: LogInMutationResponse) => {
          this.setState({ requestPending: false });
          if (!response.logIn || !response.logIn.token) {
            return;
          }
          setJwt(response.logIn.token);
          const searchParams = new URLSearchParams(window.location.search);
          window.location = searchParams.get("next") || dashboardPath();
        },
        errors => {
          this.setState({
            requestPending: false,
            userDoesNotExist: !!errors.find(e => e.code === "not-found"),
            passwordWrong: !!errors.find(e => e.code === "forbidden")
          });
        }
      )
      .catch(e => console.warn(e));
  }

  render() {
    const { classes } = this.props;
    const { userDoesNotExist, passwordWrong, requestPending } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography variant="h5">Log In</Typography>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel error={userDoesNotExist} htmlFor="username">
                  {userDoesNotExist
                    ? "User not found. Contact admin for assistance."
                    : "Username"}
                </InputLabel>
                <Input
                  onChange={e =>
                    this.setState({
                      username: e.target.value
                    })
                  }
                  error={userDoesNotExist}
                  id="username"
                  name="username"
                  onKeyDown={e => {
                    e.key === "Enter" && this._logIn();
                  }}
                  autoComplete="username"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel error={passwordWrong} htmlFor="password">
                  {passwordWrong
                    ? "Password incorrect. Contact admin for assistance."
                    : "Password"}
                </InputLabel>
                <Input
                  onChange={e =>
                    this.setState({
                      password: e.target.value
                    })
                  }
                  error={passwordWrong}
                  name="password"
                  type="password"
                  id="password"
                  onKeyDown={e => {
                    e.key === "Enter" && this._logIn();
                  }}
                  autoComplete="current-password"
                />
              </FormControl>
              <Button
                onClick={() => this._logIn()}
                fullWidth
                disabled={requestPending}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Log In
              </Button>
              <Button 
                href={`${API_URL}/accounts/password_reset`}
                className={classes.reset}
                variant="text"
                fullWidth
              >
                Reset Password
              </Button>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(LogIn, {
    viewer: graphql`
      fragment LogIn_viewer on Viewer {
        id
        username
        isAnonymous
      }
    `
  })
);
