import { commitMutation, graphql } from "react-relay";
import { Environment } from "relay-runtime";

import {
  LogInMutationInput,
  LogInMutationResponse
} from "../__generated__/LogInMutation.graphql";

const mutation = graphql`
  mutation LogInMutation($input: LogInMutationInput!) {
    logIn(input: $input) {
      username
      token
    }
  }
`;

function logIn(
  environment: Environment,
  input: LogInMutationInput
): Promise<LogInMutationResponse> {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      configs: [],
      onCompleted: (data: LogInMutationResponse | null, errors) => {
        if (data && data.logIn) {
          resolve(data);
        } else {
          reject(errors);
        }
      }
    });
  });
}

export default logIn;
