import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { DeleteLabeledBoxMutationInput } from "../__generated__/DeleteLabeledBoxMutation.graphql";

const mutation = graphql`
  mutation DeleteLabeledBoxMutation($input: DeleteLabeledBoxMutationInput!) {
    deleteLabeledBox(input: $input) {
      frame {
        id
        labeledBoxes {
          id
          pallet {
            id
            boxes {
              id
            }
          }
        }
      }
    }
  }
`;

function deleteLabeledBox(
  environment: Environment,
  input: DeleteLabeledBoxMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let label = store.get(input.id);
      if (!label) {
        return;
      }
      let existingFrame = label.getLinkedRecord("frame");
      let existingLabels = existingFrame.getLinkedRecords("labeledBoxes");
      if (!existingLabels) {
        existingLabels = [];
      }
      let newLabels = existingLabels.filter(
        l => l && l.getDataID() !== input.id
      );
      existingFrame.setLinkedRecords(newLabels, "labeledBoxes");
    }
  });
}

export default deleteLabeledBox;
