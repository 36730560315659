import React from "react";

import { createStyles, withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getSurfaceColor } from "../CanvasRenderer";

import {
  KNOWN_OBJECT_ITEMS,
  KNOWN_OBJECT_PALLETS_BINS,
  PALLET_SURFACE_TYPES
} from "../../../../utils/Enums";

import {
  KnownObject,
  PalletDetails_pallet,
  PalletSurface
} from "../../../../__generated__/PalletDetails_pallet.graphql";
import { LabelMutators } from "../LabelMutators";
import { LabelSelector } from "../SelectionState";
import { Button } from "@material-ui/core";
import { ItemDetails_item } from "../../../../__generated__/ItemDetails_item.graphql";
import { combineStyles, commonStyles } from "../../../../utils/CommonStyles";

const localStyles = theme =>
  createStyles({
    actions: {
      padding: 8,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center"
    },
    collapseWrapper: {
      border: "inset",
      borderWidth: 1
    },
    formControl: {
      margin: theme.spacing.unit,
      width: "100%",
      textTransform: "capitalize"
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      }),
      marginLeft: "auto",
      [theme.breakpoints.up("sm")]: {
        marginRight: -8
      }
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    boxList: {
      width: "100%"
    },
    cardHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 8,
      paddingRight: 8,
      minHeight: 45
    },
    headerInfo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    },
    selectStyle: {
      width: "42%",
      fontSize: 10,
      margin: "4px 8px"
    },
    menuItemFloor: {
      color: getSurfaceColor("FLOOR")
    },
    menuItemTruck: {
      color: getSurfaceColor("TRUCK")
    },
    menuItemRack: {
      color: getSurfaceColor("RACK")
    },
    menuItemForklift: {
      color: getSurfaceColor("FORKLIFT")
    },
    menuItemPalletEmpty: {
      color: getSurfaceColor("PALLET_EMPTY")
    },
    menuItemPalletLoaded: {
      color: getSurfaceColor("PALLET_LOADED")
    },
    menuItemUnknown: {
      color: getSurfaceColor("UNKNOWN")
    }
  });
const styles = combineStyles(localStyles, commonStyles);

export function ToggleButton(props: {
  message?: string;
  showContent: boolean;
  updateContent: any;
}) {
  return (
    <Button
      style={{ height: 50, width: 90, padding: "4px 8px", fontSize: "0.69rem" }}
      color={props.showContent ? "primary" : "default"}
      onClick={props.updateContent}
    >{`${
      props.showContent
        ? props.message
          ? `Hide ${props.message}`
          : `Hide`
        : props.message
        ? `Show ${props.message}`
        : `Show`
    }`}</Button>
  );
}

function SelectMenu(props: {
  updateCategory: any;
  categoryMap: Map<KnownObject | PalletSurface, string>;
  value: any;
  style: any;
  classes?: any;
}) {
  return (
    <Select
      className={props.style}
      onChange={props.updateCategory}
      variant={"outlined"}
      value={props.value}
    >
      {Array.from(props.categoryMap.entries()).map(entry => (
        <MenuItem
          key={entry[0]}
          value={entry[0]}
          classes={{
            root: (PALLET_SURFACE_TYPES.has(entry[0]) ? surfaceClassName(entry[0], props.classes) : "")
          }}
        >
          {entry[1]}
        </MenuItem>
      ))}
    </Select>
  );
}

type Props = {
  classes: any;
  pallet: PalletDetails_pallet | null;
  item: ItemDetails_item | null;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  showCorners: boolean | null;
  showPallets: boolean | null;
  showFaces: boolean | null;
  updateCorners: any;
  updateShowPallets: any;
  updateFaces: any;
};

type State = {
  expanded: boolean | null;
};

const surfaceClassName = (s: PalletSurface, classes) => {
  switch (s) {
    case "FLOOR": {
      return classes.menuItemFloor;
    }
    case "TRUCK": {
      return classes.menuItemTruck;
    }
    case "RACK": {
      return classes.menuItemRack;
    }
    case "FORKLIFT": {
      return classes.menuItemForklift;
    }
    case "PALLET_EMPTY": {
      return classes.menuItemPalletEmpty;
    }
    case "PALLET_LOADED": {
      return classes.menuItemPalletLoaded;
    }
    case "UNKNOWN": {
      return classes.menuItemUnknown;
    }
    default: {
      console.warn(`Cannot field color for label surface ${s}`);
      return "";
    }
  }
};
class LabelCardHeader extends React.Component<Props, State> {
  state = {
    expanded: null
  };

  _updatePallet(input: {
    id: string;
    knownObjectType?: KnownObject | null;
    surfaceType?: PalletSurface | null;
  }) {
    this.props.labelMutator.label.pallet.update(input);
  }

  _updateItem(input: { id: string; knownObjectType?: KnownObject | null }) {
    this.props.labelMutator.label.item.update(input);
  }

  _deletePallet(palletId: string) {
    this.props.labelMutator.label.pallet.delete(palletId);
  }
  _deleteItem(itemId: string) {
    this.props.labelMutator.label.item.delete(itemId);
  }

  renderItemHeader() {
    const {
      classes,
      item,
      labelSelector,
      showCorners,
      showPallets,
      updateCorners,
      updateShowPallets
    } = this.props;
    if (!item) {
      return null;
    }
    if (!item.objectType) {
      return null;
    }
    if (!item.shape.faces.length) {
      return null;
    }
    let { expanded } = this.state;
    if (expanded === null || expanded === undefined) {
      expanded = labelSelector.isLabelSelected(item);
    }
    const label = item.number ? (
      <Typography className={classes.number} variant={"h6"}>
        {item.number}
      </Typography>
    ) : (
      <CircularProgress size={12} thickness={4} />
    );
    return (
      <div className={classes.cardHeader}>
        {label}

        <SelectMenu
          updateCategory={e =>
            this._updateItem({
              id: item.id,
              knownObjectType: e.target.value as KnownObject
            })
          }
          categoryMap={KNOWN_OBJECT_ITEMS}
          value={item.objectType}
          style={classes.selectStyle}
        />
        <div>
          <ToggleButton
            message={"Pallets"}
            showContent={showPallets}
            updateContent={updateShowPallets}
          />
        </div>
        <div>
          <ToggleButton
            message={"Corners"}
            showContent={showCorners}
            updateContent={updateCorners}
          />
        </div>
        <Button size={"small"} onClick={() => this._deleteItem(item.id)}>
          Delete
        </Button>
      </div>
    );
  }

  renderPalletHeader() {
    const { classes, pallet, showFaces, updateFaces } =
      this.props;

    if (!pallet) {
      return null;
    }

    const label = pallet.number ? (
      <Typography variant={"h6"}>{pallet.number}</Typography>
    ) : (
      <CircularProgress size={12} thickness={4} />
    );

    return (
      <div className={classes.cardHeader}>
        <div className={classes.headerInfo}>
          {label}

          <SelectMenu
            updateCategory={e =>
              this._updatePallet({
                id: pallet.id,
                knownObjectType: e.target.value as KnownObject
              })
            }
            categoryMap={KNOWN_OBJECT_PALLETS_BINS}
            value={pallet.objectType}
            style={classes.selectStyle}
          />

          <SelectMenu
            updateCategory={e =>
              this._updatePallet({
                id: pallet.id,
                surfaceType: e.target.value as PalletSurface
              })
            }
            categoryMap={PALLET_SURFACE_TYPES}
            value={pallet.surfaceType}
            style={classes.selectStyle}
            classes={classes}
          />

          <div>
            <ToggleButton
              showContent={showFaces}
              updateContent={updateFaces}
              message={"Details"}
            />
          </div>

          <Button size={"small"} onClick={() => this._deletePallet(pallet.id)}>
            Delete
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderPalletHeader()}
        {this.renderItemHeader()}
      </div>
    );
  }
}
export default withStyles(styles)(LabelCardHeader);
