/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type PalletSurface = "FLOOR" | "FORKLIFT" | "PALLET_EMPTY" | "PALLET_LOADED" | "RACK" | "TRUCK" | "UNKNOWN" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type CreateLabeledPalletMutationInput = {
    readonly faces: ReadonlyArray<NewFaceInput>;
    readonly knownObjectType: KnownObject;
    readonly surfaceType?: PalletSurface | null;
    readonly frameId: string;
    readonly clientMutationId?: string | null;
};
export type NewFaceInput = {
    readonly side: FaceSide;
    readonly newCorners: ReadonlyArray<NewCornerInput>;
    readonly existingCorners: ReadonlyArray<string>;
};
export type NewCornerInput = {
    readonly x: number;
    readonly y: number;
    readonly visibility: Visibility;
};
export type CreateLabeledPalletMutationVariables = {
    input: CreateLabeledPalletMutationInput;
};
export type CreateLabeledPalletMutationResponse = {
    readonly createLabeledPallet: {
        readonly clientMutationId: string | null;
        readonly labeledPallet: {
            readonly id: string;
            readonly frontPockets: {
                readonly left: {
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
                readonly right: {
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
            } | null;
            readonly sidePockets: {
                readonly left: {
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
                readonly right: {
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                } | null;
            } | null;
            readonly objectType: KnownObject;
            readonly surfaceType: PalletSurface;
            readonly author: {
                readonly id: string;
                readonly username: string | null;
            };
            readonly number: number;
            readonly frame: {
                readonly id: string;
                readonly labeledPalletsCount: number;
                readonly labeledPallets: ReadonlyArray<{
                    readonly id: string;
                }>;
            };
            readonly shape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly parentShape: {
                        readonly id: string;
                        readonly faces: ReadonlyArray<{
                            readonly id: string;
                        }>;
                    };
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            };
            readonly load: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly parentShape: {
                        readonly id: string;
                        readonly faces: ReadonlyArray<{
                            readonly id: string;
                        }>;
                    };
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            } | null;
        };
    } | null;
};
export type CreateLabeledPalletMutation = {
    readonly response: CreateLabeledPalletMutationResponse;
    readonly variables: CreateLabeledPalletMutationVariables;
};



/*
mutation CreateLabeledPalletMutation(
  $input: CreateLabeledPalletMutationInput!
) {
  createLabeledPallet(input: $input) {
    clientMutationId
    labeledPallet {
      id
      frontPockets {
        left {
          corners {
            id
            x
            y
            visibility
          }
          id
        }
        right {
          corners {
            id
            x
            y
            visibility
          }
          id
        }
        id
      }
      sidePockets {
        left {
          corners {
            id
            x
            y
            visibility
          }
          id
        }
        right {
          corners {
            id
            x
            y
            visibility
          }
          id
        }
        id
      }
      objectType
      surfaceType
      author {
        id
        username
      }
      number
      frame {
        id
        labeledPalletsCount
        labeledPallets {
          id
        }
      }
      shape {
        id
        faces {
          id
          side
          parentShape {
            id
            faces {
              id
            }
          }
          corners {
            id
            x
            y
            visibility
          }
        }
      }
      load {
        id
        faces {
          id
          side
          parentShape {
            id
            faces {
              id
            }
          }
          corners {
            id
            x
            y
            visibility
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateLabeledPalletMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "x",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "y",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "visibility",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "corners",
  "storageKey": null,
  "args": null,
  "concreteType": "QuadCorner",
  "plural": true,
  "selections": (v4/*: any*/)
},
v6 = [
  (v5/*: any*/)
],
v7 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "left",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v6/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "right",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v6/*: any*/)
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "objectType",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "surfaceType",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelerUser",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number",
  "args": null,
  "storageKey": null
},
v12 = [
  (v3/*: any*/)
],
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "frame",
  "storageKey": null,
  "args": null,
  "concreteType": "Frame",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "labeledPalletsCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeledPallets",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledPallet",
      "plural": true,
      "selections": (v12/*: any*/)
    }
  ]
},
v14 = [
  (v3/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "faces",
    "storageKey": null,
    "args": null,
    "concreteType": "CuboidFace",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "side",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "parentShape",
        "storageKey": null,
        "args": null,
        "concreteType": "Cuboid",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "faces",
            "storageKey": null,
            "args": null,
            "concreteType": "CuboidFace",
            "plural": true,
            "selections": (v12/*: any*/)
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "corners",
        "storageKey": null,
        "args": null,
        "concreteType": "CuboidCorner",
        "plural": true,
        "selections": (v4/*: any*/)
      }
    ]
  }
],
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "shape",
  "storageKey": null,
  "args": null,
  "concreteType": "Cuboid",
  "plural": false,
  "selections": (v14/*: any*/)
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "load",
  "storageKey": null,
  "args": null,
  "concreteType": "Cuboid",
  "plural": false,
  "selections": (v14/*: any*/)
},
v17 = [
  (v5/*: any*/),
  (v3/*: any*/)
],
v18 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "left",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v17/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "right",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v17/*: any*/)
  },
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateLabeledPalletMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLabeledPallet",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLabeledPalletMutationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labeledPallet",
            "storageKey": null,
            "args": null,
            "concreteType": "LabeledPallet",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "frontPockets",
                "storageKey": null,
                "args": null,
                "concreteType": "PalletPocket",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "sidePockets",
                "storageKey": null,
                "args": null,
                "concreteType": "PalletPocket",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateLabeledPalletMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLabeledPallet",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLabeledPalletMutationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labeledPallet",
            "storageKey": null,
            "args": null,
            "concreteType": "LabeledPallet",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "frontPockets",
                "storageKey": null,
                "args": null,
                "concreteType": "PalletPocket",
                "plural": false,
                "selections": (v18/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "sidePockets",
                "storageKey": null,
                "args": null,
                "concreteType": "PalletPocket",
                "plural": false,
                "selections": (v18/*: any*/)
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateLabeledPalletMutation",
    "id": null,
    "text": "mutation CreateLabeledPalletMutation(\n  $input: CreateLabeledPalletMutationInput!\n) {\n  createLabeledPallet(input: $input) {\n    clientMutationId\n    labeledPallet {\n      id\n      frontPockets {\n        left {\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n          id\n        }\n        right {\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n          id\n        }\n        id\n      }\n      sidePockets {\n        left {\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n          id\n        }\n        right {\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n          id\n        }\n        id\n      }\n      objectType\n      surfaceType\n      author {\n        id\n        username\n      }\n      number\n      frame {\n        id\n        labeledPalletsCount\n        labeledPallets {\n          id\n        }\n      }\n      shape {\n        id\n        faces {\n          id\n          side\n          parentShape {\n            id\n            faces {\n              id\n            }\n          }\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n      }\n      load {\n        id\n        faces {\n          id\n          side\n          parentShape {\n            id\n            faces {\n              id\n            }\n          }\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4ec1f8c99eca3b141a7972ed2b3c2c18';
export default node;
