import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import scrollToComponent from "react-scroll-to-component";

import { KNOWN_OBJECT_PALLETS_BINS } from "../../../../utils/Enums";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import { LabelSelector } from "../SelectionState";
import { LabelMutators } from "../LabelMutators";
import { TopLevelLabelActions } from "../../../label_frame/SensorStreamWrapper";
import PalletSideBar from "./PalletSideBar";
import ItemSideBar from "./ItemSideBar";

import { SideBar_frame } from "../../../../__generated__/SideBar_frame.graphql";
import { Viewer } from "../Utils";
import { combineStyles, commonStyles } from "../../../../utils/CommonStyles";
import LabelCreationDetails from "./LabelCreationDetails";

const localStyles = theme =>
  createStyles({
    faceLists: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start"
    },
    selectWrapper: {
      background: "lightgray",
      display: "flex",
      flexDirection: "column",
      margin: 0,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: 4
    },
    tabs: {
      border: "1px solid rgba(0, 0, 0, 0.23)"
    },
    openButton: {
      position: "absolute",
      right: 32,
      paddingTop: 16
    },
    drawerPaper: {
      position: "relative",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      marginBottom: "65px"
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 8,
      flexDirection: "row",
      position: "relative"
    },
    drawer: {
      height: "fit-content",
      marginBottom: 360
    },
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    pickers: {
      background: "lightgray",
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "nowrap",
      margin: 0,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: 4
    },
    formControlSize: {
      margin: theme.spacing.unit,
      minWidth: 120,
      maxWidth: 300
    }
  });
const styles = combineStyles(localStyles, commonStyles);

type Props = {
  classes: any;
  frame: SideBar_frame;
  viewer: Viewer;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  knownObjectPoseId: string | null;
  setKnownObjectPoseId: (arg0: string | null) => void;
  setLabelerUsernames: (arg0: Set<string>) => void;
  labelerUsernames: Set<string>;
  topLevelLabelActions: TopLevelLabelActions;
};
type State = {
  // lastScrollToBoxId is set when the BoxDetails list auto-scrolls to a box on
  // box selection. The value is used to ensure that the list only auto-scrolls
  // once per box selection.
  lastScrollToBoxId: string | null;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export function knownObjectLabel(
  knownObject: KnownObject,
  targetState: TargetState
) {
  const targeted = targetState === "TARGETED";
  if (knownObject.startsWith("BOX_")) {
    return targeted ? "Target box" : "Background box";
  } else if (KNOWN_OBJECT_PALLETS_BINS.get(knownObject) !== undefined) {
    return targeted ? "Target pallet" : "Background pallet";
  }
  return targeted ? "Targeted object" : "Background object";
}
class SideBar extends React.Component<Props, State> {
  state = {
    lastScrollToBoxId: null
  };

  lastUpdateDate: Date | null;
  lastUpdateTimeoutId: number | null;

  constructor(props) {
    super(props);
    this.lastUpdateDate = null;
    this.lastUpdateTimeoutId = null;
  }

  shouldComponentUpdate() {
    // The sidebar's props can change very frequently when a user is dragging a
    // corner, which would normally force the SideBar to re-render with each
    // MouseMove event. The SideBar takes a non-trivial amount of time to
    // render, so rendering at each MouseMove event slows down the whole app,
    // making the corner drag feel sluggish.
    //
    // To account for this, only accept updates if the sidebar has not been
    // updated recently. If the sidebar has been updated recently, schedule a
    // future update with setTimeout.
    const waitToUpdateMs = 250;
    const { lastUpdateDate, lastUpdateTimeoutId } = this;
    // If the SideBar hasn't been updated, update.
    if (!lastUpdateDate) {
      return true;
    }
    // If there is already an update timeout, skip this update and wait for the
    // pending update.
    if (lastUpdateTimeoutId !== null) {
      return false;
    }
    // If the last update was a while ago, update now.
    const msSinceLastUpdate = new Date().valueOf() - lastUpdateDate.valueOf();
    const updateNow = msSinceLastUpdate > waitToUpdateMs;
    if (updateNow) {
      return true;
    }
    // Schedule a future update.
    this.lastUpdateTimeoutId = setTimeout(
      () => this.forceUpdate(),
      waitToUpdateMs
    );
    return false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { labelSelector } = this.props;
    this.lastUpdateDate = new Date();
    this.lastUpdateTimeoutId = null;
    if (!labelSelector.selectedLabelIds().length) {
      return;
    }
    const { lastScrollToBoxId } = this.state;
    const selectedLabelId = labelSelector.selectedLabelIds()[0];
    if (selectedLabelId && lastScrollToBoxId !== selectedLabelId) {
      // If the ref does not exist (e.g. because the selected label is a pallet)
      // then this scroll is a no-op.
      scrollToComponent(this.refs[selectedLabelId], {
        align: "top",
        duration: 500,
        offset: -80
      });
      this.setState({ lastScrollToBoxId: selectedLabelId });
    }
  }

  render() {
    const {
      classes,
      frame,
      labelMutator,
      labelSelector,
      topLevelLabelActions,
      viewer,
      knownObjectPoseId,
      setKnownObjectPoseId,
      labelerUsernames,
      setLabelerUsernames
    } = this.props;
    const { sensorStream } = frame;
    const allFrameLabelerUsernames = frame.labeledPallets
      .map(label => label.author.username)
      .concat(frame.labeledItems.map(label => label.author.username)) // Use filter to remove duplicates
      .filter((username, index, self) => self.indexOf(username) === index);
    if (allFrameLabelerUsernames.indexOf(viewer.username) === -1) {
      allFrameLabelerUsernames.push(viewer.username);
    }

    const { creationLabelCategory, setCreationLabelCategory } =
      topLevelLabelActions;

    const toShortName = (username: string) => {
      const labeler = sensorStream.labelers.find(l => l.username === username);
      if (!labeler) {
        return username;
      }
      return labeler.shortName;
    };
    const palletSideBar =
      creationLabelCategory === "PALLET" ? (
        <PalletSideBar
          labelMutator={labelMutator}
          labelSelector={labelSelector}
          topLevelLabelActions={topLevelLabelActions}
          labelerUsernames={labelerUsernames}
          frame={frame}
          viewer={viewer}
        />
      ) : null;
    const itemSideBar =
      creationLabelCategory === "ITEM" ? (
        <ItemSideBar
          labelMutator={labelMutator}
          labelSelector={labelSelector}
          topLevelLabelActions={topLevelLabelActions}
          labelerUsernames={labelerUsernames}
          frame={frame}
          viewer={viewer}
        />
      ) : null;

    return (
      <React.Fragment>
        <Drawer
          variant="persistent"
          anchor={"right"}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}
          open={true}
        >
          <AppBar className={classes.drawerHeader} color="default">
            <Typography>Frame {frame.indexInSensorStream} Details</Typography>
          </AppBar>
          <div className={classes.pickers}>
            <FormControl className={classes.formControlSize}>
              <InputLabel htmlFor="select-view">View</InputLabel>
              <Select
                value={knownObjectPoseId || "raw"}
                input={<Input id="select-view" />}
                onChange={event => {
                  const newValue =
                    event.target.value === "raw" ? null : event.target.value;
                  setKnownObjectPoseId(newValue);
                }}
              >
                {frame.knownObjectPoses
                  .filter(pose => this._poseNotEmpty(pose))
                  .map(pose => {
                    return (
                      <MenuItem key={pose.id} value={pose.id}>
                        {knownObjectLabel(pose.knownObject, pose.targetState)}
                      </MenuItem>
                    );
                  })}
                <MenuItem value="raw">Raw image</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControlSize}>
              <InputLabel htmlFor="select-labelers-checkbox">
                Labelers
              </InputLabel>
              <Select
                multiple
                disabled={allFrameLabelerUsernames.length === 0}
                value={[...labelerUsernames]}
                onChange={e => setLabelerUsernames(e.target.value)}
                input={<Input id="select-labelers-checkbox" />}
                renderValue={selected =>
                  labelerUsernames.size === 0
                    ? "No labelers"
                    : selected
                        .map(username =>
                          username === viewer.username
                            ? "You"
                            : toShortName(username)
                        )
                        .join(", ")
                }
                MenuProps={MenuProps}
              >
                {sensorStream.labelers.map(labeler => (
                  <MenuItem
                    disabled={
                      !allFrameLabelerUsernames.find(
                        u => u === labeler.username
                      )
                    }
                    key={labeler.id}
                    value={labeler.username}
                  >
                    <ListItemText
                      primary={
                        labeler.username === viewer.username
                          ? "You"
                          : labeler.shortName
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <AppBar color={"default"} position={"relative"}>
            <Tabs
              value={creationLabelCategory}
              onChange={(_, value) => setCreationLabelCategory(value)}
            >
              <Tab label={"Pallets"} value={"PALLET"}></Tab>
              <Tab label={"Other Items"} value={"ITEM"}></Tab>{" "}
            </Tabs>
          </AppBar>
          <LabelCreationDetails topLevelLabelActions={topLevelLabelActions} />
          {palletSideBar}
          {itemSideBar}
        </Drawer>
      </React.Fragment>
    );
  }

  _poseNotEmpty = pose => {
    const { orientation, position } = pose;
    return (
      orientation.w !== 1 ||
      orientation.x ||
      orientation.y ||
      orientation.z ||
      position.x ||
      position.y ||
      position.z
    );
  };
}

export default withStyles(styles)(
  createFragmentContainer(SideBar, {
    frame: graphql`
      fragment SideBar_frame on Frame {
        knownObjectPoses {
          id
          knownObject
          targetState
          orientation {
            w
            x
            y
            z
          }
          position {
            x
            y
            z
          }
        }
        ...PalletSideBar_frame
        ...ItemSideBar_frame
        id
        indexInSensorStream
        sensorStream {
          labelers {
            id
            username
            shortName
          }
        }
        labeledPallets {
          ...PalletDetails_pallet
          id
          number
          author {
            id
            username
          }
        }
        labeledItems {
          ...ItemDetails_item
          id
          number
          author {
            id
            username
          }
        }
      }
    `
  })
);
