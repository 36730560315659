/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type TargetState = "NA" | "NOT_TARGETED" | "TARGETED" | "UNKNOWN" | "%future added value";
export type SideBar_frame = {
    readonly knownObjectPoses: ReadonlyArray<{
        readonly id: string;
        readonly knownObject: KnownObject;
        readonly targetState: TargetState;
        readonly orientation: {
            readonly w: number;
            readonly x: number;
            readonly y: number;
            readonly z: number;
        };
        readonly position: {
            readonly x: number;
            readonly y: number;
            readonly z: number;
        };
    }>;
    readonly id: string;
    readonly indexInSensorStream: number;
    readonly sensorStream: {
        readonly labelers: ReadonlyArray<{
            readonly id: string;
            readonly username: string | null;
            readonly shortName: string | null;
        }>;
    };
    readonly labeledPallets: ReadonlyArray<{
        readonly id: string;
        readonly number: number;
        readonly author: {
            readonly id: string;
            readonly username: string | null;
        };
        readonly " $fragmentRefs": FragmentRefs<"PalletDetails_pallet">;
    }>;
    readonly labeledItems: ReadonlyArray<{
        readonly id: string;
        readonly number: number;
        readonly author: {
            readonly id: string;
            readonly username: string | null;
        };
        readonly " $fragmentRefs": FragmentRefs<"ItemDetails_item">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"PalletSideBar_frame" | "ItemSideBar_frame">;
    readonly " $refType": "SideBar_frame";
};
export type SideBar_frame$data = SideBar_frame;
export type SideBar_frame$key = {
    readonly " $data"?: SideBar_frame$data;
    readonly " $fragmentRefs": FragmentRefs<"SideBar_frame">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "x",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "y",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "z",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelerUser",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/)
  ]
};
return {
  "kind": "Fragment",
  "name": "SideBar_frame",
  "type": "Frame",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "knownObjectPoses",
      "storageKey": null,
      "args": null,
      "concreteType": "KnownObjectPose",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "knownObject",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "targetState",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orientation",
          "storageKey": null,
          "args": null,
          "concreteType": "Quaternion",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "w",
              "args": null,
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "position",
          "storageKey": null,
          "args": null,
          "concreteType": "Vector3",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ]
        }
      ]
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "indexInSensorStream",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sensorStream",
      "storageKey": null,
      "args": null,
      "concreteType": "SensorStream",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labelers",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "shortName",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeledPallets",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledPallet",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "kind": "FragmentSpread",
          "name": "PalletDetails_pallet",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeledItems",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledItem",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "kind": "FragmentSpread",
          "name": "ItemDetails_item",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "PalletSideBar_frame",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ItemSideBar_frame",
      "args": null
    }
  ]
};
})();
(node as any).hash = '13cd98cc4924626c45c336069b4becb0';
export default node;
