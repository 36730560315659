interface HasId {
  readonly id: string;
}

export enum LabelCategory {
  BOX = "BOX",
  PALLET = "PALLET",
  ITEM = "ITEM"
}

export interface LabelSelector {
  selectFace(face: HasId | null, preserveExisting?: boolean): void;
  selectCorner(corner: HasId | null, preserveExisting?: boolean): void;
  hoverLabel(label: HasId, preserveExisting?: boolean): void;
  hoverFace(face: HasId, preserveExisting?: boolean): void;
  hoverCorner(corner: HasId, preserveExisting?: boolean): void;
  clearSelections(): void;
  clearHovers(): void;
  isFaceSelected(face: HasId): boolean;
  isFaceHovered(face: HasId): boolean;
  isCornerSelected(corner: HasId): boolean;
  isCornerHovered(corner: HasId): boolean;
  isLabelSelected(label: HasId): boolean;
  selectedFaceIds(): Array<string>;
  selectedCornerIds(): Array<string>;
  selectedLabelIds(): Array<string>;
  isAnyLabelBeingCreated(): boolean;
  isFaceBeingCreated(face: HasId): boolean;
  isLabelBeingCreated(label: HasId): boolean;
  currentCreationLabelId(): string | null;
  currentCreationFaceId(): string | null;
  currentCreationPocketDetails(): {
    isFrontFace: boolean;
    isLeftPocket: boolean;
  } | null;
  startCreatingPalletPocket(faceId: string, isLeftPocket: boolean): void;
}
