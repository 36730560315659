import { graphql } from "react-relay";
import { Environment } from "react-relay";
import {
  commitLocalUpdate,
  commitMutation,
  PayloadError,
  RecordProxy
} from "relay-runtime";
import { Vector2 } from "three";
import { Corner, LabelFace } from "../components/common/canvas/LabelMutators";

import {
  CreateCornerMutationInput,
  CreateCornerMutationResponse
} from "../__generated__/CreateCornerMutation.graphql";

// Winds corners CCW. This winding is transient, unlike winding performed by the
// backend.
export function windCorners(
  unwoundCorners: ReadonlyArray<RecordProxy>
): Array<RecordProxy> {
  if (unwoundCorners.length < 4) {
    return Array.from(unwoundCorners);
  }
  let corners = unwoundCorners.concat();
  let orderings: number[][] = [
    [0, 1, 2, 3],
    [0, 1, 3, 2],
    [0, 2, 1, 3],
    [0, 2, 3, 1],
    [0, 3, 1, 2],
    [0, 3, 2, 1]
  ];

  function vector2(corner: RecordProxy | null): Vector2 {
    if (!corner) {
      return new Vector2();
    }
    return new Vector2(corner.getValue("x"), corner.getValue("y"));
  }

  // Consider six possible orderings of v1..v4. The ordering is correct iff:
  //   (v2-v1) x (v3-v1) < 0
  //   (v3-v1) x (v4-v1) < 0
  for (let i of [0, 1, 2, 3, 4, 5]) {
    const ordering: number[] = orderings[i];
    const c1: RecordProxy | null = corners[ordering[0]];
    const c2: RecordProxy | null = corners[ordering[1]];
    const c3: RecordProxy | null = corners[ordering[2]];
    const c4: RecordProxy | null = corners[ordering[3]];

    const cross1 = vector2(c2)
      .sub(vector2(c1))
      .cross(vector2(c3).sub(vector2(c1)));
    const cross2 = vector2(c3)
      .sub(vector2(c1))
      .cross(vector2(c4).sub(vector2(c1)));

    if (cross1 < 0 && cross2 < 0) {
      corners[0] = c1;
      corners[1] = c2;
      corners[2] = c3;
      corners[3] = c4;
      break;
    }
  }
  return corners;
}

const mutation = graphql`
  mutation CreateCornerMutation($input: CreateCornerMutationInput!) {
    createCorner(input: $input) {
      corner {
        cuboid {
          id
        }
        id
        x
        y
        visibility
      }
      face {
        id
        side
        corners {
          id
          x
          y
          visibility
        }
      }
      label {
        ... on LabeledBox {
          id
        }
        ... on LabeledPallet {
          id
        }
      }
    }
  }
`;

let tempID = 0;
const localId = "client:createCorner:";
function createCorner(
  environment: Environment,
  input: CreateCornerMutationInput,
  commit: boolean = true,
  face: LabelFace,
  labelId: string,
  onCompleted: (
    response: CreateCornerMutationResponse | null,
    errors: Array<PayloadError> | null
  ) => void | null,
  onError?: (error: Error) => void
) {
  if (!commit) {
    commitLocalUpdate(environment, store => {
      let faceRecord = store.get(input.faceId);
      if (!faceRecord) {
        return;
      }
      let existingCornerRecords = faceRecord.getLinkedRecords("corners");
      let cuboid = faceRecord.getLinkedRecord("parentShape");
      if (!existingCornerRecords) {
        existingCornerRecords = [];
      }
      let mutationId: string = (tempID++).toString();
      const cornerId = localId + mutationId;
      let cornerRecord = store.create(cornerId, "CuboidCorner");
      cornerRecord.setValue(cornerId, "id");
      cornerRecord.setValue(input.x, "x");
      cornerRecord.setValue(input.y, "y");
      cornerRecord.setValue(input.visibility, "visibility");
      // Wind the corners. The backend also wind corners on corner creation, and
      // it is the backend's winding which really matters. Corners are wound
      // here only to remove jitters in the UI.
      const newCornerRecords: Array<RecordProxy> = windCorners([
        ...existingCornerRecords,
        cornerRecord
      ]);
      faceRecord.setLinkedRecords(newCornerRecords, "corners");

      const newCorner: Corner = {
        id: cornerId,
        x: input.x,
        y: input.y,
        visibility: input.visibility
      };
      const parentId = cuboid.getDataID();

      onCompleted(
        {
          createCorner: {
            corner: {
              cuboid: {
                id: parentId
              },
              ...newCorner
            },
            face: face,
            label: {
              id: labelId
            }
          }
        },
        []
      );
    });
    return;
  }

  // update face messes up the objects on backend. just commit the  corner

  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let face = store.get(input.faceId);
      if (!face) {
        return;
      }
      let existingCorners = face.getLinkedRecords("corners");
      if (!existingCorners) {
        existingCorners = [];
      }
      let mutationId: string = (tempID++).toString();
      const cornerId = "client:createCorner:" + mutationId;
      let corner = store.create(cornerId, "CuboidCorner");
      corner.setValue(cornerId, "id");
      corner.setValue(input.x, "x");
      corner.setValue(input.y, "y");
      corner.setValue(input.visibility, "visibility");
      // Wind the corners. The backend also wind corners on corner creation, and
      // it is the backend's winding which really matters. Corners are wound
      // here only to remove jitters in the UI.
      const newCorners: Array<RecordProxy> = windCorners([
        ...existingCorners,
        corner
      ]);
      face.setLinkedRecords(newCorners, "corners");
    }
  });
}

export default createCorner;
