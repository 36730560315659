/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelingReportTable_viewer = {
    readonly id: string;
    readonly labelerOverviews: ReadonlyArray<{
        readonly labeler: {
            readonly username: string | null;
        } | null;
        readonly numPallets: number;
        readonly numItems: number;
        readonly numLoads: number | null;
        readonly numBins: number | null;
        readonly numLabels: number;
        readonly numIssues: number;
        readonly numOpenIssues: number;
        readonly numPockets: number;
    }>;
    readonly " $refType": "LabelingReportTable_viewer";
};
export type LabelingReportTable_viewer$data = LabelingReportTable_viewer;
export type LabelingReportTable_viewer$key = {
    readonly " $data"?: LabelingReportTable_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"LabelingReportTable_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "LabelingReportTable_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "startTime",
      "type": "DateTime",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "duration",
      "type": "BucketDuration",
      "defaultValue": "WEEK"
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labelerOverviews",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "duration",
          "variableName": "duration"
        },
        {
          "kind": "Variable",
          "name": "startTime",
          "variableName": "startTime"
        }
      ],
      "concreteType": "LabelerOverview",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeler",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "username",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numPallets",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numItems",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numLoads",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numBins",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numLabels",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numIssues",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numOpenIssues",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numPockets",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '674ad157e0680e1fcbfbef8175d21e00';
export default node;
