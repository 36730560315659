/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type CreateCornerMutationInput = {
    readonly x: number;
    readonly y: number;
    readonly visibility: Visibility;
    readonly faceId: string;
    readonly clientMutationId?: string | null;
};
export type CreateCornerMutationVariables = {
    input: CreateCornerMutationInput;
};
export type CreateCornerMutationResponse = {
    readonly createCorner: {
        readonly corner: {
            readonly cuboid: {
                readonly id: string;
            };
            readonly id: string;
            readonly x: number;
            readonly y: number;
            readonly visibility: Visibility;
        };
        readonly face: {
            readonly id: string;
            readonly side: FaceSide;
            readonly corners: ReadonlyArray<{
                readonly id: string;
                readonly x: number;
                readonly y: number;
                readonly visibility: Visibility;
            }>;
        };
        readonly label: {
            readonly id?: string;
        };
    } | null;
};
export type CreateCornerMutation = {
    readonly response: CreateCornerMutationResponse;
    readonly variables: CreateCornerMutationVariables;
};



/*
mutation CreateCornerMutation(
  $input: CreateCornerMutationInput!
) {
  createCorner(input: $input) {
    corner {
      cuboid {
        id
      }
      id
      x
      y
      visibility
    }
    face {
      id
      side
      corners {
        id
        x
        y
        visibility
      }
    }
    label {
      __typename
      ... on LabeledBox {
        id
      }
      ... on LabeledPallet {
        id
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCornerMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "x",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "y",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "visibility",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "corner",
  "storageKey": null,
  "args": null,
  "concreteType": "CuboidCorner",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cuboid",
      "storageKey": null,
      "args": null,
      "concreteType": "Cuboid",
      "plural": false,
      "selections": (v3/*: any*/)
    },
    (v2/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ]
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "face",
  "storageKey": null,
  "args": null,
  "concreteType": "CuboidFace",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "side",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "corners",
      "storageKey": null,
      "args": null,
      "concreteType": "CuboidCorner",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/)
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCornerMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createCorner",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCornerMutationPayload",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "label",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "LabeledBox",
                "selections": (v3/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "LabeledPallet",
                "selections": (v3/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCornerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createCorner",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCornerMutationPayload",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "label",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCornerMutation",
    "id": null,
    "text": "mutation CreateCornerMutation(\n  $input: CreateCornerMutationInput!\n) {\n  createCorner(input: $input) {\n    corner {\n      cuboid {\n        id\n      }\n      id\n      x\n      y\n      visibility\n    }\n    face {\n      id\n      side\n      corners {\n        id\n        x\n        y\n        visibility\n      }\n    }\n    label {\n      __typename\n      ... on LabeledBox {\n        id\n      }\n      ... on LabeledPallet {\n        id\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ccf2e1195d64a4d305257a43625c7aba';
export default node;
