import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

import { LabelingReport_viewer } from "../../__generated__/LabelingReport_viewer.graphql";
import { BucketDuration } from "../../__generated__/LabelingReportTableRefetchQuery.graphql";
import {
  getDurationAfter,
  getDurationBefore
} from "../../utils/CommonFunctions";
import LabelingReportTable from "./LabelingReportTable";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";
const ALL_TIME_DURATION = "all-time";

const localStyles = theme =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing.unit * 2
    },
    infoBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    spaced: {
      marginLeft: 48
    }
  });

const styles = combineStyles(localStyles, commonStyles);

interface Props extends WithStyles<typeof styles> {
  viewer: LabelingReport_viewer;
}
type State = {
  startTime: Date | null;
  duration: BucketDuration;
};

const defaultTime = new Date(
  new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0)
);
class LabelingReport extends React.Component<Props, State> {
  state = {
    startTime: defaultTime,
    duration: "WEEK" as BucketDuration,
    labelWidth: 0
  };

  _updateDuration(durationSelectValue: string) {
    const { startTime: rawStartTime, duration: rawDuration } = this.state;
    const duration: BucketDuration | null =
      durationSelectValue === ALL_TIME_DURATION
        ? null
        : (durationSelectValue as BucketDuration);
    // if duration changed, reset time with respect to TODAY
    const startTime: Date | null =
      duration === rawDuration
        ? rawStartTime
        : getDurationBefore(duration, new Date());

    this.setState({ duration, startTime });
  }

  render() {
    const { classes, viewer } = this.props;
    const { duration, startTime } = this.state;
    const showToggler = !!duration;
    const endTime = getDurationAfter(duration, new Date(startTime));
    return (
      <div className={classes.marginWrapper}>
        <div className={classes.infoBar}>
          <Typography variant="h4" gutterBottom>
            Labeling Report
          </Typography>
          {/* Date Filter */}
          <FormControl variant="outlined" className={classes.formControlSize}>
            <InputLabel htmlFor="labeling-report-input">Type</InputLabel>
            <Select
              value={duration || ALL_TIME_DURATION}
              onChange={e =>
                this._updateDuration(e.target.value as BucketDuration)
              }
              input={
                <OutlinedInput
                  labelWidth={32}
                  name="age"
                  id="labeling-report-input"
                />
              }
            >
              <MenuItem value={ALL_TIME_DURATION}>All time</MenuItem>
              <MenuItem value={"WEEK"}>Weekly</MenuItem>
              <MenuItem value={"MONTH"}>Monthly</MenuItem>
              <MenuItem value={"DAY"}>Daily</MenuItem>
              <MenuItem value={"YEAR"}>Yearly</MenuItem>
            </Select>
          </FormControl>
          {showToggler && (
            <React.Fragment>
              <TextField
                onChange={e => {
                  this.setState({
                    startTime: e.target.value ? new Date(e.target.value) : null
                  });
                }}
                type={"datetime-local"}
                value={moment(startTime).format("YYYY-MM-DDTHH:mm")}
                helperText={"Starting from"}
              />
              <TextField
                disabled={true}
                className={classes.spaced}
                type={"datetime-local"}
                value={moment(endTime).format("YYYY-MM-DDTHH:mm")}
                helperText={"Ending at"}
              />
            </React.Fragment>
          )}
        </div>
        <LabelingReportTable
          viewer={viewer}
          duration={duration}
          startTime={startTime}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(LabelingReport, {
    viewer: graphql`
      fragment LabelingReport_viewer on Viewer {
        id
        ...LabelingReportTable_viewer
      }
    `
  })
);
