import { Environment, Network, RecordSource, Store } from "relay-runtime";

import { getJwt } from "./utils/Auth";

export const API_URL = process.env.GRAPHQL_HOST || "/labeler/api";
const GRAPHQL_URL = `${API_URL}/graphql`;
const PRINT_ERR = process.env.NODE_ENV === "development";

function fetchQuery(operation, variables) {
  let headers = { "Content-Type": "application/json" };
  if (getJwt()) {
    headers["Authorization"] = "Bearer " + getJwt();
  }
  return fetch(GRAPHQL_URL, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query: operation.text,
      operationName: operation.name,
      variables
    })
  })
    .then(response => {
      return response.json();
    })
    .then(parsed => {
      if (parsed.errors && PRINT_ERR) {
        console.log("errors:", parsed.errors);
      }
      return parsed;
    })
    .catch(reason => {
      if (PRINT_ERR) {
        console.log("failure reason", reason);
      }
    });
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource())
});

export default environment;
