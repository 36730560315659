import { Resolver } from "found-relay";
import React from "react";
import ReactDOM from "react-dom";

import environment from "./createRelayEnvironment";
import Router from "./routes";

import "ctx-polyfill";

ReactDOM.render(
  <Router resolver={new Resolver(environment)} />,
  document.getElementById("root")
);
