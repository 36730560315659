import { Theme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export const commonColors = {
  floor: "#E6DB74", // yellow
  truck: "#AE81FF", // violet
  rack: "#F92672", // pink
  forklift: "#A1EFE4", // cyan
  item: "#FD971F", // orange
  palletLoaded: "saddlebrown",
  unknown: "black",
  sideColor: "#66D9EF", // blue
  frontColor: "#A6E22E", // green
  palletLabeled: "darkorange",
  box: "red",
  foxbotsOrange: "#E55C20",
  foxbotsYellow: "#F7941D",
  foxbotsHighlight: "#FFFACC",
  foxbotsBlack: "#302E2C",
  labelerGreen: "#B5D6B2",
  grey: "#EBEBEB"
};

export const commonStyles = (theme: Theme) => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    },
    minWidth: 100,
    maxWidth: 150
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  },
  appBar: {},
  wrapper: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    margin: 32
  },
  marginWrapper: {
    margin: 48
  },
  toolbarRoot: {
    flexWrap: "wrap"
  },
  accordionOpen: {},
  accordionClosed: { display: "none" },
  expandIcon: {
    background: "none",
    padding: "none !important",
    right: 12
  },
  selected: {
    backgroundColor: fade("#ff9800", 0.25)
  },
  unselected: {},
  flexGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    flexWrap: "wrap"
  },
  verticalScroll: {
    overflowX: "auto"
  },
  listItemHighPriority: {
    backgroundColor: theme.palette.primary[50],
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary[50]
    }
  },
  listItemNormalPriority: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  statusReady: {
    backgroundColor: commonColors.labelerGreen,
    boxShadow: `1px 1px  ${commonColors.grey}`,
    color: "grey"
  },
  statusNotReady: {
    boxShadow: `1px 1px  ${commonColors.grey}`,
    backgroundColor: commonColors.grey,
    color: "grey"
  },
  filterButton: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    margin: theme.spacing.unit,
    fontSize: 10,
    borderRadius: 10
  },
  filterButtonActive: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    margin: theme.spacing.unit,
    fontSize: 10,
    backgroundColor: commonColors.grey,
    borderRadius: 10,
    boxShadow: `inset 2px 2px 5px -5px  ${commonColors.foxbotsBlack}`
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
    textTransform: "capitalize"
  },
  formControlSize: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300
  },
  stickyButton: {
    position: "sticky",
    width: "auto",
    right: 0
  },
  // BUBBLE ITEMS FOR LISTING
  bubble: {
    display: "flex",
    borderRadius: 3,
    margin: "1px",
    padding: "5px",
    textAlign: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#fbfcfc"
  },
  bubbleHighPriority: {
    display: "flex",
    borderRadius: 3,
    margin: "1px",
    padding: "5px",
    textAlign: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#f9f2e6"
  },
  bubbleFlag: {
    backgroundColor: "#F4F5F7",
    borderBottom: "1px solid #ecf1f1",
    borderRadius: 5,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 2,
    marginTop: 2
  },
  bubbleFlagHighPriority: {
    backgroundColor: "#ffe9c7",
    borderBottom: "1px solid #ecf1f1",
    borderRadius: 5,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 2,
    marginTop: 2
  },
  bubbleItem: {
    display: "flex",
    flex: 15,
    flexWrap: "wrap"
  },
  bubbleTitle: {
    letterSpacing: 1.5,
    textAlign: "end",
    width: "inherit",
    flex: 2
  },
  bubbleText: {
    display: "inline-block",
    textAlign: "center",
    width: 50
  },

  card: {
    minWidth: 380,
    width: "100%"
  },
  cardExpanded: {
    minWidth: 380,
    width: "100%",
    border: "2px solid rgba(255, 152, 0, 0.63)",
    background: theme.palette.background.default,
    borderRadius: 4
  },
  actions: {
    padding: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  },
  collapseWrapper: {
    border: "inset",
    borderWidth: 1
  },
  cornerList: {
    width: "100%"
  },

  listItemRoot: {
    padding: "0px 4px",
    paddingLeft: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 38
  }
});

export function combineStyles(...allStyles: any[]) {
  return function CombineStyles(theme: any) {
    let firstStyle = allStyles[0];
    if (typeof firstStyle === "function") {
      // apply theme
      const themedStyle = firstStyle(theme);
      firstStyle = themedStyle;
    }

    for (let i = 1; i < allStyles.length; i++) {
      let objectToMerge = allStyles[i];
      if (typeof objectToMerge === "function") {
        // apply theme
        objectToMerge = objectToMerge(theme);
      }
      // combine by key
      let keys = Object.keys(objectToMerge);
      keys.forEach(key => {
        if (firstStyle.hasOwnProperty(key)) {
          firstStyle[key] = { ...firstStyle[key], ...objectToMerge[key] };
        } else {
          firstStyle[key] = objectToMerge[key];
        }
      });
    }
    return firstStyle;
  };
}
