import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { commitLocalUpdate, PayloadError } from "relay-runtime";

import {
  CreatePalletPocketCornerMutationInput,
  CreatePalletPocketCornerMutationResponse
} from "../__generated__/CreatePalletPocketCornerMutation.graphql";

import { FrameViewer_viewer } from "../__generated__/FrameViewer_viewer.graphql";
import { windCorners } from "./CreateCornerMutation";

const mutation = graphql`
  mutation CreatePalletPocketCornerMutation(
    $input: CreatePalletPocketCornerMutationInput!
  ) {
    createPalletPocketCorner(input: $input) {
      pallet {
        id
        shape {
          id
          faces {
            id
            side
            parentShape {
              id
              faces {
                id
                palletPocketLeft {
                  id
                  corners {
                    id
                    x
                    y
                    visibility
                  }
                }
                palletPocketRight {
                  id
                  corners {
                    id
                    x
                    y
                    visibility
                  }
                }
              }
            }
            corners {
              id
              x
              y
              visibility
            }
          }
        }
        frontPockets {
          id
          left {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
          right {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
        }
        sidePockets {
          id
          left {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
          right {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
        }
      }
    }
  }
`;

let tempID = 0;
function createPalletPocketCorner(
  viewer: FrameViewer_viewer,
  environment: Environment,
  input: CreatePalletPocketCornerMutationInput,
  shouldCommit: boolean = true,
  onCompleted?: (
    response: CreatePalletPocketCornerMutationResponse | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  let mutationId: string = (tempID++).toString();

  const fullInput: CreatePalletPocketCornerMutationInput = {
    clientMutationId: mutationId,
    ...input
  };
  const cornerId = "client:createPalletPocketCorner:" + mutationId;
  if (!shouldCommit) {
    commitLocalUpdate(environment, store => {
      // get record for pallet
      let palletRecord = store.get(input.palletId);
      if (!palletRecord) {
        return;
      }
      const pocketsName = input.isFrontFace ? "frontPockets" : "sidePockets";
      const pocketName = input.isLeftPocket ? "left" : "right";

      // get or create
      const existingShapeRecord = palletRecord.getLinkedRecord("shape");
      const existingFacesRecords =
        existingShapeRecord.getLinkedRecords("faces");
      const faceToCorners = {};
      for (const record of existingFacesRecords) {
        const corners = [record.getLinkedRecords("corners")];
        faceToCorners[record.getDataID()] = corners;
      }

      let correspondingPocketsRecords =
        palletRecord.getLinkedRecord(pocketsName);

      if (!correspondingPocketsRecords) {
        correspondingPocketsRecords = store.create(
          `client::createPalletPockets:${mutationId}`,
          "PalletPocket"
        );
        palletRecord.setLinkedRecord(correspondingPocketsRecords, pocketsName);
      }
      let existingPocketRecord =
        correspondingPocketsRecords.getLinkedRecord(pocketName);
      if (!existingPocketRecord) {
        existingPocketRecord = store.create(
          `client::createPalletPocketFace:${mutationId}`,
          "Quad"
        );
        correspondingPocketsRecords.setLinkedRecord(
          existingPocketRecord,
          pocketName
        );
      }

      const existingCornersRecords =
        existingPocketRecord.getLinkedRecords("corners") || [];

      if (existingCornersRecords.length >= 4) {
        throw new Error("Cannot add corner to face that has 4 corners");
      }
      // new corner
      if (store.get(cornerId) !== undefined) {
        throw new Error("Cannot create a corner that already exists");
      }
      // create new record for new corner
      let cornerRecord = store.create(cornerId, "QuadCorner");
      cornerRecord.setValue(cornerId, "id");
      cornerRecord.setValue(input.x, "x");
      cornerRecord.setValue(input.y, "y");
      cornerRecord.setValue(input.visibility, "visibility");
      // Wind the corners. The backend also wind corners on corner creation, and
      // it is the backend's winding which really matters. Corners are wound
      // here only to remove jitters in the UI.
      const newCornersRecords = windCorners([
        ...existingCornersRecords,
        cornerRecord
      ]);
      existingPocketRecord.setLinkedRecords(newCornersRecords, "corners");
    });
    return;
  }
  // TODO(emily): remove commit completely. Create entire pocket on last corner instead
  commitMutation(environment, {
    mutation,
    variables: {
      input: fullInput
    },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let pallet = store.get(input.palletId);
      if (!pallet) {
        return;
      }
      const pocketsName = input.isFrontFace ? "frontPockets" : "sidePockets";
      let existingPockets = pallet.getLinkedRecord(pocketsName);
      if (!existingPockets) {
        existingPockets = store.create(
          `client::createPalletPockets:${mutationId}`,
          "PalletPocket"
        );
        pallet.setLinkedRecord(existingPockets, pocketsName);
      }
      const pocketName = input.isLeftPocket ? "left" : "right";
      let existingPocket = existingPockets.getLinkedRecord(pocketName);

      if (!existingPocket) {
        existingPocket = store.create(
          `client::createPalletPocketFace:${mutationId}`,
          "Quad"
        );
        existingPockets.setLinkedRecord(existingPocket, pocketName);
      }
      const existingCorners = existingPocket.getLinkedRecords("corners") || [];
      if (existingCorners.length >= 4) {
        throw new Error("Cannot add corner to face that has 4 corners");
      }
      let corner = store.create(cornerId, "QuadCorner");
      corner.setValue(cornerId, "id");
      corner.setValue(input.x, "x");
      corner.setValue(input.y, "y");
      corner.setValue(input.visibility, "visibility");
      // Wind the corners. The backend also wind corners on corner creation, and
      // it is the backend's winding which really matters. Corners are wound
      // here only to remove jitters in the UI.
      const newCorners = windCorners([...existingCorners, corner]);
      existingPocket.setLinkedRecords(newCorners, "corners");
    }
  });
}

export default createPalletPocketCorner;
