/**
 * CanvasRender module.
 *
 * @module components/common/canvas/CanvasRenderer
 *
 * Fetches data for rendering a frame-based canvas and draws the frame on the
 * canvas.
 *
 */

import React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { Matrix3, Vector2 } from "three";
import { isEmpty } from "lodash";
import {
  CanvasRenderer_frame,
  PalletSurface
} from "../../../__generated__/CanvasRenderer_frame.graphql";
import CanvasEventHandler from "./CanvasEventHandler";
import { LabelCategory, LabelSelector } from "./SelectionState";
import { Corner, Label, LabelMutators } from "./LabelMutators";
import { TopLevelLabelActions } from "../../label_frame/SensorStreamWrapper";
import { computeCenter, minEdgeLength, transformPoint } from "./Utils";
import { commonColors } from "../../../utils/CommonStyles";
import { isPallet, isBin } from "../../../utils/CommonFunctions";

const styles = theme =>
  createStyles({
    icon: {
      fontSize: 20
    },
    message: {
      display: "flex",
      alignItems: "center"
    }
  });
interface Props extends WithStyles<typeof styles> {
  viewer: Object;
  classes: any;
  frame: CanvasRenderer_frame;
  canvasContext: CanvasRenderingContext2D;
  topLevelLabelActions: TopLevelLabelActions;
  image: HTMLImageElement;
  imageData: ImageData;
  openCV: Object | null;
  imageTFacade: Matrix3;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  knownObjectPoseId: string | null;
  setKnownObjectPoseId: (arg0: string | null) => void;
  labelerUsernames: Set<string>;
}

type ColorScheme = {
  edgeOpacity: number;
  fillColor: string;
  fillOpacity: number;
  labelNameColor: string;
  edgeColor: string;
  labelNameOpacity: number;
};

type State = {
  notificationText: string | null;
  dirtyCorner: Corner | null;
  showLabels: boolean;
  cursorContext: CanvasRenderingContext2D | null;
};

type Edge = {
  corner1: Vector2;
  corner2: Vector2;
};

function makeCursorContext() {
  const cursorCanvas = document.createElement("canvas");
  cursorCanvas.width = 16;
  cursorCanvas.height = 16;

  return cursorCanvas.getContext("2d");
}

function getHatchWidth(label: Label): number {
  if (!isPallet(label) && !isBin(label)) {
    return 0;
  }
  if (label.objectType === "PALLET_GMA_BLOCK") {
    return 8;
  }
  return 2;
}

export function getSurfaceColor(surface?: PalletSurface): string | null {
  if (!surface) {
    return null;
  }
  // Colors mostly from https://packagecontrol.io/readmes/img/1cc61fcf1202f82f5cf0b616c6719720446cfe73.png
  switch (surface) {
    case "FLOOR": {
      return commonColors.floor;
      // return "#E6DB74"; // yellow
    }
    case "TRUCK": {
      return commonColors.truck;
    }
    case "RACK": {
      return commonColors.rack;
    }
    case "FORKLIFT": {
      return commonColors.forklift;
    }
    case "PALLET_EMPTY": {
      return commonColors.item;
    }
    case "PALLET_LOADED": {
      return commonColors.palletLoaded;
    }
    case "UNKNOWN": {
      return commonColors.unknown;
    }
    default: {
      console.warn(`Cannot field color for label surface ${surface}`);
      return "black";
    }
  }
}

/**
 * Find the line segment that intersects the given line segments at the given
 * ratio along the segments.
 *
 * @param edge1
 * @param edge2
 * @param ratioAlongEdge
 * @returns Edge
 * @private
 */
function _crossEdges(edge1: Edge, edge2: Edge, ratioAlongEdge: number): Edge {
  const corner1: Vector2 = edge1.corner1
    .clone()
    .multiplyScalar(ratioAlongEdge)
    .add(edge1.corner2.clone().multiplyScalar(1 - ratioAlongEdge));
  const corner2: Vector2 = edge2.corner1
    .clone()
    .multiplyScalar(ratioAlongEdge)
    .add(edge2.corner2.clone().multiplyScalar(1 - ratioAlongEdge));
  return { corner1, corner2 };
}
class CanvasRenderer extends React.Component<Props, State> {
  state = {
    notificationText: null,
    dirtyCorner: null,
    showLabels: true,
    cursorContext: null
  };

  cursorContext: CanvasRenderingContext2D = makeCursorContext();

  makeCursorUrl(color) {
    /**
     * makeCursorUrl creates a url for a colored cursor. It can be assigned
     * directly to an element's style.cursor.
     */
    let { cursorContext } = this;

    cursorContext.clearRect(0, 11, 0, 11);

    cursorContext.strokeStyle = color;
    cursorContext.globalAlpha = 0.8;

    cursorContext.lineWidth = 2;
    cursorContext.moveTo(2, 10);
    cursorContext.lineTo(2, 2);
    cursorContext.lineTo(10, 2);
    cursorContext.moveTo(2, 2);
    cursorContext.lineTo(10, 10);
    cursorContext.stroke();

    return `url(${cursorContext.canvas.toDataURL()}), auto`;
  }

  drawCanvas() {
    const {
      image,
      canvasContext,
      frame,
      labelerUsernames,
      topLevelLabelActions
    } = this.props;
    this.clear();
    canvasContext.globalAlpha = 1;
    canvasContext.drawImage(image, 0, 0);

    if (!this.state.showLabels) {
      return;
    }

    for (let obj of frame.labeledItems
      .filter(l => !!l)
      .filter(label => labelerUsernames.has(label.author.username || ""))) {
      const suffix =
        obj.objectType && obj.objectType.startsWith("ITEM_")
          ? obj.objectType.replace("ITEM_", "")[0]
          : "";
      this._drawLabel(obj, "goldenrod", "orange", suffix);
    }
    for (let box of frame.labeledBoxes
      .filter(l => !!l)
      .filter(label => labelerUsernames.has(label.author.username || ""))) {
      this._drawLabel(
        box,
        "red",
        "Tomato",
        box.pallet && box.pallet.id
          ? " (p" + box.pallet.number.toString() + ")"
          : ""
      );
    }
    for (let pallet of frame.labeledPallets.filter(label =>
      labelerUsernames.has(label.author.username || "")
    )) {
      this._drawLabel(pallet, "darkorange", "orange", "");
    }
    for (let pallet of frame.labeledPallets) {
      const loadHasCorners =
        pallet.load &&
        pallet.load.faces &&
        pallet.load.faces[0] &&
        !isEmpty(pallet.load.faces[0].corners);
      if (!loadHasCorners) {
        continue;
      }
      const label: Label = {
        shape: { ...pallet.load },
        objectType: pallet.objectType
      };
      this._drawLabel(label, "pink", "pink", "");
    }

    if (topLevelLabelActions.showDetectedPallets) {
      for (let detected of frame.detectedPallets) {
        this._drawLabel(detected, "red", "red", "");
      }
    }

    if (topLevelLabelActions.showCanvasGrid) {
      this._drawGrid();
    }
  }

  _goToNextPose = () => {
    const { frame, knownObjectPoseId, setKnownObjectPoseId } = this.props;
    const { knownObjectPoses } = frame;
    if (knownObjectPoses.length === 0) {
      return;
    }
    // knownObjectPoseId is blank if currently viewing the raw image.
    if (!knownObjectPoseId) {
      // If there are no known poses, there is no next pose.
      if (knownObjectPoses.length === 0) {
        return;
      }

      // Go from raw image to first pose.
      setKnownObjectPoseId(knownObjectPoses[0].id);
      return;
    }
    const currentIndex = knownObjectPoses.findIndex(
      pose => pose.id === knownObjectPoseId
    );
    // Move to the next index's id, or null (raw image) if we're at the end of
    // the known object poses.
    const nextId =
      currentIndex === knownObjectPoses.length - 1
        ? null
        : knownObjectPoses[currentIndex + 1].id;
    setKnownObjectPoseId(nextId);
  };

  render() {
    const {
      viewer,
      canvasContext,
      frame,
      labelSelector,
      labelMutator,
      imageTFacade,
      imageData,
      labelerUsernames,
      topLevelLabelActions,
      classes
    } = this.props;
    const { notificationText, showLabels } = this.state;

    this.drawCanvas();
    const labels = showLabels
      ? []
          .concat(frame.labeledBoxes)
          .concat(frame.labeledPallets)
          .concat(frame.labeledItems)
          .filter(l => !!l)
          .filter(l => labelerUsernames.has(l.author.username))
      : [];

    const closeSnackBar = () => this.setState({ notificationText: null });
    const autoHideNotificationMS = 3000;
    const getCursorColor = () => {
      switch (topLevelLabelActions.creationLabelCategory) {
        case LabelCategory.BOX:
          return commonColors.box;
        case LabelCategory.ITEM:
          return commonColors.item;
        case LabelCategory.PALLET:
          return topLevelLabelActions.creationFaceSide === "FRONT"
            ? commonColors.frontColor
            : commonColors.sideColor;
      }
    };
    const cursorColor = getCursorColor();
    return (
      <React.Fragment>
        <CanvasEventHandler
          topLevelLabelActions={topLevelLabelActions}
          goToNextPose={() => this._goToNextPose()}
          drawCanvas={() => this.drawCanvas()}
          labelSelector={labelSelector}
          labelMutator={labelMutator}
          imageTFacade={imageTFacade}
          labels={labels}
          imageWidth={imageData.width}
          imageHeight={imageData.height}
          imageData={imageData}
          viewer={viewer}
          canvasContext={canvasContext}
          frameId={frame.id}
          setNotificationText={(notificationText: string | null) =>
            this.setState({ notificationText })
          }
          toggleShowLabels={() => this.setState({ showLabels: !showLabels })}
          defaultCursor={this.makeCursorUrl(cursorColor)}
        />
        {notificationText && (
          <Snackbar
            className={classes.warning}
            open={true}
            autoHideDuration={autoHideNotificationMS}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            onClose={closeSnackBar}
            onClick={closeSnackBar}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={
              <span className={classes.message} id="message-id">
                {notificationText}
              </span>
            }
            action={[
              <IconButton
                className={classes.icon}
                key="close"
                aria-label="Close"
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        )}
      </React.Fragment>
    );
  }

  clear() {
    this.props.canvasContext.save();
    this.props.canvasContext.setTransform(1, 0, 0, 1, 0, 0);
    this.props.canvasContext.clearRect(
      0,
      0,
      this.props.canvasContext.canvas.width,
      this.props.canvasContext.canvas.height
    );
    this.props.canvasContext.restore();
  }

  _drawGrid() {
    const { canvasContext, image } = this.props;
    const { width, height } = image;
    canvasContext.strokeStyle = "red";
    canvasContext.lineWidth = 1;
    canvasContext.globalAlpha = 0.3;
    // Draw horizontal lines.
    canvasContext.beginPath();
    const gridSize = 100;
    for (var x = 0; x <= width; x += gridSize) {
      canvasContext.moveTo(x, -500);
      canvasContext.lineTo(x, height + 500);
    }
    canvasContext.stroke();
    // Draw vertical lines.
    canvasContext.beginPath();
    for (var y = 0; y <= height; y += gridSize) {
      canvasContext.moveTo(-500, y);
      canvasContext.lineTo(width + 500, y);
    }
    canvasContext.stroke();
  }

  _drawLabel(
    label: Label,
    baseColor: string,
    emphasizedColor: string,
    suffix: string
  ) {
    const { canvasContext, labelSelector, topLevelLabelActions } = this.props;
    const { canvasScale } = topLevelLabelActions;
    const { shape } = label;

    const radiusBase = shape.faces.find(f => labelSelector.isFaceSelected(f))
      ? 1.2
      : 0.8;
    const adjustedRadiusBase = radiusBase * (2.2 / Math.sqrt(canvasScale));
    canvasContext.globalAlpha = 0.4;
    const cornerIdToBeingCreated: Map<string, boolean> = new Map();
    for (const face of shape.faces) {
      for (const corner of face.corners) {
        const alreadyMarkedCreated = !!cornerIdToBeingCreated.get(corner.id);
        cornerIdToBeingCreated.set(
          corner.id,
          alreadyMarkedCreated ||
            labelSelector.currentCreationFaceId() === face.id
        );
      }
    }

    const corners = new Set();
    shape.faces.forEach(face => face?.corners.forEach(c => corners.add(c)));

    label?.frontPockets?.left?.corners &&
      label.frontPockets.left.corners.forEach(c => corners.add(c));
    this._drawPocket(label?.frontPockets?.left, "<", commonColors.frontColor);
    label?.frontPockets?.right?.corners &&
      label.frontPockets.right.corners.forEach(c => corners.add(c));
    this._drawPocket(label?.frontPockets?.right, ">", commonColors.frontColor);

    label?.sidePockets?.left?.corners &&
      label.sidePockets.left.corners.forEach(c => corners.add(c));
    this._drawPocket(label?.sidePockets?.left, "<", commonColors.sideColor);
    label?.sidePockets?.right?.corners &&
      label.sidePockets.right.corners.forEach(c => corners.add(c));
    this._drawPocket(label?.sidePockets?.right, ">", commonColors.sideColor);

    shape.faces.forEach(f => {
      const edgeOpacity = labelSelector.isFaceSelected(f)
        ? 1
        : labelSelector.isFaceHovered(f)
        ? 0.8
        : 0.6;
      const normalColor =
        labelSelector.isFaceHovered(f) || labelSelector.isFaceSelected(f)
          ? emphasizedColor
          : baseColor;
      const edgeColor = !label.surfaceType
        ? normalColor
        : f.side === "SIDE"
        ? commonColors.sideColor
        : commonColors.frontColor;
      const isBlocked = label.obstacles && label.obstacles.length > 0;
      const baseLabel = !label.number
        ? ""
        : isBlocked
        ? `X${label.number.toString()}X `
        : label.number.toString();
      this._drawFace(
        f,
        baseLabel + suffix,
        edgeColor,
        getHatchWidth(label),
        getSurfaceColor(label.surfaceType),
        edgeOpacity
      );
    });

    for (let corner of corners) {
      canvasContext.beginPath();
      const facadePt = this.toFacadeCoordinates(corner.x, corner.y);
      let cornerRadius = adjustedRadiusBase;
      if (labelSelector.isAnyLabelBeingCreated()) {
        if (
          labelSelector.currentCreationLabelId() &&
          labelSelector.currentCreationLabelId() !== label.id &&
          cornerIdToBeingCreated.size > 0 &&
          !cornerIdToBeingCreated.get(corner.id)
        ) {
          // Hide corners that do not belong to the label that is being created.
          cornerRadius /= 5;
        }
      } else if (
        labelSelector.isCornerHovered(corner) ||
        labelSelector.isCornerSelected(corner)
      ) {
        cornerRadius *= 1.25;
      }
      const cornerCreationNotComplete = corner.id.startsWith("client");
      if (cornerCreationNotComplete) {
        cornerRadius *= 1.5;
      }
      canvasContext.arc(
        facadePt.x,
        facadePt.y,
        cornerRadius,
        0,
        2 * Math.PI,
        false
      );
      let cornerColor = corner.visibility === "VISIBLE" ? "red" : "blue";
      if (
        labelSelector.currentCreationFaceId() &&
        !cornerIdToBeingCreated.get(corner.id)
      ) {
        cornerColor =
          corner.visibility === "VISIBLE" ? "LightPink" : "LightBlue";
      } else if (labelSelector.isCornerHovered(corner)) {
        cornerColor = "lawngreen";
      }
      canvasContext.fillStyle = cornerColor;
      canvasContext.fill();
    }
  }

  toFacadeCoordinates = (xA: number, yA: number): Vector2 => {
    const { imageTFacade } = this.props;
    return transformPoint(xA, yA, imageTFacade);
  };

  _drawPocket(pocket, title: string, baseColor: string) {
    if (!pocket) {
      return;
    }
    const { corners: cornersImage } = pocket;
    if (cornersImage.length < 2) {
      return;
    }
    const cornersFacade = cornersImage.map(c =>
      this.toFacadeCoordinates(c.x, c.y)
    );
    const colorScheme = this._faceColorScheme(pocket, baseColor);
    const { canvasContext, topLevelLabelActions } = this.props;
    const { rotationRadians } = topLevelLabelActions;
    canvasContext.beginPath();
    canvasContext.globalAlpha = colorScheme.edgeOpacity;
    canvasContext.lineWidth = 1;
    canvasContext.strokeStyle = colorScheme.edgeColor;
    const firstPosition = cornersFacade[0];
    canvasContext.moveTo(firstPosition.x, firstPosition.y);
    cornersFacade.forEach((corner, i) => {
      // TODO(malcolm): Color edges based on corner visibility.
      const nextIndex = (i + 1) % cornersFacade.length;
      const nextPosition = cornersFacade[nextIndex];
      canvasContext.lineTo(nextPosition.x, nextPosition.y);
    });
    canvasContext.stroke();

    canvasContext.fillStyle = colorScheme.fillColor;
    canvasContext.globalAlpha = colorScheme.fillOpacity;
    canvasContext.fill();

    let bottom: number = Number.MAX_VALUE;
    let top: number = 0;
    for (let corner of cornersFacade) {
      if (corner.y > top) {
        top = corner.y;
      }
      if (corner.y < bottom) {
        bottom = corner.y;
      }
    }
    let minDistance = minEdgeLength(cornersFacade);
    let size: number = Math.min((top - bottom) / 1.25, minDistance);
    const maxAllowableLabelSize = 24;
    size = Math.min(size, maxAllowableLabelSize);

    canvasContext.fillStyle = colorScheme.labelNameColor;
    canvasContext.font = size + "px sans-serif";
    canvasContext.globalAlpha = colorScheme.labelNameOpacity;

    const center: Vector2 = computeCenter(
      cornersFacade.map(c => new Vector2(c.x, c.y))
    );

    canvasContext.textAlign = "center";
    canvasContext.textBaseline = "middle";
    canvasContext.save();

    canvasContext.translate(center.x, center.y);
    canvasContext.rotate(-rotationRadians);
    canvasContext.fillText(title, 0, 0, size);
    canvasContext.restore();
  }

  _drawFace(
    face,
    title: string,
    baseColor: string,
    hatchWidth: number,
    bottomColor: string | null,
    edgeOpacity: number
  ) {
    if (!face) {
      return;
    }
    const { corners: cornersImage } = face;
    if (cornersImage.length < 2) {
      return;
    }
    const cornersFacade = cornersImage.map(c =>
      this.toFacadeCoordinates(c.x, c.y)
    );
    const colorScheme = this._faceColorScheme(face, baseColor);
    const { canvasContext, topLevelLabelActions } = this.props;
    const { rotationRadians } = topLevelLabelActions;
    canvasContext.beginPath();
    canvasContext.globalAlpha = edgeOpacity;
    canvasContext.lineWidth = 1;
    canvasContext.strokeStyle = colorScheme.edgeColor;
    const firstPosition = cornersFacade[0];
    canvasContext.moveTo(firstPosition.x, firstPosition.y);
    cornersFacade.forEach((corner, i) => {
      // TODO(malcolm): Color edges based on corner visibility.
      const nextIndex = (i + 1) % cornersFacade.length;
      const nextPosition = cornersFacade[nextIndex];
      canvasContext.lineTo(nextPosition.x, nextPosition.y);
    });
    canvasContext.stroke();

    if (hatchWidth && cornersFacade.length > 3) {
      // Draw a + in the middle of the face.
      const hatchingEdge1 = {
        corner1: cornersFacade[0],
        corner2: cornersFacade[1]
      };
      const hatchingEdge2 = {
        corner1: cornersFacade[3],
        corner2: cornersFacade[2]
      };
      const hatchingEdge3 = {
        corner1: cornersFacade[1],
        corner2: cornersFacade[2]
      };
      const hatchingEdge4 = {
        corner1: cornersFacade[0],
        corner2: cornersFacade[3]
      };
      const crossEdge1 = _crossEdges(hatchingEdge1, hatchingEdge2, 0.5);
      const crossEdge2 = _crossEdges(hatchingEdge3, hatchingEdge4, 0.5);
      const deltaX1 = Math.abs(crossEdge1.corner1.x - crossEdge1.corner2.x);
      const deltaX2 = Math.abs(crossEdge2.corner1.x - crossEdge2.corner2.x);
      const lessHorizontalLine = deltaX1 < deltaX2 ? crossEdge1 : crossEdge2;
      const moreHorizontalLine = deltaX1 > deltaX2 ? crossEdge1 : crossEdge2;
      this._drawHatching(lessHorizontalLine, 2, baseColor, 0.8);
      this._drawHatching(moreHorizontalLine, hatchWidth, baseColor, 0.47);

      const bottomCorners = Array.from(cornersFacade).sort((c1, c2) => {
        return c2.y - c1.y;
      });
      if (bottomColor !== null) {
        this._drawHatching(
          { corner1: bottomCorners[0], corner2: bottomCorners[1] },
          6,
          bottomColor,
          0.42
        );
      }
    }

    canvasContext.fillStyle = colorScheme.fillColor;
    canvasContext.globalAlpha = colorScheme.fillOpacity;
    canvasContext.fill();

    let bottom: number = Number.MAX_VALUE;
    let top: number = 0;
    for (let corner of cornersFacade) {
      if (corner.y > top) {
        top = corner.y;
      }
      if (corner.y < bottom) {
        bottom = corner.y;
      }
    }
    let minDistance = minEdgeLength(cornersFacade);
    let size: number = Math.min((top - bottom) / 2, minDistance);
    const maxAllowableLabelSize = 16;
    size = Math.min(size, maxAllowableLabelSize);

    canvasContext.fillStyle = colorScheme.labelNameColor;
    canvasContext.font = size + "px sans-serif";
    canvasContext.globalAlpha = colorScheme.labelNameOpacity;

    const center: Vector2 = computeCenter(
      cornersFacade.map(c => new Vector2(c.x, c.y))
    );

    canvasContext.textAlign = "center";
    canvasContext.textBaseline = "middle";
    canvasContext.save();

    canvasContext.translate(center.x, center.y);
    canvasContext.rotate(-rotationRadians);
    canvasContext.fillText(title, 0, 0, size);
    canvasContext.restore();
  }

  _drawHatching(edge: Edge, width: number, color: string, ratio: number) {
    const edge1Point = edge.corner1;
    const edge2Point = edge.corner2;
    if (!edge1Point || !edge2Point) {
      return;
    }
    const hatching1: Vector2 = edge1Point
      .clone()
      .multiplyScalar(ratio)
      .add(edge2Point.clone().multiplyScalar(1 - ratio));
    const hatching2: Vector2 = edge1Point
      .clone()
      .multiplyScalar(1 - ratio)
      .add(edge2Point.clone().multiplyScalar(ratio));

    const { canvasContext } = this.props;
    const prevWidth = canvasContext.lineWidth;
    canvasContext.save();
    canvasContext.beginPath();
    canvasContext.lineWidth = width;
    canvasContext.strokeStyle = color;
    canvasContext.moveTo(hatching1.x, hatching1.y);
    canvasContext.lineTo(hatching2.x, hatching2.y);
    canvasContext.closePath();
    canvasContext.stroke();
    canvasContext.lineWidth = prevWidth;
    canvasContext.restore();
  }

  _faceColorScheme = (face, baseColor: string): ColorScheme => {
    const { labelSelector } = this.props;
    const faceIsSelected = labelSelector.isFaceSelected(face);
    const faceIsHovered = labelSelector.isFaceHovered(face);
    const labelIsSelected =
      face.parentShape &&
      face.parentShape.faces.some(face => labelSelector.isFaceSelected(face));
    const labelIsHovered =
      face.parentShape &&
      face.parentShape.faces.some(face => labelSelector.isFaceHovered(face));

    let fillOpacity = 0;
    if (faceIsSelected) {
      fillOpacity = 0.25;
    } else if (faceIsHovered) {
      fillOpacity = 0.1;
    } else if (labelIsSelected) {
      fillOpacity = 0.2;
    } else if (labelIsHovered) {
      fillOpacity = 0.1;
    }

    const fillColor = baseColor;
    const labelNameColor = baseColor;
    const edgeColor = baseColor;
    const labelNameOpacity = 0.6;
    return {
      edgeOpacity: 1,
      fillColor,
      fillOpacity,
      labelNameColor,
      edgeColor,
      labelNameOpacity
    };
  };
}

export default withStyles(styles)(
  createFragmentContainer(CanvasRenderer, {
    frame: graphql`
      fragment CanvasRenderer_frame on Frame {
        id
        cameraModel {
          id
          height
          width
          fx
          fy
          cx
          cy
        }
        knownObjectPoses {
          id
          distance
          position {
            x
            y
            z
          }
          orientation {
            w
            x
            y
            z
          }
          targetState
          knownObject
        }
        labeledItems {
          id
          number
          objectType
          thwartedPallets {
            id
            number
          }
          author {
            username
          }
          shape {
            id
            faces {
              id
              parentShape {
                id
                faces {
                  id
                }
              }
              side
              corners {
                id
                x
                y
                visibility
              }
            }
          }
        }
        labeledBoxes {
          id
          number
          objectType
          author {
            username
          }
          pallet {
            id
            number
          }
          shape {
            id
            faces {
              id
              parentShape {
                id
                faces {
                  id
                }
              }
              side
              corners {
                id
                x
                y
                visibility
              }
            }
          }
        }
        labeledPallets {
          id
          number
          objectType
          surfaceType
          obstacles {
            number
          }
          author {
            username
          }
          sidePockets {
            id
            left {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
            right {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
          }
          frontPockets {
            id
            left {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
            right {
              id
              corners {
                id
                x
                y
                visibility
              }
            }
          }
          shape {
            id
            faces {
              id
              parentShape {
                id
                faces {
                  id
                }
              }
              side
              corners {
                id
                x
                y
                visibility
              }
            }
          }
          load {
            id
            faces {
              id
              parentShape {
                id
                faces {
                  id
                }
              }
              side
              corners {
                id
                x
                y
                visibility
              }
            }
          }
        }
        detectedPallets {
          id
          objectType
          shape {
            id
            faces {
              id
              parentShape {
                id
                faces {
                  id
                }
              }
              side
              corners {
                id
                x
                y
                visibility
              }
            }
          }
        }
        indexInSensorStream
        imageMetadata {
          id
          height
          width
        }
      }
    `
  })
);
