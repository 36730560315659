import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Link from "found/lib/Link";

const styles = theme => ({
  root: {
    textDecoration: "inherit"
  },
  alwaysUnderline: {
    textDecoration: "underline"
  },
  neverUnderline: {
    textDecoration: "none"
  },
  onhoverUnderline: {
    "&:hover": {
      textDecoration: "underline"
    }
  },
  inheritColor: {
    color: "inherit"
  }
});

const propTypes = {
  viewer: PropTypes.object.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  underline: PropTypes.oneOf(["never", "onhover", "always"]),
  inheritColor: PropTypes.bool
};
class FoxLink extends React.Component<typeof propTypes> {
  render() {
    const {
      children,
      classes,
      className,
      underline,
      inheritColor,
      to,
      ...other
    } = this.props;
    let names = [classes.root];

    if (underline === "never") {
      names.push(classes.neverUnderline);
    } else if (underline === "onhover") {
      names.push(classes.onhoverUnderline);
    } else if (underline === "always") {
      names.push(classes.alwaysUnderline);
    } else {
      // default
      names.push(classes.onhoverUnderline);
    }

    if (inheritColor) {
      names.push(classes.inheritColor);
    }

    return (
      <Link to={to} className={classNames(...names, className)} {...other}>
        {children}
      </Link>
    );
  }
}

export default withStyles(styles)(FoxLink);
