const tokenName = "jwtToken";

export function setJwt(token: string) {
  localStorage.setItem(tokenName, token);
}

export function clearJwt() {
  localStorage.removeItem(tokenName);
}

export function getJwt() {
  return localStorage.getItem(tokenName);
}
