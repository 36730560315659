import React from "react";
import { graphql, createFragmentContainer } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

import { LabelSelector } from "../SelectionState";
import { LabelMutators } from "../LabelMutators";

import {
  ItemDetails_item,
  KnownObject
} from "../../../../__generated__/ItemDetails_item.graphql";
import { Viewer } from "../Utils";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LabelCardHeader from "./LabelCardHeader";
import { combineStyles, commonStyles } from "../../../../utils/CommonStyles";
import LabelFaceCorner from "./LabelFaceCorner";

const localStyles = theme =>
  createStyles({
    cornerList: {
      width: "100%"
    },
    formControl: {
      margin: theme.spacing.unit,
      width: "100%",
      textTransform: "capitalize"
    },
    actions: {
      padding: 8,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center"
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      }),
      marginLeft: "auto",
      [theme.breakpoints.up("sm")]: {
        marginRight: -8
      }
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    boxList: {
      width: "100%"
    },
    collapseWrapper: {
      border: "inset",
      borderWidth: 1
    },
    cardHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 8,
      paddingRight: 8
    },
    headerInfo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    },
    selectStyle: {
      width: "42%",
      fontSize: 10
    },
    listItemRoot: {
      padding: "0px 4px",
      paddingLeft: 24,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: 38
    },
    number: {
      paddingRight: 4
    }
  });
const styles = combineStyles(localStyles, commonStyles);

type Props = {
  classes: any;
  viewer: Viewer;
  item: ItemDetails_item;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
};

type State = {
  expanded: boolean | null;
  showCorners: boolean;
  showPallets: boolean;
};

class ItemDetails extends React.Component<Props, State> {
  state = {
    expanded: null,
    showCorners: false,
    showPallets: false
  };

  _updateItem(input: { id: string; knownObjectType?: KnownObject | null }) {
    this.props.labelMutator.label.item.update(input);
  }
  _updateCorners = () => {
    this.setState(prevState => ({
      showCorners: !prevState.showCorners
    }));
  };
  _updateShowPallets = () => {
    this.setState(prevState => ({
      showPallets: !prevState.showPallets
    }));
  };

  render() {
    const { classes, item, labelSelector, labelMutator } = this.props;
    if (!item) {
      return null;
    }
    if (!item.shape.faces.length) {
      return null;
    }
    const face = item.shape.faces[0];

    let { expanded, showCorners, showPallets } = this.state;
    if (expanded === null || expanded === undefined) {
      expanded = labelSelector.isLabelSelected(item);
    }

    const cardClass = expanded ? classes.cardExpanded : classes.card;
    const isEditingFace = labelSelector.currentCreationFaceId() === face.id;
    const palletIds = new Set(
      Array.from((item.thwartedPallets || []).map(p => p.id))
    );

    return (
      <Card
        raised={expanded}
        onMouseEnter={() => labelSelector.hoverLabel(item)}
        onMouseLeave={() => labelSelector.clearHovers()}
        className={cardClass}
      >
        <LabelCardHeader
          item={item}
          pallet={null}
          labelSelector={labelSelector}
          labelMutator={labelMutator}
          showCorners={showCorners}
          showPallets={showPallets}
          updateCorners={this._updateCorners}
          updateShowPallets={this._updateShowPallets}
          showFaces={null}
          updateFaces={null}
        />
        <Collapse in={showPallets} timeout="auto" unmountOnExit>
          <div className={classes.collapseWrapper}>
            <List dense disablePadding className={classes.cornerList}>
              {item.frame.labeledPallets.map((p, idx) => (
                <ListItem
                  divider
                  dense
                  key={p.id}
                  onMouseOver={() => labelSelector.hoverLabel(p)}
                  classes={{ root: classes.listItemRoot }}
                >
                  <Typography color="textSecondary" variant="body1">
                    Pallet {p.number.toString()}
                  </Typography>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={palletIds.has(p.id)}
                          onChange={() => {
                            if (palletIds.has(p.id)) {
                              palletIds.delete(p.id);
                            } else {
                              palletIds.add(p.id);
                            }
                            labelMutator.label.item.update({
                              id: item.id,
                              thwartedPallets: {
                                palletIds: Array.from(palletIds)
                              }
                            });
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Typography color="textSecondary" variant="body1">
                          Makes pallet unpickable?
                        </Typography>
                      }
                    />
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        </Collapse>
        <Collapse in={showCorners} timeout="auto" unmountOnExit>
          <div className={classes.collapseWrapper}>
            {!isEditingFace && face.corners.length < 4 && (
              <Button
                onClick={() => labelMutator.face.resume(face.id, item.id)}
                color="pimary"
                fullWidth
                variant={"contained"}
                className={classes.button}
              >
                Resume
              </Button>
            )}
            <List dense disablePadding className={classes.cornerList}>
              {face.corners.map((c, idx) => (
                <LabelFaceCorner
                  corner={c}
                  labelSelector={labelSelector}
                  labelMutator={labelMutator}
                  idx={idx}
                  faceId={face.id}
                  key={`${face.id}-${idx}`}
                />
              ))}
            </List>
          </div>
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(ItemDetails, {
    item: graphql`
      fragment ItemDetails_item on LabeledItem {
        id
        shape {
          faces {
            id
            side
            corners {
              id
              x
              y
              visibility
            }
          }
        }
        number
        objectType
        thwartedPallets {
          id
          number
        }
        frame {
          labeledPallets {
            id
            number
          }
        }
      }
    `
  })
);
