import React from "react";
import { graphql, createFragmentContainer } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";

import ItemDetails from "./ItemDetails";
import { LabelSelector } from "../SelectionState";
import { LabelMutators } from "../LabelMutators";
import { TopLevelLabelActions } from "../../../label_frame/SensorStreamWrapper";

import { ItemSideBar_frame } from "../../../../__generated__/ItemSideBar_frame.graphql";
import { Viewer } from "../Utils";

const styles = theme => createStyles({});

type Props = {
  classes: any;
  frame: ItemSideBar_frame;
  viewer: Viewer;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  labelerUsernames: Set<string>;
  topLevelLabelActions: TopLevelLabelActions;
};

class ItemSideBar extends React.Component<Props> {
  render() {
    const {
      classes,
      frame,
      labelMutator,
      labelSelector,
      viewer,
      labelerUsernames
    } = this.props;
    const { labeledItems } = frame;

    return (
      <React.Fragment>
        {labeledItems.length > 0 && (
          <div className={classes.faceLists}>
            {labeledItems
              .filter(label => labelerUsernames.has(label.author.username))
              .concat()
              .sort((a, b) => a.number - b.number)
              .map(item => (
                <ItemDetails
                  key={item.id}
                  viewer={viewer}
                  item={item}
                  labelSelector={labelSelector}
                  labelMutator={labelMutator}
                />
              ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(ItemSideBar, {
    frame: graphql`
      fragment ItemSideBar_frame on Frame {
        id
        labeledItems {
          ...ItemDetails_item
          id
          number
          author {
            id
            username
          }
        }
      }
    `
  })
);
