import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import ListSensorStreams from "./ListSensorStreams";
import ListHighPrioritySensorStreams from "./ListHighPrioritySensorStreams";
import ListViewerLabeledSensorStreams from "./ListViewerLabeledSensorStreams";

import { Dashboard_viewer } from "../../__generated__/Dashboard_viewer.graphql";

interface Props extends WithStyles<typeof styles> {
  viewer: Dashboard_viewer;
}
type State = {
  isLoadingMore: boolean;
};
const styles = theme =>
  createStyles({
    wrapper: {
      margin: 48
    }
  });
class Dashboard extends React.Component<Props> {
  state = {
    isLoadingMore: false
  };
  render() {
    const { viewer, classes } = this.props;
    const { isLoadingMore } = this.state;
    return (
      <div className={classes.wrapper}>
        <div>
          <ListViewerLabeledSensorStreams
            viewer={viewer}
            reload={this._forceReload}
          />
          <ListHighPrioritySensorStreams
            viewer={viewer}
            reload={this._forceReload}
            isLoadingMore={isLoadingMore}
          />
          <ListSensorStreams viewer={viewer} reload={this._forceReload} />
        </div>
      </div>
    );
  }
  _forceReload = () => {
    this.setState({ isLoadingMore: true }, () => {
      this.setState({ isLoadingMore: false });
    });
  };
}

export default withStyles(styles)(
  createFragmentContainer(Dashboard, {
    viewer: graphql`
      fragment Dashboard_viewer on Viewer {
        id
        username
        isAnonymous
        ...ListSensorStreams_viewer
        ...ListHighPrioritySensorStreams_viewer
        ...ListViewerLabeledSensorStreams_viewer
      }
    `
  })
);
