import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { UpdateLabeledPalletMutationInput } from "../__generated__/UpdateLabeledPalletMutation.graphql";

const mutation = graphql`
  mutation UpdateLabeledPalletMutation(
    $input: UpdateLabeledPalletMutationInput!
  ) {
    updateLabeledPallet(input: $input) {
      labeledPallet {
        id
        objectType
        surfaceType
      }
    }
  }
`;

function updateLabeledPallet(
  environment: Environment,
  input: UpdateLabeledPalletMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  const optimisticResponse: any = {
    updateLabeledPallet: { labeledPallet: { id: input.id, objectType: undefined, surfaceType: undefined } }
  };
  if (input.surfaceType) {
    optimisticResponse.updateLabeledPallet.labeledPallet.surfaceType =
      input.surfaceType;
  }
  if (input.knownObjectType) {
    optimisticResponse.updateLabeledPallet.labeledPallet.objectType =
      input.knownObjectType;
  }
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticResponse
  });
}

export default updateLabeledPallet;
