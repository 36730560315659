/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type PalletSurface = "FLOOR" | "FORKLIFT" | "PALLET_EMPTY" | "PALLET_LOADED" | "RACK" | "TRUCK" | "UNKNOWN" | "%future added value";
export type TargetState = "NA" | "NOT_TARGETED" | "TARGETED" | "UNKNOWN" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type CanvasRenderer_frame = {
    readonly id: string;
    readonly cameraModel: {
        readonly id: string;
        readonly height: number;
        readonly width: number;
        readonly fx: number | null;
        readonly fy: number | null;
        readonly cx: number | null;
        readonly cy: number | null;
    };
    readonly knownObjectPoses: ReadonlyArray<{
        readonly id: string;
        readonly distance: number;
        readonly position: {
            readonly x: number;
            readonly y: number;
            readonly z: number;
        };
        readonly orientation: {
            readonly w: number;
            readonly x: number;
            readonly y: number;
            readonly z: number;
        };
        readonly targetState: TargetState;
        readonly knownObject: KnownObject;
    }>;
    readonly labeledItems: ReadonlyArray<{
        readonly id: string;
        readonly number: number;
        readonly objectType: KnownObject;
        readonly thwartedPallets: ReadonlyArray<{
            readonly id: string;
            readonly number: number;
        }>;
        readonly author: {
            readonly username: string | null;
        };
        readonly shape: {
            readonly id: string;
            readonly faces: ReadonlyArray<{
                readonly id: string;
                readonly parentShape: {
                    readonly id: string;
                    readonly faces: ReadonlyArray<{
                        readonly id: string;
                    }>;
                };
                readonly side: FaceSide;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            }>;
        };
    }>;
    readonly labeledBoxes: ReadonlyArray<{
        readonly id: string;
        readonly number: number;
        readonly objectType: KnownObject;
        readonly author: {
            readonly username: string | null;
        };
        readonly pallet: {
            readonly id: string;
            readonly number: number;
        } | null;
        readonly shape: {
            readonly id: string;
            readonly faces: ReadonlyArray<{
                readonly id: string;
                readonly parentShape: {
                    readonly id: string;
                    readonly faces: ReadonlyArray<{
                        readonly id: string;
                    }>;
                };
                readonly side: FaceSide;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            }>;
        };
    }>;
    readonly labeledPallets: ReadonlyArray<{
        readonly id: string;
        readonly number: number;
        readonly objectType: KnownObject;
        readonly surfaceType: PalletSurface;
        readonly obstacles: ReadonlyArray<{
            readonly number: number;
        }>;
        readonly author: {
            readonly username: string | null;
        };
        readonly sidePockets: {
            readonly id: string;
            readonly left: {
                readonly id: string;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            } | null;
            readonly right: {
                readonly id: string;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            } | null;
        } | null;
        readonly frontPockets: {
            readonly id: string;
            readonly left: {
                readonly id: string;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            } | null;
            readonly right: {
                readonly id: string;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            } | null;
        } | null;
        readonly shape: {
            readonly id: string;
            readonly faces: ReadonlyArray<{
                readonly id: string;
                readonly parentShape: {
                    readonly id: string;
                    readonly faces: ReadonlyArray<{
                        readonly id: string;
                    }>;
                };
                readonly side: FaceSide;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            }>;
        };
        readonly load: {
            readonly id: string;
            readonly faces: ReadonlyArray<{
                readonly id: string;
                readonly parentShape: {
                    readonly id: string;
                    readonly faces: ReadonlyArray<{
                        readonly id: string;
                    }>;
                };
                readonly side: FaceSide;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            }>;
        } | null;
    }>;
    readonly detectedPallets: ReadonlyArray<{
        readonly id: string;
        readonly objectType: KnownObject;
        readonly shape: {
            readonly id: string;
            readonly faces: ReadonlyArray<{
                readonly id: string;
                readonly parentShape: {
                    readonly id: string;
                    readonly faces: ReadonlyArray<{
                        readonly id: string;
                    }>;
                };
                readonly side: FaceSide;
                readonly corners: ReadonlyArray<{
                    readonly id: string;
                    readonly x: number;
                    readonly y: number;
                    readonly visibility: Visibility;
                }>;
            }>;
        };
    }>;
    readonly indexInSensorStream: number;
    readonly imageMetadata: {
        readonly id: string;
        readonly height: number;
        readonly width: number;
    };
    readonly " $refType": "CanvasRenderer_frame";
};
export type CanvasRenderer_frame$data = CanvasRenderer_frame;
export type CanvasRenderer_frame$key = {
    readonly " $data"?: CanvasRenderer_frame$data;
    readonly " $fragmentRefs": FragmentRefs<"CanvasRenderer_frame">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "height",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "width",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "x",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "y",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "z",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "objectType",
  "args": null,
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v6/*: any*/)
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelerUser",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = [
  (v0/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "visibility",
    "args": null,
    "storageKey": null
  }
],
v11 = [
  (v0/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "faces",
    "storageKey": null,
    "args": null,
    "concreteType": "CuboidFace",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "parentShape",
        "storageKey": null,
        "args": null,
        "concreteType": "Cuboid",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "faces",
            "storageKey": null,
            "args": null,
            "concreteType": "CuboidFace",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "side",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "corners",
        "storageKey": null,
        "args": null,
        "concreteType": "CuboidCorner",
        "plural": true,
        "selections": (v10/*: any*/)
      }
    ]
  }
],
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "shape",
  "storageKey": null,
  "args": null,
  "concreteType": "Cuboid",
  "plural": false,
  "selections": (v11/*: any*/)
},
v13 = [
  (v0/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "corners",
    "storageKey": null,
    "args": null,
    "concreteType": "QuadCorner",
    "plural": true,
    "selections": (v10/*: any*/)
  }
],
v14 = [
  (v0/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "left",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v13/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "right",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v13/*: any*/)
  }
];
return {
  "kind": "Fragment",
  "name": "CanvasRenderer_frame",
  "type": "Frame",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cameraModel",
      "storageKey": null,
      "args": null,
      "concreteType": "CameraModel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fx",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fy",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cx",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cy",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "knownObjectPoses",
      "storageKey": null,
      "args": null,
      "concreteType": "KnownObjectPose",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "distance",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "position",
          "storageKey": null,
          "args": null,
          "concreteType": "Vector3",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orientation",
          "storageKey": null,
          "args": null,
          "concreteType": "Quaternion",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "w",
              "args": null,
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "targetState",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "knownObject",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeledItems",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledItem",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "thwartedPallets",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledPallet",
          "plural": true,
          "selections": (v8/*: any*/)
        },
        (v9/*: any*/),
        (v12/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeledBoxes",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledBox",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v9/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pallet",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledPallet",
          "plural": false,
          "selections": (v8/*: any*/)
        },
        (v12/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labeledPallets",
      "storageKey": null,
      "args": null,
      "concreteType": "LabeledPallet",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "surfaceType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "obstacles",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledItem",
          "plural": true,
          "selections": [
            (v6/*: any*/)
          ]
        },
        (v9/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "sidePockets",
          "storageKey": null,
          "args": null,
          "concreteType": "PalletPocket",
          "plural": false,
          "selections": (v14/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "frontPockets",
          "storageKey": null,
          "args": null,
          "concreteType": "PalletPocket",
          "plural": false,
          "selections": (v14/*: any*/)
        },
        (v12/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "load",
          "storageKey": null,
          "args": null,
          "concreteType": "Cuboid",
          "plural": false,
          "selections": (v11/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "detectedPallets",
      "storageKey": null,
      "args": null,
      "concreteType": "DetectedPallet",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        (v12/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "indexInSensorStream",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "imageMetadata",
      "storageKey": null,
      "args": null,
      "concreteType": "ImageMetadata",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    }
  ]
};
})();
(node as any).hash = '67bdcdfda76f658887d19b505599ea5d';
export default node;
