import React, { Component } from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { RelayProp } from "react-relay";
import { routerShape, matchShape } from "found/lib/PropTypes";
import { notFound } from "../../utils/Paths";

import classNames from "classnames";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SkipNext from "@material-ui/icons/SkipNext";
import SkipPrevious from "@material-ui/icons/SkipPrevious";
import NextLabeled from "@material-ui/icons/FastForward";
import PrevLabeled from "@material-ui/icons/FastRewind";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { cloneDeep, isEmpty } from "lodash";
import SensorStreamWrapperPopup from "./SensorStreamWrapperPopup";
import DebouncedTextField from "../common/DebouncedTextField";
import SensorStreamFrameSliderIcon from "./SensorStreamFrameSliderIcon";
import { labelFramePath } from "../../utils/Paths";
import {
  KNOWN_OBJECT_BOXES,
  KNOWN_OBJECT_PALLETS,
  KNOWN_OBJECT_ITEMS,
  KNOWN_OBJECT_PALLETS_BINS
} from "../../utils/Enums";
import createCV from "opencv/opencv_js.js";
import {
  isFrameLabelable,
  toggleIsFrameLabelable
} from "../common/FrameLabelable";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";

import updateSensorStream from "../../mutations/UpdateSensorStreamMutation";
import bulkSwitchFaces from "../../mutations/BulkSwitchFacesMutation";
import {
  SensorStreamWrapper_viewer,
  LightingConditions
} from "../../__generated__/SensorStreamWrapper_viewer.graphql";
import {
  FaceSide,
  PalletSurface,
  KnownObject
} from "../../__generated__/FrameViewer_viewer.graphql";
import { LabelCategory } from "../common/canvas/SelectionState.js";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

export type KnownObjectView = KnownObject | "RAW";

/**
 * IMPORTANT INFO FOR DEVELOPER
 * props.viewer.sensorStream.frameNumbers is in order of timestamp
 * frameNumbers(i) gives you the sensor stream index
 * sensor stream indexes are NOT in order of timestamp
 */

/**
 * Returns the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLast immediately returns that element . Otherwise, findLast returns undefined.
 */
function findLast<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): T | undefined {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return array[l];
  }
  return undefined;
}

const localStyles = theme =>
  createStyles({
    layout: {
      width: "auto",
      display: "flex", // Fix IE11 issue.
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    issueButton: {
      marginRight: 8,
      marginLeft: 8
    },
    bottomRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      padding: 16,
      width: "100%"
    },
    labelingDirectionsRoot: {
      flexGrow: 1,
      minHeight: 20
    },
    labelingDirectionsInput: {},
    filteredFramesRow: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: 8,
      margin: 8
    },
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    drawerPaper: {
      whiteSpace: "nowrap",
      width: "100%",
      height: "fit-content",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      overflow: "visible"
    },
    drawerPaperClose: {
      width: "100%",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      width: "100%",
      overflow: "auto"
    },
    actionWrapperBar: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flex: 1
    },
    actionWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingLeft: 8,
      paddingRight: 8
    },
    frameNumber: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: 8
    },
    frameToggles: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 4,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4
    },
    toggleButton: {
      margin: 0
    },
    navigationControls: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: 4,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      width: 320
    },
    canvasHolder: {
      overflow: "hidden",
      width: "100%",
      height: "100vh",
      position: "fixed",
      left: 0,
      top: 0,
      // set flexGrow to expand space not filled by the side drawer.
      flexGrow: 1
    },
    shortcutsPopover: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: 16
    },
    shortcutRow: {
      display: "flex",
      flexDirection: "row"
    },
    bold: {
      fontWeight: "bold"
    },
    brightnessRoot: {
      height: 36,
      width: 80
    },
    frameStrideRoot: {
      height: 36,
      width: 88
    },
    selectFrameNumberRoot: {
      height: 32,
      width: 120,
      paddingRight: 8
    },
    toggleButtonRoot: {
      height: 24,
      width: 24,
      padding: 0
    },
    inputProps: {
      height: "inherit"
    },
    formControlSize: {
      maxHeight: 60
    },
    helperText: {
      position: "relative",
      fontSize: "10px",
      backgroundColor: "#fff",
      margin: "5px 10px",
      zIndex: 0
    }
  });
const styles = combineStyles(localStyles, commonStyles);

type ShowQAPolicy = "Always" | "Never" | "OnNavigation";
interface Props extends WithStyles<typeof styles> {
  viewer: SensorStreamWrapper_viewer;
  children: Object;
  router: routerShape;
  match: matchShape;
  relay: RelayProp;
}
type State = {
  pendingFrameId: number | null;
  openCV: Object | null;
  loadedSensorStreamIndex: number | null;
  loadedFrameTimestamp: number | null;
  loadedFrameId: string | null;
  hasLoadedFrame: boolean;
  creationLabelCategory: LabelCategory;
  labelCategoryToLastKnownObjectType: Map<LabelCategory, KnownObject>;
  creationKnownObject: KnownObject;
  creationFaceSide: FaceSide;
  creationPalletSurface: PalletSurface;
  labeledFrames: ReadonlyArray<Object>;
  bottomDrawerOpen: boolean;
  rotationRadians: number;
  canvasContext: CanvasRenderingContext2D | null;
  canvasScale: number;
  preferredKnownObjectView: KnownObjectView | null;
  shortcutsPopoverAnchor: HTMLElement | null;
  canvasBrightness: number;
  frameStride: number;
  showQAPolicy: ShowQAPolicy;
  showCanvasGrid: boolean;
  showIssuesBar: boolean;
  showDetectedPallets: boolean;
  waitingForSwitchSides: boolean;
};

export interface TopLevelLabelActions {
  setLoadedFrameDetails(
    arg0: {
      indexInSensorStream: number;
      id: string;
      timestamp: string;
    } | null
  ): void;
  canvasBrightness: number;
  canvasScale: number;
  setCanvasScale(arg0: number): void;
  rotationRadians: number;
  setRotationRadians(arg0: number): void;
  preferredKnownObjectView: KnownObjectView | null;
  setPreferredKnownObjectView(arg0: KnownObjectView | null): void;
  requestPrevFrame(): void;
  requestNextFrame(): void;
  requestPrevFilteredFrame(): void;
  requestNextFilteredFrame(): void;
  requestPrevLabeledFrame(): void;
  requestNextLabeledFrame(): void;
  creationLabelCategory: LabelCategory;
  creationKnownObject: KnownObject;
  creationFaceSide: FaceSide;
  creationPalletSurface: PalletSurface;
  setCreationLabelCategory(arg0: LabelCategory): void;
  setCreationKnownObject(arg0: KnownObject): void;
  setCreationFaceSide(arg0: FaceSide): void;
  setCreationPalletSurface(arg0: PalletSurface): void;
  possibleKnownObjects(): Map<KnownObject, string>;
  showQAPolicy: ShowQAPolicy;
  showCanvasGrid: boolean;
  setShowCanvasGrid: (show: boolean) => void;
  showIssuesBar: boolean;
  setShowIssuesBar(arg0: boolean): void;
  showDetectedPallets: boolean;
  setShowDetectedPallets: (show: boolean) => void;
}

const showIssuesUrlParam = "showIssues";

const frameHasLabels = frame => {
  const hasItems = !isEmpty(frame.labeledItems);
  const hasPallets = !isEmpty(frame.labeledPallets);
  return hasItems || hasPallets;
};

const _DEFAULT_PALLET: KnownObject = "PALLET_GMA_BLOCK";
const _DEFAULT_ITEM: KnownObject = "ITEM_AIRBAG";

class SensorStreamWrapper extends Component<Props, State> {
  state = {
    pendingFrameId: null, // frame id to be loaded
    openCV: null,
    loadedSensorStreamIndex: null,
    loadedFrameTimestamp: null,
    loadedFrameId: null,
    hasLoadedFrame: false,
    creationLabelCategory: "PALLET" as LabelCategory,
    labeledFrames: [],
    creationKnownObject: _DEFAULT_PALLET,
    creationFaceSide: "FRONT" as FaceSide,
    creationPalletSurface: "FLOOR" as PalletSurface,
    bottomDrawerOpen: false,
    rotationRadians: 0,
    preferredKnownObjectView: null,
    canvasContext: null,
    canvasScale: 1,
    canvasBrightness: 115,
    shortcutsPopoverAnchor: null,
    frameStride: 1,
    showQAPolicy: "Never" as ShowQAPolicy,
    showCanvasGrid: false,
    showIssuesBar: !!Number.parseInt(
      new URLSearchParams(window.location.search).get(showIssuesUrlParam) ||
        "0",
      10
    ),
    showDetectedPallets: false,
    waitingForSwitchSides: false,
    labelCategoryToLastKnownObjectType: new Map([
      ["PALLET" as LabelCategory, _DEFAULT_PALLET],
      ["ITEM" as LabelCategory, _DEFAULT_ITEM]
    ])
  };

  componentDidMount() {
    if (!this.props.viewer.sensorStream) {
      this.props.router.push(notFound());
      return;
    }
    const canvasContext = this.refs.canvas.getContext("2d");

    fetch(
      "https://storage.googleapis.com/foxbots-labeler-public/opencv_js.wasm"
    )
      .then(response => response.arrayBuffer())
      .then(buffer => {
        createCV({ wasmBinary: buffer }).then(
          (module: { Mat?: any; onRuntimeInitialized: any }) => {
            module.onRuntimeInitialized = () => {
              this.setState({ openCV: module });
            };
            if (module.Mat) {
              this.setState({ openCV: module });
            }
          }
        );
      });

    this.setState({
      canvasContext
    });
  }

  _getFrameIndices = (
    indexOfSensorStream: number,
    framesFilteredByTimestamp: any[]
  ): [number, number] => {
    const { loadedFrameTimestamp } = this.state;
    const {
      viewer: {
        sensorStream: { frameNumbers }
      }
    } = this.props;

    const currentFrameIndex = frameNumbers.indexOf(indexOfSensorStream);

    const prevFrame = findLast(
      framesFilteredByTimestamp,
      (f: { timestamp }) => parseInt(f.timestamp, 10) < loadedFrameTimestamp
    );

    const prevLabeledFrameIndex =
      prevFrame && prevFrame.indexInSensorStream > -1
        ? frameNumbers.indexOf(prevFrame.indexInSensorStream)
        : -1;

    const nextFrame = framesFilteredByTimestamp.find(
      frame => frame.timestamp > loadedFrameTimestamp
    );

    const nextLabeledFrameIndex =
      nextFrame && nextFrame.indexInSensorStream > -1
        ? frameNumbers.indexOf(nextFrame.indexInSensorStream)
        : -1;

    return [
      prevLabeledFrameIndex === currentFrameIndex ? -1 : prevLabeledFrameIndex,
      nextLabeledFrameIndex
    ];
  };

  _getIndexAndFrame = (index: number) => {
    const { frameNumbers, numFrames } = this.props.viewer.sensorStream;
    if (index === null || index < 0 || index >= numFrames) {
      return;
    }
    const sensorStreamIndex = frameNumbers[index];
    this._loadNewFrame(sensorStreamIndex);
  };

  _loadNewFrame = (indexInSensorStream: number) => {
    if (indexInSensorStream === undefined) {
      return;
    }
    const {
      viewer: {
        sensorStream: {
          sensorName,
          run: { runName }
        }
      }
    } = this.props;

    let pushHistory = this.props.router.push;
    let previousLocation = this.props.match.location;
    const pathname = labelFramePath(runName, sensorName, indexInSensorStream);
    const newLocation = {
      ...previousLocation,
      pathname
    };
    this.setState({
      loadedSensorStreamIndex: null,
      pendingFrameId: indexInSensorStream
    });
    pushHistory(newLocation);
  };

  _updatelabelingDirections(labelingDirections: string) {
    const { viewer, relay } = this.props;
    const { sensorStream } = viewer;
    updateSensorStream(relay.environment, {
      id: sensorStream.id,
      allowExport: sensorStream.allowExport,
      lightingConditions: sensorStream.lightingConditions,
      labelingPriority: sensorStream.labelingPriority,
      labelingDirections
    });
  }

  _updateLightingConditions(lightingConditions: LightingConditions) {
    const { viewer, relay } = this.props;
    const { sensorStream } = viewer;
    updateSensorStream(relay.environment, {
      id: sensorStream.id,
      allowExport: sensorStream.allowExport,
      labelingDirections: sensorStream.labelingDirections,
      labelingPriority: sensorStream.labelingPriority,
      lightingConditions
    });
  }

  _handleFrameChange = e => {
    const inputVal = Number.parseInt(e.target.value, 10);
    const sensorStreamIndex =
      this.props.viewer.sensorStream.frameNumbers[inputVal];

    sensorStreamIndex > -1 && this._loadNewFrame(sensorStreamIndex);
  };

  _toggleAllowSensorStreamExport(sensorStream) {
    updateSensorStream(this.props.relay.environment, {
      ...sensorStream,
      allowExport: !sensorStream.allowExport
    });
  }

  render() {
    const { children, viewer, classes, relay } = this.props;
    const { sensorStream, isSupervisor } = viewer;
    if (!sensorStream) {
      return null;
    }
    const {
      loadedSensorStreamIndex,
      loadedFrameTimestamp,
      loadedFrameId,
      pendingFrameId,
      openCV,
      bottomDrawerOpen,
      creationLabelCategory,
      creationFaceSide,
      creationKnownObject,
      creationPalletSurface,
      rotationRadians,
      preferredKnownObjectView,
      canvasContext,
      shortcutsPopoverAnchor,
      canvasScale,
      canvasBrightness,
      frameStride,
      showQAPolicy,
      showCanvasGrid,
      showIssuesBar,
      showDetectedPallets,
      waitingForSwitchSides,
      labelCategoryToLastKnownObjectType
    } = this.state;
    const {
      filteredFrames: nonFilteredFrames,
      frameNumbers,
      sensorName
    } = sensorStream;
    let filteredFrames = cloneDeep(nonFilteredFrames);
    filteredFrames.sort((a, b) => a.timestamp - b.timestamp);

    const loadNewFrame = (sensorStreamIndex: number) =>
      this._loadNewFrame(sensorStreamIndex);
    const setLoadedFrameDetails = (
      details: {
        indexInSensorStream: number;
        id: string;
        timestamp: string;
      } | null
    ) => {
      this.setState({
        pendingFrameId: null,
        loadedSensorStreamIndex: details ? details.indexInSensorStream : null,
        loadedFrameId: details ? details.id : null,
        loadedFrameTimestamp: details ? parseInt(details.timestamp, 10) : null
      });
    };

    const frameOffset = (frameNumber: number, offset: number): number => {
      if (!sensorStream.numFrames) {
        return 0;
      }
      const currentIndex = sensorStream.frameNumbers.indexOf(frameNumber);
      const nextIndex = currentIndex + offset;
      if (nextIndex < 0) {
        return 0;
      }
      if (nextIndex >= sensorStream.numFrames) {
        return sensorStream.numFrames - 1;
      }
      return sensorStream.frameNumbers[nextIndex];
    };

    const labeledFrames = filteredFrames.filter(frameHasLabels);

    const [prevLabeledFrameIndex, nextLabeledFrameIndex] =
      this._getFrameIndices(loadedSensorStreamIndex, labeledFrames);
    const [prevFilteredFrameIndex, nextFilteredFrameIndex] =
      this._getFrameIndices(loadedSensorStreamIndex, filteredFrames);

    const getPrevFiltered = () => {
      this._getIndexAndFrame(prevFilteredFrameIndex);
    };
    const getNextFiltered = () => {
      this._getIndexAndFrame(nextFilteredFrameIndex);
    };

    const getPrevLabeled = () => {
      this._getIndexAndFrame(prevLabeledFrameIndex);
    };
    const getNextLabeled = () => {
      this._getIndexAndFrame(nextLabeledFrameIndex);
    };

    const getPossibleKnownObjects = (
      creationLabelCategory: LabelCategory
    ): Map<KnownObject, string> => {
      switch (creationLabelCategory) {
        case "BOX":
          return KNOWN_OBJECT_BOXES;
        case "PALLET":
          return KNOWN_OBJECT_PALLETS_BINS;
        case "ITEM":
          return KNOWN_OBJECT_ITEMS;
        default:
          return KNOWN_OBJECT_PALLETS;
      }
    };

    const sensorStreamActions: TopLevelLabelActions = {
      setLoadedFrameDetails,
      canvasScale,
      canvasBrightness,
      setCanvasScale: canvasScale => this.setState({ canvasScale }),
      creationLabelCategory,
      creationFaceSide,
      creationKnownObject,
      creationPalletSurface,
      setCreationLabelCategory: newCreationLabelCategory => {
        let objectType = labelCategoryToLastKnownObjectType.get(
          newCreationLabelCategory
        );
        if (!objectType) {
          objectType =
            newCreationLabelCategory === "PALLET"
              ? _DEFAULT_PALLET
              : _DEFAULT_ITEM;
        }
        const newFaceSide =
          newCreationLabelCategory === "ITEM" ? "FRONT" : creationFaceSide;
        this.setState({
          creationLabelCategory: newCreationLabelCategory,
          creationKnownObject: objectType,
          creationFaceSide: newFaceSide,
          labelCategoryToLastKnownObjectType:
            labelCategoryToLastKnownObjectType.set(
              newCreationLabelCategory,
              objectType
            )
        });
      },
      setCreationFaceSide: creationFaceSide =>
        this.setState({ creationFaceSide }),
      setCreationKnownObject: creationKnownObject =>
        this.setState({ creationKnownObject }),
      setCreationPalletSurface: creationPalletSurface =>
        this.setState({ creationPalletSurface }),
      possibleKnownObjects: () =>
        getPossibleKnownObjects(creationLabelCategory),
      rotationRadians,
      setRotationRadians: (rotationRadians: number) =>
        this.setState({ rotationRadians }),
      preferredKnownObjectView,
      setPreferredKnownObjectView: (
        preferredKnownObjectView: KnownObjectView | null
      ) => this.setState({ preferredKnownObjectView }),
      requestNextFilteredFrame: () => getNextFiltered(),
      requestPrevFilteredFrame: () => getPrevFiltered(),
      requestNextLabeledFrame: () => getNextLabeled(),
      requestPrevLabeledFrame: () => getPrevLabeled(),

      requestNextFrame: () =>
        loadNewFrame(frameOffset(loadedSensorStreamIndex || 0, frameStride)),
      requestPrevFrame: () => {
        if (!loadedSensorStreamIndex) {
          return;
        }
        this._loadNewFrame(
          frameOffset(loadedSensorStreamIndex, frameStride * -1)
        );
      },
      showQAPolicy,
      showCanvasGrid,
      setShowCanvasGrid: show => this.setState({ showCanvasGrid: show }),
      showIssuesBar,
      setShowIssuesBar: isOpen => this.setState({ showIssuesBar: isOpen }),
      showDetectedPallets,
      setShowDetectedPallets: show =>
        this.setState({ showDetectedPallets: show })
    };

    const frameCanvas = React.Children.map(children, child =>
      React.cloneElement(child, {
        openCV,
        topLevelLabelActions: sensorStreamActions,
        canvasContext
      })
    );

    const frameNumbersIndex = frameNumbers.indexOf(loadedSensorStreamIndex);

    const preventPrevFiltered =
      loadedSensorStreamIndex === null || prevFilteredFrameIndex < 0;
    const preventNextFiltered =
      loadedSensorStreamIndex === null ||
      (nextFilteredFrameIndex !== null &&
        nextFilteredFrameIndex + 1 >= sensorStream.numFrames) ||
      nextFilteredFrameIndex < 0;

    const preventPrevLabeled =
      loadedSensorStreamIndex === null || prevLabeledFrameIndex < 0;
    const preventNextLabeled =
      loadedSensorStreamIndex === null ||
      (nextLabeledFrameIndex !== null &&
        nextLabeledFrameIndex >= sensorStream.numFrames) ||
      nextLabeledFrameIndex < 0;

    // classNames for styling
    const runMLClassName = sensorStream.allowExport
      ? `${classes.statusReady} ${classes.buttonText} ${classes.formControlSize}`
      : ` ${classes.buttonText} ${classes.formControlSize}`;
    const labelableClassName = ` ${classes.buttonText} ${classes.formControlSize}`;

    const frameIndexToDisplay =
      pendingFrameId !== null ? pendingFrameId : loadedSensorStreamIndex;

    return (
      <div className={classes.layout}>
        <div className={classes.canvasHolder}>
          <canvas
            ref="canvas"
            height={"2000 px"}
            width={"4000 px"}
            style={{ position: "fixed" }}
            id="frame-canvas"
          />
        </div>
        {openCV && frameCanvas}
        <Drawer
          anchor="bottom"
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.bottomDrawerOpen && classes.drawerPaperClose
            )
          }}
          open={this.state.bottomDrawerOpen}
        >
          {/* Always showing */}
          <div className={classes.toolbar}>
            <div className={classes.actionWrapperBar}>
              <div className={classes.actionWrapper}>
                <div className={classes.navigationControls}>
                  <Tooltip title="Prev Labeled">
                    <span>
                      <IconButton
                        color="primary"
                        onClick={() => getPrevLabeled()}
                        variant="outlined"
                        disabled={preventPrevLabeled}
                      >
                        <PrevLabeled />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Prev Filtered">
                    <span>
                      <IconButton
                        color="primary"
                        onClick={() => getPrevFiltered()}
                        variant="outlined"
                        disabled={preventPrevFiltered}
                      >
                        <SkipPrevious />
                      </IconButton>
                    </span>
                  </Tooltip>
                  {frameIndexToDisplay !== null &&
                    frameIndexToDisplay !== undefined && (
                      <DebouncedTextField
                        classes={{
                          root: classes.selectFrameNumberRoot
                        }}
                        InputProps={{
                          classes: {
                            root: classes.inputProps
                          }
                        }}
                        variant="outlined"
                        label="Frame"
                        helperText={`${sensorName}/frames/${loadedSensorStreamIndex}`}
                        FormHelperTextProps={{
                          className: classes.helperText
                        }}
                        type="number"
                        min="0"
                        max={`${sensorStream.numFrames - 1}`}
                        value={frameNumbersIndex} // eslint-disable-next-line
                        inputProps={{ step: frameStride }}
                        onChangeDebounced={e => this._handleFrameChange(e)}
                        disabled={loadedSensorStreamIndex === null}
                      />
                    )}
                  <Tooltip title="Next Filtered">
                    <span>
                      <IconButton
                        color="primary"
                        onClick={() => getNextFiltered()}
                        disabled={preventNextFiltered}
                        variant="outlined"
                      >
                        <SkipNext />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Next Labeled">
                    <span>
                      <IconButton
                        color="primary"
                        onClick={() => getNextLabeled()}
                        disabled={preventNextLabeled}
                        variant="outlined"
                      >
                        <NextLabeled />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
                <TextField
                  classes={{ root: classes.brightnessRoot }}
                  variant="outlined"
                  label="Brightness"
                  type="number"
                  value={canvasBrightness}
                  InputProps={{
                    classes: { root: classes.inputProps }
                  }} // eslint-disable-next-line
                  inputProps={{ step: 15 }}
                  style={{ marginRight: 8, marginLeft: 48 }}
                  onChange={e =>
                    this.setState({
                      canvasBrightness: Number.parseInt(e.target.value, 10)
                    })
                  }
                />
                <Tooltip title="Changes the number of (unfiltered) frames skipped when advancing frames.">
                  <TextField
                    classes={{ root: classes.frameStrideRoot }}
                    variant="outlined"
                    label="Frame Stride"
                    type="number" // eslint-disable-next-line
                    InputProps={{
                      classes: { root: classes.inputProps }
                    }}
                    style={{ marginRight: 16 }}
                    value={frameStride}
                    onChange={e =>
                      this.setState({
                        frameStride: Number.parseInt(e.target.value, 10)
                      })
                    }
                  />
                </Tooltip>
              </div>

              <div className={classes.actionWrapper}>
                <Typography
                  style={{ paddingRight: 24 }}
                  color={"textSecondary"}
                >
                  {sensorStream.run.runName}
                </Typography>
                <Typography
                  style={{ paddingRight: 24 }}
                  color={"textSecondary"}
                >
                  {labeledFrames.length} / {sensorStream.numFrames} frames
                  labeled
                </Typography>
              </div>

              <div className={classes.actionWrapper}>
                <FormControl className={classes.formControlSize}>
                  <InputLabel htmlFor="show-qa-policy">Show QA</InputLabel>
                  <Select
                    variant="outlined"
                    value={showQAPolicy}
                    onChange={e =>
                      this.setState({
                        showQAPolicy: e.target.value as ShowQAPolicy
                      })
                    }
                    input={<Input id="show-qa-policy" />}
                  >
                    <MenuItem value="OnNavigation">
                      <ListItemText primary="When navigating" />
                    </MenuItem>
                    <MenuItem value="Always">
                      <ListItemText primary="Always" />
                    </MenuItem>
                    <MenuItem value="Never">
                      <ListItemText primary="Never" />
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  className={classes.issueButton}
                  onClick={() => {
                    this.setState({
                      showIssuesBar: !showIssuesBar
                    });

                    const url = new URL(window.location);
                    url.searchParams.set(
                      showIssuesUrlParam,
                      showIssuesBar ? "0" : "1"
                    );
                    window.history.replaceState("", "", url);
                  }}
                  variant="outlined"
                >
                  {showIssuesBar ? "Hide Issues" : "Show Issues"}
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showCanvasGrid}
                      onChange={() =>
                        this.setState({
                          showCanvasGrid: !showCanvasGrid
                        })
                      }
                      color="primary"
                    />
                  }
                  label={
                    <Typography color="textSecondary" variant="body1">
                      100x100 grid
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDetectedPallets}
                      onChange={() =>
                        this.setState({
                          showDetectedPallets: !showDetectedPallets
                        })
                      }
                      color="primary"
                    />
                  }
                  label={
                    <Typography color="textSecondary" variant="body1">
                      Detections
                    </Typography>
                  }
                />
                <Button
                  color="default"
                  aria-owns={
                    !!shortcutsPopoverAnchor ? "shortcut-popper" : undefined
                  }
                  aria-haspopup="true"
                  variant="outlined"
                  onClick={e =>
                    this.setState({
                      shortcutsPopoverAnchor: e.currentTarget
                    })
                  }
                >
                  Shortcuts
                </Button>

                <Popover
                  id="shortcut-popper"
                  open={!!shortcutsPopoverAnchor}
                  anchorEl={shortcutsPopoverAnchor}
                  onClose={() =>
                    this.setState({ shortcutsPopoverAnchor: null })
                  }
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                >
                  {/*These are defined in CanvasEventHandler*/}
                  <SensorStreamWrapperPopup />
                </Popover>
              </div>
            </div>
            {bottomDrawerOpen && (
              <IconButton
                onClick={() => this.setState({ bottomDrawerOpen: false })}
              >
                <ExpandMore />
              </IconButton>
            )}
            {!bottomDrawerOpen && (
              <IconButton
                onClick={() => this.setState({ bottomDrawerOpen: true })}
              >
                <ExpandLess />
              </IconButton>
            )}
          </div>
          {bottomDrawerOpen && (
            <>
              <div className={classes.filteredFramesRow}>
                {filteredFrames.map(frame => {
                  return (
                    <SensorStreamFrameSliderIcon
                      viewer={viewer}
                      loadFrame={loadNewFrame}
                      loadedSensorStreamIndex={
                        pendingFrameId !== null && pendingFrameId !== undefined
                          ? pendingFrameId
                          : loadedSensorStreamIndex
                      }
                      loadedFrameTimestamp={loadedFrameTimestamp}
                      frame={frame}
                      key={frame.id}
                    />
                  );
                })}
              </div>
              <div className={classes.bottomRow}>
                {/* mark (un)labelable */}
                <Button
                  onClick={() => {
                    loadedFrameId && toggleIsFrameLabelable(loadedFrameId);
                    this.forceUpdate();
                  }}
                  className={labelableClassName}
                  variant="outlined"
                  disabled={!loadedFrameId}
                >
                  {loadedFrameId && isFrameLabelable(loadedFrameId)
                    ? "Mark Unlabelable"
                    : "Mark Labelable"}
                </Button>
                {isSupervisor && (
                  <React.Fragment>
                    <Tooltip title="Send to Machine Learning Loop.">
                      <Button
                        variant="outlined"
                        className={runMLClassName}
                        onClick={() =>
                          this._toggleAllowSensorStreamExport({
                            id: sensorStream.id,
                            labelingDirections: sensorStream.labelingDirections,
                            labelingPriority: sensorStream.labelingPriority,
                            lightingConditions: sensorStream.lightingConditions,
                            allowExport: sensorStream.allowExport
                          })
                        }
                      >
                        {sensorStream.allowExport
                          ? "Run Marked Done"
                          : "Mark Run Done"}
                      </Button>
                    </Tooltip>
                  </React.Fragment>
                )}

                {isSupervisor && (
                  <React.Fragment>
                    <Button
                      disabled={waitingForSwitchSides}
                      variant="outlined"
                      onClick={() => {
                        this.setState({
                          waitingForSwitchSides: true
                        });
                        bulkSwitchFaces(
                          relay.environment,
                          { sensorStreamId: sensorStream.id },
                          () =>
                            this.setState({
                              waitingForSwitchSides: false
                            }),
                          () =>
                            this.setState({
                              waitingForSwitchSides: false
                            })
                        );
                      }}
                    >
                      Switch sides
                    </Button>
                  </React.Fragment>
                )}
                {/* Lighting Conditions */}
                <FormControl className={classes.formControlSize}>
                  <InputLabel htmlFor="select-lighting-conditions">
                    Lighting Conditions
                  </InputLabel>
                  <Select
                    input={<Input id="select-lighting-conditions" />}
                    value={sensorStream.lightingConditions}
                    onChange={e =>
                      this._updateLightingConditions(e.target.value)
                    }
                  >
                    <MenuItem value="OVERHEAD_NORMAL">
                      Overhead (normal)
                    </MenuItem>
                    <MenuItem value="OVERHEAD_FRIENDLY">
                      Overhead (friendly)
                    </MenuItem>
                    <MenuItem value="BACKLIT">Backlit</MenuItem>
                    <MenuItem value="ILLUMINATED_BY_ROBOT">
                      Illuminated by robot
                    </MenuItem>
                    <MenuItem value="DARK">Dark</MenuItem>
                    <MenuItem value="VERY_DARK">Very dark</MenuItem>
                  </Select>
                </FormControl>
                {/* Lighting Directions (Notes) */}
                <TextField
                  classes={{ root: classes.labelingDirectionsRoot }}
                  style={{ marginLeft: 8 }}
                  InputProps={{
                    classes: {
                      root: classes.labelingDirectionsInput
                    }
                  }}
                  variant="outlined"
                  multiline
                  label="Notes"
                  onBlur={e => this._updatelabelingDirections(e.target.value)}
                  defaultValue={sensorStream.labelingDirections}
                />
              </div>
            </>
          )}
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(SensorStreamWrapper, {
    viewer: graphql`
      fragment SensorStreamWrapper_viewer on Viewer {
        id
        username
        isSupervisor
        sensorStream: sensorStreamByName(
          runName: $runName
          sensorName: $sensorName
        ) {
          ...SensorStreamFrameSlider_sensorStream
          id
          labelingDirections
          lightingConditions
          labelingPriority
          sensorName
          numFrames
          allowExport
          run {
            runName
          }
          frameNumbers
          filteredFrames {
            ...SensorStreamFrameSliderIcon_frame
            id
            mlReady
            labeledPallets {
              id
              objectType
            }
            labeledItems {
              id
              objectType
            }
            indexInSensorStream
            timestamp
          }
        }
      }
    `
  })
);
