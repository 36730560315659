import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import {
  CreateLabeledItemMutationInput,
  CreateLabeledItemMutationResponse
} from "../__generated__/CreateLabeledItemMutation.graphql";

import { FrameViewer_viewer } from "../__generated__/FrameViewer_viewer.graphql";

const mutation = graphql`
  mutation CreateLabeledItemMutation($input: CreateLabeledItemMutationInput!) {
    createLabeledItem(input: $input) {
      clientMutationId
      labeledItem {
        id
        objectType
        author {
          id
          username
        }
        number
        thwartedPallets {
          id
          number
        }
        frame {
          id
          labeledBoxesCount
          labeledBoxes {
            id
          }
          labeledItems {
            id
          }
          labeledPallets {
            boxes {
              id
            }
          }
        }
        shape {
          id
          faces {
            id
            side
            parentShape {
              id
              faces {
                id
              }
            }
            corners {
              id
              x
              y
              visibility
            }
          }
        }
      }
    }
  }
`;

let tempID = 0;
function createLabeledItem(
  viewer: FrameViewer_viewer,
  environment: Environment,
  input: CreateLabeledItemMutationInput,
  onCompleted?: (
    response: CreateLabeledItemMutationResponse | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  let mutationId: string = (tempID++).toString();
  const fullInput: CreateLabeledItemMutationInput = {
    clientMutationId: mutationId,
    ...input
  };
  commitMutation(environment, {
    mutation,
    variables: {
      input: fullInput
    },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let frame = store.get(input.frameId);
      if (!frame) {
        return;
      }
      const author = store.get(viewer.id);
      if (!author) {
        return;
      }
      let existingLabels = frame.getLinkedRecords("labeledItems");
      if (!existingLabels) {
        existingLabels = [];
      }
      const labelId = "client:createLabelLabel:" + mutationId;
      const shapeId = "client:createLabelShape:" + mutationId;
      const faceId = "client:createLabelFace:" + mutationId;
      const cornerId = "client:createLabelCorner:" + mutationId;

      let label = store.create(labelId, "LabeledItem");
      let shape = store.create(shapeId, "Cuboid");
      let face = store.create(faceId, "CuboidFace");
      let corner = store.create(cornerId, "CuboidCorner");

      label.setValue(labelId, "id");
      label.setValue("", "number");
      shape.setValue(shapeId, "id");
      face.setValue(faceId, "id");
      corner.setValue(cornerId, "id");

      corner.setValue(input.faces[0].newCorners[0].x, "x");
      corner.setValue(input.faces[0].newCorners[0].y, "y");
      corner.setValue(input.faces[0].newCorners[0].visibility, "visibility");
      corner.setValue(cornerId, "id");

      face.setLinkedRecords([corner], "corners");
      shape.setLinkedRecords([face], "faces");
      shape.setLinkedRecords([corner], "corners");
      face.setLinkedRecord(shape, "parentShape");
      label.setLinkedRecords([], "thwartedPallets");
      label.setLinkedRecord(shape, "shape");
      label.setLinkedRecord(frame, "frame");
      label.setLinkedRecord(author, "author");
      frame.setLinkedRecords([label, ...existingLabels], "labeledItems");
    }
  });
}

export default createLabeledItem;
