import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { BulkSwitchFacesMutationInput } from "../__generated__/BulkSwitchFacesMutation.graphql";

const mutation = graphql`
  mutation BulkSwitchFacesMutation($input: BulkSwitchFacesMutationInput!) {
    bulkSwitchFaces(input: $input) {
      pallets {
        id
        shape {
          id
          faces {
            id
            side
          }
        }
      }
    }
  }
`;

function bulkSwitchFaces(
  environment: Environment,
  input: BulkSwitchFacesMutationInput,
  onCompleted: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void | null,
  onError: (error: Error) => void | null
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError
  });
}

export default bulkSwitchFaces;
