import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { UpdateSensorStreamMutationInput } from "../__generated__/UpdateSensorStreamMutation.graphql";

const mutation = graphql`
  mutation UpdateSensorStreamMutation(
    $input: UpdateSensorStreamMutationInput!
  ) {
    updateSensorStream(input: $input) {
      sensorStream {
        id
        labelingDirections
        labelingPriority
        lightingConditions
        allowExport
      }
    }
  }
`;

function updateSensorStream(
  environment: Environment,
  input: UpdateSensorStreamMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticResponse: {
      updateSensorStream: {
        sensorStream: input
      }
    }
  });
}

export default updateSensorStream;
