/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type DeleteFaceMutationInput = {
    readonly id: string;
    readonly clientMutationId?: string | null;
};
export type DeleteFaceMutationVariables = {
    input: DeleteFaceMutationInput;
};
export type DeleteFaceMutationResponse = {
    readonly deleteFace: {
        readonly cuboid: {
            readonly id: string;
            readonly faces: ReadonlyArray<{
                readonly id: string;
            }>;
        };
        readonly frame: {
            readonly labeledBoxes: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly labeledPallets: ReadonlyArray<{
                readonly id: string;
                readonly frontPockets: {
                    readonly id: string;
                    readonly left: {
                        readonly id: string;
                        readonly corners: ReadonlyArray<{
                            readonly id: string;
                            readonly x: number;
                            readonly y: number;
                            readonly visibility: Visibility;
                        }>;
                    } | null;
                    readonly right: {
                        readonly id: string;
                        readonly corners: ReadonlyArray<{
                            readonly id: string;
                            readonly x: number;
                            readonly y: number;
                            readonly visibility: Visibility;
                        }>;
                    } | null;
                } | null;
                readonly sidePockets: {
                    readonly id: string;
                    readonly left: {
                        readonly id: string;
                        readonly corners: ReadonlyArray<{
                            readonly id: string;
                            readonly x: number;
                            readonly y: number;
                            readonly visibility: Visibility;
                        }>;
                    } | null;
                    readonly right: {
                        readonly id: string;
                        readonly corners: ReadonlyArray<{
                            readonly id: string;
                            readonly x: number;
                            readonly y: number;
                            readonly visibility: Visibility;
                        }>;
                    } | null;
                } | null;
            }>;
        };
    } | null;
};
export type DeleteFaceMutation = {
    readonly response: DeleteFaceMutationResponse;
    readonly variables: DeleteFaceMutationVariables;
};



/*
mutation DeleteFaceMutation(
  $input: DeleteFaceMutationInput!
) {
  deleteFace(input: $input) {
    cuboid {
      id
      faces {
        id
      }
    }
    frame {
      labeledBoxes {
        id
      }
      labeledPallets {
        id
        frontPockets {
          id
          left {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
          right {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
        }
        sidePockets {
          id
          left {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
          right {
            id
            corners {
              id
              x
              y
              visibility
            }
          }
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteFaceMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "cuboid",
  "storageKey": null,
  "args": null,
  "concreteType": "Cuboid",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "faces",
      "storageKey": null,
      "args": null,
      "concreteType": "CuboidFace",
      "plural": true,
      "selections": (v3/*: any*/)
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labeledBoxes",
  "storageKey": null,
  "args": null,
  "concreteType": "LabeledBox",
  "plural": true,
  "selections": (v3/*: any*/)
},
v6 = [
  (v2/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "corners",
    "storageKey": null,
    "args": null,
    "concreteType": "QuadCorner",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "x",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "y",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "visibility",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v7 = [
  (v2/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "left",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v6/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "right",
    "storageKey": null,
    "args": null,
    "concreteType": "Quad",
    "plural": false,
    "selections": (v6/*: any*/)
  }
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labeledPallets",
  "storageKey": null,
  "args": null,
  "concreteType": "LabeledPallet",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "frontPockets",
      "storageKey": null,
      "args": null,
      "concreteType": "PalletPocket",
      "plural": false,
      "selections": (v7/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sidePockets",
      "storageKey": null,
      "args": null,
      "concreteType": "PalletPocket",
      "plural": false,
      "selections": (v7/*: any*/)
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteFaceMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteFace",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFaceMutationPayload",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "frame",
            "storageKey": null,
            "args": null,
            "concreteType": "Frame",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteFaceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteFace",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFaceMutationPayload",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "frame",
            "storageKey": null,
            "args": null,
            "concreteType": "Frame",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v8/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteFaceMutation",
    "id": null,
    "text": "mutation DeleteFaceMutation(\n  $input: DeleteFaceMutationInput!\n) {\n  deleteFace(input: $input) {\n    cuboid {\n      id\n      faces {\n        id\n      }\n    }\n    frame {\n      labeledBoxes {\n        id\n      }\n      labeledPallets {\n        id\n        frontPockets {\n          id\n          left {\n            id\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n          right {\n            id\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n        }\n        sidePockets {\n          id\n          left {\n            id\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n          right {\n            id\n            corners {\n              id\n              x\n              y\n              visibility\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0ceac19ea9dea27fd2a731ea4d9dd84e';
export default node;
