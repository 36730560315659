import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { UpdateLabelingIssueMutationInput } from "../__generated__/UpdateLabelingIssueMutation.graphql";

const mutation = graphql`
  mutation UpdateLabelingIssueMutation(
    $input: UpdateLabelingIssueMutationInput!
  ) {
    updateLabelingIssue(input: $input) {
      issue {
        id
        status
      }
    }
  }
`;

function updateLabelingIssue(
  environment: Environment,
  input: UpdateLabelingIssueMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticResponse: {
      updateLabelingIssue: {
        issue: {
          id: input.issueId,
          status: input.status
        }
      }
    }
  });
}

export default updateLabelingIssue;
