import React from "react";
import { graphql, createFragmentContainer } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { LabelSelector } from "../SelectionState";
import { LabelMutators, Label } from "../LabelMutators";

import { LabelFaceListItem_face } from "../../../../__generated__/LabelFaceListItem_face.graphql";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { Viewer } from "../Utils";
import LabelFaceCorner from "./LabelFaceCorner";
import { ToggleButton } from "./LabelCardHeader";
import { Divider } from "@material-ui/core";

const styles = theme =>
  createStyles({
    labelDetailsText: {
      textTransform: "capitalize"
    },
    button: {
      margin: 0
    },
    wrapper: {
      padding: 0,
      margin: 0,
      borderWidth: 2,
      borderRadius: 4
    },
    wrapperExpanded: {
      padding: 0,
      margin: 0,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: blueGrey[400],
      borderRadius: 4
    },
    cardHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    },
    headerInfo: {
      display: "flex",
      padding: 0,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    },
    cornerPaper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: theme.palette.background.paper
    },
    cornerList: {
      width: "100%"
    },
    listItemRoot: {
      padding: "0px 4px",
      paddingLeft: 24,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: 50
    },
    pocket: {
      display: "flex",
      flex: 1
    }
  });

type Props = {
  classes: any;
  viewer: Viewer;
  face: LabelFaceListItem_face;
  faceName: string;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  label: Label;
  palletPockets;
};

type State = {
  showPockets: boolean | null;
  showCorners: boolean;
};

class LabelFaceListItem extends React.Component<Props, State> {
  state = {
    showPockets: null,
    showCorners: false
  };

  toggleCollapse = () => {
    this.setState(state => ({ showPockets: !state.showPockets }));
  };

  componentDidUpdate() {
    const { face, labelSelector } = this.props;
    const { showPockets } = this.state;
    const isEditingFace = labelSelector.currentCreationFaceId() === face.id;
    const shouldBeOpen = isEditingFace || labelSelector.isFaceSelected(face);
    if (shouldBeOpen && showPockets === null) {
      this.setState({ showPockets: true });
    }
  }

  _renderEditingPanel() {
    const { classes, face, labelSelector, labelMutator, label } =
      this.props;
    const labelId = label.id;
    const isEditingFace = labelSelector.currentCreationFaceId() === face.id;
    const onClick = () => labelMutator.face.resume(face.id, labelId);

    if (!isEditingFace && face.corners.length < 4) {
      return (
        <Button
          onClick={onClick}
          color="primary"
          fullWidth
          variant={"contained"}
          className={classes.button}
        >
          Resume
        </Button>
      );
    } else if (isEditingFace) {
      return <Typography color="textSecondary">Creating...</Typography>;
    }
  }

  _renderPocketsCollapsePanel() {
    const { classes } = this.props;
    const { showPockets } = this.state;

    return (
      <Collapse in={!!showPockets} timeout="auto" unmountOnExit>
        <Paper className={classes.cornerPaper}>
          <div className={classes.cardHeader}>
            <>{this._renderPocket("Left")}</>
            <>{this._renderPocket("Right")}</>
          </div>
        </Paper>
      </Collapse>
    );
  }
  _renderCornersCollapsePanel() {
    const { classes, face, labelSelector, labelMutator } = this.props;
    const { showCorners } = this.state;
    return (
      <Collapse in={showCorners} className={classes.cornerList}>
        <List dense disablePadding className={classes.cornerList}>
          {face.corners.map((c, idx) => (
            <LabelFaceCorner
              corner={c}
              labelSelector={labelSelector}
              labelMutator={labelMutator}
              idx={idx}
              faceId={face.id}
              key={`${face.id}-${idx}`}
            />
          ))}
        </List>
      </Collapse>
    );
  }
  _renderPocket(pocketName: string) {
    const { classes, face, labelSelector, labelMutator, palletPockets } =
      this.props;
    const isLeftPocket = pocketName === "Left";

    const palletPocket = isLeftPocket
      ? palletPockets?.left
      : palletPockets?.right;

    return (
      <div className={classes.pocket}>
        {palletPocket && (
          <Button
            className={classes.pocket}
            variant={"outlined"}
            onClick={() =>
              labelMutator.label.pallet.deletePocket(face.id, isLeftPocket)
            }
          >
            Delete {pocketName} Pocket
          </Button>
        )}
        {!palletPocket && (
          <Button
            className={classes.pocket}
            variant={"outlined"}
            onClick={() =>
              labelSelector.startCreatingPalletPocket(face.id, isLeftPocket)
            }
          >
            Create {pocketName} Pocket
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { classes, face, labelSelector, labelMutator, faceName } = this.props;
    const { showPockets, showCorners } = this.state;
    const onClick = () => labelSelector.selectFace(face);
    const creatingLoad = face.corners ? false : true;
    const isLoad = faceName.includes("load");

    return (
      <div>
        <ListItem
          classes={{ root: classes.listItemRoot }}
          dense
          selected={face && labelSelector.isFaceSelected(face)}
        >
          <ListItemText
            onClick={onClick}
            primary={faceName}
            className={classes.labelDetailsText}
          />
          <ToggleButton
            message={"Corners"}
            showContent={showCorners}
            updateContent={() =>
              this.setState({
                showCorners: !showCorners
              })
            }
          />
          {!isLoad ? (
            <ToggleButton
              message={"Pockets"}
              showContent={showPockets}
              updateContent={this.toggleCollapse}
            />
          ) : (
            <div
              style={{
                height: 50,
                width: 90,
                padding: "4px 8px"
              }}
            />
          )}
          <Button
            size={"small"}
            onClick={() => labelMutator.face.delete(face.id)}
          >
            Delete Face
          </Button>
        </ListItem>

        <Divider />

        <>{!creatingLoad && showCorners && this._renderEditingPanel()}</>
        <>{!creatingLoad && this._renderCornersCollapsePanel()}</>
        <>{!isLoad && this._renderPocketsCollapsePanel()}</>
      </div>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(LabelFaceListItem, {
    face: graphql`
      fragment LabelFaceListItem_face on CuboidFace {
        id
        side
        corners {
          id
          x
          y
          visibility
        }
      }
    `
  })
);
