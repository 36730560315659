/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CameraPosition = "GRIPPER" | "HAND_HELD" | "PELVIS" | "UNKNOWN" | "UNSPECIFIED" | "%future added value";
export type DatasetType = "TEST" | "TRAINING" | "VALIDATION" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type LabelingActivityTableRefetchQueryVariables = {
    knownObjects?: ReadonlyArray<KnownObject> | null;
    cameraPositions?: ReadonlyArray<CameraPosition> | null;
    runName?: string | null;
};
export type LabelingActivityTableRefetchQueryResponse = {
    readonly viewer: {
        readonly labelCounts: ReadonlyArray<{
            readonly datasetType: DatasetType | null;
            readonly numLabels: number | null;
            readonly numLabeledFrames: number | null;
            readonly numSensorStreams: number | null;
        }>;
    } | null;
};
export type LabelingActivityTableRefetchQuery = {
    readonly response: LabelingActivityTableRefetchQueryResponse;
    readonly variables: LabelingActivityTableRefetchQueryVariables;
};



/*
query LabelingActivityTableRefetchQuery(
  $knownObjects: [KnownObject!]
  $cameraPositions: [CameraPosition!]
  $runName: String
) {
  viewer {
    labelCounts(knownObjects: $knownObjects, cameraPositions: $cameraPositions, runName: $runName) {
      datasetType
      numLabels
      numLabeledFrames
      numSensorStreams
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "knownObjects",
    "type": "[KnownObject!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cameraPositions",
    "type": "[CameraPosition!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "runName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labelCounts",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "cameraPositions",
      "variableName": "cameraPositions"
    },
    {
      "kind": "Variable",
      "name": "knownObjects",
      "variableName": "knownObjects"
    },
    {
      "kind": "Variable",
      "name": "runName",
      "variableName": "runName"
    }
  ],
  "concreteType": "LabelCounts",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "datasetType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numLabels",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numLabeledFrames",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numSensorStreams",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LabelingActivityTableRefetchQuery",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LabelingActivityTableRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "LabelingActivityTableRefetchQuery",
    "id": null,
    "text": "query LabelingActivityTableRefetchQuery(\n  $knownObjects: [KnownObject!]\n  $cameraPositions: [CameraPosition!]\n  $runName: String\n) {\n  viewer {\n    labelCounts(knownObjects: $knownObjects, cameraPositions: $cameraPositions, runName: $runName) {\n      datasetType\n      numLabels\n      numLabeledFrames\n      numSensorStreams\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '59f80b1121f557cf39f5f39ec40a1a17';
export default node;
