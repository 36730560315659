/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Dashboard_viewer = {
    readonly id: string;
    readonly username: string | null;
    readonly isAnonymous: boolean | null;
    readonly " $fragmentRefs": FragmentRefs<"ListSensorStreams_viewer" | "ListHighPrioritySensorStreams_viewer" | "ListViewerLabeledSensorStreams_viewer">;
    readonly " $refType": "Dashboard_viewer";
};
export type Dashboard_viewer$data = Dashboard_viewer;
export type Dashboard_viewer$key = {
    readonly " $data"?: Dashboard_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"Dashboard_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "Dashboard_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAnonymous",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ListSensorStreams_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ListHighPrioritySensorStreams_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ListViewerLabeledSensorStreams_viewer",
      "args": null
    }
  ]
};
(node as any).hash = 'ca7af00333841a81c11e50574aab750e';
export default node;
