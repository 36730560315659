/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_LabelingReport_QueryVariables = {};
export type routes_LabelingReport_QueryResponse = {
    readonly viewer: {
        readonly isAnonymous: boolean | null;
        readonly isApprovedForAccess: boolean | null;
        readonly " $fragmentRefs": FragmentRefs<"LabelingReport_viewer">;
    } | null;
};
export type routes_LabelingReport_Query = {
    readonly response: routes_LabelingReport_QueryResponse;
    readonly variables: routes_LabelingReport_QueryVariables;
};



/*
query routes_LabelingReport_Query {
  viewer {
    isAnonymous
    isApprovedForAccess
    ...LabelingReport_viewer
    id
  }
}

fragment LabelingReportTable_viewer on Viewer {
  id
  labelerOverviews(duration: WEEK) {
    labeler {
      username
      id
    }
    numPallets
    numItems
    numLoads
    numBins
    numLabels
    numIssues
    numOpenIssues
    numPockets
  }
}

fragment LabelingReport_viewer on Viewer {
  id
  ...LabelingReportTable_viewer
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAnonymous",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isApprovedForAccess",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routes_LabelingReport_Query",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "LabelingReport_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes_LabelingReport_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labelerOverviews",
            "storageKey": "labelerOverviews(duration:\"WEEK\")",
            "args": [
              {
                "kind": "Literal",
                "name": "duration",
                "value": "WEEK"
              }
            ],
            "concreteType": "LabelerOverview",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "labeler",
                "storageKey": null,
                "args": null,
                "concreteType": "LabelerUser",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "username",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numPallets",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numItems",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numLoads",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numBins",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numLabels",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numIssues",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numOpenIssues",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numPockets",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routes_LabelingReport_Query",
    "id": null,
    "text": "query routes_LabelingReport_Query {\n  viewer {\n    isAnonymous\n    isApprovedForAccess\n    ...LabelingReport_viewer\n    id\n  }\n}\n\nfragment LabelingReportTable_viewer on Viewer {\n  id\n  labelerOverviews(duration: WEEK) {\n    labeler {\n      username\n      id\n    }\n    numPallets\n    numItems\n    numLoads\n    numBins\n    numLabels\n    numIssues\n    numOpenIssues\n    numPockets\n  }\n}\n\nfragment LabelingReport_viewer on Viewer {\n  id\n  ...LabelingReportTable_viewer\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'e06313081a4193eec87653579b593e5b';
export default node;
