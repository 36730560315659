import React from "react";
import { graphql, createRefetchContainer, RelayRefetchProp } from "react-relay";
import { Disposable } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import LoadingScreen from "../common/LoadingScreen";
import LabelingIssueListItem from "./LabelingIssueListItem";

import { ListIssuesForViewer_viewer } from "../../__generated__/ListIssuesForViewer_viewer.graphql";

const styles = theme =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block"
      }
    },
    appBar: {},
    wrapper: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      margin: 32
    },
    toolbarRoot: {
      flexWrap: "wrap"
    },
    list: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start"
    }
  });

interface Props extends WithStyles<typeof styles> {
  viewer: ListIssuesForViewer_viewer;
  relay: RelayRefetchProp;
}
type State = {
  showNeedsLabelerAttention: boolean;
  showNeedsReviewerAttention: boolean;
  showResolved: boolean;
  pendingRefetch: Disposable | null;
  isLoadingMore: boolean;
};

class ListIssuesForViewer extends React.Component<Props, State> {
  state = {
    showNeedsLabelerAttention: true,
    showNeedsReviewerAttention: true,
    showResolved: false,
    runNameQuery: null,
    pendingRefetch: null,
    isLoadingMore: false
  };
  render() {
    const { viewer, classes } = this.props;
    const {
      pendingRefetch,
      isLoadingMore,
      showNeedsReviewerAttention,
      showNeedsLabelerAttention,
      showResolved
    } = this.state;

    const { endCursor } = this.props.viewer.issuesForViewer.pageInfo;
    return (
      <div className={classes.wrapper}>
        <AppBar color="default" position="static" className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarRoot }}>
            <Typography className={classes.title} color="inherit" noWrap>
              Issues for you
            </Typography>
            <div className={classes.grow} />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => {
                      this.setState(
                        {
                          showNeedsLabelerAttention: e.currentTarget.checked
                        },
                        () => this._refetch()
                      );
                    }}
                    checked={showNeedsLabelerAttention}
                  />
                }
                label={"Needs your attention"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => {
                      this.setState(
                        {
                          showNeedsReviewerAttention: e.currentTarget.checked
                        },
                        () => this._refetch()
                      );
                    }}
                    checked={showNeedsReviewerAttention}
                  />
                }
                label={"Needs reviewer's attention"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => {
                      this.setState(
                        {
                          showResolved: e.currentTarget.checked
                        },
                        () => this._refetch()
                      );
                    }}
                    checked={showResolved}
                  />
                }
                label={"Is resolved"}
              />
            </div>
          </Toolbar>
        </AppBar>
        <List className={classes.list}>
          {viewer.issuesForViewer.edges.length === 0 && (
            <Typography>No issues match your description.</Typography>
          )}
          {/*If loading more, show loading icon on bottom*/}
          {pendingRefetch && !isLoadingMore && <LoadingScreen />}
          {viewer.issuesForViewer &&
            viewer.issuesForViewer.edges.map(({ node: issue }) => (
              <LabelingIssueListItem
                key={issue.id}
                issue={issue}
                viewer={viewer}
                sensorStream={issue}
              />
            ))}
          {isLoadingMore && <LoadingScreen />}
        </List>
        <Button
          fullWidth
          disabled={
            pendingRefetch !== null ||
            !viewer.issuesForViewer.pageInfo.hasNextPage
          }
          onClick={() => this._refetch(endCursor)}
        >
          Load More
        </Button>
      </div>
    );
  }

  _refetch = (after: string | null = null) => {
    const {
      pendingRefetch,
      showResolved,
      showNeedsLabelerAttention,
      showNeedsReviewerAttention
    } = this.state;
    if (pendingRefetch) {
      pendingRefetch.dispose();
    }
    const statuses = [];
    if (showResolved) {
      statuses.push("RESOLVED");
    }
    if (showNeedsLabelerAttention) {
      statuses.push("NEEDS_LABELER_ATTENTION");
    }
    if (showNeedsReviewerAttention) {
      statuses.push("NEEDS_QA_ATTENTION");
    }
    const refetchVariables = fragmentVariables => {
      const countIssuesForViewer = fragmentVariables.countIssuesForViewer;
      return {
        onlyForViewer: true,
        countIssuesForViewer,
        statuses,
        after
      };
    };
    this.setState({
      isLoadingMore: !!after,
      pendingRefetch: this.props.relay.refetch(
        refetchVariables,
        null, // Use the refetchVariables as renderVariables
        () => this.setState({ isLoadingMore: false, pendingRefetch: null }),
        { force: true } // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
      )
    });
  };
}

export default withStyles(styles, { withTheme: true })(
  createRefetchContainer(
    ListIssuesForViewer,
    {
      viewer: graphql`
        fragment ListIssuesForViewer_viewer on Viewer
        @argumentDefinitions(
          countIssuesForViewer: { type: "Int", defaultValue: 15 }
          onlyForViewer: { type: "Boolean", defaultValue: true }
        ) {
          id
          issuesForViewer: issues(
            first: $countIssuesForViewer
            onlyForViewer: $onlyForViewer
            statuses: [NEEDS_LABELER_ATTENTION, NEEDS_QA_ATTENTION]
            after: null
          )
            @connection(
              key: "ListIssuesForViewer_issuesForViewer"
              filters: []
            ) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                ...LabelingIssueListItem_issue
                description
                frame {
                  id
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query ListIssuesForViewerRefetchQuery(
        $statuses: [LabelingIssueStatus!]
        $onlyForViewer: Boolean
        $countIssuesForViewer: Int!
        $after: String
      ) {
        viewer {
          id
          issuesForViewer: issues(
            statuses: $statuses
            onlyForViewer: $onlyForViewer
            first: $countIssuesForViewer
            after: $after
          )
            @connection(
              key: "ListIssuesForViewer_issuesForViewer"
              filters: []
            ) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                ...LabelingIssueListItem_issue
                description
                frame {
                  id
                }
              }
            }
          }
        }
      }
    `
  )
);
