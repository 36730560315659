/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type LabelingPriority = "HIGH" | "LOW" | "UNSPECIFIED" | "%future added value";
export type LightingConditions = "BACKLIT" | "DARK" | "ILLUMINATED_BY_ROBOT" | "OVERHEAD_FRIENDLY" | "OVERHEAD_NORMAL" | "VERY_DARK" | "%future added value";
export type SensorStreamWrapper_viewer = {
    readonly id: string;
    readonly username: string | null;
    readonly isSupervisor: boolean | null;
    readonly sensorStream: {
        readonly id: string;
        readonly labelingDirections: string;
        readonly lightingConditions: LightingConditions;
        readonly labelingPriority: LabelingPriority;
        readonly sensorName: string;
        readonly numFrames: number;
        readonly allowExport: boolean;
        readonly run: {
            readonly runName: string;
        };
        readonly frameNumbers: ReadonlyArray<number>;
        readonly filteredFrames: ReadonlyArray<{
            readonly id: string;
            readonly mlReady: boolean;
            readonly labeledPallets: ReadonlyArray<{
                readonly id: string;
                readonly objectType: KnownObject;
            }>;
            readonly labeledItems: ReadonlyArray<{
                readonly id: string;
                readonly objectType: KnownObject;
            }>;
            readonly indexInSensorStream: number;
            readonly timestamp: string;
            readonly " $fragmentRefs": FragmentRefs<"SensorStreamFrameSliderIcon_frame">;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"SensorStreamFrameSlider_sensorStream">;
    };
    readonly " $refType": "SensorStreamWrapper_viewer";
};
export type SensorStreamWrapper_viewer$data = SensorStreamWrapper_viewer;
export type SensorStreamWrapper_viewer$key = {
    readonly " $data"?: SensorStreamWrapper_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"SensorStreamWrapper_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "objectType",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "SensorStreamWrapper_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "runName",
      "type": "String!"
    },
    {
      "kind": "RootArgument",
      "name": "sensorName",
      "type": "String!"
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSupervisor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "sensorStream",
      "name": "sensorStreamByName",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "runName",
          "variableName": "runName"
        },
        {
          "kind": "Variable",
          "name": "sensorName",
          "variableName": "sensorName"
        }
      ],
      "concreteType": "SensorStream",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "labelingDirections",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lightingConditions",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "labelingPriority",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sensorName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numFrames",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "allowExport",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "run",
          "storageKey": null,
          "args": null,
          "concreteType": "Run",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "runName",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "frameNumbers",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "filteredFrames",
          "storageKey": null,
          "args": null,
          "concreteType": "Frame",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "mlReady",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "labeledPallets",
              "storageKey": null,
              "args": null,
              "concreteType": "LabeledPallet",
              "plural": true,
              "selections": (v1/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "labeledItems",
              "storageKey": null,
              "args": null,
              "concreteType": "LabeledItem",
              "plural": true,
              "selections": (v1/*: any*/)
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "indexInSensorStream",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "timestamp",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "SensorStreamFrameSliderIcon_frame",
              "args": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "SensorStreamFrameSlider_sensorStream",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'a545d5bbeedb2b651e7d8e26e985efa6';
export default node;
