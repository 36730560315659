/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TopBar_viewer = {
    readonly id: string;
    readonly isAnonymous: boolean | null;
    readonly " $refType": "TopBar_viewer";
};
export type TopBar_viewer$data = TopBar_viewer;
export type TopBar_viewer$key = {
    readonly " $data"?: TopBar_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"TopBar_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "TopBar_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAnonymous",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'c9baa78dbf048f25584b13ca78c32d41';
export default node;
