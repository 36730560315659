/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelingPriority = "HIGH" | "LOW" | "UNSPECIFIED" | "%future added value";
export type ListViewerLabeledSensorStreams_viewer = {
    readonly username: string | null;
    readonly isAnonymous: boolean | null;
    readonly isSupervisor: boolean | null;
    readonly viewerLabeledSensorStreams: {
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly numFrames: number;
                readonly labelingPriority: LabelingPriority;
                readonly createTime: unknown;
                readonly updateTime: unknown;
                readonly run: {
                    readonly runName: string;
                };
                readonly " $fragmentRefs": FragmentRefs<"SensorStreamListItem_sensorStream">;
            } | null;
        } | null>;
    } | null;
    readonly " $refType": "ListViewerLabeledSensorStreams_viewer";
};
export type ListViewerLabeledSensorStreams_viewer$data = ListViewerLabeledSensorStreams_viewer;
export type ListViewerLabeledSensorStreams_viewer$key = {
    readonly " $data"?: ListViewerLabeledSensorStreams_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ListViewerLabeledSensorStreams_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ListViewerLabeledSensorStreams_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": "countViewerLabeledSensorStreams",
        "cursor": null,
        "direction": "forward",
        "path": [
          "viewerLabeledSensorStreams"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "countViewerLabeledSensorStreams",
      "type": "Int",
      "defaultValue": 15
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAnonymous",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSupervisor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "viewerLabeledSensorStreams",
      "name": "__ListViewerLabeledSensorStreams_viewerLabeledSensorStreams_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "SensorStreamConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SensorStreamEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SensorStream",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "numFrames",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "labelingPriority",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createTime",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updateTime",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "run",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Run",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "runName",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "SensorStreamListItem_sensorStream",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'c3f3397f2569943606f4ac54eed00c2e';
export default node;
