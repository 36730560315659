import React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";

import { combineStyles, commonStyles } from "../../utils/CommonStyles";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  KnownObject,
  SensorStreamListItem_sensorStream
} from "../../__generated__/SensorStreamListItem_sensorStream.graphql";
import { Tooltip } from "@material-ui/core";
import {
  KNOWN_OBJECT_ITEMS,
  KNOWN_OBJECT_PALLETS_BINS,
  PalletBinItem,
} from "../../utils/Enums";
import FoxLink from "../links/FoxLink";
import { sensorStreamPath } from "../../utils/Paths";

const localStyles = () =>
  createStyles({
    buttonText: {
      fontSize: "10px !important"
    },
    secondaryContainer: {
      display: "flex",
      flexDirection: "column",
      width: 100
    },
    inLineArrow: {
      position: "relative",
      top: -2
    },
    detailRowItems: {
      float: "left",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "10px !important"
    },
    tablePallets: {
      flex: 15,
      clear: "both",
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "66%"
    },
    tableItems: {
      flex: 10,
      display: "table",
      clear: "both",
      padding: 4,
      marginRight: 90
    },
    rows: {
      display: "flex",
      flexDirection: "row",
      fontSize: "10px !important"
    },
    titlePadding: {
      padding: 4,
      flex: 1
    },
    buttonSpace: {
      fontSize: "10px !important",
      padding: 0,
      paddingLeft: 5,
      textTransform: "lowercase"
    },
    subList: {
      width: "100%"
    }
  });

const styles = combineStyles(localStyles, commonStyles);

interface Props extends WithStyles<typeof styles> {
  sensorStream: SensorStreamListItem_sensorStream;
  isSupervisor: boolean;
  onClickSensorStreamExport: any;
}
class SensorStreamListItemDropdown extends React.Component<Props> {
  state = {
    isListOpen: false,
    authors: []
  };

  componentDidMount() {
    this._updateAuthors();
  }
  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.sensorStream.labelSummary !==
      this.props.sensorStream.labelSummary
    ) {
      this._updateAuthors();
    }
  }

  _updateAuthors = () => {
    const authors = new Set();
    const {
      labelSummary: {
        labeledItemsAll,
        labeledPalletsAll: labeledPalletsBinsAll
      }
    } = this.props.sensorStream;
    for (const pallet of labeledPalletsBinsAll) {
      authors.add(pallet.author.username);
    }
    for (const item of labeledItemsAll) {
      authors.add(item.author.username);
    }
    this.setState({
      authors
    });
  };

  _renderItemBubble = (
    objectType: string,
    labeledItems: PalletBinItem[],
    typesMap: Map<KnownObject, string>
  ) => {
    const { classes, sensorStream } = this.props;

    const html = [];
    html.push(
      <td className={classes.bubbleTitle} key={`detail-${objectType}`}>
        {labeledItems.length}{" "}
        {typesMap.get(objectType as KnownObject).replace(" ", " • ")}
      </td>
    );

    const itemsByIndex: Map<number, PalletBinItem[]> = labeledItems.reduce(
      (itemMap, item) =>
        itemMap.set(item.frame.indexInSensorStream, [
          ...(itemMap.get(item.frame.indexInSensorStream) || []),
          item
        ]),
      new Map<number, PalletBinItem[]>()
    );
    const flagClass =
      sensorStream.labelingPriority === "HIGH"
        ? classes.bubbleFlagHighPriority
        : classes.bubbleFlag;

    const text = [];
    for (const [index, items] of itemsByIndex) {
      const numItemsText = `${items.length} item${
        items.length === 1 ? "" : "s"
      }`;
      text.push(
        <FoxLink
          to={sensorStreamPath(
            sensorStream.run.runName,
            sensorStream.sensorName,
            index
          )}
        >
          <div className={flagClass}>
            <div className={classes.bubbleText}>frame {index}</div>•
            <div className={classes.bubbleText}>{numItemsText}</div>
          </div>
        </FoxLink>
      );
    }

    html.push(<div className={classes.bubbleItem}>{text}</div>);

    return html;
  };

  _getDetailsMessage = (
    allLabeledItems: readonly PalletBinItem[],
    typeMap: Map<KnownObject, string>
  ): any[] => {
    const { classes, sensorStream } = this.props;
    const bubbleClassName =
      sensorStream.labelingPriority === "HIGH"
        ? classes.bubbleHighPriority
        : classes.bubble;

    const itemsByObjectType = allLabeledItems.reduce(
      (itemMap, item) =>
        itemMap.set(item.objectType, [
          ...(itemMap.get(item.objectType) || []),
          item
        ]),
      new Map<string, PalletBinItem[]>()
    );

    const itemsByObjectTypeArray = Array.from(itemsByObjectType).sort(
      (a: [string, PalletBinItem[]], b: [string, PalletBinItem[]]) => {
        return a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0;
      }
    );
    return Array.from(itemsByObjectTypeArray).map(([objectType, items]) => {
      const bubble = this._renderItemBubble(objectType, items, typeMap);
      return <td className={bubbleClassName}>{bubble}</td>;
    });
  };

  _renderStatusButton = (canMarkReady: boolean) => {
    const { classes, sensorStream, onClickSensorStreamExport } = this.props;
    const { allowExport } = sensorStream;
    const text = allowExport ? "Marked Ready" : "Mark Ready";
    const classname = allowExport
      ? `${classes.statusReady} ${classes.buttonText}`
      : ` ${classes.buttonText}`;
    if (canMarkReady) {
      return (
        <ListItemSecondaryAction className={classes.secondaryContainer}>
          <Button
            className={classname}
            onClick={() =>
              onClickSensorStreamExport({
                id: sensorStream.id,
                labelingDirections: sensorStream.labelingDirections,
                labelingPriority: sensorStream.labelingPriority,
                lightingConditions: sensorStream.lightingConditions,
                allowExport: sensorStream.allowExport
              })
            }
          >
            {text}
          </Button>
        </ListItemSecondaryAction>
      );
    } else {
      return (
        <Tooltip title={"Cannot send runs with Issues"}>
          <ListItemSecondaryAction className={classes.secondaryContainer}>
            <Button className={classes.buttonText} disabled={true}>
              {text}
            </Button>
          </ListItemSecondaryAction>
        </Tooltip>
      );
    }
  };
  render() {
    const { isSupervisor, classes } = this.props;
    const { isListOpen } = this.state;
    const {
      issues,
      numFrames,
      labelSummary: {
        labeledItemsAll,
        labeledPalletsAll: labeledPalletsBinsAll
      }
    } = this.props.sensorStream;
    if (!numFrames) {
      return null;
    }
    const resolvedIssues = issues.filter(issue => {
      return issue.status === "RESOLVED";
    });

    const canMarkReady = issues.length === resolvedIssues.length;

    return (
      <>
        <div className={classes.subList}>
          <Button
            className={classes.buttonSpace}
            onClick={() =>
              this.setState(prevState => ({
                isListOpen: !prevState.isListOpen
              }))
            }
          >
            more details
            {isListOpen ? (
              <ArrowRightIcon className={classes.inLineArrow} />
            ) : (
              <ArrowLeftIcon className={classes.inLineArrow} />
            )}
          </Button>
          {isSupervisor && this._renderStatusButton(canMarkReady)}
          {isListOpen && (
            <>
              <div className={classes.rows}>
                <span className={classes.titlePadding}>Labelers:</span>
                <span className={classes.titlePadding}>
                  {new Array(...this.state.authors).join(" ")}
                </span>
              </div>
              <div className={classes.rows}>
                <span className={classes.titlePadding}>Pallets:</span>
                <table className={classes.tablePallets}>
                  {this._getDetailsMessage(
                    labeledPalletsBinsAll,
                    KNOWN_OBJECT_PALLETS_BINS
                  )}
                </table>
                <span className={classes.titlePadding}> Items:</span>
                <table className={classes.tableItems}>
                  {this._getDetailsMessage(labeledItemsAll, KNOWN_OBJECT_ITEMS)}
                </table>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SensorStreamListItemDropdown);
