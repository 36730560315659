import React from "react";
import { graphql, createFragmentContainer } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import LabelingIssueSideBarListItem from "./LabelingIssueSideBarListItem";

import CreateIssueDialog from "../CreateIssueDialog";
import { LabelSelector } from "../SelectionState";
import { LabelMutators } from "../LabelMutators";
import { TopLevelLabelActions } from "../../../label_frame/SensorStreamWrapper";

import { IssuesBar_frame } from "../../../../__generated__/IssuesBar_frame.graphql";
import { Viewer } from "../Utils";

const styles = theme =>
  createStyles({
    drawerPaper: {
      position: "relative",
      border: "1px solid rgba(0, 0, 0, 0.23)"
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 8,
      flexDirection: "row",
      position: "relative"
    },
    issues: {}
  });

type Props = {
  classes: any;
  frame: IssuesBar_frame;
  viewer: Viewer;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
  labelerUsernames: Set<string>;
  topLevelLabelActions: TopLevelLabelActions;
};
type State = {
  createIssueDialogOpen: boolean;
};

class IssuesBar extends React.Component<Props, State> {
  state = {
    createIssueDialogOpen: false
  };

  render() {
    const {
      classes,
      frame,
      labelSelector,
      topLevelLabelActions,
      viewer,
      labelerUsernames
    } = this.props;
    const { createIssueDialogOpen } = this.state;

    const { sensorStream } = frame;
    const { issues: sensorStreamIssues } = sensorStream;
    const { issues: frameIssues } = frame;
    const issues = frameIssues.concat(
      sensorStreamIssues.filter(i => !frameIssues.find(fi => fi.id === i.id))
    );

    return (
      <React.Fragment>
        <Drawer
          variant="persistent"
          anchor={"left"}
          open={topLevelLabelActions.showIssuesBar}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <AppBar className={classes.drawerHeader} color="default">
            <Typography>Issues</Typography>
          </AppBar>
          {viewer.isQualityController && (
            <Button
              onClick={() => this.setState({ createIssueDialogOpen: true })}
              variant="outlined"
              fullWidth
            >
              Create
            </Button>
          )}

          <div className={classes.issues}>
            <List className={classes.list}>
              {issues.map(issue => (
                <LabelingIssueSideBarListItem
                  key={issue.id}
                  viewer={viewer}
                  issue={issue}
                  viewingFrameId={frame.id}
                />
              ))}
            </List>
          </div>
        </Drawer>
        {createIssueDialogOpen && (
          <CreateIssueDialog
            frame={frame}
            labelSelector={labelSelector}
            viewer={viewer}
            displayedLabelerUsernames={labelerUsernames}
            closer={() => this.setState({ createIssueDialogOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(IssuesBar, {
    frame: graphql`
      fragment IssuesBar_frame on Frame {
        ...CreateIssueDialog_frame
        id
        indexInSensorStream
        issues {
          id
          ...LabelingIssueSideBarListItem_issue
        }
        sensorStream {
          issues {
            id
            ...LabelingIssueSideBarListItem_issue
          }
        }
      }
    `
  })
);
