/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"TopBar_viewer">;
    readonly " $refType": "App_viewer";
};
export type App_viewer$data = App_viewer;
export type App_viewer$key = {
    readonly " $data"?: App_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"App_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "App_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "TopBar_viewer",
      "args": null
    }
  ]
};
(node as any).hash = '3d70815c0bdba274a734428947deda41';
export default node;
