import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import ListIssuesForViewer from "./ListIssuesForViewer";
import ListIssuesByViewer from "./ListIssuesByViewer";
import { LabelingIssues_viewer } from "../../__generated__/LabelingIssues_viewer.graphql";

const styles = theme =>
  createStyles({
    wrapper: {
      margin: 48
    }
  });

interface Props extends WithStyles<typeof styles> {
  viewer: LabelingIssues_viewer;
}
class LabelingIssues extends React.Component<Props> {
  render() {
    const { viewer, classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <Typography variant="h4" gutterBottom>
          Issues
        </Typography>
        <ListIssuesForViewer viewer={viewer} />
        <ListIssuesByViewer viewer={viewer} />
      </div>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(LabelingIssues, {
    viewer: graphql`
      fragment LabelingIssues_viewer on Viewer {
        id
        ...ListIssuesForViewer_viewer
        ...ListIssuesByViewer_viewer
      }
    `
  })
);
