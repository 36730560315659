/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type CreateLabeledItemMutationInput = {
    readonly faces: ReadonlyArray<NewFaceInput>;
    readonly knownObjectType: KnownObject;
    readonly frameId: string;
    readonly clientMutationId?: string | null;
};
export type NewFaceInput = {
    readonly side: FaceSide;
    readonly newCorners: ReadonlyArray<NewCornerInput>;
    readonly existingCorners: ReadonlyArray<string>;
};
export type NewCornerInput = {
    readonly x: number;
    readonly y: number;
    readonly visibility: Visibility;
};
export type CreateLabeledItemMutationVariables = {
    input: CreateLabeledItemMutationInput;
};
export type CreateLabeledItemMutationResponse = {
    readonly createLabeledItem: {
        readonly clientMutationId: string | null;
        readonly labeledItem: {
            readonly id: string;
            readonly objectType: KnownObject;
            readonly author: {
                readonly id: string;
                readonly username: string | null;
            };
            readonly number: number;
            readonly thwartedPallets: ReadonlyArray<{
                readonly id: string;
                readonly number: number;
            }>;
            readonly frame: {
                readonly id: string;
                readonly labeledBoxesCount: number;
                readonly labeledBoxes: ReadonlyArray<{
                    readonly id: string;
                }>;
                readonly labeledItems: ReadonlyArray<{
                    readonly id: string;
                }>;
                readonly labeledPallets: ReadonlyArray<{
                    readonly boxes: ReadonlyArray<{
                        readonly id: string;
                    }>;
                }>;
            };
            readonly shape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly parentShape: {
                        readonly id: string;
                        readonly faces: ReadonlyArray<{
                            readonly id: string;
                        }>;
                    };
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            };
        };
    } | null;
};
export type CreateLabeledItemMutation = {
    readonly response: CreateLabeledItemMutationResponse;
    readonly variables: CreateLabeledItemMutationVariables;
};



/*
mutation CreateLabeledItemMutation(
  $input: CreateLabeledItemMutationInput!
) {
  createLabeledItem(input: $input) {
    clientMutationId
    labeledItem {
      id
      objectType
      author {
        id
        username
      }
      number
      thwartedPallets {
        id
        number
      }
      frame {
        id
        labeledBoxesCount
        labeledBoxes {
          id
        }
        labeledItems {
          id
        }
        labeledPallets {
          boxes {
            id
          }
          id
        }
      }
      shape {
        id
        faces {
          id
          side
          parentShape {
            id
            faces {
              id
            }
          }
          corners {
            id
            x
            y
            visibility
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateLabeledItemMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "objectType",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelerUser",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "thwartedPallets",
  "storageKey": null,
  "args": null,
  "concreteType": "LabeledPallet",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v6/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "labeledBoxesCount",
  "args": null,
  "storageKey": null
},
v9 = [
  (v3/*: any*/)
],
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labeledBoxes",
  "storageKey": null,
  "args": null,
  "concreteType": "LabeledBox",
  "plural": true,
  "selections": (v9/*: any*/)
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labeledItems",
  "storageKey": null,
  "args": null,
  "concreteType": "LabeledItem",
  "plural": true,
  "selections": (v9/*: any*/)
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "boxes",
  "storageKey": null,
  "args": null,
  "concreteType": "LabeledBox",
  "plural": true,
  "selections": (v9/*: any*/)
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "shape",
  "storageKey": null,
  "args": null,
  "concreteType": "Cuboid",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "faces",
      "storageKey": null,
      "args": null,
      "concreteType": "CuboidFace",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "side",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "parentShape",
          "storageKey": null,
          "args": null,
          "concreteType": "Cuboid",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "faces",
              "storageKey": null,
              "args": null,
              "concreteType": "CuboidFace",
              "plural": true,
              "selections": (v9/*: any*/)
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "corners",
          "storageKey": null,
          "args": null,
          "concreteType": "CuboidCorner",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "x",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "y",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "visibility",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateLabeledItemMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLabeledItem",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLabeledItemMutationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labeledItem",
            "storageKey": null,
            "args": null,
            "concreteType": "LabeledItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "frame",
                "storageKey": null,
                "args": null,
                "concreteType": "Frame",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "labeledPallets",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabeledPallet",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/)
                    ]
                  }
                ]
              },
              (v13/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateLabeledItemMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLabeledItem",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLabeledItemMutationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labeledItem",
            "storageKey": null,
            "args": null,
            "concreteType": "LabeledItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "frame",
                "storageKey": null,
                "args": null,
                "concreteType": "Frame",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "labeledPallets",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabeledPallet",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              },
              (v13/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateLabeledItemMutation",
    "id": null,
    "text": "mutation CreateLabeledItemMutation(\n  $input: CreateLabeledItemMutationInput!\n) {\n  createLabeledItem(input: $input) {\n    clientMutationId\n    labeledItem {\n      id\n      objectType\n      author {\n        id\n        username\n      }\n      number\n      thwartedPallets {\n        id\n        number\n      }\n      frame {\n        id\n        labeledBoxesCount\n        labeledBoxes {\n          id\n        }\n        labeledItems {\n          id\n        }\n        labeledPallets {\n          boxes {\n            id\n          }\n          id\n        }\n      }\n      shape {\n        id\n        faces {\n          id\n          side\n          parentShape {\n            id\n            faces {\n              id\n            }\n          }\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '9223e46b005b96745f0205d54acda740';
export default node;
