import React from "react";
import { graphql } from "react-relay";
import { BrowserProtocol, createQueryMiddleware } from "farce";
import { createFarceRouter, createRender, makeRouteConfig, Route } from "found";
import { stringify } from "qs";
import RedirectException from "found/lib/RedirectException";

import App from "./App";
import SignUp from "./components/accounts/SignUp";
import LogIn from "./components/accounts/LogIn";
import Dashboard from "./components/dashboard/Dashboard";
import LabelingActivity from "./components/activity/LabelingActivity";
import LabelingReport from "./components/reports/LabelingReport";
import LabelingIssues from "./components/issues/LabelingIssues";
import FrameViewer from "./components/common/canvas/FrameViewer";
import SensorStreamWrapper from "./components/label_frame/SensorStreamWrapper";
import { logInPath, dashboardPath } from "./utils/Paths";
import LoadingScreen from "./components/common/LoadingScreen";
import PageNotFound from "./components/common/PageNotFound";
import AccountPending from "./components/common/AccountPending";

class AsyncRoute extends Route {
  render({ Component, props }) {
    return Component && props ? (
      <Component {...props} />
    ) : (
      <LoadingScreen fullScreen />
    );
  }
}

class AuthenticatedAsyncRoute extends Route {
  render({ Component, props, resolving }) {
    if (props) {
      const { viewer, match } = props;
      if (!viewer || viewer.isAnonymous) {
        let newLocation = match.location;
        newLocation.query = { next: match.location.pathname };
        newLocation.pathname = logInPath();
        newLocation.action = "PUSH";
        throw new RedirectException(newLocation);
      }
    }
    if (props && !props.viewer.isApprovedForAccess) {
      return <AccountPending />;
    }
    return Component && props ? (
      <Component {...props} />
    ) : (
      <LoadingScreen fullScreen />
    );
  }
}

class NotAuthenticatedAsyncRoute extends Route {
  render({ Component, props, resolving, match }) {
    if (props) {
      const { viewer, match } = props;
      if (viewer && viewer.isAnonymous === false) {
        let newLocation = match.location;
        newLocation.pathname = dashboardPath();
        newLocation.action = "PUSH";
        throw new RedirectException(newLocation);
      }
    }
    return Component && props ? (
      <Component {...props} />
    ) : (
      <LoadingScreen fullScreen />
    );
  }
}

const AppViewerQuery = graphql`
  query routes_App_Query {
    viewer {
      isAnonymous
      ...App_viewer
    }
  }
`;
const SignupViewerQuery = graphql`
  query routes_Signup_Query {
    viewer {
      isAnonymous
      ...SignUp_viewer
    }
  }
`;
const LogInViewerQuery = graphql`
  query routes_LogIn_Query {
    viewer {
      isAnonymous
      ...LogIn_viewer
    }
  }
`;
const DashboardViewerQuery = graphql`
  query routes_Dashboard_Query {
    viewer {
      isAnonymous
      isApprovedForAccess
      ...Dashboard_viewer
    }
  }
`;
const LabelingActivityViewerQuery = graphql`
  query routes_LabelingActivityViewer_Query {
    viewer {
      isAnonymous
      isApprovedForAccess
      ...LabelingActivity_viewer
    }
  }
`;
const LabelingReportViewerQuery = graphql`
  query routes_LabelingReport_Query {
    viewer {
      isAnonymous
      isApprovedForAccess
      ...LabelingReport_viewer
    }
  }
`;
const LabelingIssuesViewerQuery = graphql`
  query routes_LabelingIssues_Query {
    viewer {
      isAnonymous
      isApprovedForAccess
      ...LabelingIssues_viewer
    }
  }
`;
const FrameViewerQuery = graphql`
  query routes_FrameViewer_Query(
    $runName: String!
    $sensorName: String!
    $indexInSensorStream: Int!
    $timestamp: String
  ) {
    viewer {
      id
      isAnonymous
      isApprovedForAccess
      ...FrameViewer_viewer
    }
  }
`;
const FrameViewerByTimestampQuery = graphql`
  query routes_FrameViewerByTimestamp_Query(
    $runName: String!
    $sensorName: String!
    $indexInSensorStream: Int
    $timestamp: String!
  ) {
    viewer {
      id
      isAnonymous
      isApprovedForAccess
      ...FrameViewer_viewer
    }
  }
`;
const SensorStreamWrapperQuery = graphql`
  query routes_SensorStreamWrapper_Query(
    $runName: String!
    $sensorName: String!
  ) {
    viewer {
      id
      isAnonymous
      isApprovedForAccess
      ...SensorStreamWrapper_viewer
    }
  }
`;

export default createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [
    createQueryMiddleware({
      stringify: query => stringify(query, { encode: false })
    })
  ],
  routeConfig: makeRouteConfig(
    <AsyncRoute path="labeler" query={AppViewerQuery} Component={App}>
      <NotAuthenticatedAsyncRoute
        path={"sign-up"}
        query={SignupViewerQuery}
        Component={SignUp}
      />
      <NotAuthenticatedAsyncRoute
        path={"log-in"}
        query={LogInViewerQuery}
        Component={LogIn}
      />
      <AuthenticatedAsyncRoute
        path={"dashboard"}
        query={DashboardViewerQuery}
        Component={Dashboard}
      />
      <AuthenticatedAsyncRoute
        path={"activity"}
        query={LabelingActivityViewerQuery}
        Component={LabelingActivity}
      />
      <AuthenticatedAsyncRoute
        path={"reports"}
        query={LabelingReportViewerQuery}
        Component={LabelingReport}
      />
      <AuthenticatedAsyncRoute
        path={"issues"}
        query={LabelingIssuesViewerQuery}
        Component={LabelingIssues}
      />
      <AuthenticatedAsyncRoute
        path={"label-tool/runs/:runName/:sensorName/frames"}
        query={SensorStreamWrapperQuery}
        Component={SensorStreamWrapper}
      >
        <AuthenticatedAsyncRoute
          path={"/timestamps/:timestamp"}
          query={FrameViewerByTimestampQuery}
          Component={FrameViewer}
        />
        <AuthenticatedAsyncRoute
          path={":indexInSensorStream"}
          query={FrameViewerQuery}
          Component={FrameViewer}
        />
      </AuthenticatedAsyncRoute>
      <AsyncRoute path={"not-found"} Component={PageNotFound} />
      <AsyncRoute path={"*"} Component={PageNotFound} />
    </AsyncRoute>
  ),

  render: createRender({})
});
