/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type CreateLoadMutationInput = {
    readonly palletId: string;
    readonly clientMutationId?: string | null;
};
export type CreateLoadMutationVariables = {
    input: CreateLoadMutationInput;
};
export type CreateLoadMutationResponse = {
    readonly createLoad: {
        readonly pallet: {
            readonly id: string;
            readonly load: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly parentShape: {
                        readonly id: string;
                        readonly faces: ReadonlyArray<{
                            readonly id: string;
                        }>;
                    };
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            } | null;
        };
    } | null;
};
export type CreateLoadMutation = {
    readonly response: CreateLoadMutationResponse;
    readonly variables: CreateLoadMutationVariables;
};



/*
mutation CreateLoadMutation(
  $input: CreateLoadMutationInput!
) {
  createLoad(input: $input) {
    pallet {
      id
      load {
        id
        faces {
          id
          side
          parentShape {
            id
            faces {
              id
            }
          }
          corners {
            id
            x
            y
            visibility
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateLoadMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createLoad",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateLoadMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pallet",
        "storageKey": null,
        "args": null,
        "concreteType": "LabeledPallet",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "load",
            "storageKey": null,
            "args": null,
            "concreteType": "Cuboid",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "faces",
                "storageKey": null,
                "args": null,
                "concreteType": "CuboidFace",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "side",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "parentShape",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Cuboid",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "faces",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CuboidFace",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "corners",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CuboidCorner",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "x",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "y",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "visibility",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateLoadMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateLoadMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateLoadMutation",
    "id": null,
    "text": "mutation CreateLoadMutation(\n  $input: CreateLoadMutationInput!\n) {\n  createLoad(input: $input) {\n    pallet {\n      id\n      load {\n        id\n        faces {\n          id\n          side\n          parentShape {\n            id\n            faces {\n              id\n            }\n          }\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '65a58ed12eb7c8f95fbf64ed34976432';
export default node;
