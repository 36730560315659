/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_App_QueryVariables = {};
export type routes_App_QueryResponse = {
    readonly viewer: {
        readonly isAnonymous: boolean | null;
        readonly " $fragmentRefs": FragmentRefs<"App_viewer">;
    } | null;
};
export type routes_App_Query = {
    readonly response: routes_App_QueryResponse;
    readonly variables: routes_App_QueryVariables;
};



/*
query routes_App_Query {
  viewer {
    isAnonymous
    ...App_viewer
    id
  }
}

fragment App_viewer on Viewer {
  ...TopBar_viewer
}

fragment TopBar_viewer on Viewer {
  id
  isAnonymous
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAnonymous",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routes_App_Query",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "App_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes_App_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routes_App_Query",
    "id": null,
    "text": "query routes_App_Query {\n  viewer {\n    isAnonymous\n    ...App_viewer\n    id\n  }\n}\n\nfragment App_viewer on Viewer {\n  ...TopBar_viewer\n}\n\nfragment TopBar_viewer on Viewer {\n  id\n  isAnonymous\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '42cc885b34c7ab69a89ffb6d0410e223';
export default node;
