import React from "react";
import { graphql, createFragmentContainer } from "react-relay";

import { createStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";

import LabelFaceList from "./LabelFaceList";
import { LabelSelector } from "../SelectionState";
import { LabelMutators } from "../LabelMutators";
import { combineStyles, commonStyles } from "../../../../utils/CommonStyles";

import { PalletDetails_pallet } from "../../../../__generated__/PalletDetails_pallet.graphql";
import { Viewer } from "../Utils";
import LabelCardHeader from "./LabelCardHeader";
import { Divider } from "@material-ui/core";

const localStyles = theme => createStyles({});
const styles = combineStyles(localStyles, commonStyles);

type Props = {
  classes: any;
  viewer: Viewer;
  pallet: PalletDetails_pallet;
  labelSelector: LabelSelector;
  labelMutator: LabelMutators;
};

type State = {
  expanded: boolean | null;
};

class PalletDetails extends React.Component<Props, State> {
  state = {
    expanded: false
  };

  _updateFaces = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  };

  render() {
    const { classes, pallet, labelSelector, labelMutator, viewer } = this.props;
    if (!pallet) {
      return null;
    }

    let { expanded } = this.state;
    if (expanded === null || expanded === undefined) {
      expanded = labelSelector.isLabelSelected(pallet);
    }

    const cardClass = expanded ? classes.cardExpanded : classes.card;

    return (
      <Card
        raised={expanded}
        onMouseEnter={() => labelSelector.hoverLabel(pallet)}
        onMouseLeave={() => labelSelector.clearHovers()}
        className={cardClass}
      >
        <LabelCardHeader
          pallet={pallet}
          item={null}
          labelSelector={labelSelector}
          labelMutator={labelMutator}
          showCorners={null}
          showPallets={null}
          showFaces={expanded}
          updateFaces={this._updateFaces}
          updateCorners={null}
          updateShowPallets={null}
        />
        <Divider />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <LabelFaceList
            viewer={viewer}
            label={pallet}
            labelSelector={labelSelector}
            labelMutator={labelMutator}
          />
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(styles)(
  createFragmentContainer(PalletDetails, {
    pallet: graphql`
      fragment PalletDetails_pallet on LabeledPallet {
        id
        ...LabelFaceList_label
        number
        objectType
        surfaceType
        boxes {
          id
          number
        }
        frame {
          labeledBoxes {
            id
            number
            pallet {
              id
            }
          }
        }
      }
    `
  })
);
