/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DeletePalletPocketMutationInput = {
    readonly faceId: string;
    readonly isLeftPocket: boolean;
    readonly clientMutationId?: string | null;
};
export type DeletePalletPocketMutationVariables = {
    input: DeletePalletPocketMutationInput;
};
export type DeletePalletPocketMutationResponse = {
    readonly deletePalletPocket: {
        readonly parent: {
            readonly id: string;
            readonly left: {
                readonly id: string;
            } | null;
            readonly right: {
                readonly id: string;
            } | null;
        };
        readonly face: {
            readonly id: string;
            readonly palletPocketLeft: {
                readonly id: string;
            } | null;
            readonly palletPocketRight: {
                readonly id: string;
            } | null;
        };
    } | null;
};
export type DeletePalletPocketMutation = {
    readonly response: DeletePalletPocketMutationResponse;
    readonly variables: DeletePalletPocketMutationVariables;
};



/*
mutation DeletePalletPocketMutation(
  $input: DeletePalletPocketMutationInput!
) {
  deletePalletPocket(input: $input) {
    parent {
      id
      left {
        id
      }
      right {
        id
      }
    }
    face {
      id
      palletPocketLeft {
        id
      }
      palletPocketRight {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeletePalletPocketMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deletePalletPocket",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeletePalletPocketMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "parent",
        "storageKey": null,
        "args": null,
        "concreteType": "PalletPocket",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "left",
            "storageKey": null,
            "args": null,
            "concreteType": "Quad",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "right",
            "storageKey": null,
            "args": null,
            "concreteType": "Quad",
            "plural": false,
            "selections": (v2/*: any*/)
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "face",
        "storageKey": null,
        "args": null,
        "concreteType": "CuboidFace",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "palletPocketLeft",
            "storageKey": null,
            "args": null,
            "concreteType": "Quad",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "palletPocketRight",
            "storageKey": null,
            "args": null,
            "concreteType": "Quad",
            "plural": false,
            "selections": (v2/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeletePalletPocketMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeletePalletPocketMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeletePalletPocketMutation",
    "id": null,
    "text": "mutation DeletePalletPocketMutation(\n  $input: DeletePalletPocketMutationInput!\n) {\n  deletePalletPocket(input: $input) {\n    parent {\n      id\n      left {\n        id\n      }\n      right {\n        id\n      }\n    }\n    face {\n      id\n      palletPocketLeft {\n        id\n      }\n      palletPocketRight {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b76ec387a86a421a12b163a22802c25f';
export default node;
