import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { CreateLabelingIssueMutationInput } from "../__generated__/CreateLabelingIssueMutation.graphql";

const mutation = graphql`
  mutation CreateLabelingIssueMutation(
    $input: CreateLabelingIssueMutationInput!
  ) {
    createLabelingIssue(input: $input) {
      issue {
        id
        ...LabelingIssueListItem_issue
        ...LabelingIssueSideBarListItem_issue
        labeler {
          id
        }
        reporter {
          id
        }
        boxes {
          id
        }
        pallets {
          id
        }
        description
        status
        category
        frame {
          id
        }
      }
      frame {
        id
        issues {
          id
          ...LabelingIssueListItem_issue
          ...LabelingIssueSideBarListItem_issue
        }
      }
      sensorStream {
        id
        issues {
          id
          ...LabelingIssueListItem_issue
          ...LabelingIssueSideBarListItem_issue
        }
      }
    }
  }
`;

function createLabelingIssue(
  environment: Environment,
  input: CreateLabelingIssueMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  const boxes: ReadonlyArray<{ readonly id: string }> = input.boxes.map(id => ({
    id: id || ""
  }));
  const pallets: ReadonlyArray<{ readonly id: string }> = input.pallets.map(
    id => ({
      id: id || ""
    })
  );
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticResponse: {
      createLabelingIssue: {
        issue: {
          labeler: {
            id: input.labelerId
          },
          category: input.category,
          status: input.status,
          description: input.description,
          boxes,
          pallets
        }
      }
    }
  });
}

export default createLabelingIssue;
