/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type BucketDuration = "DAY" | "HOUR" | "MINUTE" | "MONTH" | "WEEK" | "YEAR" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type LabelingActivityChartRefetchQueryVariables = {
    bucketDuration?: BucketDuration | null;
    knownObjects?: ReadonlyArray<KnownObject> | null;
    numBuckets?: number | null;
    startTime?: unknown | null;
};
export type LabelingActivityChartRefetchQueryResponse = {
    readonly viewer: {
        readonly labelSummaryTimeBuckets: ReadonlyArray<{
            readonly duration: BucketDuration | null;
            readonly startTime: unknown;
            readonly perLabelerSummaries: ReadonlyArray<{
                readonly labeler: {
                    readonly id: string;
                    readonly username: string | null;
                };
                readonly summary: {
                    readonly numPallets: number;
                    readonly numItems: number | null;
                };
            } | null>;
        } | null> | null;
    } | null;
};
export type LabelingActivityChartRefetchQuery = {
    readonly response: LabelingActivityChartRefetchQueryResponse;
    readonly variables: LabelingActivityChartRefetchQueryVariables;
};



/*
query LabelingActivityChartRefetchQuery(
  $bucketDuration: BucketDuration
  $knownObjects: [KnownObject!]
  $numBuckets: Int
  $startTime: DateTime
) {
  viewer {
    labelSummaryTimeBuckets(bucketDuration: $bucketDuration, numBuckets: $numBuckets, knownObjects: $knownObjects, startTime: $startTime) {
      duration
      startTime
      perLabelerSummaries {
        labeler {
          id
          username
        }
        summary {
          numPallets
          numItems
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "bucketDuration",
    "type": "BucketDuration",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "knownObjects",
    "type": "[KnownObject!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "numBuckets",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startTime",
    "type": "DateTime",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labelSummaryTimeBuckets",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "bucketDuration",
      "variableName": "bucketDuration"
    },
    {
      "kind": "Variable",
      "name": "knownObjects",
      "variableName": "knownObjects"
    },
    {
      "kind": "Variable",
      "name": "numBuckets",
      "variableName": "numBuckets"
    },
    {
      "kind": "Variable",
      "name": "startTime",
      "variableName": "startTime"
    }
  ],
  "concreteType": "LabelSummaryTimeBucket",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "duration",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "startTime",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "perLabelerSummaries",
      "storageKey": null,
      "args": null,
      "concreteType": "PerLabelerLabelSummary",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeler",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelerUser",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "username",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "summary",
          "storageKey": null,
          "args": null,
          "concreteType": "LabelSummary",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numPallets",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numItems",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LabelingActivityChartRefetchQuery",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LabelingActivityChartRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "LabelingActivityChartRefetchQuery",
    "id": null,
    "text": "query LabelingActivityChartRefetchQuery(\n  $bucketDuration: BucketDuration\n  $knownObjects: [KnownObject!]\n  $numBuckets: Int\n  $startTime: DateTime\n) {\n  viewer {\n    labelSummaryTimeBuckets(bucketDuration: $bucketDuration, numBuckets: $numBuckets, knownObjects: $knownObjects, startTime: $startTime) {\n      duration\n      startTime\n      perLabelerSummaries {\n        labeler {\n          id\n          username\n        }\n        summary {\n          numPallets\n          numItems\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '19054f7cc39e46873509bac4d11f0b0b';
export default node;
