/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type FaceSide = "FRONT" | "SIDE" | "TOP" | "%future added value";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type Visibility = "OCCLUDED" | "OFF_FRAME" | "UNKNOWN" | "VISIBLE" | "%future added value";
export type UpdateLabeledBoxMutationInput = {
    readonly id: string;
    readonly pallet?: UpdateBoxPalletInput | null;
    readonly knownObjectType?: KnownObject | null;
    readonly clientMutationId?: string | null;
};
export type UpdateBoxPalletInput = {
    readonly id?: string | null;
};
export type UpdateLabeledBoxMutationVariables = {
    input: UpdateLabeledBoxMutationInput;
};
export type UpdateLabeledBoxMutationResponse = {
    readonly updateLabeledBox: {
        readonly labeledBox: {
            readonly id: string;
            readonly number: number;
            readonly objectType: KnownObject;
            readonly frame: {
                readonly id: string;
                readonly labeledPallets: ReadonlyArray<{
                    readonly id: string;
                    readonly boxes: ReadonlyArray<{
                        readonly id: string;
                    }>;
                }>;
            };
            readonly pallet: {
                readonly id: string;
                readonly boxes: ReadonlyArray<{
                    readonly id: string;
                }>;
            } | null;
            readonly shape: {
                readonly id: string;
                readonly faces: ReadonlyArray<{
                    readonly id: string;
                    readonly side: FaceSide;
                    readonly corners: ReadonlyArray<{
                        readonly id: string;
                        readonly x: number;
                        readonly y: number;
                        readonly visibility: Visibility;
                    }>;
                }>;
            };
        };
    } | null;
};
export type UpdateLabeledBoxMutation = {
    readonly response: UpdateLabeledBoxMutationResponse;
    readonly variables: UpdateLabeledBoxMutationVariables;
};



/*
mutation UpdateLabeledBoxMutation(
  $input: UpdateLabeledBoxMutationInput!
) {
  updateLabeledBox(input: $input) {
    labeledBox {
      id
      number
      objectType
      frame {
        id
        labeledPallets {
          id
          boxes {
            id
          }
        }
      }
      pallet {
        id
        boxes {
          id
        }
      }
      shape {
        id
        faces {
          id
          side
          corners {
            id
            x
            y
            visibility
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateLabeledBoxMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "boxes",
    "storageKey": null,
    "args": null,
    "concreteType": "LabeledBox",
    "plural": true,
    "selections": [
      (v1/*: any*/)
    ]
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateLabeledBox",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLabeledBoxMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "labeledBox",
        "storageKey": null,
        "args": null,
        "concreteType": "LabeledBox",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "number",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "objectType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "frame",
            "storageKey": null,
            "args": null,
            "concreteType": "Frame",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "labeledPallets",
                "storageKey": null,
                "args": null,
                "concreteType": "LabeledPallet",
                "plural": true,
                "selections": (v2/*: any*/)
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pallet",
            "storageKey": null,
            "args": null,
            "concreteType": "LabeledPallet",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shape",
            "storageKey": null,
            "args": null,
            "concreteType": "Cuboid",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "faces",
                "storageKey": null,
                "args": null,
                "concreteType": "CuboidFace",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "side",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "corners",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CuboidCorner",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "x",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "y",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "visibility",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateLabeledBoxMutation",
    "type": "RootMutations",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateLabeledBoxMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateLabeledBoxMutation",
    "id": null,
    "text": "mutation UpdateLabeledBoxMutation(\n  $input: UpdateLabeledBoxMutationInput!\n) {\n  updateLabeledBox(input: $input) {\n    labeledBox {\n      id\n      number\n      objectType\n      frame {\n        id\n        labeledPallets {\n          id\n          boxes {\n            id\n          }\n        }\n      }\n      pallet {\n        id\n        boxes {\n          id\n        }\n      }\n      shape {\n        id\n        faces {\n          id\n          side\n          corners {\n            id\n            x\n            y\n            visibility\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4b5db24db8e0b7e9d0b31e9e10009729';
export default node;
