/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type KnownObject = "BIN_BMW_M1" | "BIN_BMW_M2" | "BIN_BMW_M3" | "BIN_BMW_M4" | "BIN_BMW_M5" | "BIN_BMW_M6" | "BIN_BMW_M7" | "BIN_BMW_M8" | "BIN_BMW_P1" | "BIN_BMW_P2" | "BIN_IBC" | "BLOCK_SKID_BMW" | "BLOCK_SKID_DIVERT" | "BLOCK_SKID_DIVERT_SMALL" | "BOX_BABY_OIL_WITHOUT_ALOE" | "BOX_BABY_OIL_WITH_ALOE" | "BOX_CONTACT_LENSES" | "BOX_JETPUFFED" | "BOX_LOWES_LARGE" | "BOX_LOWES_SMALL" | "BOX_M2" | "BOX_NEWARK" | "BOX_PLAIN_WIDE" | "BOX_PROXY_10_12_7" | "BOX_PROXY_12_12_8" | "BOX_PROXY_13_13_8" | "BOX_UNSPECIFIED" | "BOX_ZALANDO" | "ITEM_AIRBAG" | "ITEM_FORKLIFT" | "ITEM_HUMAN" | "ITEM_LOAD_RESTRAINT" | "ITEM_STABILIZING_CARDBOARD" | "ITEM_STRIPED_AIRBAG" | "ITEM_UNKNOWN" | "ITEM_WEATHER_GUARD_LEFT" | "ITEM_WEATHER_GUARD_RIGHT" | "ITEM_WEATHER_GUARD_TOP" | "PALLET_BD_SURFACE" | "PALLET_CP1" | "PALLET_CUSTOM_BD" | "PALLET_EUR" | "PALLET_GMA" | "PALLET_GMA_BLOCK" | "PALLET_HALF" | "PALLET_UNSPECIFIED" | "STRINGER_CANADIAN" | "UNKNOWN" | "%future added value";
export type LabelingIssueStatus = "NEEDS_LABELER_ATTENTION" | "NEEDS_QA_ATTENTION" | "RESOLVED" | "%future added value";
export type LabelingPriority = "HIGH" | "LOW" | "UNSPECIFIED" | "%future added value";
export type LightingConditions = "BACKLIT" | "DARK" | "ILLUMINATED_BY_ROBOT" | "OVERHEAD_FRIENDLY" | "OVERHEAD_NORMAL" | "VERY_DARK" | "%future added value";
export type PalletSurface = "FLOOR" | "FORKLIFT" | "PALLET_EMPTY" | "PALLET_LOADED" | "RACK" | "TRUCK" | "UNKNOWN" | "%future added value";
export type RunSource = "BD_FIELD_TRIP" | "BD_TRIAL_RUN" | "FOX_FIELD_TRIP" | "FOX_OFFICE" | "UNKNOWN" | "%future added value";
export type SensorStreamListItem_sensorStream = {
    readonly id: string;
    readonly labelingDirections: string;
    readonly lightingConditions: LightingConditions;
    readonly sensorName: string;
    readonly numFrames: number;
    readonly frameNumbers: ReadonlyArray<number>;
    readonly labelingPriority: LabelingPriority;
    readonly allowExport: boolean;
    readonly issues: ReadonlyArray<{
        readonly status: LabelingIssueStatus;
        readonly " $fragmentRefs": FragmentRefs<"LabelingIssueListItem_issue">;
    }>;
    readonly run: {
        readonly runName: string;
        readonly source: RunSource;
        readonly createTime: unknown;
        readonly updateTime: unknown;
    };
    readonly labelSummary: {
        readonly labeledFrameCount: number;
        readonly viewerLabeledFrameCount: number;
        readonly labeledPocketCount: number;
        readonly labeledItemCount: number;
        readonly labeledPalletsAll: ReadonlyArray<{
            readonly id: string;
            readonly objectType: KnownObject;
            readonly surfaceType: PalletSurface;
            readonly author: {
                readonly id: string;
                readonly username: string | null;
                readonly shortName: string | null;
            };
            readonly frame: {
                readonly indexInSensorStream: number;
            };
        }>;
        readonly labeledItemsAll: ReadonlyArray<{
            readonly id: string;
            readonly objectType: KnownObject;
            readonly author: {
                readonly id: string;
                readonly username: string | null;
                readonly shortName: string | null;
            };
            readonly frame: {
                readonly indexInSensorStream: number;
            };
        }>;
    };
    readonly " $refType": "SensorStreamListItem_sensorStream";
};
export type SensorStreamListItem_sensorStream$data = SensorStreamListItem_sensorStream;
export type SensorStreamListItem_sensorStream$key = {
    readonly " $data"?: SensorStreamListItem_sensorStream$data;
    readonly " $fragmentRefs": FragmentRefs<"SensorStreamListItem_sensorStream">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "objectType",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelerUser",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shortName",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "frame",
  "storageKey": null,
  "args": null,
  "concreteType": "Frame",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "indexInSensorStream",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "SensorStreamListItem_sensorStream",
  "type": "SensorStream",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "labelingDirections",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lightingConditions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sensorName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numFrames",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "frameNumbers",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "labelingPriority",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "allowExport",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "issues",
      "storageKey": null,
      "args": null,
      "concreteType": "LabelingIssue",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "LabelingIssueListItem_issue",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "run",
      "storageKey": null,
      "args": null,
      "concreteType": "Run",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "runName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "source",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createTime",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "updateTime",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "labelSummary",
      "storageKey": null,
      "args": null,
      "concreteType": "SensorStreamLabelSummary",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "labeledFrameCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "viewerLabeledFrameCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "labeledPocketCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "labeledItemCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeledPalletsAll",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledPallet",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "surfaceType",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "labeledItemsAll",
          "storageKey": null,
          "args": null,
          "concreteType": "LabeledItem",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '00dfa853404e1ec5295cfafe3c683b4e';
export default node;
