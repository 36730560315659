/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_LabelingActivityViewer_QueryVariables = {};
export type routes_LabelingActivityViewer_QueryResponse = {
    readonly viewer: {
        readonly isAnonymous: boolean | null;
        readonly isApprovedForAccess: boolean | null;
        readonly " $fragmentRefs": FragmentRefs<"LabelingActivity_viewer">;
    } | null;
};
export type routes_LabelingActivityViewer_Query = {
    readonly response: routes_LabelingActivityViewer_QueryResponse;
    readonly variables: routes_LabelingActivityViewer_QueryVariables;
};



/*
query routes_LabelingActivityViewer_Query {
  viewer {
    isAnonymous
    isApprovedForAccess
    ...LabelingActivity_viewer
    id
  }
}

fragment LabelingActivityChart_viewer on Viewer {
  labelSummaryTimeBuckets(bucketDuration: DAY, numBuckets: 30) {
    duration
    startTime
    perLabelerSummaries {
      labeler {
        id
        username
      }
      summary {
        numPallets
        numItems
      }
    }
  }
}

fragment LabelingActivityTable_viewer on Viewer {
  labelCounts {
    datasetType
    numLabels
    numLabeledFrames
    numSensorStreams
  }
}

fragment LabelingActivity_viewer on Viewer {
  id
  ...LabelingActivityChart_viewer
  ...LabelingActivityTable_viewer
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAnonymous",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isApprovedForAccess",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routes_LabelingActivityViewer_Query",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "LabelingActivity_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes_LabelingActivityViewer_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labelSummaryTimeBuckets",
            "storageKey": "labelSummaryTimeBuckets(bucketDuration:\"DAY\",numBuckets:30)",
            "args": [
              {
                "kind": "Literal",
                "name": "bucketDuration",
                "value": "DAY"
              },
              {
                "kind": "Literal",
                "name": "numBuckets",
                "value": 30
              }
            ],
            "concreteType": "LabelSummaryTimeBucket",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "duration",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startTime",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "perLabelerSummaries",
                "storageKey": null,
                "args": null,
                "concreteType": "PerLabelerLabelSummary",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "labeler",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabelerUser",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "summary",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabelSummary",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numPallets",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numItems",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "labelCounts",
            "storageKey": null,
            "args": null,
            "concreteType": "LabelCounts",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "datasetType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numLabels",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numLabeledFrames",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numSensorStreams",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routes_LabelingActivityViewer_Query",
    "id": null,
    "text": "query routes_LabelingActivityViewer_Query {\n  viewer {\n    isAnonymous\n    isApprovedForAccess\n    ...LabelingActivity_viewer\n    id\n  }\n}\n\nfragment LabelingActivityChart_viewer on Viewer {\n  labelSummaryTimeBuckets(bucketDuration: DAY, numBuckets: 30) {\n    duration\n    startTime\n    perLabelerSummaries {\n      labeler {\n        id\n        username\n      }\n      summary {\n        numPallets\n        numItems\n      }\n    }\n  }\n}\n\nfragment LabelingActivityTable_viewer on Viewer {\n  labelCounts {\n    datasetType\n    numLabels\n    numLabeledFrames\n    numSensorStreams\n  }\n}\n\nfragment LabelingActivity_viewer on Viewer {\n  id\n  ...LabelingActivityChart_viewer\n  ...LabelingActivityTable_viewer\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '1c570053c9c5b638e0a682d3c0a406f8';
export default node;
