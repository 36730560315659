import React from "react";
import { graphql, createRefetchContainer } from "react-relay";
import moment from "moment";
import { Disposable, RelayRefetchProp } from "react-relay";
import {
  createStyles,
  withStyles,
  WithTheme,
  WithStyles
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LoadingScreen from "../common/LoadingScreen";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import {
  ResponsiveContainer,
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

import {
  LabelingActivityChart_viewer,
  BucketDuration
} from "../../__generated__/LabelingActivityChart_viewer.graphql";
import { KnownObject } from "../../__generated__/LabelingActivityChartRefetchQuery.graphql";
import { KNOWN_OBJECT_ENABLED } from "../../utils/Enums";
import { combineStyles, commonStyles } from "../../utils/CommonStyles";
import DebouncedSelect from "../common/DebouncedSelect";

const localStyles = theme =>
  createStyles({
    formControlSmall: {
      margin: theme.spacing.unit,
      textTransform: "capitalize"
    },
    formControlNoLabel: {
      margin: theme.spacing.unit,
      marginTop: 24,
      textTransform: "capitalize"
    },
    dropDownMenuItem: {
      textTransform: "capitalize"
    },
    selectFormControl: {
      margin: theme.spacing.unit,
      minWidth: 180,
      maxWidth: 300,
      textTransform: "capitalize"
    },
    numBucketsInput: {
      margin: 4,
      width: 40
    },
    appBar: {},
    wrapper: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      margin: 8,
      padding: 0
    },
    chartWrapper: {
      margin: 0,
      padding: 0,
      height: 400,
      width: "100%",
      overflow: "visible"
    }
  });
const styles = combineStyles(localStyles, commonStyles);

interface Props extends WithStyles<typeof styles>, WithTheme {
  viewer: LabelingActivityChart_viewer;
  relay: RelayRefetchProp;
}
type State = {
  pendingRefetch: Disposable | null;
  bucketDuration: BucketDuration;
  startTime: string | null;
  numBuckets: number;
  knownObjects: Array<KnownObject> | null;
};

const DEFAULT_BUCKET_DURATION: BucketDuration = "DAY";
const DEFAULT_NUM_BUCKETS: number = 30;

class LabelingActivityChart extends React.Component<Props, State> {
  state = {
    pendingRefetch: null,
    bucketDuration: DEFAULT_BUCKET_DURATION,
    startTime: null,
    numBuckets: DEFAULT_NUM_BUCKETS,
    knownObjects: null
  };
  render() {
    const { viewer, classes, theme } = this.props;
    const { bucketDuration, numBuckets, pendingRefetch, knownObjects } =
      this.state;
    const { labelSummaryTimeBuckets } = viewer;
    if (!labelSummaryTimeBuckets) {
      return null;
    }
    const labelers = new Set();
    const chartKeys: Set<string> = new Set();
    labelSummaryTimeBuckets.forEach(dailySummary => {
      dailySummary.perLabelerSummaries.forEach(labelerSummary => {
        const { username } = labelerSummary.labeler;
        labelers.add(username);
        chartKeys.add(username + " pallets");
        chartKeys.add(username + " items");
      });
    });

    const numColors = labelers.size + 1;
    const hslStride = 360 / numColors;
    const labelerColors: Map<string, string> = new Map();
    let hslValue = 0;
    for (const labeler of labelers) {
      hslValue += hslStride;
      labelerColors.set(labeler, "hsl(" + hslValue.toString() + ", 50%, 70%)");
    }

    const barChartData = [];
    const lineChartData = [];
    let numPallets = 0;
    let numItems = 0;
    for (const dailySummary of labelSummaryTimeBuckets) {
      const formattedDate = this._formatTickText(
        dailySummary.startTime as string,
        dailySummary.duration
      );
      let flattened = {
        name: formattedDate
      };
      for (const perLabelerSummary of dailySummary.perLabelerSummaries) {
        const { username } = perLabelerSummary.labeler;
        const palletsKey = username + " pallets";
        const itemsKey = username + " items";
        const { summary } = perLabelerSummary;
        flattened[palletsKey] = summary.numPallets;
        flattened[itemsKey] = summary.numItems;
        numPallets += summary.numPallets;
        numItems += summary.numItems;
      }
      barChartData.push(flattened);
      lineChartData.push({
        name: formattedDate,
        Pallets: numPallets,
        Items: numItems
      });
    }
    return (
      <div className={classes.wrapper}>
        <AppBar color="default" position="static" className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarRoot }}>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Activity Charts
            </Typography>
            <FormControl className={classes.selectFormControl}>
              <InputLabel htmlFor="select-known-objects">Objects</InputLabel>

              <DebouncedSelect
                className={classes.dropDown}
                value={knownObjects || []}
                itemMap={KNOWN_OBJECT_ENABLED}
                onChangeDebounced={e => {
                  this.setState(
                    {
                      knownObjects: !e.target.value.length
                        ? null
                        : e.target.value
                    },
                    () => this._refetch()
                  );
                }}
              />
            </FormControl>
            <FormControl className={classes.formControlNoLabel}>
              <Input
                className={classes.numBucketsInput}
                type="number"
                inputProps={{ min: 1, max: 100 }}
                value={numBuckets}
                onChange={e => {
                  this.setState({ numBuckets: parseInt(e.target.value) }, () =>
                    this._refetch()
                  );
                }}
              />
            </FormControl>
            <FormControl className={classes.formControlNoLabel}>
              <Select
                value={bucketDuration}
                onChange={e => {
                  this.setState(
                    {
                      bucketDuration: e.target.value as BucketDuration
                    },
                    () => this._refetch()
                  );
                }}
                input={<Input id="select-bucket-duration" />}
              >
                {["DAY", "HOUR", "MINUTE", "WEEK", "MONTH"].map(
                  (bd: BucketDuration) => (
                    <MenuItem
                      key={bd}
                      value={bd}
                      className={classes.dropDownMenuItem}
                      style={{
                        fontWeight:
                          bd === bucketDuration
                            ? theme.typography.fontWeightRegular
                            : theme.typography.fontWeightMedium
                      }}
                    >
                      {bd.toLowerCase() + "s"}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl
              className={classes.formControlSmall}
              aria-describedby="activity-start-time-helper-text"
            >
              <FormHelperText id="activity-start-time-helper-text">
                starting
              </FormHelperText>
              <Input
                id="activity-start-time"
                type="datetime-local"
                onChange={e => {
                  // Pass the time string through moment to add the local timezone
                  const startTime = e.target.value
                    ? moment(e.target.value).format()
                    : null;
                  this.setState({ startTime }, () => this._refetch());
                }}
              />
            </FormControl>
          </Toolbar>
        </AppBar>
        {pendingRefetch && <LoadingScreen />}
        {!pendingRefetch && (
          <React.Fragment>
            <div className={classes.chartWrapper}>
              <ResponsiveContainer>
                <BarChart
                  data={barChartData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />

                  <Line type="monotone" dataKey="numPallets" stroke="#82ca9d" />
                  {Array.from(chartKeys).map(key => (
                    <Bar
                      dataKey={key}
                      key={key}
                      stackId={key.split(" ")[1]}
                      fill={labelerColors.get(key.split(" ")[0])}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className={classes.chartWrapper}>
              <ResponsiveContainer>
                <LineChart
                  width={600}
                  height={300}
                  data={lineChartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Pallets" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  _formatTickText = (
    rawText: string,
    bucketDuration: BucketDuration
  ): string => {
    const time = moment.utc(rawText);
    switch (bucketDuration) {
      case "DAY":
        return time.format("M/D");
      case "HOUR":
        return time.format("M/D H:00");
      case "MINUTE":
        return time.format("M/D H:mm");
      case "WEEK":
        return time.format("M/D");
      case "MONTH":
        return time.format("MMM YY");
      default:
        return rawText;
    }
  };
  
  _refetch = () => {
    const {
      bucketDuration,
      pendingRefetch,
      numBuckets,
      knownObjects,
      startTime
    } = this.state;
    if (pendingRefetch) {
      pendingRefetch.dispose();
    }
    if (numBuckets < 2) {
      return;
    }
    const refetchVariables = fragmentVariables => {
      return {
        bucketDuration,
        numBuckets,
        knownObjects,
        startTime
      };
    };
    this.setState({
      pendingRefetch: this.props.relay.refetch(
        refetchVariables,
        null, // Use the refetchVariables as renderVariables
        () => this.setState({ pendingRefetch: null }),
        { force: true } // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
      )
    });
  };
}

export default withStyles(styles, { withTheme: true })(
  createRefetchContainer(
    LabelingActivityChart,
    {
      viewer: graphql`
        fragment LabelingActivityChart_viewer on Viewer
        @argumentDefinitions(
          bucketDuration: { type: BucketDuration, defaultValue: DAY }
          knownObjects: { type: "[KnownObject!]", defaultValue: null }
          numBuckets: { type: Int, defaultValue: 30 }
          startTime: { type: DateTime, defaultValue: null }
        ) {
          labelSummaryTimeBuckets(
            bucketDuration: $bucketDuration
            numBuckets: $numBuckets
            knownObjects: $knownObjects
            startTime: $startTime
          ) {
            duration
            startTime
            perLabelerSummaries {
              labeler {
                id
                username
              }
              summary {
                numPallets
                numItems
              }
            }
          }
        }
      `
    },
    graphql`
      query LabelingActivityChartRefetchQuery(
        $bucketDuration: BucketDuration
        $knownObjects: [KnownObject!]
        $numBuckets: Int
        $startTime: DateTime
      ) {
        viewer {
          labelSummaryTimeBuckets(
            bucketDuration: $bucketDuration
            numBuckets: $numBuckets
            knownObjects: $knownObjects
            startTime: $startTime
          ) {
            duration
            startTime
            perLabelerSummaries {
              labeler {
                id
                username
              }
              summary {
                numPallets
                numItems
              }
            }
          }
        }
      }
    `
  )
);
