import { commitMutation, graphql } from "react-relay";
import { Environment } from "react-relay";
import { PayloadError } from "relay-runtime";

import { UpdateLabeledBoxMutationInput } from "../__generated__/UpdateLabeledBoxMutation.graphql";

const mutation = graphql`
  mutation UpdateLabeledBoxMutation($input: UpdateLabeledBoxMutationInput!) {
    updateLabeledBox(input: $input) {
      labeledBox {
        id
        number
        objectType
        frame {
          id
          labeledPallets {
            id
            boxes {
              id
            }
          }
        }
        pallet {
          id
          boxes {
            id
          }
        }
        shape {
          id
          faces {
            id
            side
            corners {
              id
              x
              y
              visibility
            }
          }
        }
      }
    }
  }
`;

function updateLabeledBox(
  environment: Environment,
  input: UpdateLabeledBoxMutationInput,
  onCompleted?: (
    response: Object | null,
    errors: Array<PayloadError> | null
  ) => void,
  onError?: (error: Error) => void
) {
  commitMutation(environment, {
    mutation,
    variables: { input },
    configs: [],
    onCompleted,
    onError,
    optimisticUpdater: store => {
      let existingBox = store.get(input.id);
      if (!existingBox) {
        return;
      }
      const existingPallet = existingBox.getLinkedRecord("pallet");
      const existingPalletId = existingPallet
        ? existingPallet.getValue("id")
        : null;
      const removeBoxFromCurrentPallet =
        existingPallet &&
        (!input.pallet ||
          !input.pallet.id ||
          input.pallet.id !== existingPalletId);
      if (removeBoxFromCurrentPallet) {
        const existingPalletBoxes = existingPallet
          ? existingPallet.getLinkedRecords("boxes") || []
          : [];
        let newPalletBoxes = existingPalletBoxes
          .concat()
          .filter(b => b && b.getValue("id") !== input.id);
        existingPallet.setLinkedRecords(newPalletBoxes, "boxes");
        existingBox.setValue(null, "pallet");
      }

      const addBoxToNewPallet =
        input.pallet && input.pallet.id && input.pallet.id !== existingPalletId;
      if (addBoxToNewPallet) {
        const newPallet = store.get(input.pallet.id);
        if (newPallet) {
          existingBox.setLinkedRecord(newPallet, "pallet");
          const existingPalletBoxes = newPallet.getLinkedRecords("boxes") || [];
          newPallet.setLinkedRecords(
            [...existingPalletBoxes, existingBox],
            "boxes"
          );
        }
      }
    }
  });
}

export default updateLabeledBox;
