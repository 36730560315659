/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelingIssueStatus = "NEEDS_LABELER_ATTENTION" | "NEEDS_QA_ATTENTION" | "RESOLVED" | "%future added value";
export type ListIssuesForViewerRefetchQueryVariables = {
    statuses?: ReadonlyArray<LabelingIssueStatus> | null;
    onlyForViewer?: boolean | null;
    countIssuesForViewer: number;
    after?: string | null;
};
export type ListIssuesForViewerRefetchQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly issuesForViewer: {
            readonly pageInfo: {
                readonly hasNextPage: boolean;
                readonly endCursor: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly description: string;
                    readonly frame: {
                        readonly id: string;
                    } | null;
                    readonly " $fragmentRefs": FragmentRefs<"LabelingIssueListItem_issue">;
                } | null;
            } | null>;
        };
    } | null;
};
export type ListIssuesForViewerRefetchQuery = {
    readonly response: ListIssuesForViewerRefetchQueryResponse;
    readonly variables: ListIssuesForViewerRefetchQueryVariables;
};



/*
query ListIssuesForViewerRefetchQuery(
  $statuses: [LabelingIssueStatus!]
  $onlyForViewer: Boolean
  $countIssuesForViewer: Int!
  $after: String
) {
  viewer {
    id
    issuesForViewer: issues(statuses: $statuses, onlyForViewer: $onlyForViewer, first: $countIssuesForViewer, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...LabelingIssueListItem_issue
          description
          frame {
            id
          }
          __typename
        }
        cursor
      }
    }
  }
}

fragment LabelingIssueListItem_issue on LabelingIssue {
  id
  description
  category
  status
  reporter {
    username
    id
  }
  labeler {
    username
    id
  }
  pallets {
    number
    id
  }
  boxes {
    number
    id
  }
  sensorStream {
    sensorName
    run {
      runName
      id
    }
    id
  }
  frame {
    indexInSensorStream
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "statuses",
    "type": "[LabelingIssueStatus!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "onlyForViewer",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "countIssuesForViewer",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "countIssuesForViewer"
  },
  {
    "kind": "Variable",
    "name": "onlyForViewer",
    "variableName": "onlyForViewer"
  },
  {
    "kind": "Variable",
    "name": "statuses",
    "variableName": "statuses"
  }
],
v7 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "username",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/)
],
v8 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "number",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ListIssuesForViewerRefetchQuery",
    "type": "RootQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "issuesForViewer",
            "name": "__ListIssuesForViewer_issuesForViewer_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "LabelingIssueConnection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LabelingIssueEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabelingIssue",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "frame",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Frame",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      },
                      (v4/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "LabelingIssueListItem_issue",
                        "args": null
                      }
                    ]
                  },
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ListIssuesForViewerRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "issuesForViewer",
            "name": "issues",
            "storageKey": null,
            "args": (v6/*: any*/),
            "concreteType": "LabelingIssueConnection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LabelingIssueEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LabelingIssue",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "category",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "reporter",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LabelerUser",
                        "plural": false,
                        "selections": (v7/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "labeler",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LabelerUser",
                        "plural": false,
                        "selections": (v7/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "pallets",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LabeledPallet",
                        "plural": true,
                        "selections": (v8/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "boxes",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LabeledBox",
                        "plural": true,
                        "selections": (v8/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "sensorStream",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SensorStream",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sensorName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "run",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Run",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "runName",
                                "args": null,
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ]
                          },
                          (v1/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "frame",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Frame",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "indexInSensorStream",
                            "args": null,
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  },
                  (v5/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": "issuesForViewer",
            "name": "issues",
            "args": (v6/*: any*/),
            "handle": "connection",
            "key": "ListIssuesForViewer_issuesForViewer",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ListIssuesForViewerRefetchQuery",
    "id": null,
    "text": "query ListIssuesForViewerRefetchQuery(\n  $statuses: [LabelingIssueStatus!]\n  $onlyForViewer: Boolean\n  $countIssuesForViewer: Int!\n  $after: String\n) {\n  viewer {\n    id\n    issuesForViewer: issues(statuses: $statuses, onlyForViewer: $onlyForViewer, first: $countIssuesForViewer, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...LabelingIssueListItem_issue\n          description\n          frame {\n            id\n          }\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n\nfragment LabelingIssueListItem_issue on LabelingIssue {\n  id\n  description\n  category\n  status\n  reporter {\n    username\n    id\n  }\n  labeler {\n    username\n    id\n  }\n  pallets {\n    number\n    id\n  }\n  boxes {\n    number\n    id\n  }\n  sensorStream {\n    sensorName\n    run {\n      runName\n      id\n    }\n    id\n  }\n  frame {\n    indexInSensorStream\n    id\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": "countIssuesForViewer",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "viewer",
            "issuesForViewer"
          ]
        }
      ]
    }
  }
};
})();
(node as any).hash = '7abd524dcbb339e1106fec80c5b724c3';
export default node;
